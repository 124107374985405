import { copiarObjeto, formatarMonetario, mensagensDeValidacao, renderizarValidacao, services } from 'Common';
import {
	AutoProgressBar,
	ButtonCancelar,
	ButtonSalvar,
	Col,
	estadosBotaoCancelar,
	estadosBotaoSalvar,
	Fieldset,
	Form,
	FormActions,
	FormContent,
	Grid,
	InputDate,
	InputMoney,
	InputSelectPercentualOrValor,
	Modal,
	SingleSelectConta,
	SingleSelectFormaPagamento,
	TextArea,
	tipos,
} from 'components';
import { formatISO, isValid, parseISO } from 'date-fns';
import { Field, useFormikContext, withFormik } from 'formik';
import { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import * as Yup from 'yup';
import { aplicarPercentual, validarFormulario } from '../../../../Util';
import {
	asyncGetContaPagar,
	asyncUpdateContaPagar,
	buscarContaFavoritaDespesa,
	buscarFormaPagamentoDinheiro,
} from '../../Requests';
import {
	converterContaPagarParaApi,
	converterContaPagarParaFormulario,
} from '../ModalContaPagar/Util/contaPagarConverter';
import { helpMessage } from './Util/constantes';

const initialValue = {
	id: null,
	idTemporario: null,
	data: formatISO(new Date()),
	desconto: 0,
	juros: 0,
	multa: 0,
	formaPagamento: null,
	conta: null,
	valor: null,
	valorAPagar: null,
	observacao: null,
	pagamentoNovoOuAlterado: true,
};

const parteStyle = {
	fontSize: '13px',
	color: '#000000',
	margin: '0px 0px',
};

const totalStyle = {
	fontSize: '20px',
	color: '#000000',
	margin: '10px 0px 0px 0px',
};

function ModalPagamentoTableView({ registroSelecionado, visible, onHide, ...props }) {
	const { values, resetForm, setFieldValue, errors, initialValues, handleSubmit } = useFormikContext();

	const [registroCompleto, setRegistroCompleto] = useState(null);
	const [tipoDesconto, setTipoDesconto] = useState(tipos.VALOR);
	const [percentualDesconto, setPercentualDesconto] = useState(0);
	const [tipoJuros, setTipoJuros] = useState(tipos.VALOR);
	const [percentualJuros, setPercentualJuros] = useState(0);
	const [tipoMulta, setTipoMulta] = useState(tipos.VALOR);
	const [percentualMulta, setPercentualMulta] = useState(0);

	useEffect(() => {
		setTimeout(() => {
			document.getElementById('valorPagamento')?.focus();
		}, 500);
		buscarContaFavoritaDespesa(({ data }) => {
			if (data.page.totalElements > 0) {
				resetForm({
					values: {
						...initialValues,
						conta: {
							label: data.content[0].nome,
							value: data.content[0].id,
							registro: data.content[0],
						},
					},
				});
			}
		});

		buscarFormaPagamentoDinheiro(({ data }) => {
			if (data.page.totalElements > 0) {
				resetForm({
					values: {
						...initialValues,
						formaPagamento: {
							label: data.content[0].descricao,
							value: data.content[0].id,
							registro: data.content[0],
						},
					},
				});
			}
		});

		asyncGetContaPagar(
			registroSelecionado.id,
			({ data }) => {
				const registroPagar = converterContaPagarParaFormulario(data);
				setRegistroCompleto(registroPagar);
				if (registroPagar.conta) {
					setFieldValue('conta', registroPagar.conta);
				}
				if (registroPagar.formaPagamento) {
					setFieldValue('formaPagamento', registroPagar.formaPagamento);
				}
			},
			null,
			false
		);
	}, []);

	function onChangeValor(e) {
		const valor = e.target.value;
		setFieldValue('valor', valor);

		// CALCULAR  PERCENTUAIS
		if (tipoDesconto === tipos.PERCENTUAL) {
			setFieldValue('desconto', aplicarPercentual(valor, percentualDesconto));
		}

		if (tipoJuros === tipos.PERCENTUAL) {
			setFieldValue('juros', aplicarPercentual(valor, percentualJuros));
		}

		if (tipoMulta === tipos.PERCENTUAL) {
			setFieldValue('multa', aplicarPercentual(valor, percentualMulta));
		}
	}

	async function salvar() {
		handleSubmit();

		if (await validarFormulario(props)) {
			if (values && isValid) {
				const valoresParaSalvar = copiarObjeto(registroCompleto);
				valoresParaSalvar.pagamentos.push({ ...values, pagamentoNovoOuAlterado: true });
				asyncUpdateContaPagar(converterContaPagarParaApi(valoresParaSalvar), () => {
					onHide(true);
				});
			}
		}
	}

	const total = values.valor + values.juros + values.multa - values.desconto;

	return (
		<Modal header="Confirmar pagamento" visible={visible} onHide={onHide}>
			<AutoProgressBar />
			<Form>
				<FormActions>
					<ButtonCancelar estadoBotao={estadosBotaoCancelar.VOLTAR} onClick={() => onHide()} />
					<ButtonSalvar estadoBotao={estadosBotaoSalvar.SALVAR} onClick={salvar} />
				</FormActions>
				<FormContent>
					<Grid>
						<Field
							sm="12"
							md="6"
							lg="4"
							xl="4"
							id="valorPagamento"
							component={InputMoney}
							label="Valor"
							name="valor"
							obrigatorio
							value={values.valor}
							helpMessage={helpMessage.valor}
							onChange={(e) => onChangeValor(e)}
							size={15}
							allowNegative={false}
						/>
						<Field
							sm="12"
							md="6"
							lg="4"
							xl="4"
							component={InputDate}
							label="Data do pagamento"
							name="data"
							obrigatorio
							onChange={(e) => setFieldValue('data', e.target.value)}
							value={values.data}
						/>
						<Field
							sm="12"
							md="6"
							lg="4"
							xl="4"
							name="formaPagamento"
							label="Forma de pagamento"
							obrigatorio
							component={SingleSelectFormaPagamento}
							value={values.formaPagamento}
							helpMessage={helpMessage.formaPagamento}
							onChange={(e) => setFieldValue('formaPagamento', e)}
							url={`${services.GESTOR}/v1/contas_pagar/relacoes/formas_pagamento`}
						/>
						<Field
							sm="12"
							md="6"
							lg="4"
							xl="4"
							name="conta"
							label="Conta"
							obrigatorio
							component={SingleSelectConta}
							value={values.conta}
							helpMessage={helpMessage.conta}
							onChange={(e) => setFieldValue('conta', e)}
							url={`${services.GESTOR}/v1/contas_pagar/relacoes/contas`}
						/>
						<Fieldset legend="Informações complementares" style={{ width: '100%' }} className="fieldset">
							<Grid col="12">
								<Col sm="12" md="6" lg="4" xl="4">
									<Field
										component={InputSelectPercentualOrValor}
										label="Descontos"
										name="desconto"
										value={values.desconto}
										valueBase={values.valor}
										errors={errors.desconto}
										helpMessage={helpMessage.desconto}
										onChange={(tipo, value, percentual) => {
											setTipoDesconto(tipo);
											setPercentualDesconto(percentual);
											setFieldValue('desconto', value);
										}}
										size={15}
									/>
								</Col>
								<Col sm="12" md="6" lg="4" xl="4">
									<Field
										component={InputSelectPercentualOrValor}
										label="Juros"
										name="juros"
										value={values.juros}
										valueBase={values.valor}
										helpMessage={helpMessage.juros}
										onChange={(tipo, value, percentual) => {
											setTipoJuros(tipo);
											setPercentualJuros(percentual);
											setFieldValue('juros', value);
										}}
										size={15}
									/>
								</Col>
								<Col sm="12" md="6" lg="4" xl="4">
									<Field
										component={InputSelectPercentualOrValor}
										label="Multa"
										name="multa"
										value={values.multa}
										valueBase={values.valor}
										helpMessage={helpMessage.multa}
										onChange={(tipo, value, percentual) => {
											setTipoMulta(tipo);
											setPercentualMulta(percentual);
											setFieldValue('multa', value);
										}}
										size={15}
									/>
								</Col>
								<Field
									sm="12"
									component={TextArea}
									placeholder="Escreva sua observação aqui"
									label="Observação"
									name="observacao"
									helpMessage={helpMessage.observacao}
									maxLength={4096}
									onChange={(e) => setFieldValue('observacao', e.target.value)}
								/>
							</Grid>
						</Fieldset>
						<Col>
							<p style={parteStyle}>{`Descontos: ${formatarMonetario(values.desconto)}`}</p>
							<p style={parteStyle}>{`Juros: ${formatarMonetario(values.juros)}`}</p>
							<p style={parteStyle}>{`Multa: ${formatarMonetario(values.multa)}`}</p>
							<p style={totalStyle}>{`Total: ${formatarMonetario(total)}`}</p>
							{errors.total && renderizarValidacao(errors.total, true)}
						</Col>
					</Grid>
				</FormContent>
			</Form>
		</Modal>
	);
}

const ModalPagamentoTable = withFormik({
	validateOnChange: true,
	validateOnBlur: false,

	mapPropsToValues(props) {
		if (props.registroSelecionado) {
			return {
				...initialValue,
				valor: props.registroSelecionado.valorAPagar,
				valorAPagar: props.registroSelecionado.valorAPagar,
			};
		} else {
			return { ...initialValue };
		}
	},

	validate(values) {
		const errors = {};
		const valorTotal = values.valor + values.juros + values.multa - values.desconto;

		if (values.valor > values.valorAPagar) {
			errors.valor = 'O valor a pagar não deve ser maior que o valor total da conta';
		}

		if (values.valor <= 0) {
			errors.valor = 'O valor deve ser maior que zero';
		}

		if (valorTotal < 0) {
			errors.desconto = 'O desconto não pode exceder o valor total';
		}

		if (values.data && !isValid(parseISO(values.data))) {
			errors.data = mensagensDeValidacao.DATA_INVALIDA;
		}

		return errors;
	},

	validationSchema: Yup.object().shape({
		data: Yup.string().nullable().required(mensagensDeValidacao.DATA_INVALIDA),
		valor: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		conta: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		formaPagamento: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
	}),
	handleSubmit: () => {},
})(ModalPagamentoTableView);

export default withRouter(ModalPagamentoTable);
