function converterPastasParaForm(pastas) {
	if (pastas?.length > 0) {
		return pastas.map((pasta) => ({
			id: pasta.id,
			descricao: pasta.descricao,
			nivel: pasta.nivel,
			codigoNivel: pasta.codigoNivel,
			isPasta: true,
			filhos: pasta.filhos,
			vinculados: pasta.vinculados,
			pastaPai: pasta.pastaPai,
		}));
	}

	return [];
}

async function converterDocumentosParaForm(documentos) {
	if (documentos?.length > 0) {
		const resultado = await Promise.all(
			documentos.map(async (documento) => ({
				id: documento.id,
				identificacao: documento.identificacao,
				vigenciaInicio: documento.vigenciaInicio,
				vigenciaFinal: documento.vigenciaFinal,
				documentoValor: documento.documentoValor,
				documentoValorMensal: documento.documentoValorMensal,
				status: documento.status,
				arquivado: documento.arquivado,
				isDocumento: true,
			}))
		);
		return resultado;
	}

	return [];
}

export { converterPastasParaForm, converterDocumentosParaForm };
