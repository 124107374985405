import { validarUUID } from 'Common';

function getSelectedDocumentIds(registros) {
	const selectedIds = [];

	Object.keys(registros).forEach((key) => {
		if (validarUUID(key)) {
			selectedIds.push(key);
		}
	});

	return selectedIds;
}

export { getSelectedDocumentIds };
