import { isBefore, isEqual, isWithinInterval, parseISO } from 'date-fns';
import { StatusDocumentoParaSelect } from '../../../../../components/select/SingleSelectAssinatura/Util/constantes';
import { montarLabelPessoa } from '../../../../../components/select/SingleSelectPessoa/Util';
import { statusGestaoDocumento, tipoGestaoDocumento } from '../../Util/constantes';

//--------------- Para Formulario ---------------//
function converterGestaoDocumentoParaFormulario(documento) {
	return {
		id: documento.id,
		identificacao: documento.identificacao,
		status: validarStatusParaFormulario(documento.status),
		tipoGestaoDocumento: documento.tipoGestaoDocumento,
		arquivado: documento.arquivado,
		documentoValor: documento.documentoValor ?? 0,
		documentoValorMensal: documento.documentoValorMensal ?? 0,
		partes: converterPartesParaFormulario(documento.partes),
		produtos: converterProdutosParaFormulario(documento.produtos),
		arquivos: converterArquivosParaFormulario(documento.arquivos),
		arquivoDocumento: converterArquivoDocumentoParaFormulario(documento.arquivoDocumento),
		anexos: converterAnexosParaListagem(documento.anexos, documento.tipoGestaoDocumento),
		vigenciaInicio: documento.vigenciaInicio,
		vigenciaFinal: documento.vigenciaFinal,
		usuario: converterUsuarioParaFormulario(documento?.responsavel.usuario),
		setor: converterSetorParaFormulario(documento?.responsavel.setor),
		diasAlertaVigencia: documento.responsavel.diasAlertaExpiracao,
		observacao: documento.observacao,
		pasta: documento?.responsavel.pasta ? documento?.responsavel.pasta.id : null,
	};
}

function converterSetorParaFormulario(setor) {
	if (setor) {
		return {
			label: setor?.nome,
			value: setor?.id,
			registro: setor,
		};
	}
}

function converterUsuarioParaFormulario(usuario) {
	if (usuario) {
		return {
			label: usuario?.nome,
			value: usuario?.id,
			registro: usuario,
		};
	}
}

function validarStatusParaFormulario(status) {
	if (!status) {
		return statusGestaoDocumento.PENDENTE;
	} else {
		return status;
	}
}

function converterPartesParaFormulario(partes) {
	if (partes) {
		return partes
			.map((parte) => {
				return {
					label: parte ? montarLabelPessoa(parte) : '',
					value: parte?.id,
					registro: {
						id: parte?.id,
						nome: parte?.nome,
						cpf: parte?.cpf ? parte?.cpf : null,
						cnpj: parte?.cnpj ? parte?.cnpj : null,
						identificacao: parte?.identificacao ? parte?.identificacao : null,
					},
				};
			})
			.sort((a, b) => {
				return a.registro?.codigo - b.registro?.codigo;
			});
	}
	return [];
}

function converterArquivoDocumentoParaFormulario(documento) {
	if (documento) {
		return {
			label: `${documento?.identificacao} - ${StatusDocumentoParaSelect[documento?.status]}`,
			value: documento?.id,
			registro: {
				id: documento.id,
				identificacao: documento?.identificacao,
				status: documento.status,
				dataCriacao: documento.dataCriacao,
			},
		};
	}
}

function converterProdutosParaFormulario(produtos) {
	if (produtos) {
		return produtos
			.map((produtos) => {
				return {
					label: `${produtos?.codigo} - ${produtos?.nome}`,
					value: produtos?.id,
					registro: {
						id: produtos?.id,
						codigo: produtos?.codigo,
						nome: produtos?.nome,
						sku: produtos?.sku,
					},
				};
			})
			.sort((a, b) => {
				return a.registro?.codigo - b.registro?.codigo;
			});
	}
	return [];
}

function converterStatusAnexoParaFormulario(anexo, tipoGestaoDocumentoAnexo) {
	if (tipoGestaoDocumentoAnexo === 'DOCUMENTO') {
		const diaAtul = new Date();
		if (isBefore(parseISO(anexo.vigenciaFinal), diaAtul)) {
			return 'VIGENCIA_EXPIRADA';
		} else if (
			isEqual(parseISO(anexo.vigenciaInicio), new Date()) ||
			isBefore(parseISO(anexo.vigenciaInicio), new Date())
		) {
			return 'VIGENTE';
		} else {
			return 'PENDENTE';
		}
	}

	return anexo.arquivoDocumento?.status ?? 'SEM DOCUMENTO';
}

function converterArquivosParaFormulario(arquivosApi) {
	const arquivos = [];
	if (arquivosApi) {
		arquivosApi?.forEach((arquivo) => {
			arquivos.push({ id: arquivo.id, name: arquivo.nome, size: arquivo.tamanho, type: arquivo.tipo });
		});
	}
	return arquivos;
}

function converterAnexosParaListagem(anexos, tipoGestaoDocumento) {
	if (anexos) {
		return anexos.map((anexo) => {
			return {
				//Listagem

				id: anexo.id,
				identificacao: anexo.identificacao,
				vigenciaInicio: anexo.vigenciaInicio,
				vigenciaFinal: anexo.vigenciaFinal,
				anexoValor: anexo.anexoValor ?? 0,
				status: converterStatusAnexoParaFormulario(anexo, tipoGestaoDocumento),

				//Fomulario
				anexoValorMensal: anexo.anexoValorMensal ?? 0,
				arquivoDocumento: anexo.arquivoDocumento,
				criadoEm: anexo.criadoEm,
				partes: anexo.partes,
				produtos: anexo.produtos,
			};
		});
	}
	return [];
}

function converterAnexosParaFormulario(anexos, tipoGestaoDocumentoAnexo) {
	let anexoConvertido = {
		id: anexos.id,
		tipoGestaoDocumento: anexos.tipoGestaoDocumento,
		identificacao: anexos.identificacao,
		vigenciaInicio: anexos.vigenciaInicio,
		vigenciaFinal: anexos.vigenciaFinal,
		anexoValor: anexos.anexoValor ?? 0,
		status: converterStatusAnexoParaFormulario(anexos, tipoGestaoDocumentoAnexo),

		//Fomulario
		anexoValorMensal: anexos.anexoValorMensal ?? 0,
		arquivos: converterArquivosParaFormulario(anexos.arquivos),
		criadoEm: anexos.criadoEm,
		partes: converterPartesParaFormulario(anexos.partes),
		produtos: converterProdutosParaFormulario(anexos.produtos),
		arquivoDocumento: converterArquivoDocumentoParaFormulario(anexos.arquivoDocumento),
	};
	return anexoConvertido;
}

//--------------- Para API //---------------//
function converterGestaoDocumentoParaApi(documento) {
	let documentoConvertido = {
		id: documento.id,
		identificacao: documento.identificacao,
		arquivado: documento.arquivado,
		status: converterStatusParaApi(documento),
		tipoGestaoDocumento: documento.tipoGestaoDocumento,
		documentoValor: documento.documentoValor ?? 0,
		documentoValorMensal: documento.documentoValorMensal ?? 0,
		partes: converterPartesParaApi(documento.partes),
		produtos: converterProdutosParaApi(documento.produtos),
		arquivoDocumento: converterArquivoDocumentoParaApi(documento.arquivoDocumento),
		vigenciaInicio: documento.vigenciaInicio,
		vigenciaFinal: documento.vigenciaFinal,
		responsavel: converterResponsavelParaApi(documento),
		observacao: documento.observacao,
	};
	return documentoConvertido;
}

function converterResponsavelParaApi(documento) {
	return {
		pasta: documento.pasta ? { id: documento.pasta } : null,
		usuario: { id: documento.usuario.value },
		setor: { id: documento.setor.value },
		diasAlertaExpiracao: documento.diasAlertaVigencia,
	};
}

function converterStatusParaApi(documento) {
	if (documento.tipoGestaoDocumento === tipoGestaoDocumento.CONTRATO) {
		return validarStatusParaApi(documento.status);
	} else {
		if (documento.status === statusGestaoDocumento.PENDENTE) {
			if (documento.vigenciaFinal && isBefore(parseISO(documento.vigenciaFinal), new Date())) {
				return statusGestaoDocumento.VIGENCIA_EXPIRADA;
			} else if (
				isEqual(parseISO(documento.vigenciaInicio), new Date()) ||
				isBefore(parseISO(documento.vigenciaInicio), new Date())
			) {
				return statusGestaoDocumento.VIGENTE;
			}
		}
		return documento.status;
	}
}

function validarStatusParaApi(status) {
	if (!status) {
		return statusGestaoDocumento.PENDENTE;
	} else {
		return status;
	}
}

function converterPartesParaApi(partes) {
	if (partes) {
		return partes.map((parte) => {
			return {
				id: parte.registro?.id,
				nome: parte.registro?.nome,
				cpf: parte.registro?.cpf ? parte.registro?.cpf : null,
				cnpj: parte.registro?.cnpj ? parte.registro?.cnpj : null,
				identificacao: parte.registro?.identificacao ? parte.registro?.identificacao : null,
				value: parte.registro?.id,
			};
		});
	}
	return [];
}

function converterArquivoDocumentoParaApi(documento) {
	if (documento) {
		return {
			id: documento.registro?.id,
			value: documento.registro?.id,
		};
	} else {
		return null;
	}
}

function converterProdutosParaApi(produtos) {
	if (produtos) {
		return produtos.map((produtos) => {
			return {
				id: produtos.registro?.id,
				nome: produtos.registro?.nome,
				sku: produtos.registro?.sku,
				value: produtos.registro?.id,
			};
		});
	}
	return [];
}

function converterAnexosParaApiDocumento(anexos) {
	if (anexos) {
		return anexos.map((anexos) => {
			return {
				id: anexos.registro?.id,
			};
		});
	}
	return [];
}

function converterAnexosParaApi(anexos, idDocumento) {
	return {
		id: anexos.id,
		documento: idDocumento,
		identificacao: anexos.identificacao,
		partes: converterPartesParaApi(anexos.partes),
		produtos: converterProdutosParaApi(anexos.produtos),
		arquivoDocumento: converterArquivoDocumentoParaApi(anexos.arquivoDocumento),
		vigenciaInicio: anexos.vigenciaInicio,
		vigenciaFinal: anexos.vigenciaFinal,
		anexoValor: anexos.anexoValor ?? 0,
		anexoValorMensal: anexos.anexoValorMensal ?? 0,
		tipoGestaoDocumento: anexos.tipoGestaoDocumento,
	};
}

export {
	converterGestaoDocumentoParaFormulario,
	converterGestaoDocumentoParaApi,
	converterAnexosParaApi,
	converterAnexosParaFormulario,
};
