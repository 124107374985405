import { format, formatISO } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { FaCheckCircle, FaEquals, FaExclamationCircle } from 'react-icons/fa';
import { connect } from 'react-redux';
import { useUpdateEffect } from 'react-use';
import { useContextPesquisa } from 'views/Util/Context/ContextPesquisa';
import { readFormasPagamento } from 'views/cadastros/financas/CondicaoPagamento/Requests';
import { usuarioPossuiPermissao } from '../../../Common/Autenticacao';
import { services } from '../../../Common/Constantes/api';
import { estadosCadastro, permissoes, recursos } from '../../../Common/Constantes/autorizacao';
import { construirUrl } from '../../../Common/Rsql';
import Col from '../../../components/Col';
import DateInterval from '../../../components/DateInterval';
import DescricaoFiltroAvancado from '../../../components/DescricaoFiltroAvancado';
import Form from '../../../components/Form';
import FormActions from '../../../components/FormActions';
import FormContent from '../../../components/FormContent';
import Grid from '../../../components/Grid';
import If from '../../../components/If';
import PesquisaAvancada from '../../../components/PesquisaAvancada';
import { tipoCampos } from '../../../components/PesquisaAvancada/Util/constantes';
import { confirm } from '../../../components/Toast';
import InputSearch from '../../../components/input/InputSearch';
import { Financas } from '../../Util/constantes';
import montarMensagemExclusaoTitulos from '../Util/functions';
import CardTotalizadorListagem from '../components/CardTotalizadorListagem';
import ModalQuitacaoTitulos from '../components/ModalQuitacaoTitulos';
import ModalReparcelamentoTitulos from '../components/ModalReparcelamentoTitulos';
import {
	asyncDeleteContaPagar,
	asyncDeleteContaPagarProximasEmAberto,
	asyncDeleteContaPagarTodasEmAberto,
	asyncGetCategoriasDespesa,
	asyncGetContaPagar,
	asyncGetContasPagar,
	asyncGetTotalizadoresCards,
} from './Requests';
import { OPTIONS_FILTRO_AVANCADO_CONTAS_PAGAR } from './Util/constantes';
import ActionButtons from './components/ActionButtons';
import ModalContaPagar from './components/ModalContaPagar';
import { ModalExclusaoContasPagar, TIPOS_EXCLUSOES_CONTAS_PAGAR } from './components/ModalExclusaoContasPagar';
import ModalPagamentoTable from './components/ModalPagamentoTable';
import TabelaContasPagar from './components/TabelaContasPagar';

function currentMonth() {
	const date = new Date();
	const firstDay = format(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd');
	const lastDay = format(new Date(date.getFullYear(), date.getMonth() + 1, 0), 'yyyy-MM-dd');
	const formatedDate = `vencimento>=${firstDay};vencimento<=${lastDay}`;

	return formatedDate;
}

function ContasPagar(props) {
	const { location } = props;

	const {
		valorPesquisa,
		setValorPesquisa,
		interval,
		setInterval,
		sortField,
		setSortField,
		sortOrder,
		setSortOrder,
		page,
		setPage,
		rows,
		setRows,
		size,
		setSize,
		filtroAvancado,
		setFiltroAvancado,
		descricaoFiltroAvancado,
		setDescricaoFiltroAvancado,
		selectedCard,
		setSelectedCard,
	} = useContextPesquisa();

	const [podeInserir] = useState(usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_PAGAR, permissoes.INSERIR));
	const [podeReparcelar] = useState(usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_PAGAR, permissoes.REPARCELAR));
	const [podeEditar] = useState(usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_PAGAR, permissoes.EDITAR));
	const [podeExcluir] = useState(usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_PAGAR, permissoes.EXCLUIR));

	const [exibirModalContaPagar, setExibirModalContaPagar] = useState(false);
	const [exibirModalExclusao, setExibirModalExclusao] = useState(false);
	const [exibirModalConfirmarPagamento, setExibirModalConfirmarPagamento] = useState(false);
	const [exibirModalQuitacaoTitulos, setExibirModalQuitacaoTitulos] = useState(false);
	const [exibirModalReparcelamentoTitulos, setExibirModalReparcelamentoTitulos] = useState(false);
	const [registroSelecionado, setRegistroSelecionado] = useState(null);
	const [documentosSelecionados, setDocumentosSelecionados] = useState([]);

	const [valorCard, setValorCard] = useState('');

	const [optionsFiltroAvancado, setOptionsFiltroAvancado] = useState(OPTIONS_FILTRO_AVANCADO_CONTAS_PAGAR);
	const [limparFiltroPesquisaAvancada, setLimparFiltroPesquisaAvancada] = useState(false);
	const [firstRender, setFirstRender] = useState(true);

	const [cards, setCards] = useState({
		pago: 0,
		aPagar: 0,
		vencidas: 0,
		totalAPagar: 0,
	});
	const [registros, setRegistros] = useState([]);
	const [totalRecords, setTotalRecords] = useState(0);
	const [filtroData, setFiltroData] = useState(
		`vencimento>=${formatISO(interval.dataInicial, {
			representation: 'date',
		})};vencimento<=${formatISO(interval.dataFinal, {
			representation: 'date',
		})}`
	);

	const informacoesPermissoes = {
		podeInserir: podeInserir,
		podeEditar: podeEditar,
		podeExcluir: podeExcluir,
		estadoCadastro: estadosCadastro.EDICAO,
	};

	const pesquisarCallback = useCallback(() => {
		if (!firstRender) {
			pesquisar();
		}
	});

	useEffect(() => {
		pesquisar();

		if (location && location.state) {
			handleChangeInterval({
				dataInicial: location.state.dataInicial,
				dataFinal: location.state.dataFinal,
			});
		}

		setTimeout(() => {
			document.getElementById('ContasPagarInputSearch')?.focus();
		}, 500);
	}, []);

	useUpdateEffect(() => {
		pesquisarCallback();
	}, [page, rows, sortOrder, sortField, filtroAvancado, interval, selectedCard]);

	function handleChangeInterval(interval) {
		setInterval(interval);
		setFiltroData(
			`vencimento>=${formatISO(interval.dataInicial, {
				representation: 'date',
			})};vencimento<=${formatISO(interval.dataFinal, {
				representation: 'date',
			})}`
		);
	}

	async function pesquisar() {
		const filtro = buscarFiltro();

		let campoOrdenacao = sortField;
		let sentidoOrdenacao = sortOrder;
		if (firstRender) {
			setSortField('vencimento');
			setSortOrder(0);
			campoOrdenacao = 'vencimento';
			sentidoOrdenacao = 0;
		}

		const url = construirUrl(
			`${services.GESTOR}/v1/contas_pagar/resumo`,
			filtro || '?',
			size,
			page,
			sentidoOrdenacao > 0 ? `${campoOrdenacao},asc` : `${campoOrdenacao},desc`
		);

		asyncGetContasPagar(url, ({ data: contasPagar }) => {
			setRegistros(contasPagar.content);
			setTotalRecords(contasPagar.page.totalElements);
			setFirstRender(false);
		});

		asyncGetTotalizadoresCards(interval, ({ data: totais }) => {
			setCards({
				pago: totais.despesasPagas,
				aPagar: totais.despesasAPagar,
				vencidas: totais.despesasVencidas,
				totalAPagar: totais.despesasTotal,
			});
		});
	}

	function buscarFiltro() {
		let filtroRSQL = String('?query=(')
			.concat(`descricao=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
			.concat(`pessoaCodigo=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
			.concat(`pessoaNome=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
			.concat(`observacao=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
			.concat(`valor=contains="*${valorPesquisa.replaceAll('&', '%26')}*")`);

		if (filtroData) {
			filtroRSQL = filtroRSQL.concat(`;(${filtroData})`);
		} else {
			filtroRSQL = filtroRSQL.concat(`;(${currentMonth()})`);
		}
		if (filtroAvancado) {
			filtroRSQL = filtroRSQL.concat(`;(${filtroAvancado})`);
		}
		if (valorCard) {
			filtroRSQL = filtroRSQL.concat(`;(${valorCard})`);
		}

		return filtroRSQL;
	}

	function onPesquisar() {
		pesquisarCallback();
	}

	function onPesquisarFiltroAvancado(e) {
		setFiltroAvancado(e);
		setLimparFiltroPesquisaAvancada(false);
	}

	async function fetchOptionsFiltroAvancado() {
		const options = [...optionsFiltroAvancado];

		function addOption(newOption) {
			let indexOption = 0;
			const hasOption = options.find((e, i) => {
				if (e.name === newOption.name) {
					indexOption = i;
					return true;
				}
				return false;
			});

			if (hasOption) {
				options.splice(indexOption, 1);
			}

			options.push(newOption);
		}

		const promises = [
			readFormasPagamento(({ data: response }) => {
				if (response.content) {
					const newOption = {
						label: 'Forma pagamento',
						name: 'formaPagamentoId',
						type: tipoCampos.SELECT,
						optionSelect: [],
					};
					response.content.forEach((option) => {
						newOption.optionSelect.push({ label: option.descricao, value: option.id });
					});

					addOption(newOption);
				}
			}),

			asyncGetCategoriasDespesa(({ data: response }) => {
				const categorias = {
					label: 'Categoria de despesas',
					name: 'categorias.categoria.nome',
					type: tipoCampos.SELECT,
					optionSelect:
						response && response.content.map((categoria) => ({ label: categoria.nome, value: categoria.nome })),
				};
				addOption(categorias);
			}),
		];

		await Promise.all(promises).then(() => {
			setOptionsFiltroAvancado(
				options.sort((a, b) => {
					if (a.label < b.label) {
						return -1;
					}
					if (a.label > b.label) {
						return 1;
					}
					return 0;
				})
			);
		});
	}

	function onHideModalContaPagar() {
		pesquisarCallback();
		setExibirModalContaPagar(false);
		setRegistroSelecionado(null);
	}

	function handlePageChange(e) {
		setSize(e.rows);
		setRows(e.rows);
		setPage(e.page);
	}

	function handleSelectCardPagas(cardName) {
		pesquisarRSQLTotaisCard(cardName, ['PAGA', 'PARCIALMENTE_PAGA']);
	}

	function handleSelectCardAPagar(cardName) {
		pesquisarRSQLTotaisCard(cardName, ['PARCIALMENTE_PAGA', 'NAO_PAGA']);
	}

	function handleSelectCardVencidas(cardName) {
		pesquisarRSQLTotaisCard(cardName, new Date());
	}

	function pesquisarRSQLTotaisCard(cardName, valoresDoFiltro) {
		if (selectedCard === cardName) {
			setSelectedCard('');
			setValorCard('');
			setPage(0);
			// pesquisarCallback();
		} else if (cardName === 'cardVencidas') {
			const dataVencimento = format(valoresDoFiltro, 'yyyy-MM-dd');

			setSelectedCard(cardName);
			setValorCard(`vencimento<${dataVencimento};status!=PAGA`);
			setPage(0);
			// pesquisarCallback();
		} else {
			setSelectedCard(cardName);
			setValorCard(`status==${valoresDoFiltro.join(' or status==')}`);
			setPage(0);
			// pesquisarCallback();
		}
	}

	async function handleEditItem(item) {
		await asyncGetContaPagar(item.id, ({ data: contaPagar }) => {
			setRegistroSelecionado(contaPagar);
			setExibirModalContaPagar(true);
		});
	}

	async function handleEfetuarPagamentoItem(item) {
		setExibirModalConfirmarPagamento(true);
		setRegistroSelecionado(item);
	}

	async function handleRemoveItem(item) {
		if (item.itemRepeticao) {
			setExibirModalExclusao(true);
			setRegistroSelecionado(item);
		} else {
			confirm('Confirmação', montarMensagemExclusaoTitulos(item.status, item.origem), async () => {
				await asyncDeleteContaPagar(item.id, () => onPesquisar());
			});
		}
	}

	function onHideModalExclusao(tipoExclusao) {
		if (tipoExclusao) {
			const deleteCallback = () => {
				onPesquisar();
				setExibirModalExclusao(false);
				setRegistroSelecionado(null);
			};

			if (tipoExclusao === TIPOS_EXCLUSOES_CONTAS_PAGAR.APENAS_SELECIONADA) {
				asyncDeleteContaPagar(registroSelecionado.id, deleteCallback);
			} else if (tipoExclusao === TIPOS_EXCLUSOES_CONTAS_PAGAR.PROXIMAS_EM_ABERTO) {
				asyncDeleteContaPagarProximasEmAberto(registroSelecionado.id, deleteCallback);
			} else {
				asyncDeleteContaPagarTodasEmAberto(registroSelecionado.id, deleteCallback);
			}
		} else {
			setExibirModalExclusao(false);
			setRegistroSelecionado(null);
		}
	}

	function onHideModalConfirmarPagamento(pagamentoConfirmado) {
		if (pagamentoConfirmado) {
			onPesquisar();
		}
		setExibirModalConfirmarPagamento(false);
		setRegistroSelecionado(null);
	}

	function montarHeader() {
		return (
			<span
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<span>Contas a pagar</span>
			</span>
		);
	}

	return (
		<>
			<Form header={montarHeader()}>
				<FormActions>
					<ActionButtons
						{...{
							podeInserir,
							podeReparcelar,
							setExibirModalContaPagar,
							setRegistroSelecionado,
							documentosSelecionados,
							setExibirModalReparcelamentoTitulos,
							setExibirModalQuitacaoTitulos,
						}}
					/>
				</FormActions>
				<FormContent>
					<Grid justifyCenter verticalAlignCenter>
						<span style={{ padding: '12px' }}>
							<DateInterval interval={interval} onChange={handleChangeInterval} />
						</span>
						<InputSearch
							onPesquisar={onPesquisar}
							value={valorPesquisa}
							onChange={(value) => setValorPesquisa(value)}
							removerEComercial={false}
							id="ContasPagarInputSearch"
						/>

						<span style={{ padding: '12px' }}>
							<PesquisaAvancada
								optionsFiltros={optionsFiltroAvancado}
								onPesquisarClick={onPesquisarFiltroAvancado}
								onChangeFiltroRsql={(rsql) => setFiltroAvancado(rsql)}
								onChangeDescricaoFiltro={(e) => setDescricaoFiltroAvancado(e)}
								limparFiltro={limparFiltroPesquisaAvancada}
								onOpen={fetchOptionsFiltroAvancado}
							/>
						</span>
					</Grid>

					<Grid justifyBetween>
						<Col xs="12" sm="6" md="6" lg="3" xl="3">
							<CardTotalizadorListagem
								name="cardAPagar"
								title="A pagar no período"
								helpMessage="Soma dos valores a pagar no período selecionado"
								icon={<FaCheckCircle title="Não paga" size={22} color="888888" />}
								primaryColor={Financas.cores.cinza}
								value={cards.aPagar}
								selectable
								selected={selectedCard === 'cardAPagar'}
								onSelect={handleSelectCardAPagar}
							/>
						</Col>
						<Col xs="12" sm="6" md="6" lg="3" xl="3">
							<CardTotalizadorListagem
								name="cardPagas"
								title="Pagas no período"
								helpMessage="Soma dos valores pagos no período selecionado"
								icon={<FaCheckCircle size={22} color={Financas.cores.verde} />}
								primaryColor={Financas.cores.verde}
								value={cards.pago}
								selectable
								selected={selectedCard === 'cardPagas'}
								onSelect={handleSelectCardPagas}
							/>
						</Col>
						<Col xs="12" sm="6" md="6" lg="3" xl="3">
							<CardTotalizadorListagem
								name="cardVencidas"
								title="Todas as vencidas"
								helpMessage="Soma dos valores de todas as contas a pagar vencidas independente do período"
								icon={<FaExclamationCircle size={22} color={Financas.cores.vermelho} />}
								primaryColor={Financas.cores.vermelho}
								value={cards.vencidas}
								selectable
								selected={selectedCard === 'cardVencidas'}
								onSelect={handleSelectCardVencidas}
							/>
						</Col>
						<Col xs="12" sm="6" md="6" lg="3" xl="3">
							<CardTotalizadorListagem
								name="cardtotalAPagar"
								title="Total no período"
								helpMessage="Total de pagamentos dentro do período selecionado"
								icon={<FaEquals size={22} color={Financas.cores.azul} />}
								primaryColor={Financas.cores.azul}
								value={cards.totalAPagar}
							/>
						</Col>
					</Grid>
					<Grid style={{ paddingTop: '10px' }} justifyCenter verticalAlignCenter>
						<DescricaoFiltroAvancado texto={descricaoFiltroAvancado} />
						<TabelaContasPagar
							registros={registros}
							totalRecords={totalRecords}
							rows={size}
							page={page}
							sortField={sortField}
							sortOrder={sortOrder}
							setSortField={(sortField) => setSortField(sortField)}
							setSortOrder={(sortOrder) => setSortOrder(sortOrder)}
							onEditItem={handleEditItem}
							onEfetuarPagamentoItem={handleEfetuarPagamentoItem}
							onRemoveItem={handleRemoveItem}
							onPageChange={(e) => handlePageChange(e)}
							documentosSelecionados={documentosSelecionados}
							setDocumentosSelecionados={setDocumentosSelecionados}
						/>
					</Grid>
				</FormContent>
			</Form>
			<If test={exibirModalContaPagar}>
				<ModalContaPagar
					onNovoClick={() => setRegistroSelecionado(null)}
					visible={exibirModalContaPagar}
					onHide={onHideModalContaPagar}
					registroSelecionado={registroSelecionado}
				/>
			</If>
			<If test={exibirModalExclusao}>
				<ModalExclusaoContasPagar visible={exibirModalExclusao} onHide={onHideModalExclusao} />
			</If>
			<If test={exibirModalConfirmarPagamento}>
				<ModalPagamentoTable
					registroSelecionado={registroSelecionado}
					visible={exibirModalConfirmarPagamento}
					onHide={onHideModalConfirmarPagamento}
				/>
			</If>
			<If test={exibirModalQuitacaoTitulos}>
				<ModalQuitacaoTitulos
					isRecebimento={false}
					titulos={documentosSelecionados}
					visible={exibirModalQuitacaoTitulos}
					onHide={(resetar) => {
						setDocumentosSelecionados([]);
						setExibirModalQuitacaoTitulos(false);
						if (resetar) {
							pesquisar();
						}
					}}
					informacoesPermissoes={informacoesPermissoes}
					pathUrl="contas_pagar"
				/>
			</If>
			<If test={exibirModalReparcelamentoTitulos}>
				<ModalReparcelamentoTitulos
					origem="CONTAS_PAGAR"
					podeReparcelar={podeReparcelar}
					documentosSelecionados={documentosSelecionados}
					setDocumentosSelecionados={setDocumentosSelecionados}
					visible={exibirModalReparcelamentoTitulos}
					onHide={(resetar) => {
						setDocumentosSelecionados([]);
						setExibirModalReparcelamentoTitulos(false);
						if (resetar) {
							pesquisar();
						}
					}}
				/>
			</If>
		</>
	);
}

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
});

export default connect(mapStateToProps)(ContasPagar);
