import { Button, Checkbox, Col, Dropdown, Grid, If, InputDouble, Message, Subtitle, TextArea } from 'components';
import { Field, useFormikContext } from 'formik';
import { useState } from 'react';
import { useContextConfiguracoesGerais } from 'views/configuracao/ConfiguracoesGerais/Context';
import { INITIAL_VALUES, TIPO_ALERTA_VIGENCIA_OPTIONS } from 'views/configuracao/ConfiguracoesGerais/Util/constantes';
import { helpParametrizacao } from '../../Help';
import { ModalConfirmacaoPadrao } from './components/ModalConfirmacaoPadrao';
import { ModalSignatarioPadrao } from './components/ModalSignatarioPadrao';

function TabDocumentos() {
	const { isMobile, isTablet, podeEditarEmpresa } = useContextConfiguracoesGerais();
	const { values, errors, setFieldValue } = useFormikContext();

	const [visibleSignatarioPadrao, setVisibleSignatarioPadrao] = useState(false);
	const [visibleConfirmacaoPadrao, setVisibleConfirmacaoPadrao] = useState(false);

	function handleCloseSignatarioPadrao() {
		setVisibleSignatarioPadrao(false);
	}

	function handleCloseConfirmacaoPadrao() {
		setVisibleConfirmacaoPadrao(false);
	}

	const insertAtCursor = (myField, myValue) => {
		const txtarea = document.getElementById('corpoEmailDocumentos');
		const start = txtarea.selectionStart;
		const end = txtarea.selectionEnd;
		const finText = txtarea.value.substring(0, start) + myValue + txtarea.value.substring(end);
		txtarea.value = finText;
		txtarea.focus();
		txtarea.selectionEnd = end + myValue.length;
		setFieldValue(`parametrizacao.${myField}`, txtarea.value);
	};

	function handleSalvarSignatarioPadrao(dados) {
		setFieldValue('parametrizacao.documentos.signatarioPadrao', dados);
		setVisibleSignatarioPadrao(false);
	}

	function handleSalvarConfirmacaoPadrao(dados) {
		setFieldValue('parametrizacao.documentos.confirmacaoPadrao', dados);
		setVisibleConfirmacaoPadrao(false);
	}

	function handleAlterarCheckboxAlertaVigencia(check) {
		setFieldValue('parametrizacao.documentos.emailAlertaVigencia', check);

		if (check) {
			setFieldValue(
				'parametrizacao.documentos.tipoAlertaVigencia',
				INITIAL_VALUES.parametrizacao.documentos.tipoAlertaVigencia
			);
			setFieldValue(
				'parametrizacao.documentos.diasAlertaVigencia',
				INITIAL_VALUES.parametrizacao.documentos.diasAlertaVigencia
			);
		} else {
			setFieldValue('parametrizacao.documentos.tipoAlertaVigencia', null);
			setFieldValue('parametrizacao.documentos.diasAlertaVigencia', null);
		}
	}

	function montarTextoMessage(podeEditarEmpresa) {
		return (
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					flexDirection: isMobile ? 'column' : 'row',
				}}
			>
				<div
					style={{ maxWidth: '30rem', paddingBottom: isMobile ? '6px' : null, textAlign: isMobile ? 'center' : null }}
				>
					<p style={{ display: isMobile ? 'block' : 'flex' }}>
						<If test={!isMobile}>
							<p>Ao clicar nos botões ao lado, uma</p>
						</If>
						<If test={isMobile}>
							<p>Ao clicar nos botões abaixo, uma</p>
						</If>
						<span
							title="'Variáveis' serão os dados inseridos automaticamente, como por exemplo a identificação do documento e o nome da empresa"
							style={{ fontWeight: 'bold' }}
						>
							&nbsp;variável&nbsp;
						</span>
						será incluída ao texto.
					</p>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: isMobile || isTablet ? 'column' : 'row',
						alignItems: isMobile ? 'start' : 'end',
					}}
				>
					<Button
						className="button-variavel-identificacao"
						label="Identificação documento"
						onClick={() => insertAtCursor('documentos.corpoEmailDocumentos', '{identificacaoDocumento}')}
						style={{
							marginLeft: isTablet ? '6px' : '0px',
							whiteSpace: 'nowrap',
							width: isMobile || isTablet ? '12.2rem' : 'auto',
						}}
						disabled={!podeEditarEmpresa}
					/>
					<Button
						label="Nome organização"
						onClick={() => insertAtCursor('documentos.corpoEmailDocumentos', '{nomeOrganizacao}')}
						style={{
							marginLeft: isMobile ? '0px' : '6px',
							marginTop: isMobile || isTablet ? '6px' : '0px',
							width: isMobile || isTablet ? '12.2rem' : 'auto',
						}}
						disabled={!podeEditarEmpresa}
					/>
				</div>
			</div>
		);
	}

	return (
		<>
			<Grid>
				<Subtitle subtitle="Documentos" />
				<Field
					sm="12"
					md="6"
					lg="6"
					xl="6"
					component={Dropdown}
					label="Tipo de gestão de documentos"
					name="tipoGestaoDocumento"
					value={values.parametrizacao.documentos?.tipoGestaoDocumento}
					onChange={(e) => setFieldValue('parametrizacao.documentos.tipoGestaoDocumento', e.value)}
					options={[
						{ label: 'Contrato', value: 'CONTRATO' },
						{ label: 'Documento', value: 'DOCUMENTO' },
					]}
					obrigatorio
					showClear={false}
					disabled={!podeEditarEmpresa}
				/>
				<Field
					helpMessage={helpParametrizacao.emailAlertaVigencia}
					sm="12"
					md="12"
					lg="12"
					xl="12"
					component={Checkbox}
					label="Enviar alerta para gestão de documento no fim de sua vigência"
					name="emailAlertaVigencia"
					value={values.parametrizacao.documentos?.emailAlertaVigencia}
					onChange={(e) => handleAlterarCheckboxAlertaVigencia(e.checked)}
					checked={values.parametrizacao.documentos?.emailAlertaVigencia}
					disabled={!podeEditarEmpresa}
				/>
				<If test={values.parametrizacao.documentos?.emailAlertaVigencia}>
					<Field
						sm="12"
						md="4"
						lg="4"
						xl="4"
						component={InputDouble}
						label="Alerta antes do fim da vigência"
						name="diasAlertaVigencia"
						value={values.parametrizacao.documentos?.diasAlertaVigencia}
						errors={errors.diasAlertaVigencia}
						touched
						onChange={(e) => setFieldValue('parametrizacao.documentos.diasAlertaVigencia', e.target.value)}
						decimalScale={0}
						suffix=" dia(s)"
						allowNegative={false}
						obrigatorio
						disabled={!podeEditarEmpresa || !values.parametrizacao.documentos?.emailAlertaVigencia}
					/>
					<Field
						sm="12"
						md="4"
						lg="4"
						xl="4"
						component={Dropdown}
						label="Tipo de alerta da vigência"
						name="tipoAlertaVigencia"
						value={values.parametrizacao.documentos?.tipoAlertaVigencia}
						onChange={(e) => setFieldValue('parametrizacao.documentos.tipoAlertaVigencia', e.value)}
						options={TIPO_ALERTA_VIGENCIA_OPTIONS}
						obrigatorio
						showClear={false}
						disabled={!podeEditarEmpresa || !values.parametrizacao.documentos?.emailAlertaVigencia}
					/>
				</If>
				<Subtitle subtitle="Assinatura" />
				<Message
					severity="info"
					text={montarTextoMessage(podeEditarEmpresa)}
					colStyle={{
						paddingRight: isMobile || isTablet ? '0px' : '0.5em',
					}}
				/>
				<Field
					helpMessage={helpParametrizacao.corpoEmailDocumentos}
					sm="12"
					md="12"
					lg="12"
					xl="12"
					component={TextArea}
					label="Mensagem do corpo do email assinatura eletrônica"
					id="corpoEmailDocumentos"
					name="corpoEmailDocumentos"
					value={values.parametrizacao.documentos?.corpoEmailDocumentos}
					onChange={(e) => setFieldValue('parametrizacao.documentos.corpoEmailDocumentos', e.target.value)}
					disabled={!podeEditarEmpresa}
				/>
				<Col>
					<div
						style={{
							display: 'flex',
							flexDirection: isMobile || isTablet ? 'column' : 'row',
							alignItems: isMobile ? 'start' : 'end',
						}}
					>
						<Button
							label="Signatário(s) padrão"
							onClick={() => setVisibleSignatarioPadrao(true)}
							style={{
								marginLeft: isTablet ? '6px' : '0px',
								whiteSpace: 'nowrap',
								width: isMobile || isTablet ? '12.2rem' : 'auto',
							}}
							disabled={!podeEditarEmpresa}
						/>
						<Button
							label="Confirmação(ões) padrão"
							onClick={() => setVisibleConfirmacaoPadrao(true)}
							style={{
								marginLeft: isMobile ? '0px' : '6px',
								marginTop: isMobile || isTablet ? '6px' : '0px',
								width: isMobile || isTablet ? '12.2rem' : 'auto',
							}}
							disabled={!podeEditarEmpresa}
						/>
					</div>
				</Col>
			</Grid>
			<If test={visibleSignatarioPadrao}>
				<ModalSignatarioPadrao
					visible={visibleSignatarioPadrao}
					onHide={() => handleCloseSignatarioPadrao()}
					handleSalvarSignatarioPadrao={(event) => handleSalvarSignatarioPadrao(event)}
					values={values.parametrizacao.documentos.signatarioPadrao}
				/>
			</If>
			<If test={visibleConfirmacaoPadrao}>
				<ModalConfirmacaoPadrao
					visible={visibleConfirmacaoPadrao}
					onHide={() => handleCloseConfirmacaoPadrao()}
					handleSalvarConfirmacaoPadrao={(event) => handleSalvarConfirmacaoPadrao(event)}
					values={values.parametrizacao.documentos.confirmacaoPadrao}
				/>
			</If>
		</>
	);
}

export { TabDocumentos };
