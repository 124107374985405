import * as Yup from 'yup';
import { Field, useFormikContext, withFormik } from 'formik';

import {
	Button,
	ButtonCancelar,
	Col,
	FormActions,
	FormContent,
	Grid,
	Modal,
	TextArea,
	estadosBotaoCancelar,
} from 'components';
import { mensagensDeValidacao } from 'Common';

import { useContextCTe } from 'views/transporte/CTe/Context';
import { validarFormulario } from 'views/Util';
import { cancelarCTe } from 'views/transporte/CTe/Requests';
import { CTE_SITUACAO } from 'views/transporte/CTe/Util/constantes';
import { removerAcentos } from 'Common/ManipulacaoDeString';

function ModalCancelamentoImpl({ visible, onHide, resetFormPrincipal, cte }) {
	const { values, setFieldValue, handleSubmit, validateForm } = useFormikContext();
	const { informacoesPermissoes, setExibirLoadingTransmissao, setMessageLoading } = useContextCTe();

	const isCancelado = Boolean(cte.situacao === CTE_SITUACAO.CANCELADO);

	function handleChangeMotivoCancelamento(event) {
		setFieldValue('motivoCancelamento', event.target.value);
	}

	async function handleClickCancelar() {
		handleSubmit();

		if (await validarFormulario({ values, validateForm })) {
			setExibirLoadingTransmissao(true);
			setMessageLoading('Cancelando CT-e...');
			await cancelarCTe(
				cte.id,
				{ motivoCancelamento: converterParaApi(values.motivoCancelamento) },
				({ data: response }) => {
					setExibirLoadingTransmissao(false);
					setMessageLoading('');
					resetFormPrincipal({
						values: { ...cte, situacao: response.situacao, motivoCancelamento: values.motivoCancelamento },
					});
				},
				() => {
					setExibirLoadingTransmissao(false);
					setMessageLoading('');
				}
			);
			onHide();
		}
	}

	function converterParaApi(justificativa) {
		if (justificativa) {
			let textoCancelamento = String(justificativa).trim();
			textoCancelamento = removerAcentos(textoCancelamento);

			return textoCancelamento;
		}

		return '';
	}

	return (
		<Modal header={isCancelado ? 'Motivo do cancelamento' : 'Cancelar CT-e'} visible={visible} onHide={onHide}>
			<FormContent>
				<Grid style={{ marginTop: '5px' }}>
					<Field
						sm="12"
						md="12"
						lg="12"
						xl="12"
						component={TextArea}
						size={255}
						name="motivoCancelamento"
						label={isCancelado ? null : 'Justificativa do cancelamento'}
						value={values.motivoCancelamento}
						onChange={(event) => handleChangeMotivoCancelamento(event)}
						obrigatorio={!isCancelado}
						touched
						disabled={isCancelado}
					/>
				</Grid>
			</FormContent>
			<FormActions>
				<Grid
					style={{
						display: 'flex',
						justifyContent: 'end',
						margin: 0,
					}}
				>
					<Col
						xs="12"
						sm="6"
						md="6"
						lg="4"
						xl="4"
						style={{
							padding: 0,
							margin: 0,
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'flex-end',
						}}
					>
						<ButtonCancelar estadoBotao={estadosBotaoCancelar.VOLTAR} onClick={onHide} />
						<Button
							icon={isCancelado ? 'fa fa-reply' : 'fa fa-paper-plane-o'}
							type="submit"
							style={{ marginRight: '0px !important' }}
							label="Cancelar CT-e"
							color="danger"
							onClick={handleClickCancelar}
							disabled={isCancelado}
							{...informacoesPermissoes}
						/>
					</Col>
				</Grid>
			</FormActions>
		</Modal>
	);
}

const ModalCancelamento = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues(props) {
		return {
			motivoCancelamento: props.cte?.motivoCancelamento || null,
		};
	},

	validationSchema: Yup.object().shape({
		motivoCancelamento: Yup.string()
			.trim()
			.min(15, 'A justificativa deve ter mais de 15 caracteres')
			.required(mensagensDeValidacao.OBRIGATORIO)
			.nullable(),
	}),
	handleSubmit: () => {},
})(ModalCancelamentoImpl);

export { ModalCancelamento };
