export function removerElemento(array, elemento) {
	let index = array.indexOf(elemento);
	let arrayCopiado = [...array];
	if (index > -1) {
		arrayCopiado.splice(index, 1);
	}
	return arrayCopiado;
}

export function removerUltimoElemento(array) {
	let arrayCopiado = [...array];
	return arrayCopiado?.length > 0 ? arrayCopiado.slice(0, -1) : [];
}

export function removerElementoPorId(array, elemento, nomeColunaId) {
	let index = 0;
	let resultado = [];

	for (index; index < array.length; index++) {
		if (array[index][nomeColunaId] !== elemento[nomeColunaId]) resultado.push(array[index]);
	}
	return resultado;
}

export function elementoExiste(elemento, array) {
	for (const item in array) {
		if (array.hasOwnProperty(item)) {
			const element = this.state.options[item];
			if (element === elemento) {
				return true;
			}
		}
	}
}

export function capturarElementoPorId(array, id) {
	for (let index = 0; index < array.length; index++) {
		const element = array[index];
		if (element.id === id) {
			return element;
		}
	}
}

export function copiarObjeto(obj) {
	// Se não for array ou objeto, retorna null
	if (typeof obj !== 'object' || obj === null) {
		return obj;
	}

	let cloned, i;

	// Handle: Date
	if (obj instanceof Date) {
		cloned = new Date(obj.getTime());
		return cloned;
	}

	// Handle: array
	if (obj instanceof Array) {
		cloned = [];
		for (i = 0; i < obj.length; i++) {
			cloned[i] = copiarObjeto(obj[i]);
		}

		return cloned;
	}

	// Handle: object
	cloned = {};
	for (i in obj)
		if (obj.hasOwnProperty(i)) {
			cloned[i] = copiarObjeto(obj[i]);
		}

	return cloned;
}

export function removeNullObjeto(obj) {
	for (var propName in obj) {
		if (obj[propName] === null || obj[propName] === undefined) {
			delete obj[propName];
		}
	}
	return obj;
}

export function setNullForIdProperties(obj, ignoredProps = []) {
	if (typeof obj === 'object' && obj !== null) {
		for (let prop in obj) {
			if (obj.hasOwnProperty(prop)) {
				const isIgnoredProps = ignoredProps.includes(prop);
				if (prop === 'id') {
					obj[prop] = null;
				} else if (Array.isArray(obj[prop]) && !isIgnoredProps) {
					obj[prop].forEach((item) => setNullForIdProperties(item, ignoredProps));
				} else if (typeof obj[prop] === 'object' && !isIgnoredProps) {
					setNullForIdProperties(obj[prop], ignoredProps);
				}
			}
		}
	}
}

export function hasCommonValues(array1, array2) {
	for (let i = 0; i < array1.length; i++) {
		if (array2.includes(array1[i])) {
			return true;
		}
	}
	return false;
}
