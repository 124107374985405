function converterPastasParaOptions(data) {
	const idNodeMap = {};
	const root = [];

	data.forEach((item) => {
		const node = {
			key: item.id,
			label: item.descricao,
			data: item.descricao,
			registro: item,
			children: [],
		};
		idNodeMap[item.id] = node;
	});

	data.forEach((item) => {
		const node = idNodeMap[item.id];
		if (item.pastaPai) {
			const parentNode = idNodeMap[item.pastaPai];
			if (parentNode) {
				parentNode.children.push(node);
			} else {
				root.push(node);
			}
		} else {
			root.push(node);
		}
	});

	return { root };
}

export { converterPastasParaOptions };
