/* eslint-disable no-unused-vars */
import { useCallback, useEffect, useState } from 'react';
import { useEffectOnce, useUpdateEffect } from 'react-use';
import { useFormikContext, withFormik } from 'formik';

import {
	buscarConfiguracaoUsuario,
	configuracoesUsuario,
	construirUrl,
	mensagensDeValidacao,
	salvarConfiguracaoUsuario,
	services,
	usePrevious,
	validarUUID,
} from 'Common';
import { Form, FormActions, FormContent, Grid, Tutorial, tutorialStepsRegiao } from 'components';

import { getMunicipiosRegiaoVenda, getRegiaoVenda } from '../Requests';
import { useContextRegiaoVenda } from '../Context';
import ActionButtons from './Components/ActionButtons';
import { HeaderRegiao } from './Components/HeaderRegiao';
import { INITIAL_VALUES_REGIAO, PESQUISAURL } from './Utils/constantes';
import {
	converterApiParaForm,
	converterEstadoMunicipioParaForm,
	converterMunicipiosParaForm,
} from './Utils/regiaoConverter';
import TabelaMunicipios from './Components/TabelaMunicipios';

function RegiaoFormView() {
	const { values, setFieldValue, setValues, resetForm } = useFormikContext();
	const prevValues = usePrevious(values);
	const {
		idRegiao,
		estadosLocal,
		setEstadosLocal,
		sortField,
		sortOrder,
		page,
		setPage,
		size,
		setSize,
		setTotalRecords,
		controleBuscarMunicipiosPaginados,
		setControleBuscarMunicipiosPaginados,
	} = useContextRegiaoVenda();

	const [firstRender, setFirstRender] = useState(true);
	const [controleResetForm, setControleResetForm] = useState(false);
	const [deveExibirTutorial] = useState(buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_REGIAO));
	const [tutorialVisible, setTutorialVisible] = useState(false);

	const fetchCallback = useCallback(() => {
		if (!firstRender) {
			setControleBuscarMunicipiosPaginados(true);
		}
	});

	useEffectOnce(() => {
		if (validarUUID(idRegiao)) {
			asyncSelectRegistro(idRegiao);
		}

		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_REGIAO, false, null, false);
		}

		setTimeout(() => {
			document.getElementById('InputNomeRegiao')?.focus();
		}, 500);
		setFirstRender(false);
	});

	useEffect(() => {
		if (controleResetForm) {
			resetForm({ values: values });
			setControleResetForm(false);
		}
	}, [controleResetForm]);

	useEffect(() => {
		if (controleBuscarMunicipiosPaginados) {
			selectMunicipiosDaRegiao(idRegiao);
			setControleBuscarMunicipiosPaginados(false);
		}
	}, [controleBuscarMunicipiosPaginados]);

	useUpdateEffect(() => {
		if (prevValues?.municipios !== values?.municipios) {
			values.municipios?.forEach((municipio) => {
				const isEstadoPresente = values.estados.some((estado) => estado.value === municipio.municipio.estado.id);
				const estadoConvertido = converterEstadoMunicipioParaForm(municipio.municipio.estado);
				if (!isEstadoPresente) {
					setEstadosLocal([...values.estados, estadoConvertido]);
					setFieldValue('estados', [...values.estados, estadoConvertido]);
				}
			});
		}
	}, [values.municipios]);

	async function asyncSelectRegistro(idRegistro) {
		await getRegiaoVenda(idRegistro, ({ data: regiao }) => {
			const converted = converterApiParaForm(regiao);
			setEstadosLocal(converted.estados);
			setValues(converted);
		});
		setControleBuscarMunicipiosPaginados(true);
	}

	async function selectMunicipiosDaRegiao(idRegistro) {
		const url = construirUrl(
			`${services.GESTOR}/v1/vendas/regiao/${idRegistro}/municipios`,
			'?',
			size,
			page,
			sortOrder > 0 ? `${sortField},asc` : `${sortField},desc`
		);

		const promises = [
			getMunicipiosRegiaoVenda(url, ({ data: response }) => {
				setFieldValue('municipios', converterMunicipiosParaForm(response.content));
				setTotalRecords(response.page.totalElements);
				setSize(response.page.size);
				setPage(response.page.pageNumber);
			}),
		];

		await Promise.all(promises);

		setControleResetForm(true);
	}

	return (
		<>
			<Tutorial
				steps={tutorialStepsRegiao}
				showSkipButton
				continuous
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
				disableScrolling
			/>
			<Form header="Cadastro de região de venda" isModal={false} className="card-default screen-max-width">
				<FormActions className="screen-max-width">
					<ActionButtons asyncSelectRegistro={asyncSelectRegistro} />
				</FormActions>
				<FormContent>
					<Grid>
						<HeaderRegiao fetchCallback={fetchCallback} />
						<TabelaMunicipios pesquisaURL={PESQUISAURL} />
					</Grid>
				</FormContent>
			</Form>
		</>
	);
}

const RegiaoForm = withFormik({
	enableReinitialize: true,
	validateOnChange: true,
	validateOnBlur: false,

	mapPropsToValues() {
		return INITIAL_VALUES_REGIAO;
	},

	validate: (values) => {
		const errors = {};

		if (!values.nome) {
			errors.nome = mensagensDeValidacao.OBRIGATORIO;
		}

		if (!values.situacao) {
			errors.situacao = mensagensDeValidacao.OBRIGATORIO;
		}

		if (values.municipios?.length < 1) {
			errors.municipios = mensagensDeValidacao.OBRIGATORIO;
		}

		return errors;
	},

	handleSubmit: () => {},
})(RegiaoFormView);

export { RegiaoForm };
