import { format, parse } from 'date-fns';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useContextPesquisa } from 'views/Util/Context/ContextPesquisa';
import { connect } from 'react-redux';
import { formatarMonetario, removerElemento } from '../../../../../Common';
import {
	Badge,
	ButtonEditarTable,
	ButtonExcluirTable,
	NenhumRegistroEncontrado,
	Paginacao,
} from '../../../../../components';
import { atualizarUrl } from '../../../../Util';
import { confirmarExclusao } from '../../../../Util/ExclusaoDeRegistros';
import { asyncDeleteNotaEntrada } from '../../Requests';
import { ColorsSituacao } from '../../Util/constantes';

const styleColumn = {
	textOverflow: 'ellipsis',
	overflow: 'hidden',
};

function TabelasNotas(props) {
	const { registros, setRegistros, totalElements, setTotalElements, podeEditar, podeExcluir } = props;

	const { setSortField, sortField, setSortOrder, sortOrder, page, setPage, rows, setRows } = useContextPesquisa();

	function renderFornecedor(row) {
		return (
			<span
				style={{
					display: 'flex',
					wordBreak: 'break-word',
					maxWidth: props.isMobile ? '70%' : '100%',
					textAlign: props.isMobile ? 'end' : 'start',
				}}
			>
				{row.fornecedor}
			</span>
		);
	}

	function renderEmissaoField(row) {
		return (
			<span>{row.dataEntrada ? format(parse(row.dataEntrada, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy') : ' - '}</span>
		);
	}

	function renderSituacao(row) {
		const styleBackground = {
			borderRadius: '20px',
			padding: row.situacao === 'PENDENTE' ? '0.1rem 1.3rem' : '0.1rem 1.2rem',
		};

		switch (row.situacao) {
			case 'PENDENTE': {
				return Badge(ColorsSituacao.textPendente, ColorsSituacao.bgPendente, 'Pendente', styleBackground);
			}
			case 'FINALIZADA': {
				return Badge(ColorsSituacao.textFinalizada, ColorsSituacao.bgFinalizada, 'Finalizada', styleBackground);
			}
			case 'CANCELADA': {
				return Badge(ColorsSituacao.textCancelada, ColorsSituacao.bgCancelada, 'Cancelada', styleBackground);
			}
			default:
				return row.situacao;
		}
	}

	function renderOpcoes(row) {
		const isPendente = row.situacao === 'PENDENTE';
		return (
			<div style={{ display: 'flex' }}>
				<ButtonEditarTable
					onClick={() => {
						atualizarUrl(props.history, '/nota_entrada/cadastro', row.id);
					}}
					disabled={!podeEditar}
				/>
				<ButtonExcluirTable
					onClick={() => {
						onDelete(row);
					}}
					podeExcluir={podeExcluir}
					disabled={!isPendente}
				/>
			</div>
		);
	}

	function onDelete(row) {
		confirmarExclusao(() => asyncExcluirRegistro(row));
	}

	async function asyncExcluirRegistro(registro) {
		await asyncDeleteNotaEntrada(registro.id, () => {
			setRegistros(removerElemento(registros, registro));
			setTotalElements(totalElements - 1);
		});
	}

	function onPageChange(event) {
		setRows(event.rows);
		setPage(event.page);
	}

	function onSort(e) {
		setSortField(e.sortField);
		setSortOrder(e.sortOrder);
	}

	return (
		<>
			<DataTable
				className="table"
				rowClassName="table-row"
				cellClassName="table-row-cell"
				responsive
				sortField={sortField}
				sortOrder={sortOrder}
				onSort={onSort}
				value={registros}
				style={{ width: '100%' }}
				emptyMessage={<NenhumRegistroEncontrado />}
			>
				<Column
					className="step-listagem-order"
					field="serie"
					header="Série"
					body={(row) => row.serie}
					sortable
					style={{
						...styleColumn,
						width: '8%',
					}}
				/>
				<Column
					field="numero"
					header="Número"
					body={(row) => row.numero}
					sortable
					style={{
						...styleColumn,
						width: '10%',
					}}
				/>
				<Column
					field="fornecedor"
					header="Fornecedor"
					body={(row) => renderFornecedor(row)}
					sortable
					style={{
						...styleColumn,
						width: '50%',
					}}
				/>
				<Column
					field="dataEntrada"
					header="Data"
					body={(row) => renderEmissaoField(row)}
					sortable
					style={{
						...styleColumn,
						width: '12%',
					}}
				/>
				<Column
					field="situacao"
					header="Situação"
					body={renderSituacao}
					style={{
						...styleColumn,
						width: '12%',
					}}
				/>
				<Column
					field="valor"
					header="Valor"
					body={(row) => formatarMonetario(row.valor)}
					sortable
					style={{
						...styleColumn,
						width: 'auto',
						fontWeight: 'bold',
					}}
				/>
				<Column
					header="Ações"
					body={renderOpcoes}
					style={{
						...styleColumn,
						width: 'auto',
					}}
				/>
			</DataTable>
			<Paginacao totalElements={totalElements} rows={rows} page={page} onPageChange={(e) => onPageChange(e)} />
		</>
	);
}

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
});

export default connect(mapStateToProps)(TabelasNotas);
