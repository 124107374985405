/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { TreeSelect } from 'primereact/treeselect';
import { IoCloseSharp } from 'react-icons/io5';

import {
	buscarHiddenDeAcordoComAsPermissoes,
	get,
	permissoes,
	recursos,
	renderizarValidacao,
	services,
	usuarioPossuiPermissao,
} from 'Common';
import { Col } from 'components';

import { converterPastasParaOptions } from './Utils/converter';

function SingleSelectPastas({
	sm,
	md,
	lg,
	xl,
	colStyle,
	errors,
	touched,
	warnings,
	hidden,
	style,
	value,
	onNodeSelect,
	onChange,
	...props
}) {
	const [nodes, setNodes] = useState(null);
	const [podeVisualizar] = useState(usuarioPossuiPermissao(recursos.GESTAO_DOCUMENTOS, permissoes.VISUALIZAR));

	useEffect(() => {
		buscarOptionsPastas();
	}, []);

	function buscarOptionsPastas() {
		get(`${services.GESTOR}/v1/pastas`, null, (content) => {
			const dadosConvertidos = converterPastasParaOptions(content.data);
			dadosConvertidos.root.unshift({
				label: (
					<span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '-32px' }}>
						<IoCloseSharp size={20} style={{ marginRight: '12px' }} />
						Limpar seleção
					</span>
				),
				data: 'limpar_selecao',
				key: 'limpar_selecao',
			});
			setNodes(dadosConvertidos.root);
		});
	}

	function getColStyle() {
		if (buscarHiddenDeAcordoComAsPermissoes(podeVisualizar, hidden)) {
			return { display: 'none', ...colStyle };
		}
		return colStyle;
	}

	function handleChange(e) {
		if (e.value === 'limpar_selecao') {
			onChange(null);
		} else {
			onChange(e);
		}
	}

	return (
		<Col sm={sm} md={md} lg={lg} xl={xl} style={getColStyle()}>
			<label style={{ display: 'block' }} htmlFor="single">
				Selecione uma pasta
			</label>
			<TreeSelect
				id="single"
				style={{ ...style, width: '100%', height: '32px' }}
				value={value}
				options={nodes}
				selectionMode="single"
				onNodeSelect={onNodeSelect}
				onChange={handleChange}
				{...props}
			/>
			{renderizarValidacao(errors, touched, warnings)}
		</Col>
	);
}

export default SingleSelectPastas;
