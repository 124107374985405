export const tutorialSteps = [
	{
		target: 'body',
		content: `Este é o cadastro de "Forma de pagamento". Estes registros são utilizados na parte de pagamentos do sistema.`,
		disableBeacon: true,
		placement: 'center',
	},
	{
		target: '.step-forma-pagamento-tipo',
		content:
			'Este campo tem como objetivo destacar para o sistema qual o tipo da condicão de pagamento. À vista, a prazo ou sem pagamento.',
		placement: 'top',
	},
	{
		target: '.step-forma-pagamento-parcela-minima',
		content:
			'Nesta área é possível vincular as categorias de receita e/ou despesa, automatizando a utilização do plano de contas.',
		placement: 'top',
	},
];
