import {
	buscarConfiguracaoUsuario,
	configuracoesUsuario,
	construirUrl,
	formatarParaPesquisarTiposEnumerados,
	salvarConfiguracaoUsuario,
	services,
} from 'Common';
import {
	Button,
	DateInterval,
	DescricaoFiltroAvancado,
	Form,
	FormActions,
	FormContent,
	Grid,
	If,
	InputSearch,
	PesquisaAvancada,
	Tutorial,
	confirm,
	tutorialStepsListagens,
} from 'components';
import ModalHistorico from 'components/ModalHistorico';
import { formatISO } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { atualizarUrl } from 'views/Util';
import { useContextPesquisa } from 'views/Util/Context/ContextPesquisa';
import { useContextOrdemServico } from './Context';
import { asyncDeleteOrdemServico, asyncGetOrdensServico, asyncGetTotalizadores } from './Requests';
import { OPTIONS_FILTRO_AVANCADO_ORDEM_SERVICO } from './Util/constantes';
import { Cards } from './components/Cards';
import { TabelaOrdensServico } from './components/TabelaOrdensServico';

function OrdemServicoImpl({ history, isMobile }) {
	const { podeInserir } = useContextOrdemServico();

	const {
		valorPesquisa,
		setValorPesquisa,
		interval,
		setInterval,
		sortField,
		setSortField,
		sortOrder,
		setSortOrder,
		page,
		setPage,
		rows,
		setRows,
		size,
		setSize,
		totalRecords,
		setTotalRecords,
		filtroAvancado,
		setFiltroAvancado,
		descricaoFiltroAvancado,
		setDescricaoFiltroAvancado,
		selectedCard,
		setSelectedCard,
	} = useContextPesquisa();

	const [filtroData, setFiltroData] = useState(createFiltroData(interval));
	const [registros, setRegistros] = useState([]);
	const [registroSelecionado, setRegistroSelecionado] = useState({});
	const [limparFiltroPesquisaAvancada, setLimparFiltroPesquisaAvancada] = useState(false);
	const [cards, setCards] = useState({});
	const [visibleModalHistorico, setVisibleModalHistorico] = useState(false);
	const [deveExibirTutorial] = useState(buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS));
	const [tutorialVisible, setTutorialVisible] = useState(false);
	const [firstRender, setFirstRender] = useState(true);

	const fetchCallback = useCallback(() => {
		if (!firstRender) {
			fetchOrdensServico();
		}
	});

	useEffect(() => {
		fetchOrdensServico();
		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS, false, null, false);
		}

		setTimeout(() => {
			document.getElementById('OrdemServicoInputSearch')?.focus();
		}, 500);
	}, []);

	useEffect(() => {
		fetchCallback();
	}, [page, rows, sortOrder, sortField, filtroAvancado, interval, selectedCard]);

	async function fetchOrdensServico() {
		if (interval.dataInicial !== null && interval.dataFinal !== null) {
			const filter = getFilter();
			let campoOrdenacao = sortField;
			let sentidoOrdenacao = sortOrder;
			if (firstRender) {
				setSortField('numero');
				setSortOrder(-1);
				campoOrdenacao = 'numero';
				sentidoOrdenacao = -1;
			}
			const url = construirUrl(
				`${services.GESTOR}/v1/ordem_servico/resumo`,
				filter || '?',
				size,
				page,
				sentidoOrdenacao > 0 ? `${campoOrdenacao},asc` : `${campoOrdenacao},desc`
			);

			const promises = [
				asyncGetTotalizadores(interval, ({ data: response }) => {
					const newCards = {};

					response &&
						// eslint-disable-next-line array-callback-return
						response.map((item) => {
							// eslint-disable-next-line default-case
							switch (item.status) {
								case 'PENDENTE': {
									newCards.pendente = item;
									break;
								}
								case 'FINALIZADA': {
									newCards.finalizado = item;
									break;
								}
								case 'CANCELADA': {
									newCards.cancelado = item;
									break;
								}
							}
						});

					setCards(newCards);
				}),
				asyncGetOrdensServico(url, ({ data: response }) => {
					setRegistros(response.content);
					setTotalRecords(response.page.totalElements);
					setFirstRender(false);
				}),
			];

			await Promise.all(promises);
		}
	}

	function getFilter() {
		const valorTiposEnumerados = formatarParaPesquisarTiposEnumerados(valorPesquisa);

		let filter = String('?query=(')
			.concat(`numero=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
			.concat(`situacao=contains="*${valorTiposEnumerados.replaceAll('&', '%26')}*",`)
			.concat(`clienteNome=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
			.concat(`clienteCodigo=contains="*${valorPesquisa.replaceAll('&', '%26')}*")`)
			.concat(`;(${filtroData})`);

		if (filtroAvancado) {
			filter = filter.concat(`;(${filtroAvancado})`);
		}
		if (selectedCard) {
			filter = filter.concat(`;(situacao=contains="*${selectedCard}*")`);
		}

		return filter;
	}

	function handleClickNovo() {
		atualizarUrl(history, '/ordem_servico/cadastro');
	}

	function handleChangeInterval(interval) {
		setInterval(() => {
			setFiltroData(createFiltroData(interval));
			return interval;
		});
	}

	function createFiltroData(interval) {
		return `dataAbertura>=${formatISO(interval.dataInicial, {
			representation: 'date',
		})};dataAbertura<=${formatISO(interval.dataFinal, {
			representation: 'date',
		})}`;
	}

	function handleChangeOrder(event) {
		setSortField(event.sortField);
		setSortOrder(event.sortOrder);
	}

	function handlePageChange(event) {
		setSize(event.rows);
		setRows(event.rows);
		setPage(event.page);
	}

	function handleClickInputSearch(value) {
		setValorPesquisa(value);
	}

	// function handleSearch() {
	//   fetchOrdensServico();
	// }

	async function handleSearchFiltroAvancado(filtro) {
		setLimparFiltroPesquisaAvancada(false);
		setFiltroAvancado(filtro);
	}

	function handleChangeFiltroRsql(rsql) {
		setFiltroAvancado(rsql);
	}

	function handleChangeDescricaoFiltro(value) {
		setDescricaoFiltroAvancado(value);
	}

	function handleClickDeletar(row) {
		confirm('Atenção', `Você tem certeza que deseja <b>excluir</b> a ordem de serviço número ${row.numero}?`, () => {
			asyncDeleteOrdemServico(row.id, 'Ordem de serviço excluida com sucesso', () => {
				fetchOrdensServico();
			});
		});
	}

	return (
		<>
			<Tutorial
				steps={tutorialStepsListagens}
				showSkipButton
				continuous
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
				disableScrolling
			/>
			<Form header="Ordem de serviço">
				<FormActions>
					<Button
						className="step-listagem-novo p-button-primary"
						label={isMobile ? 'Nova ordem' : 'Nova ordem de serviço'}
						icon="fa fa-plus"
						title="Inserir uma nova ordem de serviço"
						onClick={handleClickNovo}
						podeInserir={podeInserir}
						disabled={!podeInserir}
					/>
				</FormActions>
				<FormContent>
					<Grid justifyCenter verticalAlignCenter>
						<span style={{ padding: '12px' }}>
							<DateInterval onChange={handleChangeInterval} interval={interval} />
						</span>
						<InputSearch
							id="OrdemServicoInputSearch"
							className="step-listagem-input-search"
							value={valorPesquisa}
							onChange={handleClickInputSearch}
							onPesquisar={fetchCallback}
						/>
						<span className="step-listagem-filtro-avancado" style={{ padding: '12px' }}>
							<PesquisaAvancada
								optionsFiltros={OPTIONS_FILTRO_AVANCADO_ORDEM_SERVICO}
								onPesquisarClick={handleSearchFiltroAvancado}
								onChangeFiltroRsql={handleChangeFiltroRsql}
								onChangeDescricaoFiltro={handleChangeDescricaoFiltro}
								limparFiltro={limparFiltroPesquisaAvancada}
							/>
						</span>
					</Grid>
					<Grid justifyBetween>
						<Cards cards={cards} selectedCard={selectedCard} setSelectedCard={setSelectedCard} />
						<DescricaoFiltroAvancado texto={descricaoFiltroAvancado} />
						<TabelaOrdensServico
							isMobile={isMobile}
							history={history}
							registros={registros}
							rows={size}
							page={page}
							sortField={sortField}
							sortOrder={sortOrder}
							totalRecords={totalRecords}
							handlePageChange={handlePageChange}
							handleChangeOrder={handleChangeOrder}
							handleClickDeletar={handleClickDeletar}
							registroSelecionado={registroSelecionado}
							setRegistroSelecionado={setRegistroSelecionado}
							setVisibleModalHistorico={setVisibleModalHistorico}
						/>
					</Grid>
				</FormContent>
			</Form>
			<If test={visibleModalHistorico}>
				<ModalHistorico
					header="Histórico da ordem de serviço"
					visible={visibleModalHistorico}
					onHide={() => setVisibleModalHistorico(false)}
					mapping="ordem_servico"
					id={registroSelecionado.id}
					showIcon={false}
				/>
			</If>
		</>
	);
}

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
});

export const OrdemServico = connect(mapStateToProps)(OrdemServicoImpl);
