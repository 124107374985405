import { format, formatISO , parse } from 'date-fns';

import { formatarMonetario } from '../../../../../../Common/Mascara';
import { montarLabelPessoa } from '../../../../../../components/select/SingleSelectPessoa/Util';

export function converterContaPagarParaFormulario(values) {
	return {
		id: values.id,
		filialId: values.filialId,
		descricao: values.descricao,
		categorias: converterContaPagarCategoriasParaFormulario(values.categorias),
		tipo: values.tipo,
		emissao: values.emissao,
		formaPagamento: converterValueParaSelect(values.formaPagamento),
		conta: converterValueParaSelect(values.conta),
		vencimento: values.vencimento,
		valor: values.valor,
		ocorrencia: values.ocorrencia,
		quantidadeRepeticoes: values.quantidadeRepeticoes,
		itemRepeticao: values.itemRepeticao ?? null,
		compraId: values.compraId,
		status: values.status,

		pago: false,
		valorPago: null,
		dataPago: formatISO(new Date()),
		contaPago: null,

		pagamentos: converterPagamentosParaFormulario(values.pagamentos),

		pessoa: converterPessoaParaFormulario(values.pessoa),
		competencia: values.competencia,
		dataDocumento: values.dataDocumento,
		numeroDocumento: values.numeroDocumento,
		observacao: values.observacao,

		compensacaoAutomatica: values.compensarAutomaticamente,
		tipoCompensacao: values.tipoCompensacao,
		dataCompensacao: values.dataCompensacao,
		tipoTaxa: values.tipoTaxa,
		taxaCategoria: converterValueParaSelect(values.taxaCategoria),
		taxaAliquota: values.taxaAliquota,
		taxaValor: values.taxaValor,

		reparcelamentoOrigem: values.reparcelamentoOrigem ?? null,
		reparcelamentoDestino: values.reparcelamentoDestino ?? null,
	};
}

function converterPessoaParaFormulario(pessoaApi) {
	if (pessoaApi) {
		return {
			label: montarLabelPessoa(pessoaApi),
			value: pessoaApi.id,
			registro: pessoaApi,
		};
	}
	return null;
}

export function converterValueParaSelect(value, label) {
	return value
		? {
				value: value.id ?? null,
				label: label ?? value.nome ?? value.descricao,
				registro: value,
			}
		: null;
}

function converterContaPagarCategoriasParaFormulario(values){
	const categorias = [];
	if (values) {
		values?.forEach(registro =>{
			const contaPagarCategoria = {
				categoria: converterCategoriaParaFormulario(registro.categoria),
				valor: registro.valor,
				percentual: registro.percentual,
			};
			categorias.push(contaPagarCategoria);
		});
	}
	return categorias;
}

function converterCategoriaParaFormulario(categoriaApi) {
	if (categoriaApi) {
		return {
			label: categoriaApi.nome,
			value: categoriaApi.id,
			registro: categoriaApi,
		};
	}
	return null;
}

function converterPagamentosParaFormulario(pagamentosApi) {
	if (!pagamentosApi) {
		return [];
	}
	const pagamentosFormulario = pagamentosApi.map((pagamentoApi) => {
		const data = pagamentoApi.data ? parse(pagamentoApi.data, 'yyyy-MM-dd', new Date()) : null;
		return {
			...pagamentoApi,
			data: pagamentoApi.data,
			dataLabel: data ? format(data, 'dd/MM/yyyy') : null,
			valorLabel: formatarMonetario(pagamentoApi.valor),
			jurosLabel: formatarMonetario(pagamentoApi.juros),
			descontosLabel: formatarMonetario(pagamentoApi.descontos),
			multaLabel: formatarMonetario(pagamentoApi.multa),
			totalLabel: formatarMonetario(pagamentoApi.total),
			conta: converterContaParaFormulario(pagamentoApi.conta),
			formaPagamento: converterValueParaSelect(pagamentoApi.formaPagamento),
			pagamentoNovoOuAlterado: pagamentoApi.pagamentoNovoOuAlterado || false,
		};
	});
	return pagamentosFormulario;
}

function converterContaParaFormulario(contaApi) {
	if (contaApi) {
		return {
			label: contaApi.nome,
			value: contaApi.id,
			registro: contaApi,
		};
	}
	return null;
}

function converterContaPagarCategoriasParaApi(values){
	const categorias = [];
	if (values) {
		values.forEach(registro =>{
			const contaPagarCategoria = {
				categoria: {id: registro.categoria.value},
				valor: registro.valor,
				percentual: registro.percentual,
			};
			categorias.push(contaPagarCategoria);
		});
	}
	return categorias;
}

function converterPagamentosParaApi(values) {
	let pagamentosApi = [];
	if (values.pago) {
		pagamentosApi.push({
			valor: values.valorPago,
			data: values.dataPago,
			formaPagamento: values.formaPagamento ? { id: values.formaPagamento.value } : null,
			conta: values.contaPago ? { id: values.contaPago.value } : null,
			juros: 0,
			multa: 0,
			desconto: 0,
			pagamentoNovoOuAlterado: values.pagamentoNovoOuAlterado || false,
		});
	} else {
		pagamentosApi = values.pagamentos.map((pagamentoFormulario) => ({
				...pagamentoFormulario,
				formaPagamento: pagamentoFormulario.formaPagamento ? { id: pagamentoFormulario.formaPagamento.value } : null,
				conta: pagamentoFormulario.conta ? { id: pagamentoFormulario.conta.value } : null,
				juros: pagamentoFormulario.juros || 0,
				multa: pagamentoFormulario.multa || 0,
				desconto: pagamentoFormulario.desconto || 0,
				pagamentoNovoOuAlterado: pagamentoFormulario.pagamentoNovoOuAlterado || false,
			}));
	}
	return pagamentosApi;
}

export function converterContaPagarParaApi(values) {
	return {
		...values,
		categorias: converterContaPagarCategoriasParaApi(values.categorias),
		formaPagamento: values.formaPagamento ? { id: values.formaPagamento.value } : null,
		conta: values.conta ? { id: values.conta.value } : null,
		pessoa: values.pessoa ? { id: values.pessoa.value } : null,
		pagamentos: values.pagamentos || values.pago ? converterPagamentosParaApi(values) : null,
		compraId: values.compraId ?? null,
		compensarAutomaticamente: values.compensacaoAutomatica ?? false,
		dataCompensacao: values.dataCompensacao ?? null,
		taxaCategoria: values.taxaCategoria ? { id: values.taxaCategoria.value ?? values.taxaCategoria } : null,
		taxaValor: values.taxaValor ?? null,
		taxaAliquota: values.taxaAliquota ?? null,
		tipoCompensacao: values.tipoCompensacao ?? null,
		tipoTaxa: values.tipoTaxa ?? null,
	};
}
