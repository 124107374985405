import { Button, ButtonNovo, notify, ToastTypes } from 'components';
import { MdOutlineAttachMoney } from 'react-icons/md';
import { FaPrint, FaRandom } from 'react-icons/fa';
import { CONTA_RECEBER_STATUS } from 'views/financas/components/ModalReparcelamentoTitulos/Utils/constantes';
import { asyncGetImprimirPromissoria } from '../../Requests';

function ActionButtons({
	podeInserir,
	podeReparcelar,
	setExibirModalContaReceber,
	setRegistroSelecionado,
	setExibirModalQuitacaoTitulos,
	setExibirModalReparcelamentoTitulos,
	documentosSelecionados,
}) {
	function handleExibirRecebimentoTitulos() {
		let mostrarModal = true;

		if (Object.keys(documentosSelecionados)?.length === 0) {
			mostrarModal = false;
		}

		documentosSelecionados?.forEach((documento) => {
			if ([CONTA_RECEBER_STATUS.RECEBIDA, CONTA_RECEBER_STATUS.REPARCELADA].includes(documento.status)) {
				mostrarModal = false;
			}
		});

		if (mostrarModal) {
			setExibirModalQuitacaoTitulos(true);
		} else {
			notify('Recebimento em lote não permite títulos já quitados ou reparcelados', ToastTypes.ERROR);
		}
	}

	function handleExibirReparcelamentoTitulos() {
		let mostrarModal = true;
		let hasErrorTituloPago = false;
		const hasPessoasDiferentesSelecionadas = existePessoasDiferentesSelecionadas();

		if (Object.keys(documentosSelecionados)?.length === 0) {
			mostrarModal = false;
		}

		if (hasPessoasDiferentesSelecionadas) {
			mostrarModal = false;
		}

		documentosSelecionados?.forEach((documento) => {
			if (documento.status !== 'NAO_RECEBIDA') {
				mostrarModal = false;
				hasErrorTituloPago = true;
			}
		});

		if (mostrarModal) {
			setExibirModalReparcelamentoTitulos(true);
		} else if (hasErrorTituloPago) {
			notify('Reparcelamento de títulos permite apenas títulos não quitados', ToastTypes.ERROR);
		} else if (hasPessoasDiferentesSelecionadas) {
			notify('Reparcelamento de títulos permite apenas títulos da mesma pessoa ou sem pessoa', ToastTypes.ERROR);
		}
	}

	function handleExibirImprimirPromissoria() {
		let imprimir = true;
		let listaContaReceber = '';

		if (Object.keys(documentosSelecionados)?.length === 0) {
			imprimir = false;
		}

		documentosSelecionados?.forEach((documento) => {
			if ([CONTA_RECEBER_STATUS.RECEBIDA, CONTA_RECEBER_STATUS.REPARCELADA].includes(documento.status)) {
				imprimir = false;
			}
		});

		if (documentosSelecionados) {
			listaContaReceber = documentosSelecionados.map(item => `'${item.id}'`).join(',');
		  }

		if (imprimir) {
			imprimirPromissoria(listaContaReceber)
		} else {
			notify('Impressão de promissória(s) em lote não permite títulos já quitados ou reparcelados', ToastTypes.ERROR);
		}
	}

	async function imprimirPromissoria(listaContaReceber) {
		await asyncGetImprimirPromissoria(listaContaReceber, async ({ data: arquivo }) => {
			const arquivoPDF = new Blob([arquivo], { type: 'application/pdf' });
			const arquivoURL = URL.createObjectURL(arquivoPDF);
			const promissoria = window.open(arquivoURL);
			if (promissoria) {
				promissoria.onload = function onLoadPromissoria() {
					setTimeout(() => {
						promissoria.document.title = 'Nota promissória';
					}, 250);
				};
			}
		});
	}
	

	function existePessoasDiferentesSelecionadas() {
		const pessoasSelecionadas = new Set();

		documentosSelecionados?.forEach((titulo) => {
			if (titulo?.pessoaCodigo) {
				pessoasSelecionadas.add(titulo.pessoaCodigo);
			}
		});

		return pessoasSelecionadas.size > 1;
	}

	return (
		<>
			<ButtonNovo
				label="Nova conta a receber"
				title="Inserir uma nova conta a receber"
				onClick={() => {
					setExibirModalContaReceber(true);
					setRegistroSelecionado(null);
				}}
				disabled={!podeInserir}
			/>
			<Button
				label="Recebimento de títulos"
				title="Recebimento em lote de títulos"
				color="success"
				icon={<MdOutlineAttachMoney size={20} />}
				onClick={() => handleExibirRecebimentoTitulos()}
				disabled={Object.keys(documentosSelecionados)?.length === 0}
			/>
			<Button
				label="Reparcelamento de títulos"
				title="Reparcelamento em lote de títulos"
				color="danger"
				icon={<FaRandom size={20} style={{ paddingRight: 5 }} />}
				onClick={() => handleExibirReparcelamentoTitulos()}
				disabled={Object.keys(documentosSelecionados)?.length === 0 || !podeReparcelar}
			/>
			<Button
				label="Imprimir promissória(s)"
				title="Imprimir promissória(s) em lote"
				color="primary"
				icon={<FaPrint size={20} style={{ paddingRight: 5 }} />}
				onClick={() => handleExibirImprimirPromissoria()}
				disabled={Object.keys(documentosSelecionados)?.length === 0 || !podeReparcelar}
			/>
		</>
	);
}

export { ActionButtons };
