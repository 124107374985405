import { buscarDadosLoginLocalStorage } from 'Common';
import { services } from '../../../../Common/Constantes/api';
import { post, get, del, exibirToast, put } from '../../../../Common/Requisicoes';
import { notify } from '../../../../components/Toast';
import { formatISO } from 'date-fns';

//LISTAGEM

export async function asyncGetPesquisaDocumentos(url, onSuccess, onError, exibirLoading = true) {
	return await get(url, null, onSuccess, onError, exibirLoading);
}

export async function asyncGetTotalizadores(interval, arquivados, onSuccess, onError) {
	if (interval.dataInicial && interval.dataFinal) {
		return await get(
			`${services.GESTOR}/v1/documentos/gestao_documentos/totais?dataInicio=${formatISO(interval.dataInicial, {
				representation: 'date',
			})}&dataFim=${formatISO(interval.dataFinal, {
				representation: 'date',
			})}&arquivados=${arquivados}`,
			null,
			onSuccess,
			onError
		);
	}
}

export async function asyncGetPastasNivelRaiz() {
	return new Promise((resolve, reject) => {
		get(
			`${services.GESTOR}/v1/pastas/nivel_raiz`,
			null,
			({ data }) => resolve(data),
			(error) => reject(error)
		);
	});
}

export async function asyncGetPastasNivelFilho(id) {
	return new Promise((resolve, reject) => {
		get(
			`${services.GESTOR}/v1/pastas/${id}/filhos`,
			null,
			({ data }) => resolve(data),
			(error) => reject(error)
		);
	});
}

export async function asyncGetDocumentosParaPastas(url) {
	return new Promise((resolve, reject) => {
		get(
			url,
			null,
			(data) => resolve(data),
			(error) => reject(error)
		);
	});
}

export async function atualizarDocumentosParaNovaPasta(idDocumentos, idPasta, onSuccess, onError) {
	return await put(
		`${services.GESTOR}/v1/documentos/gestao_documentos/atualizar_pastas_documentos?idPastaSelecionada=${idPasta}`,
		idDocumentos,
		null,
		onSuccess,
		onError
	);
}

export async function limparPastasDocumentos(idDocumentos, onSuccess, onError) {
	return await put(
		`${services.GESTOR}/v1/documentos/gestao_documentos/limpar_pastas_documentos`,
		idDocumentos,
		null,
		onSuccess,
		onError
	);
}

//DOCUMENTOS

export async function asyncArquivarGestaoDocumento(
	idDocumento,
	onSuccess,
	onError,
	exibirLoading = true,
	mostrarToast = true
) {
	await post(
		`${services.GESTOR}/v1/documentos/gestao_documentos/${idDocumento}/arquivar`,
		null,
		null,
		(e) => {
			if (mostrarToast) {
				notify('Documento arquivado com sucesso');
			}
			onSuccess && onSuccess(e);
		},
		onError,
		exibirLoading
	);
}
export async function asyncDesarquivarGestaoDocumento(
	idDocumento,
	onSuccess,
	onError,
	exibirLoading = true,
	mostrarToast = true
) {
	await post(
		`${services.GESTOR}/v1/documentos/gestao_documentos/${idDocumento}/desarquivar`,
		null,
		null,
		(e) => {
			if (mostrarToast) {
				notify('Documento desarquivado com sucesso');
			}
			onSuccess && onSuccess(e);
		},
		onError,
		exibirLoading
	);
}
export async function asyncExcluirDocumento(idDocumento, onSuccess, onError, exibirLoading = true) {
	await del(
		`${services.GESTOR}/v1/documentos/gestao_documentos/${idDocumento}`,
		null,
		onSuccess,
		onError,
		exibirLoading
	);
}

export async function asyncCreateDocumento(documento, onSuccess, mostrarToast = true) {
	return await post(`${services.GESTOR}/v1/documentos/gestao_documentos`, documento, null, (e) => {
		if (mostrarToast) {
			notify('Documento criado com sucesso.');
		}
		onSuccess && onSuccess(e);
	});
}

export async function asyncGetDocumento(idDocumento, onSuccess, onError) {
	return await get(`${services.GESTOR}/v1/documentos/gestao_documentos/${idDocumento}`, null, onSuccess, onError);
}

export async function asyncUpdateDocumento(documento, onSuccess, mostrarToast = true) {
	return await put(`${services.GESTOR}/v1/documentos/gestao_documentos/${documento.id}`, documento, null, (e) => {
		if (mostrarToast) {
			notify('Documento atualizado com sucesso.');
		}
		onSuccess && onSuccess(e);
	});
}

export async function asyncDeleteDocumento(idDocumento, onSuccess, onError) {
	return await del(
		`${services.GESTOR}/v1/documentos/gestao_documentos/${idDocumento}`,
		null,
		exibirToast(onSuccess, 'Documento excluído com sucesso.', onError)
	);
}

export async function asyncRescisaoDocumento(idDocumento, onSuccess, onError, mostrarToast = true) {
	return await post(`${services.GESTOR}/v1/documentos/gestao_documentos/${idDocumento}/rescindir`, null, null, (e) => {
		if (mostrarToast) {
			notify('Documento rescindido com sucesso.');
		}
		onSuccess && onSuccess(e);
	});
}

export async function asyncCancelarRescisaoDocumento(idDocumento, onSuccess, onError, mostrarToast = true) {
	return await post(
		`${services.GESTOR}/v1/documentos/gestao_documentos/${idDocumento}/cancelar_rescisao`,
		null,
		null,
		(e) => {
			if (mostrarToast) {
				notify('Cancelada a rescisão do documento com sucesso.');
			}
			onSuccess && onSuccess(e);
		}
	);
}

// ANEXOS
export function asyncGetAnexosResumo(url, onSuccess, onError, exibirLoading = true) {
	return get(url, null, onSuccess, onError, exibirLoading);
}

export async function asyncCreateAnexo(anexo, onSucess, onError) {
	return await post(
		`${services.GESTOR}/v1/documentos/gestao_documentos/anexo`,
		anexo,
		null,
		exibirToast(onSucess, 'Anexo criado com sucesso.'),
		onError
	);
}

export async function asyncGetAnexo(idAnexo, onSuccess, onError) {
	return await get(`${services.GESTOR}/v1/documentos/gestao_documentos/anexo/${idAnexo}`, null, onSuccess, onError);
}

export async function asyncUpdateAnexo(anexo, onSuccess, onError) {
	return await put(
		`${services.GESTOR}/v1/documentos/gestao_documentos/anexo/${anexo.id}`,
		anexo,
		null,
		exibirToast(onSuccess, 'Anexo atualizado com sucesso.'),
		onError
	);
}

export async function asyncDeleteAnexo(idAnexo, onSuccess, onError) {
	return await del(
		`${services.GESTOR}/v1/documentos/gestao_documentos/anexo/${idAnexo}`,
		null,
		exibirToast(onSuccess, 'Anexo excluído com sucesso.', onError)
	);
}

export async function asyncAdicionarArquivosGestaoDocumento(documentoId, arquivos, onSuccess, onError) {
	const formData = new FormData();
	arquivos?.forEach((arquivo) => {
		formData.append('arquivos', arquivo);
	});

	await post(
		`${services.GESTOR}/v1/documentos/gestao_documentos/${documentoId}/arquivos`,
		formData,
		{ headers: { 'content-type': 'multipart/form-data' } },
		onSuccess,
		onError
	);
}

export async function asyncAdicionarArquivosAnexo(anexoId, arquivos, onSuccess, onError) {
	const formData = new FormData();
	arquivos?.forEach((arquivo) => {
		formData.append('arquivos', arquivo);
	});

	await post(
		`${services.GESTOR}/v1/documentos/gestao_documentos/anexos/${anexoId}/arquivos`,
		formData,
		{ headers: { 'content-type': 'multipart/form-data' } },
		onSuccess,
		onError
	);
}

export async function asyncBaixarArquivo(idArquivo, isAnexo, onSuccess, onError) {
	let url = `${services.GESTOR}/v1/documentos/gestao_documentos/arquivos/${idArquivo}`;

	isAnexo
		? (url = `${services.GESTOR}/v1/documentos/gestao_documentos/anexos/arquivos/${idArquivo}`)
		: (url = `${services.GESTOR}/v1/documentos/gestao_documentos/arquivos/${idArquivo}`);

	return await get(url, { responseType: 'arraybuffer' }, onSuccess, onError);
}

export async function asyncGetConfiguracoesEmpresa(onSuccess) {
	await get(`${services.GESTOR}/v1/empresa`, null, onSuccess);
}

export async function asyncBuscarResponsavel() {
	let dados = {};
	const dadosLogin = buscarDadosLoginLocalStorage();
	const { setorFavorito } = await asyncBuscarSetoresUsuario();

	dados.setor = setorFavorito;

	await get(
		`${services.GESTOR}/v1/documentos/relacoes/usuarios?query=credencial.id==${dadosLogin.id}`,
		null,
		({ data }) => {
			dados.usuario = data.content[0];
		},
		null,
		false
	);

	return dados;
}

async function asyncBuscarSetoresUsuario() {
	let result = {};

	await get(
		`${services.GESTOR}/v1/documentos/relacoes/setores`,
		null,
		({ data }) => {
			result.setores = data.content;
			result.setorFavorito = data.content.find((setor) => setor.favorito);
		},
		null,
		false
	);

	return result;
}
