import { Button, ButtonNovo, notify, ToastTypes } from 'components';
import { FaRandom } from 'react-icons/fa';
import { MdOutlineAttachMoney } from 'react-icons/md';
import { CONTA_PAGAR_STATUS } from 'views/financas/components/ModalReparcelamentoTitulos/Utils/constantes';

function ActionButtons({
	podeInserir,
	podeReparcelar,
	setExibirModalContaPagar,
	setRegistroSelecionado,
	documentosSelecionados,
	setExibirModalReparcelamentoTitulos,
	setExibirModalQuitacaoTitulos,
}) {
	function handleExibirPagamentoTitulos() {
		let mostrarModal = true;

		if (Object.keys(documentosSelecionados)?.length === 0) {
			mostrarModal = false;
		}

		documentosSelecionados?.forEach((documento) => {
			if ([CONTA_PAGAR_STATUS.PAGA, CONTA_PAGAR_STATUS.REPARCELADA].includes(documento.status)) {
				mostrarModal = false;
			}
		});

		if (mostrarModal) {
			setExibirModalQuitacaoTitulos(true);
		} else {
			notify('Pagamento em lote não permite títulos já quitados ou reparcelados', ToastTypes.ERROR);
		}
	}

	function handleExibirReparcelamentoTitulos() {
		let mostrarModal = true;
		let hasErrorTituloPago = false;
		const hasPessoasDiferentesSelecionadas = existePessoasDiferentesSelecionadas();

		if (Object.keys(documentosSelecionados)?.length === 0) {
			mostrarModal = false;
		}

		if (hasPessoasDiferentesSelecionadas) {
			mostrarModal = false;
		}

		documentosSelecionados?.forEach((documento) => {
			if (documento.status !== 'NAO_PAGA') {
				mostrarModal = false;
				hasErrorTituloPago = true;
			}
		});

		if (mostrarModal) {
			setExibirModalReparcelamentoTitulos(true);
		} else if (hasErrorTituloPago) {
			notify('Reparcelamento de títulos permite apenas títulos não quitados', ToastTypes.ERROR);
		} else if (hasPessoasDiferentesSelecionadas) {
			notify('Reparcelamento de títulos permite apenas títulos da mesma pessoa ou sem pessoa', ToastTypes.ERROR);
		}
	}

	function existePessoasDiferentesSelecionadas() {
		const pessoasSelecionadas = new Set();

		documentosSelecionados?.forEach((titulo) => {
			if (titulo?.pessoaCodigo) {
				pessoasSelecionadas.add(titulo.pessoaCodigo);
			}
		});

		return pessoasSelecionadas.size > 1;
	}

	return (
		<>
			<ButtonNovo
				label="Nova conta a pagar"
				title="Inserir uma nova conta a pagar"
				onClick={() => {
					setExibirModalContaPagar(true);
					setRegistroSelecionado(null);
				}}
				disabled={!podeInserir}
			/>
			<Button
				label="Pagamento de títulos"
				title="Pagamento em lote de títulos"
				color="success"
				icon={<MdOutlineAttachMoney size={20} />}
				onClick={() => handleExibirPagamentoTitulos()}
				disabled={Object.keys(documentosSelecionados)?.length === 0}
			/>
			<Button
				label="Reparcelamento de títulos"
				title="Reparcelamento em lote de títulos"
				color="danger"
				icon={<FaRandom size={20} style={{ paddingRight: 5 }} />}
				onClick={() => handleExibirReparcelamentoTitulos()}
				disabled={Object.keys(documentosSelecionados)?.length === 0 || !podeReparcelar}
			/>
		</>
	);
}

export default ActionButtons;
