export function buscarUltimoParametroDaUrl(url) {
	const urlCortada = url.split('/');
	return urlCortada[urlCortada.length - 1];
}

export function removerCaracteres(valor, caracteres) {
	if (!valor) {
		return valor;
	}
	const expressaoRegular = '[' + caracteres.join('\\') + ']';
	const regexp = new RegExp(expressaoRegular, 'g');
	return valor.replace(regexp, '');
}

export function replaceCaracteresEspeciais(valor) {
	let resultado = valor;

	resultado = resultado.replace(/[&]/g, '%26');
	resultado = resultado.replace(/[#]/g, '%23');
	resultado = resultado.replace(/["]/g, '%22');
	resultado = resultado.replace(/[\[]/g, '%5B');
	resultado = resultado.replace(/[\]]/g, '%5D');
	resultado = resultado.replace(/[`]/g, '%60');
	resultado = resultado.replace(/[|]/g, '%7C');
	resultado = resultado.replace(/[\\]/g, '%5C');
	resultado = resultado.replace(/[\^]/g, '%5E');
	resultado = resultado.replace(/[\{]/g, '%7B');
	resultado = resultado.replace(/[\}]/g, '%7D');

	return resultado;
}

export function formatarDataParaPesquisa(valor) {
	let resultado = valor;

	if (typeof valor !== 'string') {
		return resultado;
	}

	function converterParaFormatoPadrao(data) {
		const partes = data.split(/[-/.]/);

		if (partes.length === 3) {
			const [dia, mes, ano] = partes.map(Number);
			if (ano && mes >= 1 && mes <= 12 && dia >= 1 && dia <= 31) {
				return `${ano}-${String(mes).padStart(2, '0')}-${String(dia).padStart(2, '0')}`;
			}
		}

		if (partes.length === 2) {
			const [dia, mes] = partes.map(Number);
			if (mes >= 1 && mes <= 12 && dia >= 1 && dia <= 31) {
				return `${String(mes).padStart(2, '0')}-${String(dia).padStart(2, '0')}`;
			}
		}

		return null;
	}
	const dataConvertida = converterParaFormatoPadrao(valor);

	if (dataConvertida) {
		resultado = dataConvertida;
	}

	return resultado;
}

export function removerAcentos(valor) {
	let resultado = valor;

	resultado = resultado.replace(/[ÀÁÂÃÄÅ]/g, 'A');
	resultado = resultado.replace(/[àáâãäå]/g, 'a');
	resultado = resultado.replace(/[ÈÉÊËẼ]/g, 'E');
	resultado = resultado.replace(/[éèêẽ]/g, 'e');
	resultado = resultado.replace(/[ÍÌĨ]/g, 'I');
	resultado = resultado.replace(/[íìĩ]/g, 'i');
	resultado = resultado.replace(/[ÓÒÔÕ]/g, 'O');
	resultado = resultado.replace(/[óòôõ]/g, 'o');
	resultado = resultado.replace(/[ÚÙÛŨ]/g, 'U');
	resultado = resultado.replace(/[ç]/g, 'c');
	resultado = resultado.replace(/[Ç]/g, 'C');
	resultado = resultado.replace(/[´`^~¨]/g, '');

	return resultado;
}

export function formatarParaPesquisarTiposEnumerados(valor) {
	let resultado = removerAcentos(valor);
	resultado = resultado.replace(/[/ -]/g, '_');
	return resultado;
}

export function formatarParaPesquisarParaCamposBoolean(valorDigitado, valorEsperadoParaTrue, valorEsperadoParaFalse) {
	let resultado = String(removerAcentos(valorDigitado)).toLowerCase();

	if (String(valorEsperadoParaTrue).toLowerCase().includes(resultado)) {
		return true;
	} else if (String(valorEsperadoParaFalse).toLowerCase().includes(resultado)) {
		return false;
	}
}

export function validarUUID(uuid) {
	//https://stackoverflow.com/questions/7905929/how-to-test-valid-uuid-guid
	return /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(uuid);
}

export function validarEmail(email) {
	var re =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}

export function gerarUUID() {
	return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
		//eslint-disable-next-line
		(c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
	);
}

export function buscarDescricaoTamanhoArquivo(bytes) {
	var sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'HB', 'YB'];

	if (bytes === 0) return '0 Byte';
	var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
	return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

export function titlelize(text) {
	let loweredText = text.toLowerCase();
	let wordsArray = loweredText.split(' ');

	let words = wordsArray.map((word) => {
		let firstLetter = word[0];
		if (word.length > 2) {
			return firstLetter.toUpperCase() + word.slice(1);
		} else {
			return firstLetter + word.slice(1);
		}
	});
	return words.join(' ');
}
