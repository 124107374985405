import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import {
	buscarConfiguracaoUsuario,
	configuracoesUsuario,
	construirUrl,
	formatarParaPesquisarTiposEnumerados,
	permissoes,
	recursos,
	removerCaracteres,
	removerElemento,
	salvarConfiguracaoUsuario,
	services,
	usuarioPossuiPermissao,
} from 'Common';
import {
	Badge,
	ButtonEditarTable,
	ButtonExcluirTable,
	ButtonNovo,
	Checkbox,
	Col,
	DescricaoFiltroAvancado,
	Form,
	FormActions,
	FormContent,
	Grid,
	InputSearch,
	NenhumRegistroEncontrado,
	Paginacao,
	PesquisaAvancada,
	Tutorial,
	tutorialStepsListagens,
} from 'components';

import { atualizarUrl } from 'views/Util';
import { confirmarExclusao } from 'views/Util/ExclusaoDeRegistros';
import { useContextPesquisa } from 'views/Util/Context/ContextPesquisa';
import { deleteVeiculo, readVeiculos } from './Requests';
import { COLORS, OPTIONS_FILTRO_AVANCADO, STYLE_BACKGROUND } from './Util/constantes';
import { formatarPlaca } from './Util/function';

function VeiculosTransporteImpl({ isMobile, history }) {
	const {
		valorPesquisa,
		setValorPesquisa,
		page,
		setPage,
		rows,
		setRows,
		sortOrder,
		setSortOrder,
		sortField,
		setSortField,
		filtroAvancado,
		setFiltroAvancado,
		descricaoFiltroAvancado,
		setDescricaoFiltroAvancado,
		exibirBloqueadas,
		setExibirBloqueadas,
	} = useContextPesquisa();

	const [registros, setRegistros] = useState([]);
	const [totalElements, setTotalElements] = useState(0);
	const [tutorialVisible, setTutorialVisible] = useState(false);
	const [firstRender, setFirstRender] = useState(true);

	const deveExibirTutorial = buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS);
	const podeInserir = usuarioPossuiPermissao(recursos.TRANSPORTE, permissoes.INSERIR);
	const podeEditar = usuarioPossuiPermissao(recursos.TRANSPORTE, permissoes.EDITAR);
	const podeExcluir = usuarioPossuiPermissao(recursos.TRANSPORTE, permissoes.EXCLUIR);

	const sortFieldLocal = sortField?.length > 0 ? sortField : 'placa';

	const pesquisarCallback = useCallback(() => {
		if (!firstRender) {
			pesquisar();
		}
	});

	useEffect(() => {
		pesquisar();

		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS, false, null, false);
		}

		setTimeout(() => {
			if (document.getElementById('input-search-veiculos')) {
				document.getElementById('input-search-veiculos').focus();
			}
		}, 500);
	}, []);

	useEffect(() => {
		pesquisarCallback();
	}, [exibirBloqueadas, page, rows, sortField, filtroAvancado]);

	useEffect(() => {
		setPage(0);
	}, [valorPesquisa]);

	async function pesquisar() {
		let filtro = buscarFiltro();
		filtro += `${exibirBloqueadas ? '' : ';situacao=="ATIVO"'}`;
		const url = construirUrl(
			`${services.GESTOR}/v1/transporte/veiculos/resumo`,
			filtro,
			rows,
			page,
			sortOrder > 0 ? `${sortField},asc` : `${sortField},desc`
		);

		await readVeiculos(url, ({ data }) => {
			setRegistros(data.content);
			setTotalElements(data.page.totalElements);
			setFirstRender(false);

			if (!exibirBloqueadas && !!filtroAvancado && filtroAvancado.includes('situacao=="INATIVO"')) {
				setExibirBloqueadas(true);
			}
		});
	}

	function buscarFiltro() {
		const valorTiposEnumerados = formatarParaPesquisarTiposEnumerados(removerCaracteres(valorPesquisa, ['&']));
		const pesquisaCodigo = removerCaracteres(valorPesquisa, ['.', '/', '-', '&']);

		let result = `?query=(placa=contains="*${pesquisaCodigo}*",renavam=contains="*${valorTiposEnumerados}*",tipo=contains="*${valorTiposEnumerados}*",situacao=contains="*${valorTiposEnumerados}*")`;

		if (filtroAvancado) {
			result += `;${filtroAvancado}`;
		}

		return result;
	}

	async function onPesquisarFiltroAvancado(filtro) {
		setFiltroAvancado(filtro);
	}

	function onSort(event) {
		setSortOrder(event.sortOrder);
		setSortField(event.sortField);
	}

	function onChangeCheckboxExibirInativas(element) {
		setExibirBloqueadas(element.checked);
	}

	function aplicarEstiloInativa(row, field) {
		if (row.situacao === 'INATIVO') {
			return (
				<span style={{ fontStyle: 'italic', opacity: '0.7' }}>{typeof field === 'object' ? field.sigla : field}</span>
			);
		}
		return (
			<span
				style={{
					display: 'flex',
					wordBreak: 'break-all',
					paddingLeft: isMobile ? '15%' : '0',
				}}
			>
				{typeof field === 'object' ? field.sigla : field}
			</span>
		);
	}

	function renderSituacao(row) {
		const styleDescription = {
			fontStyle: row.situacao === 'INATIVO' ? 'italic' : '',
			opacity: row.situacao === 'INATIVO' ? '0.7' : '',
			margin: row.situacao === 'ATIVO' ? '3px 16px' : '3px 10px',
		};

		switch (row.situacao) {
			case 'ATIVO': {
				return Badge(COLORS.textAtivo, COLORS.bgAtivo, 'Ativo', STYLE_BACKGROUND, styleDescription);
			}
			case 'INATIVO': {
				return Badge(COLORS.textInativo, COLORS.bgInativo, 'Inativo', STYLE_BACKGROUND, styleDescription);
			}
			default:
				return row.situacao;
		}
	}

	function renderOpcoes(row) {
		return (
			<div style={{ display: 'flex' }}>
				<ButtonEditarTable onClick={() => handleClickEditar(row)} disabled={!podeEditar} />
				<ButtonExcluirTable onClick={() => handleClickExcluir(row)} podeExcluir={podeExcluir} />
			</div>
		);
	}

	function handleClickEditar(row) {
		atualizarUrl(history, '/transporte/veiculos/cadastro', row.id);
	}

	function handleClickExcluir(row) {
		confirmarExclusao(() => deleteRegistro(row));
	}

	async function deleteRegistro(registro) {
		await deleteVeiculo(registro.id, () => {
			setRegistros(removerElemento(registros, registro));
			setTotalElements(totalElements - 1);
		});
	}

	function handlePageChange(event) {
		setRows(event.rows);
		setPage(event.page);
	}

	function getTipoValue(value) {
		return value === 'TRACAO' ? 'Tração' : 'Reboque';
	}

	return (
		<>
			<Tutorial
				steps={tutorialStepsListagens}
				showSkipButton
				continuous
				disableScrolling
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>
			<Form header="Veículos">
				<FormActions>
					<ButtonNovo
						className="step-listagem-novo"
						label="Novo veículo"
						onClick={() => {
							history.push('/transporte/veiculos/cadastro');
						}}
						podeInserir={podeInserir}
					/>
				</FormActions>
				<FormContent>
					<Grid justifyCenter>
						<InputSearch
							id="input-search-veiculos"
							className="step-listagem-input-search"
							onPesquisar={() => pesquisar(0)}
							value={valorPesquisa}
							onChange={(value) => setValorPesquisa(value)}
						/>
						<Col sm="12" md="4" lg="3" xl="3" className="step-listagem-filtro-avancado">
							<PesquisaAvancada
								className="step-listagem-filtro-avancado"
								optionsFiltros={OPTIONS_FILTRO_AVANCADO}
								onPesquisarClick={onPesquisarFiltroAvancado}
								onChangeFiltroRsql={(rsql) => setFiltroAvancado(rsql, () => {})}
								onChangeDescricaoFiltro={(descricao) => setDescricaoFiltroAvancado(descricao)}
							/>
						</Col>
					</Grid>
					<DescricaoFiltroAvancado texto={descricaoFiltroAvancado} />
					<DataTable
						className="table"
						rowClassName="table-row"
						cellClassName="table-row-cell"
						responsive
						value={registros}
						sortField={sortFieldLocal}
						sortOrder={sortOrder}
						onSort={onSort}
						emptyMessage={<NenhumRegistroEncontrado />}
						header={
							<div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
								<span>
									<Checkbox
										name="exibirBloqueadas"
										inputId="exibirBloqueadas"
										checked={exibirBloqueadas}
										onChange={onChangeCheckboxExibirInativas}
										label="Exibir veículos inativos"
									/>
								</span>
							</div>
						}
					>
						<Column
							field="placa"
							header="Placa"
							body={(row) => aplicarEstiloInativa(row, formatarPlaca(row.placa))}
							sortable
							style={{ width: '20%' }}
						/>
						<Column
							field="renavam"
							header="Renavam"
							body={(row) => aplicarEstiloInativa(row, row.renavam)}
							sortable
							style={{ width: '20%' }}
						/>
						<Column
							field="tipo"
							header="Tipo"
							body={(row) => aplicarEstiloInativa(row, getTipoValue(row.tipo))}
							sortable
							style={{ width: '20%' }}
						/>
						<Column
							field="estado"
							header="Estado"
							body={(row) => aplicarEstiloInativa(row, row.uf)}
							sortable
							style={{ width: '20%' }}
						/>
						<Column field="situacao" header="Situação" body={renderSituacao} sortable style={{ width: '15%' }} />
						<Column className="step-listagem-acoes" header="Ações" body={renderOpcoes} style={{ width: '7em' }} />
					</DataTable>
					<Paginacao totalElements={totalElements} rows={rows} page={page} onPageChange={handlePageChange} />
				</FormContent>
			</Form>
		</>
	);
}

function mapStateToProps(state) {
	return {
		isMobile: state.dispositivo.isMobile,
	};
}

export const VeiculosTransporte = connect(mapStateToProps)(VeiculosTransporteImpl);
