import { useEffect } from 'react';
import * as Yup from 'yup';
import { validarFormulario } from 'views/Util';
import { isValid, parseISO, format, formatISO, startOfMonth } from 'date-fns';
import { withFormik, Field } from 'formik';
import { Dropdown, Grid, InputDate, MultipleSelectNcms } from 'components';
import { mensagensDeValidacao } from 'Common';
import { asyncBaixarRelatorioFaturamentoPorNcm } from 'views/fiscal/vendas/NFe/Requests';
import ModalRelatorio from '../../../../../../components/ModalRelatorio';
import { helpMessage } from './util/constantes';

const initialValue = {
  dataInicial: formatISO(startOfMonth(new Date())),
  dataFinal: new Date().toISOString(),
  documentos: 'Todos',
  operacoes: 'Todas',
  setores: null,
};

function FaturamentoPorNcm(props) {
  const { visible, onHide, values, handleSubmit, validateForm, setFieldValue } = props;

  useEffect(() => {
    setTimeout(() => {
      document.getElementById('InputDataInicial')?.focus();
    }, 500);
  }, []);

  async function imprimir() {
    await handleSubmit();
    await validateForm();

    try {
      const dataInicialFormatada = format(parseISO(values.dataInicial), 'yyyy-MM-dd');
      const dataFinalFormatada = format(parseISO(values.dataFinal), 'yyyy-MM-dd');
      let ncms = '';

      if (values.ncms) {
        ncms = values.ncms.map(item => `'${item.value}'`).join(',');
      }

      if (await validarFormulario(props)) {
        await asyncBaixarRelatorioFaturamentoPorNcm(
          dataInicialFormatada,
          dataFinalFormatada,
          values.documentos,
          values.operacoes,
          ncms,
          ({ data: pdf }) => {
            const arquivoPDF = new Blob([pdf], { type: 'application/pdf' });
            const arquivoURL = URL.createObjectURL(arquivoPDF);
            const relatorio = window.open(arquivoURL);
            if (relatorio) {
              relatorio.onload = () => {
                setTimeout(() => {
                  relatorio.document.title = 'Relatório faturamento por NCM';
                }, 250);
              };
            }
            onHide();
          }
        );
      }
    } catch (e) {
      console.error(e);
    }
  }

  const parametros = (
    <Grid>
      <Field
        sm="12"
        md="6"
        lg="3"
        xl="3"
        component={InputDate}
        label="Data inicial"
        name="dataInicial"
        obrigatorio
        helpMessage={helpMessage.dataInicial}
        value={values.dataInicial}
        touched
        id="InputDataInicial"
      />
      <Field
        sm="12"
        md="6"
        lg="3"
        xl="3"
        component={InputDate}
        label="Data final"
        name="dataFinal"
        obrigatorio
        helpMessage={helpMessage.dataFinal}
        value={values.dataFinal}
        touched
      />
      <Field
        sm="12"
        md="6"
        lg="3"
        xl="3"
        component={Dropdown}
        label="Documento(s)"
        name="documentos"
        onChange={e => setFieldValue('documentos', e.value)}
        options={[
          { value: 'NF-e', label: 'NF-es' },
          { value: 'NFC-e', label: 'NFC-es' },
          { value: 'Todos', label: 'NF-es e NFC-es' },
        ]}
        value={values.documentos}
        showClear={false}
      />
      <Field
        sm="12"
        md="6"
        lg="3"
        xl="3"
        component={Dropdown}
        label="Operacão(es)"
        name="operacoes"
        onChange={e => setFieldValue('operacoes', e.value)}
        options={[
          { value: 'ENTRADA', label: 'Entrada' },
          { value: 'SAIDA', label: 'Saída' },
          { value: 'Todas', label: 'Entrada e saída' },
        ]}
        value={values.operacoes}
        showClear={false}
      />
      <Field
        sm="12"
        md="12"
        lg="12"
        xl="12"
        component={MultipleSelectNcms}
        label="NCM(s)"
        name="ncm"
        onChange={e => setFieldValue('ncms', e)}
        value={values.ncms}
        showClear={false}
        utilizaFavorito
      />
    </Grid>
  );
  return (
    <ModalRelatorio
      header="Relatório faturamento por NCM"
      visible={visible}
      onHide={onHide}
      onImprimir={imprimir}
      parametros={parametros}
      isValid={Object.keys(props.errors).length === 0}
    />
  );
}

const ModalFaturamentoPorNcm = withFormik({
  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: false,

  mapPropsToValues() {
    return initialValue;
  },

  validate(values) {
    const errors = {};

    if (!values.dataInicial || values.dataInicial === '') {
      errors.dataInicial = mensagensDeValidacao.OBRIGATORIO;
    }

    if (!values.dataFinal || values.dataFinal === '') {
      errors.dataFinal = mensagensDeValidacao.OBRIGATORIO;
    }

    if (values.dataInicial && !isValid(parseISO(values.dataInicial))) {
      errors.dataInicial = mensagensDeValidacao.DATA_INVALIDA;
    }

    if (values.dataFinal && !isValid(parseISO(values.dataFinal))) {
      errors.dataFinal = mensagensDeValidacao.DATA_INVALIDA;
    }

    return errors;
  },

  validationSchema: Yup.object().shape({
    dataInicial: Yup.string().nullable().required(mensagensDeValidacao.DATA_INVALIDA),
    dataFinal: Yup.string().nullable().required(mensagensDeValidacao.DATA_INVALIDA),
  }),

  handleSubmit: () => {},
})(FaturamentoPorNcm);

export default ModalFaturamentoPorNcm;
