import { services } from 'Common';
import { Grid, InputDouble, SingleSelectPastas, SingleSelectSetor, SingleSelectUsuario } from 'components';
import { Field, useFormikContext } from 'formik';

function FormResponsavel({ informacoesPermissoes, disabledAlertaVigencia }) {
	const { values, setFieldValue } = useFormikContext();

	return (
		<Grid>
			<Field
				sm="12"
				md="6"
				lg="3"
				xl="3"
				component={SingleSelectPastas}
				name="pasta"
				label="Pasta"
				value={values.pasta}
				onChange={(selectedNode) => {
					setFieldValue('pasta', selectedNode?.value);
				}}
				{...informacoesPermissoes}
			/>

			<Field
				sm="12"
				md="6"
				lg="3"
				xl="3"
				component={SingleSelectUsuario}
				name="usuario"
				label="Usuário"
				obrigatorio
				url={`${services.GESTOR}/v1/documentos/relacoes/usuarios`}
				value={values.usuario}
				onChange={(e) => setFieldValue('usuario', e)}
				esconderBotao
				isClearable={false}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="6"
				lg="3"
				xl="3"
				component={SingleSelectSetor}
				name="setor"
				label="Setor"
				obrigatorio
				url={`${services.GESTOR}/v1/documentos/relacoes/setores`}
				onChange={(e) => setFieldValue('setor', e)}
				value={values.setor}
				esconderBotao
				isClearable={false}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="6"
				lg="3"
				xl="3"
				component={InputDouble}
				label="Alerta antes do fim da vigência"
				name="diasAlertaVigencia"
				value={values.diasAlertaVigencia}
				onChange={(e) => setFieldValue('diasAlertaVigencia', e.target.value)}
				decimalScale={0}
				suffix=" dia(s)"
				allowNegative={false}
				obrigatorio={!disabledAlertaVigencia}
				title={disabledAlertaVigencia ? 'Parametro desabilitado nas configurações' : 'Alerta antes do fim da vigência'}
				disabled={disabledAlertaVigencia}
				{...informacoesPermissoes}
			/>
		</Grid>
	);
}

export { FormResponsavel };
