import { addMonths, formatISO } from 'date-fns';
import {
	CONDICAO_PAGAMENTO_COMPENSACAO_TIPO,
	CONDICAO_PAGAMENTO_TAXA_TIPO,
} from 'views/cadastros/financas/CondicaoPagamento/Util/constantes';
import { tipoCampos } from '../../../../components/PesquisaAvancada/Util/constantes';

export const OPTIONS_FILTRO_AVANCADO_CONTAS_PAGAR = [
	{
		label: 'Status',
		name: 'status',
		type: tipoCampos.SELECT,
		optionSelect: [
			{ label: 'Não paga', value: 'NAO_PAGA' },
			{ label: 'Parcialmente paga', value: 'PARCIALMENTE_PAGA' },
			{ label: 'Paga', value: 'PAGA' },
		],
	},
	{ label: 'Descrição', name: 'descricao', type: tipoCampos.STRING },
	{ label: 'Valor', name: 'valor', type: tipoCampos.DECIMAL },
	{ label: 'Observação', name: 'observacao', type: tipoCampos.STRING },
];

export const PERIODICIDADE_DROPDOWN = [
	{ label: 'Dias', value: 'DIARIA' },
	{ label: 'Semanas', value: 'SEMANAL' },
	{ label: 'Meses', value: 'MENSAL' },
	{ label: 'Trimestres', value: 'TRIMESTRAL' },
	{ label: 'Semestres', value: 'SEMESTRAL' },
	{ label: 'Anos', value: 'ANUAL' },
];

export const TIPO_CONTA_PAGAR = {
	UNICA: 'UNICA',
	VALOR_FIXO: 'VALOR_FIXO',
	VALOR_PARCELADO: 'VALOR_PARCELADO',
};

export const STYLE_DESCRICAO_PAGAMENTO = {
	margin: '0',
	width: '100%',
	textAlign: 'end',
	padding: '0.5em',
};

export const INITIAL_VALUES = {
	id: null,
	descricao: null,
	categorias: [],
	tipo: 'UNICA',
	emissao: formatISO(new Date()),
	formaPagamento: null,
	conta: null,
	vencimento: formatISO(addMonths(new Date(), 1)),
	valor: 0,
	ocorrencia: 'MENSAL',
	quantidadeRepeticoes: 2,

	pago: false,
	valorPago: null,
	dataPago: formatISO(new Date()),
	contaPago: null,

	pagamentos: [],

	pessoa: null,
	competencia: formatISO(new Date()),
	dataDocumento: formatISO(new Date()),
	numeroDocumento: null,
	observacao: null,

	compensacaoAutomatica: false,
	tipoCompensacao: CONDICAO_PAGAMENTO_COMPENSACAO_TIPO.VENCIMENTO,
	dataCompensacao: formatISO(addMonths(new Date(), 1)),
	tipoTaxa: CONDICAO_PAGAMENTO_TAXA_TIPO.PERCENTUAL,
	taxaCategoria: null,
	taxaAliquota: 0,
	taxaValor: 0,

	activeIndex: null,
};
