import { del, exibirToast, get, post, put, services } from 'Common';

export async function asyncGetPastasNivelRaiz(onSuccess, onError) {
	await get(`${services.GESTOR}/v1/pastas/nivel_raiz`, null, onSuccess, onError);
}

export async function asyncGetPastasNivelFilho(id, onSuccess, onError) {
	await get(`${services.GESTOR}/v1/pastas/${id}/filhos`, null, onSuccess, onError);
}

export async function asyncPutDesvincularDocumentos(id, onSuccess, onError) {
	await put(
		`${services.GESTOR}/v1/pastas/${id}/desvincular`,
		{},
		null,
		exibirToast(onSuccess, 'Documento(s) atualizado(s) com sucesso'),
		onError
	);
}

export async function asyncDeletarPastas(id, onSuccess, onError) {
	await del(`${services.GESTOR}/v1/pastas/${id}`, null, exibirToast(onSuccess, 'Pasta excluída com sucesso'), onError);
}

export async function asyncPostPastas(pasta, onSuccess, onError) {
	await post(`${services.GESTOR}/v1/pastas`, pasta, null, exibirToast(onSuccess, 'Pasta criada com sucesso'), onError);
}

export async function asyncPutPastas(id, pasta, onSuccess, onError) {
	await put(
		`${services.GESTOR}/v1/pastas/${id}`,
		pasta,
		null,
		exibirToast(onSuccess, 'Pasta atualizada com sucesso'),
		onError
	);
}
