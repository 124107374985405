import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { format, parse } from 'date-fns';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { CiDiscount1 } from 'react-icons/ci';

import {
	buscarConfiguracaoUsuario,
	colors,
	configuracoesUsuario,
	construirUrl,
	permissoes,
	recursos,
	removerElemento,
	salvarConfiguracaoUsuario,
	services,
	usuarioPossuiPermissao,
} from 'Common';
import {
	Badge,
	ButtonEditarTable,
	ButtonExcluirTable,
	ButtonNovo,
	Col,
	DescricaoFiltroAvancado,
	Form,
	FormActions,
	FormContent,
	Grid,
	InputSearch,
	NenhumRegistroEncontrado,
	Paginacao,
	PesquisaAvancada,
	Tutorial,
	tutorialStepsListagens,
} from 'components';

import { useContextPesquisa } from 'views/Util/Context/ContextPesquisa';
import { atualizarUrl } from 'views/Util';
import { confirmarExclusao } from 'views/Util/ExclusaoDeRegistros';

import { deleteTabelaPreco, readTabelasPreco } from './Requests';
import { CADASTRO_URL, OPTIONS_FILTRO_AVANCADO, TABELA_PRECO_INFORMACOES_SITUACAO } from './Util/constantes';

function TabelaPrecoImpl({ history, isMobile }) {
	const {
		valorPesquisa,
		setValorPesquisa,
		setSortField,
		sortField,
		setSortOrder,
		sortOrder,
		page,
		setPage,
		rows,
		setRows,
		filtroAvancado,
		setFiltroAvancado,
		descricaoFiltroAvancado,
		setDescricaoFiltroAvancado,
	} = useContextPesquisa();

	const [registros, setRegistros] = useState([]);
	const [totalElements, setTotalElements] = useState(1);
	const [isFirstRender, setIsFirstRender] = useState(true);
	const [tutorialVisible, setTutorialVisible] = useState(false);

	const deveExibirTutorial = buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS);
	const podeInserir = usuarioPossuiPermissao(recursos.CADASTROS_TABELAS_PRECO, permissoes.INSERIR);
	const podeEditar = usuarioPossuiPermissao(recursos.CADASTROS_TABELAS_PRECO, permissoes.EDITAR);
	const podeExcluir = usuarioPossuiPermissao(recursos.CADASTROS_TABELAS_PRECO, permissoes.EXCLUIR);
	const sortFieldLocal = sortField?.length > 0 ? sortField : 'codigo';

	const fetchCallback = useCallback(() => {
		if (!isFirstRender) {
			fetch();
		}
	});

	useEffect(() => {
		fetch();

		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS, false, null, false);
		}

		setTimeout(() => {
			if (document.getElementById('input-search-tabela_preco')) {
				document.getElementById('input-search-tabela_preco')?.focus();
			}
		}, 500);
	}, []);

	useEffect(() => {
		fetchCallback();
	}, [page, rows, sortOrder, sortFieldLocal, filtroAvancado]);

	useEffect(() => {
		setPage(0);
	}, [valorPesquisa]);

	async function fetch() {
		const filtro = getFilter();
		console.trace();

		const url = construirUrl(
			`${services.GESTOR}/v1/vendas/tabelas_preco/resumo`,
			filtro || '?',
			rows,
			page,
			sortOrder > 0 ? `${sortFieldLocal},asc` : `${sortFieldLocal},desc`
		);

		await readTabelasPreco(url, ({ data }) => {
			setRegistros(data.content);
			setTotalElements(data.page.totalElements);
			setIsFirstRender(false);
		});
	}

	function getFilter() {
		let filter = String('?query=(')
			.concat(`codigo=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
			.concat(`nome=contains="*${valorPesquisa.replaceAll('&', '%26')}*")`);

		if (filtroAvancado) {
			filter = filter.concat(`;(${filtroAvancado})`);
		}

		return filter;
	}

	async function fetchFiltroAvancado(filtro) {
		setFiltroAvancado(filtro);
	}

	function onSort(event) {
		setSortOrder(event.sortOrder);
		setSortField(event.sortField);
	}

	function handleChangePage(event) {
		setRows(event.rows);
		setPage(event.page);
	}

	function renderFieldSituacao(row) {
		const styleBadge = {
			borderRadius: '20px',
			fontWeight: 'bold',
			fontSize: '13px',
			display: 'flex',
			height: '1.5rem',
			width: '7rem',
			alignItems: 'center',
			justifyContent: 'center',
		};
		const situacaoTabelaPreco = TABELA_PRECO_INFORMACOES_SITUACAO[row.situacao];

		return Badge(
			situacaoTabelaPreco?.strongColor,
			situacaoTabelaPreco?.lightColor,
			situacaoTabelaPreco?.name,
			styleBadge
		);
	}

	function renderOptions(row) {
		return (
			<div style={{ display: 'flex' }}>
				<ButtonEditarTable onClick={() => handleClickEditar(row)} disabled={!podeEditar} />
				<ButtonExcluirTable onClick={() => handleClickExcluir(row)} podeExcluir={podeExcluir} />
			</div>
		);
	}

	function handleClickEditar(row) {
		atualizarUrl(history, CADASTRO_URL, row.id);
	}

	function handleClickExcluir(row) {
		confirmarExclusao(() => deleteRegistro(row));
	}

	async function deleteRegistro(registro) {
		await deleteTabelaPreco(registro.id, () => {
			setRegistros(removerElemento(registros, registro));
			setTotalElements(totalElements - 1);
		});
	}

	function renderFieldData(row) {
		const label = row.dataVencimento ? format(parse(row.dataVencimento, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy') : null;

		return (
			<span
				title={label ?? 'Sem vencimendo informado'}
				style={label ? null : { display: 'flex', justifyContent: 'center' }}
			>
				{label ?? '-'}
			</span>
		);
	}

	function renderFieldName(row) {
		return (
			<span style={{ display: 'flex', alignItems: 'center' }}>
				<span>{row.nome}</span>
				{row.tabelaPromocional ? (
					<span style={{ marginLeft: '4px', display: 'flex' }}>
						<CiDiscount1 size={22} color={colors.verde} title="Tabela promocional" />
					</span>
				) : null}
			</span>
		);
	}

	return (
		<>
			<Tutorial
				steps={tutorialStepsListagens}
				showSkipButton
				continuous
				disableScrolling
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>
			<Form header="Tabelas de preço">
				<FormActions>
					<ButtonNovo
						className="step-listagem-novo"
						label={isMobile ? 'Nova tabela' : 'Nova tabela de preço'}
						onClick={() => history.push(CADASTRO_URL)}
						podeInserir={podeInserir}
						style={{ height: '32px' }}
					/>
				</FormActions>
				<FormContent>
					<Grid justifyCenter>
						<InputSearch
							id="input-search-tabela_preco"
							className="step-listagem-input-search"
							onPesquisar={() => fetch()}
							value={valorPesquisa}
							onChange={(value) => setValorPesquisa(value)}
						/>
						<Col sm="12" md="4" lg="3" xl="3" className="step-listagem-filtro-avancado">
							<PesquisaAvancada
								className="step-listagem-filtro-avancado"
								optionsFiltros={OPTIONS_FILTRO_AVANCADO}
								onPesquisarClick={fetchFiltroAvancado}
								onChangeFiltroRsql={(rsql) => setFiltroAvancado(rsql)}
								onChangeDescricaoFiltro={(e) => setDescricaoFiltroAvancado(e)}
							/>
						</Col>
					</Grid>
					<DescricaoFiltroAvancado texto={descricaoFiltroAvancado} />
					<DataTable
						className="table"
						rowClassName="table-row"
						cellClassName="table-row-cell"
						responsive
						value={registros}
						sortField={sortFieldLocal}
						sortOrder={sortOrder}
						onSort={onSort}
						emptyMessage={<NenhumRegistroEncontrado />}
					>
						<Column field="codigo" header="Código" body={(row) => row.codigo} style={{ width: '110px' }} sortable />
						<Column field="nome" header="Nome" body={renderFieldName} sortable />
						<Column
							header="Situação"
							field="situacao"
							sortable
							body={renderFieldSituacao}
							style={{ color: colors.cinzaDark, width: '130px' }}
						/>
						<Column
							field="dataVencimento"
							header="Vencimento"
							body={renderFieldData}
							style={{ width: '110px' }}
							sortable
						/>
						<Column className="step-listagem-acoes" body={renderOptions} header="Ações" style={{ width: '7em' }} />
					</DataTable>
					<Paginacao totalElements={totalElements} rows={rows} page={page} onPageChange={handleChangePage} />
				</FormContent>
			</Form>
		</>
	);
}

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
	isTablet: state.dispositivo.isTablet,
});

export const TabelaPreco = connect(mapStateToProps)(TabelaPrecoImpl);
