import { useLayoutEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FaFolderTree } from 'react-icons/fa6';
import { FaListUl } from 'react-icons/fa';

import { Button, tutorialStepsListagens, Tutorial, FormActions, Form, FormContent } from 'components';
import { recursos, permissoes, usuarioPossuiPermissao, salvarItemLocalStorage, buscarItemLocalStorage } from 'Common';

import { ListagemTable } from './components/ListagemTable';
import { TIPO_EXIBICAO } from './Util/constantes';
import { ListagemPastas } from './components/ListagemPastas';

function GestaoDocumentos({ isMobile, isTablet, isLessHd, history, location }) {
	const [tutorialVisible, setTutorialVisible] = useState(false);
	const [tipoExibicao, setTipoExibicao] = useState(null);
	const [pasta, setPasta] = useState(null);

	const podeInserir = usuarioPossuiPermissao(recursos.GESTAO_DOCUMENTOS, permissoes.INSERIR);
	const podeEditar = usuarioPossuiPermissao(recursos.GESTAO_DOCUMENTOS, permissoes.EDITAR);
	const podeExcluir = usuarioPossuiPermissao(recursos.GESTAO_DOCUMENTOS, permissoes.EXCLUIR);

	useLayoutEffect(() => {
		setTipoExibicao(buscarItemLocalStorage('tipoVisualizarDocumentos', true));
	}, []);

	function getHeader() {
		return (
			<div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
				<span>Gestão de documentos</span>
				<span>
					<Button
						title="Tipo de visualização dos documentos"
						style={{ display: 'flex', height: '32px' }}
						onClick={() => onClickTipoVisualizacaoDocumentos()}
					>
						{tipoExibicao === TIPO_EXIBICAO.LIST_TABLE ? <FaFolderTree /> : <FaListUl />}
					</Button>
				</span>
			</div>
		);
	}

	function onClickTipoVisualizacaoDocumentos() {
		const tipoVisualizacaoDocumento =
			tipoExibicao === TIPO_EXIBICAO.LIST_TABLE ? TIPO_EXIBICAO.TREE_TABLE : TIPO_EXIBICAO.LIST_TABLE;
		setTipoExibicao(tipoVisualizacaoDocumento);
		salvarItemLocalStorage('tipoVisualizarDocumentos', tipoVisualizacaoDocumento, true);
	}

	return (
		<>
			<Tutorial
				steps={tutorialStepsListagens}
				showSkipButton
				continuous
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
				disableScrolling
			/>
			<Form header={getHeader()}>
				<FormActions>
					<Button
						label="Novo documento"
						className="p-button-primary"
						icon="fa fa-plus"
						title="Inserir um novo documento"
						onClick={() => {
							history.push({
								pathname: '/documentos/gestao_documentos/cadastro',
								state: { pastaId: pasta ? pasta.id : null },
							});
						}}
						disabled={!podeInserir}
						style={{ margin: '5px' }}
					/>
				</FormActions>
				<FormContent>
					{tipoExibicao === TIPO_EXIBICAO.LIST_TABLE ? (
						<ListagemTable
							isMobile={isMobile}
							isLessHd={isLessHd}
							location={location}
							history={history}
							setTutorialVisible={setTutorialVisible}
						/>
					) : (
						<ListagemPastas
							isMobile={isMobile}
							isTablet={isTablet}
							isLessHd={isLessHd}
							history={history}
							location={location}
							podeInserir={podeInserir}
							podeEditar={podeEditar}
							podeExcluir={podeExcluir}
							pastaAnterior={pasta}
							setPastaAnterior={setPasta}
						/>
					)}
				</FormContent>
			</Form>
		</>
	);
}

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
	isTablet: state.dispositivo.isTablet,
	isLessHd: state.dispositivo.isLessHd,
});

export default connect(mapStateToProps)(GestaoDocumentos);
