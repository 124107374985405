import { modulos } from '../../Common';

export const metodosAtualizarUrl = {
	PUSH: 'PUSH',
	POP: 'POP',
};

export const APLICACAO = {
	DOCS_DIGITAIS: 'DOCS_DIGITAIS',
	MSYS_GESTOR: 'MSYS_GESTOR',
	GESTOR_MEI: 'GESTOR_MEI',
};

export async function validarFormulario(props) {
	const validacao = await props.validateForm(props.values);	
	return Object.getOwnPropertyNames(validacao).length === 0;
}

export function atualizarUrl(history, urlBase, idSelecionado, metodo = metodosAtualizarUrl.PUSH) {
	let url = urlBase;

	if (idSelecionado) {
		url += '/' + idSelecionado;
	}

	if (url !== history?.location.pathname) {
		if (metodo === metodosAtualizarUrl.POP) {
			history?.replace(url);
		} else {
			history?.push(url);
		}
	}
}

export function voltarParaAPesquisa(history, pesquisaUrl) {
	history.push(pesquisaUrl);
}

export function buscarUUIDDaUrl(history) {
	return history ? history.location.search.substring(1) : '';
}

export function aplicarPercentual(valorTotal, percentual) {
	let valor = (percentual / 100) * valorTotal;
	valor = Math.floor(valor * 100) / 100;

	return valor;
}

export function tituloAplicacao() {
	if (window.location.href.includes('docsdigitais')) {
		return 'DOCs Digitais';
	} else if (window.location.href.includes('gestormei')) {
		return 'Gestor MEI';
	} else {
		return 'Gestor';
	}
}

export function arquivoManifest() {
	if (window.location.href.includes('docsdigitais')) {
		return 'manifestDocsDigitais';
	} else if (window.location.href.includes('gestormei')) {
		return 'manifestGestorMei';
	} else {
		return 'manifestGestor';
	}
}

export function metaDescription() {
	if (window.location.href.includes('docsdigitais')) {
		return 'Crie sua conta gratuita e transforme a gestão e assinatura de documentos na sua empresa. Tempo pra você e resultados pra sua empresa. Conheça e comprove!';
	} else if (window.location.href.includes('gestormei')) {
		return 'Sistema de gestão online perfeito para microempreendedores que buscam praticidade e crescimento com baixo investimento. Gestão de vendas, estoque, finanças e muito mais, de qualquer lugar, fácil e seguro!';
	} else {
		return 'É o Sistema Online completo para gestão de vendas, compras, estoque e finanças num único lugar. MSYS GESTOR é controle total com máxima eficiência e mais lucros pra você!';
		
	}	
}	

export function retornarAplicacao() {
	if (window.location.href.includes('docsdigitais')) {
		return APLICACAO.DOCS_DIGITAIS;
	} else if (window.location.href.includes('gestormei')) {
		return APLICACAO.GESTOR_MEI;
	} else {
		return APLICACAO.MSYS_GESTOR;
	}
}

export function modulosDocs() {
	return [modulos.DOCUMENTOS];
}

export function modulosGestor() {
	return Object.keys(modulos).filter((modulo) => modulo !== modulos.ADMINISTRACAO && modulo !== modulos.DOCUMENTOS);
}
