import {
	buscarConfiguracaoUsuario,
	configuracoesUsuario,
	construirUrl,
	permissoes,
	recursos,
	removerElemento,
	salvarConfiguracaoUsuario,
	services,
	usuarioPossuiPermissao,
} from 'Common';
import {
	Badge,
	ButtonEditarTable,
	ButtonExcluirTable,
	ButtonNovo,
	Col,
	DescricaoFiltroAvancado,
	Form,
	FormActions,
	FormContent,
	Grid,
	InputSearch,
	NenhumRegistroEncontrado,
	Paginacao,
	PesquisaAvancada,
	Tutorial,
	tipoCampos,
	tutorialStepsListagens,
} from 'components';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Checkbox } from 'primereact/checkbox';
import { useEffect, useState } from 'react';
import { GoBookmark } from 'react-icons/go';
import { atualizarUrl } from 'views/Util';
import { useContextPesquisa } from 'views/Util/Context/ContextPesquisa';
import { confirmarExclusao } from 'views/Util/ExclusaoDeRegistros';
import { deleteCondicaoPagamento, readCondicoesPagamento, readFormasPagamento } from './Requests';
import {
	CADASTRO_URL,
	COLORS_INDICACAO_MOVIMENTO,
	COLORS_SITUACAO,
	OPTIONS_FILTRO_AVANCADO,
	CONDICAO_PAGAMENTO_SITUACAO,
	CONDICAO_PAGAMENTO_MOVIMENTACAO,
	CONDICAO_PAGAMENTO_TIPO_OPTIONS,
} from './Util/constantes';

function CondicaoPagamento({ isMobile, history }) {
	const {
		valorPesquisa,
		setValorPesquisa,
		setSortField,
		sortField,
		setSortOrder,
		sortOrder,
		page,
		setPage,
		rows,
		setRows,
		filtroAvancado,
		setFiltroAvancado,
		descricaoFiltroAvancado,
		setDescricaoFiltroAvancado,
		exibirBloqueadas,
		setExibirBloqueadas,
	} = useContextPesquisa();

	const [registros, setRegistros] = useState([]);
	const [totalElements, setTotalElements] = useState(1);
	const [tutorialVisible, setTutorialVisible] = useState(false);
	const [isFirstRender, setIsFirstRender] = useState(true);
	const [filtroAvancadoOptions, setFiltroAvancadoOptions] = useState(OPTIONS_FILTRO_AVANCADO);

	const deveExibirTutorial = buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS);
	const podeInserir = usuarioPossuiPermissao(recursos.FORMAS_PAGAMENTO, permissoes.INSERIR);
	const podeEditar = usuarioPossuiPermissao(recursos.FORMAS_PAGAMENTO, permissoes.EDITAR);
	const podeExcluir = usuarioPossuiPermissao(recursos.FORMAS_PAGAMENTO, permissoes.EXCLUIR);
	const sortFieldLocal = sortField?.length > 0 ? sortField : 'descricao';

	useEffect(() => {
		if (isFirstRender) {
			setSortOrder(1);
		}

		fetch();
	}, [exibirBloqueadas, filtroAvancado, rows, page]);

	useEffect(() => {
		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS, false, null, false);
		}

		setTimeout(() => {
			document.getElementById('input-search-condicao-pagamento')?.focus();
		}, 500);
	}, []);

	useEffect(() => {
		setPage(0);
	}, [valorPesquisa]);

	async function fetch() {
		const filtro = getFilter();
		const url = construirUrl(
			`${services.GESTOR}/v1/condicoes_pagamento/resumo`,
			filtro || '?',
			rows,
			page,
			sortOrder > 0 ? `${sortFieldLocal},asc` : `${sortFieldLocal},desc`
		);

		await readCondicoesPagamento(url, ({ data }) => {
			setRegistros(data.content);
			setTotalElements(data.page.totalElements);
			setIsFirstRender(false);
		});
	}

	function getFilter() {
		let filter = String('?query=')
			.concat(`(descricao=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
			.concat(`formaPagamento.descricao=contains="*${valorPesquisa.replaceAll('&', '%26')}*")`);

		filter += String(`${exibirBloqueadas ? `` : `;situacao=="${CONDICAO_PAGAMENTO_SITUACAO.ATIVO}"`}`);

		if (filtroAvancado) {
			filter = filter.concat(`;(${filtroAvancado})`);
		}

		return filter;
	}

	async function fetchFiltroAvancado(filtro) {
		setFiltroAvancado(filtro);
	}

	function onSort(event) {
		setSortOrder(event.sortOrder);
		setSortField(event.sortField);
	}

	function handleChangePage(event) {
		setRows(event.rows);
		setPage(event.page);
	}

	function onChangeCheckboxExibirInativas(element) {
		setExibirBloqueadas(element.checked);
	}

	function aplicarEstiloInativa(row, field) {
		if (row.situacao === CONDICAO_PAGAMENTO_SITUACAO.INATIVO) {
			return <span style={{ fontStyle: 'italic', opacity: '0.7' }}>{field}</span>;
		}
		return field;
	}

	function renderDescricao(row) {
		return (
			<span
				style={{
					display: 'flex',
					alignItems: 'center',
					wordBreak: 'break-word',
					maxWidth: isMobile ? '70%' : '100%',
					textAlign: isMobile ? 'end' : 'start',
				}}
			>
				{aplicarEstiloInativa(row, row.descricao)}
				{row.favorita ? (
					<span>
						<GoBookmark
							size={22}
							color="#fbc02d"
							title={`Condição de ${
								row.movimentacao === CONDICAO_PAGAMENTO_MOVIMENTACAO.ENTRADA ? 'entrada' : 'saída'
							} favorita`}
						/>
					</span>
				) : null}
			</span>
		);
	}

	function renderFormaPagamento(row) {
		return aplicarEstiloInativa(row, row.formaPagamento?.descricao ?? '-');
	}

	function renderTipo(row) {
		return aplicarEstiloInativa(
			row,
			CONDICAO_PAGAMENTO_TIPO_OPTIONS.filter((item) => item.value === row.tipo)[0]?.label
		);
	}

	function renderIndicacaoMovimento(row) {
		const styleBackground = {
			borderRadius: '20px',
			padding: '0.2rem 1.1rem',
		};

		const styleDescription = {
			fontStyle: row.situacao === CONDICAO_PAGAMENTO_SITUACAO.INATIVO ? 'italic' : '',
			opacity: row.situacao === CONDICAO_PAGAMENTO_SITUACAO.INATIVO ? '0.7' : '',
			margin: row.movimentacao === CONDICAO_PAGAMENTO_MOVIMENTACAO.ENTRADA ? '0.2rem 1.1rem' : '0.2rem 1.5rem',
		};

		switch (row.movimentacao) {
			case CONDICAO_PAGAMENTO_MOVIMENTACAO.ENTRADA: {
				return Badge(
					COLORS_INDICACAO_MOVIMENTO.textEntrada,
					COLORS_INDICACAO_MOVIMENTO.bgEntrada,
					'Entrada',
					styleBackground,
					styleDescription
				);
			}
			case CONDICAO_PAGAMENTO_MOVIMENTACAO.SAIDA: {
				return Badge(
					COLORS_INDICACAO_MOVIMENTO.textSaida,
					COLORS_INDICACAO_MOVIMENTO.bgSaida,
					'Saída',
					styleBackground,
					styleDescription
				);
			}
			default:
				return row.movimentacao;
		}
	}

	function renderSituacao(row) {
		const styleBackground = {
			borderRadius: '20px',
			padding: '0.2rem 1.1rem',
		};

		const styleDescription = {
			fontStyle: row.situacao === CONDICAO_PAGAMENTO_SITUACAO.INATIVO ? 'italic' : '',
			opacity: row.situacao === CONDICAO_PAGAMENTO_SITUACAO.INATIVO ? '0.7' : '',
			margin: row.situacao === CONDICAO_PAGAMENTO_SITUACAO.ATIVO ? '3px 16px' : '3px 10px',
		};

		switch (row.situacao) {
			case CONDICAO_PAGAMENTO_SITUACAO.ATIVO: {
				return Badge(COLORS_SITUACAO.textAtivo, COLORS_SITUACAO.bgAtivo, 'Ativa', styleBackground, styleDescription);
			}
			case CONDICAO_PAGAMENTO_SITUACAO.INATIVO: {
				return Badge(
					COLORS_SITUACAO.textInativo,
					COLORS_SITUACAO.bgInativo,
					'Inativa',
					styleBackground,
					styleDescription
				);
			}
			default:
				return row.situacao;
		}
	}

	function renderOptions(row) {
		return (
			<div style={{ display: 'flex' }}>
				<ButtonEditarTable onClick={() => handleClickEditar(row)} disabled={!podeEditar} />
				<ButtonExcluirTable
					onClick={() => handleClickExcluir(row)}
					podeExcluir={podeExcluir}
					disabled={row.movimentacao === CONDICAO_PAGAMENTO_MOVIMENTACAO.ENTRADA}
				/>
			</div>
		);
	}

	function handleClickEditar(row) {
		atualizarUrl(history, CADASTRO_URL, row.id);
	}

	function handleClickExcluir(row) {
		confirmarExclusao(() => deleteRegistro(row));
	}

	async function deleteRegistro(registro) {
		await deleteCondicaoPagamento(registro.id, () => {
			setRegistros(removerElemento(registros, registro));
			setTotalElements(totalElements - 1);
		});
	}

	async function fetchOptionsFiltroAvancado() {
		const options = [...filtroAvancadoOptions];

		function addOption(newOption) {
			let indexOption = 0;
			const hasOption = options.find((e, i) => {
				if (e.name === newOption.name) {
					indexOption = i;
					return true;
				}
				return false;
			});

			if (hasOption) {
				options.splice(indexOption, 1);
			}

			options.push(newOption);
		}

		const promises = [
			readFormasPagamento(({ data: response }) => {
				if (response.content) {
					const newOption = {
						label: 'Forma pagamento',
						name: 'formaPagamento.id',
						type: tipoCampos.SELECT,
						optionSelect: [],
					};
					response.content.forEach((option) => {
						newOption.optionSelect.push({ label: option.descricao, value: option.id });
					});

					addOption(newOption);
				}
			}),
		];

		await Promise.all(promises).then(() => {
			setFiltroAvancadoOptions(
				options.sort((a, b) => {
					if (a.label < b.label) {
						return -1;
					}
					if (a.label > b.label) {
						return 1;
					}
					return 0;
				})
			);
		});
	}

	return (
		<>
			<Tutorial
				steps={tutorialStepsListagens}
				showSkipButton
				continuous
				disableScrolling
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>
			<Form header="Condições de pagamento">
				<FormActions>
					<ButtonNovo
						className="step-listagem-novo"
						label="Nova condição"
						onClick={() => history.push(CADASTRO_URL)}
						podeInserir={podeInserir}
					/>
				</FormActions>
				<FormContent>
					<Grid justifyCenter>
						<InputSearch
							id="input-search-condicao-pagamento"
							className="step-listagem-input-search"
							onPesquisar={() => fetch(0)}
							value={valorPesquisa}
							onChange={setValorPesquisa}
						/>
						<Col sm="12" md="4" lg="3" xl="3" className="step-listagem-filtro-avancado">
							<PesquisaAvancada
								className="step-listagem-filtro-avancado"
								optionsFiltros={filtroAvancadoOptions}
								onPesquisarClick={fetchFiltroAvancado}
								onChangeFiltroRsql={setFiltroAvancado}
								onChangeDescricaoFiltro={setDescricaoFiltroAvancado}
								onOpen={fetchOptionsFiltroAvancado}
							/>
						</Col>
					</Grid>
					<DescricaoFiltroAvancado texto={descricaoFiltroAvancado} />
					<DataTable
						className="table"
						rowClassName="table-row"
						cellClassName="table-row-cell"
						responsive
						value={registros}
						sortField={sortFieldLocal}
						sortOrder={sortOrder}
						onSort={onSort}
						emptyMessage={<NenhumRegistroEncontrado />}
						header={
							<span style={{ display: 'flex', justifyContent: 'flex-end' }}>
								<Checkbox
									name="exibirBloqueadas"
									inputId="exibirBloqueadas"
									checked={exibirBloqueadas}
									onChange={onChangeCheckboxExibirInativas}
								/>
								<label htmlFor="exibirBloqueadas" className="p-checkbox-label">
									Exibir condições inativas
								</label>
							</span>
						}
					>
						<Column field="descricao" header="Descriçao" body={renderDescricao} style={{ width: '35%' }} sortable />
						<Column
							field="formaPagamento.descricao"
							header="Forma"
							body={renderFormaPagamento}
							style={{ width: '25%' }}
							sortable
						/>
						<Column field="tipo" header="Tipo" body={renderTipo} style={{ width: '17rem' }} sortable />
						<Column
							field="movimentacao"
							header="Indicação movimento"
							body={renderIndicacaoMovimento}
							sortable
							style={{ width: '15rem' }}
						/>
						<Column field="situacao" header="Situação" body={renderSituacao} sortable style={{ width: '12rem' }} />
						<Column className="step-listagem-acoes" body={renderOptions} header="Ações" style={{ width: '6%' }} />
					</DataTable>
					<Paginacao totalElements={totalElements} rows={rows} page={page} onPageChange={handleChangePage} />
				</FormContent>
			</Form>
		</>
	);
}

export { CondicaoPagamento };
