import { useReducer, useEffect } from 'react';
import { format, parseISO } from 'date-fns';

import { permissoes, recursos, usuarioPossuiPermissao } from 'Common';
import { Button, Col } from 'components';

import { atualizarUrl } from 'views/Util';
import { dataReducer } from './reducer';
import {
	asyncGetOrcamentoPorDia,
	asyncGetOrcamentoUltimosDozeMeses,
	asyncGetProdutosMaisVendidos,
	asyncGetRankingClientes,
	asyncGetRankingVendedores,
	asyncGetResultadoPeriodo,
} from './requests';
import { addDataProdutosVendidos, addDataRankingClientes, addDataRankingVendedores, setData } from './reducer/actions';
import { CardsResultado } from '../CardsResultado';
import { CardList } from '../CardList';
import { ChartPorDia } from '../ChartPorDia';
import { ChartUltimosDozeMeses } from '../ChartUltimosDozeMeses';

function DashboardOrcamento({ dataInicial, dataFinal, isMobile, history }) {
	const [
		{
			cardsResultado,
			produtosVendidos,
			rankingClientes,
			rankingVendedores,
			orcamentoPorDia,
			orcamentoUltimosDozeMeses,
			loading,
			podeInserirOrcamento,
		},
		dispatch,
	] = useReducer(
		dataReducer,
		{
			cardsResultado: {},
			produtosVendidos: {},
			rankingClientes: {},
			rankingVendedores: {},
			orcamentoPorDia: {},
			orcamentoUltimosDozeMeses: {},
			loading: true,
			podeInserirOrcamento: false,
		},
		(initialState) => ({
			...initialState,
			podeInserirOrcamento: usuarioPossuiPermissao(recursos.VENDAS_ORCAMENTOS, permissoes.INSERIR),
		})
	);
	const styleCards = isMobile ? { padding: '0.5em 0em', margin: '8px', flex: 'auto' } : { padding: '0.5em' };

	const styleContainerButton = {
		display: 'flex',
		justifyContent: isMobile ? 'center' : 'flex-start',
		padding: isMobile ? '15px 0 0 2px' : '0 0 0 2px',
	};

	const styleButton = {
		margin: isMobile ? '3px 5px' : '3px 7px',
		width: '185px',
	};

	useEffect(() => {
		fetchData();
	}, [dataInicial, dataFinal]);

	async function fetchData() {
		let newData = {
			loading: false,
		};
		const promises = [
			asyncGetResultadoPeriodo(dataInicial, dataFinal, ({ data }) => {
				const { periodoAtual, periodoAnterior } = data;
				const dataCards = {
					pendentes: {
						valor: periodoAtual.valorOrcamentoPendente,
						evolucao: calculateEvolution(periodoAtual.valorOrcamentoPendente, periodoAnterior.valorOrcamentoPendente),
					},
					finalizados: {
						valor: periodoAtual.valorOrcamentoFinalizado,
						evolucao: calculateEvolution(
							periodoAtual.valorOrcamentoFinalizado,
							periodoAnterior.valorOrcamentoFinalizado
						),
					},
					cancelados: {
						valor: periodoAtual.valorOrcamentoCancelada,
						evolucao: calculateEvolution(periodoAtual.valorOrcamentoCancelada, periodoAnterior.valorOrcamentoCancelada),
					},
				};

				newData = {
					...newData,
					cardsResultado: dataCards,
				};
			}),
			asyncGetProdutosMaisVendidos(dataInicial, dataFinal, 0, ({ data }) => {
				newData = {
					...newData,
					produtosVendidos: data,
				};
			}),
			asyncGetRankingClientes(dataInicial, dataFinal, 0, ({ data }) => {
				newData = {
					...newData,
					rankingClientes: data,
				};
			}),
			asyncGetRankingVendedores(dataInicial, dataFinal, 0, ({ data }) => {
				newData = {
					...newData,
					rankingVendedores: data,
				};
			}),
			asyncGetOrcamentoPorDia(dataInicial, dataFinal, ({ data }) => {
				const dias = [];
				const pendentes = [];
				const finalizados = [];
				const cancelados = [];

				data.forEach((day) => {
					dias.push(format(parseISO(day.data), 'dd/MM/yyyy'));
					pendentes.push(day.valorOrcamentoPendente);
					finalizados.push(day.valorOrcamentoFinalizado);
					cancelados.push(day.valorOrcamentoCancelada);
				});

				newData = {
					...newData,
					orcamentoPorDia: { dias, pendentes, finalizados, cancelados },
				};
			}),
			asyncGetOrcamentoUltimosDozeMeses(dataInicial, dataFinal, ({ data }) => {
				const meses = [];
				const pendentes = [];
				const finalizados = [];
				const cancelados = [];

				data.forEach((day) => {
					meses.push(day.competencia);
					pendentes.push(day.valorOrcamentoPendente);
					finalizados.push(day.valorOrcamentoFinalizado);
					cancelados.push(day.valorOrcamentoCancelada);
				});

				newData = {
					...newData,
					orcamentoUltimosDozeMeses: {
						meses,
						pendentes,
						finalizados,
						cancelados,
					},
				};
			}),
		];

		await Promise.all(promises).then(() => dispatch(setData(newData)));
	}

	async function handleClickVerMaisProdutosMaisVendidos(page) {
		await asyncGetProdutosMaisVendidos(dataInicial, dataFinal, page, ({ data }) => {
			dispatch(addDataProdutosVendidos(data));
		});
	}

	async function handleClickVerMaisRankingClientes(page) {
		await asyncGetRankingClientes(dataInicial, dataFinal, page, ({ data }) => {
			dispatch(addDataRankingClientes(data));
		});
	}

	async function handleClickVerMaisRankingVendedores(page) {
		await asyncGetRankingVendedores(dataInicial, dataFinal, page, ({ data }) => {
			dispatch(addDataRankingVendedores(data));
		});
	}

	function calculateEvolution(value, oldValue) {
		if (oldValue === 0) {
			return 0;
		}

		return ((value * 100) / oldValue - 100).toFixed(0);
	}

	function handleClickNovoOrcamento() {
		atualizarUrl(history, '/orcamento/cadastro');
	}

	return (
		<>
			<Col style={styleContainerButton}>
				<Button
					label="Novo orçamento"
					onClick={handleClickNovoOrcamento}
					style={styleButton}
					disabled={!podeInserirOrcamento}
					title={!podeInserirOrcamento ? 'Você não possui permissão para criar novos orçamentos' : null}
				/>
			</Col>
			<CardsResultado data={cardsResultado} isMobile={isMobile} />
			<Col sm="6" md="4" lg="4" xl="4" style={styleCards}>
				<CardList
					title="Produtos mais vendidos"
					helpMessage="Lista dos produtos mais vendidos no período selecionado"
					data={produtosVendidos.content || []}
					loading={loading}
					visibleButtonVerMais={((produtosVendidos?.page?.number ?? 0) + 1) * 10 < (produtosVendidos?.page?.totalElements ?? 0)}
					onClickVerMais={handleClickVerMaisProdutosMaisVendidos}
					page={produtosVendidos?.page?.number ?? 0}
				/>
			</Col>
			<Col sm="6" md="4" lg="4" xl="4" style={styleCards}>
				<CardList
					title="Ranking de clientes"
					helpMessage="Lista de clientes que mais utilizados no período"
					data={rankingClientes.content || []}
					loading={loading}
					visibleButtonVerMais={((rankingClientes?.page?.number ?? 0) + 1) * 10 < (rankingClientes?.page?.totalElements ?? 0)}
					onClickVerMais={handleClickVerMaisRankingClientes}
					page={rankingClientes?.page?.number ?? 0}
				/>
			</Col>
			<Col sm="6" md="4" lg="4" xl="4" style={styleCards}>
				<CardList
					title="Ranking de vendedores"
					helpMessage="Lista de vendedores que mais venderam no período"
					data={rankingVendedores.content || []}
					loading={loading}
					visibleButtonVerMais={((rankingVendedores?.page?.number ?? 0) + 1) * 10 < (rankingVendedores?.page?.totalElements ?? 0)}
					onClickVerMais={handleClickVerMaisRankingVendedores}
					page={rankingVendedores?.page?.number ?? 0}
				/>
			</Col>
			<Col style={styleCards}>
				<ChartPorDia
					title="Orçamentos por dia no período selecionado"
					helpMessage="Lista o valor total dos orçamentos por situação no período selecionado"
					data={orcamentoPorDia}
					loading={loading}
					isMobile={isMobile}
				/>
			</Col>
			<Col style={styleCards}>
				<ChartUltimosDozeMeses
					title="Orçamentos nos últimos 12 meses"
					label="Total de orçamentos no mês"
					helpMessage="Lista o valor total dos orçamentos agrupadas por mês nos últimos doze meses"
					data={orcamentoUltimosDozeMeses}
					loading={loading}
					isMobile={isMobile}
				/>
			</Col>
		</>
	);
}
export { DashboardOrcamento };
