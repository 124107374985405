import {
	buscarConfiguracaoUsuario,
	buscarDadosLoginLocalStorage,
	configuracoesUsuario,
	construirUrl,
	modulos,
	permissoes,
	recursos,
	salvarConfiguracaoUsuario,
	services,
	sincronizarInformacoesLogin,
	usePrevious,
	usuarioPossuiPermissao,
} from 'Common';
import { fazerLogin } from 'Common/Autenticacao';
import {
	ButtonCancelar,
	Card,
	Col,
	FormGroup,
	Grid,
	If,
	LayoutMenuSuperior,
	Paper,
	Tutorial,
	confirm,
	estadosBotaoCancelar,
	tutorialStepsPlanos,
} from 'components';
import parse from 'date-fns/parse';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useContextPlanos } from './Context';
import {
	asyncAderirAoPlano,
	asyncGetCartao,
	asyncGetHistorico,
	asyncGetInformacoesGeraisPlano,
	asyncGetPlano,
	asyncRemoverCartao,
} from './Requests';
import { METODO_PAGAMENTO } from './Util/constantes';
import { converterPlanoParaFormulario } from './Util/planoConverter';
import { dispararAcoesReduxPossuiSuporteViaChat } from './Util/redux';
import { CardHistorico } from './components/CardHistorico';
import { CardMeuCartao } from './components/CardMeuCartao';
import { CardPlano } from './components/CardPlano';
import { CardUtilizacao } from './components/CardUtilizacao';
import ModalConfirmarAdesao from './components/ModalConfirmarAdesao';
import { ModalDadosCartao } from './components/ModalDadosCartao';

const MeioPagamento = {
	SEM_PAGAMENTO: 'SEM_PAGAMENTO',
	SISTEMA_COBRANCA: 'SISTEMA_COBRANCA',
	BOLETO_BANCARIO: 'BOLETO_BANCARIO',
};

const avisoPlanoExpiradoStyle = {
	fontSize: '16px',
	fontWeight: 'bold',
	color: '#e23838',
	textAlign: 'center',
};

function PlanosImpl({ isModal, mensagemAviso, isMobile, isTablet, isLessHd, isDesktop, ...props }) {
	const [podeEditar] = useState(usuarioPossuiPermissao(recursos.PLANOS, permissoes.EDITAR));
	const [planos, setPlanos] = useState([]);
	const [historico, setHistorico] = useState({
		registros: [],
		totalElements: 0,
	});
	const [tutorialVisible, setTutorialVisible] = useState(false);
	const [modalAdesaoVisible, setModalAdesaoVisible] = useState(false);
	const [modalCartaoVisible, setModalCartaoVisible] = useState(false);
	const [novoPlano, setNovoPlano] = useState({});
	const [novaAdesaoEfetuada, setNovaAdesaoEfetuada] = useState(false);
	const [informacoesCartao, setInformacoesCartao] = useState({
		numero: '-',
		expiracao: '-',
		titular: '-',
	});
	const [informacoesAdicionaisPlano, setInformacoesAdicionaisPlano] = useState({
		valorPlanoAtual: 0,
		efetuarPagamentoAoAderir: false,
		cartaoDeCreditoCadastrado: false,
	});

	const { meioPagamento, setMeioPagamento, setActiveIndex, setSelecionarMetodo } = useContextPlanos();

	const isAdesaoInstantanea = novoPlano.valor - informacoesAdicionaisPlano.valorPagoNoUltimoMes <= 0;

	const prevProps = usePrevious(props);

	useEffect(() => {
		atualizarDados();

		if (buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_PLANOS) !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_PLANOS, false, null, false);
		}
	}, []);

	useEffect(() => {
		if (prevProps?.dataAtualizacaoPlano !== props.dataAtualizacaoPlano) {
			atualizarDados();
		}
	}, [props]);

	function atualizarDados() {
		atualizarInformacoesPlano();
		atualizarInformacoesGeraisPlano();
		atualizarInformacoesCartao();
		atualizarInformacoesHistorico();
	}

	function atualizarInformacoesGeraisPlano() {
		asyncGetInformacoesGeraisPlano(({ data: informacoesAdicionaisPlano }) => {
			setInformacoesAdicionaisPlano({
				...informacoesAdicionaisPlano,
				dataProximoPagamento: parse(informacoesAdicionaisPlano.dataProximoPagamento, 'yyyy-MM-dd', new Date()),
			});
		});
	}

	function atualizarInformacoesPlano() {
		asyncGetPlano(({ data: planos }) => {
			const planosFiltrados = planos.filter(
				(r) => r.aplicacao === buscarDadosLoginLocalStorage()?.organizacao.aplicacao
			);
			setPlanos(converterPlanoParaFormulario(planosFiltrados));
		});
	}

	function atualizarInformacoesCartao() {
		asyncGetCartao(({ data: informacoesCartao }) => {
			setInformacoesCartao(informacoesCartao);
			if (Object.keys(informacoesCartao).length > 0) {
				setMeioPagamento(METODO_PAGAMENTO.CARTAO);
			}
		});
	}

	function atualizarInformacoesHistorico(page = 0, sortField = 'receivedDate', sortOrder = -1, rows = 10) {
		const url = construirUrl(
			`${services.GESTOR}/v1/planos/historico_pagamentos`,
			'?query=()',
			rows,
			page,
			sortOrder > 0 ? `${sortField},asc` : `${sortField},desc`
		);

		asyncGetHistorico(url, ({ data: historico }) => {
			setHistorico({
				registros: historico.content,
				totalElements: historico.page.totalElements,
			});
		});
	}

	function onVoltarClick() {
		if (isModal) {
			props.onHide();
		} else {
			props.history.goBack();
		}
	}

	function onAderirClick(novoPlano) {
		setNovoPlano(novoPlano);
		setModalAdesaoVisible(true);
	}

	async function aderir(forma) {
		const pagamento = { formaPagamento: forma };
		let mensagemSucesso = forma === 'PIX' ? null : 'Plano contratado com sucesso!';

		if (novoPlano.valor > 0 && informacoesAdicionaisPlano.efetuarPagamentoAoAderir && forma !== 'PIX') {
			mensagemSucesso = 'Pagamento efetuado com sucesso!';
		}

		await asyncAderirAoPlano(
			novoPlano.id,
			pagamento,
			mensagemSucesso,
			() => {
				setNovaAdesaoEfetuada(true);
				atualizarDadosLocais();
				if (forma !== 'PIX' || isAdesaoInstantanea) {
					setActiveIndex(2);
				}
			},
			() => {
				setSelecionarMetodo(true);
			}
		);

		if (isModal) {
			setTimeout(() => props.onHide(), 3000);
		}
	}

	async function atualizarDadosLocais() {
		const dadosLocalStorage = buscarDadosLoginLocalStorage();
		if (novoPlano.valor === 0 && dadosLocalStorage.organizacao.bloqueada) {
			fazerLogin(dadosLocalStorage.email, dadosLocalStorage.senha, dadosLocalStorage.manterConectado, () => {
				atualizarDados();
			});
		} else {
			atualizarDados();
			await sincronizarInformacoesLogin();
			if (Object.keys(novoPlano)?.length > 0 && novoPlano.modulo === modulos.ESSENCIAL) {
				dispararAcoesReduxPossuiSuporteViaChat(novoPlano.suporteChat);
			}
		}
	}

	function removerCartaoCredito() {
		confirm(
			'Confirmação',
			'Tem certeza que deseja remover seu cartão de crédito?',
			async () => {
				await asyncRemoverCartao(() => atualizarDados());
			},
			() => {},
			'Sim',
			'Não'
		);
	}

	function renderizarInformacoesPlano() {
		const planoAtual = planos.find((plano) => plano.aderido);

		if (planoAtual && planoAtual.meioPagamento === MeioPagamento.SISTEMA_COBRANCA) {
			return (
				<>
					<Col sm="12" md="12" lg="4" xl="4">
						<Card
							className="step-planos-cartao card-planos"
							title="Método de pagamento"
							style={{
								height: isTablet ? '9.3rem' : 'auto',
							}}
						>
							<CardMeuCartao
								informacoesCartao={informacoesCartao}
								onAlterarClick={() => setModalCartaoVisible(true)}
								onRemoverCartaoClick={removerCartaoCredito}
								podeEditar={podeEditar}
								planoAtual={planoAtual}
								dataProximoPagamento={informacoesAdicionaisPlano.dataProximoPagamento}
								valorPlanoAtual={informacoesAdicionaisPlano.valorPlanoAtual}
								isTablet={isTablet}
								isLessHd={isLessHd}
								isDesktop={isDesktop}
								atualizarDadosLocais={atualizarDadosLocais}
							/>
						</Card>
					</Col>
					<Col sm="12" md="6" lg="4" xl="4">
						<Card className="card-planos card-utilizacao" title="Utilização do plano">
							<CardUtilizacao planos={planos} planoAtual={planoAtual} className="step-planos-utilizacao" />
						</Card>
					</Col>
					<Col sm="12" md="6" lg="4" xl="4">
						<Card className="step-planos-historico card-planos" title="Histórico de pagamentos">
							<CardHistorico
								historico={historico}
								valorPlanoAtual={informacoesAdicionaisPlano.valorPlanoAtual}
								atualizarInformacoesHistorico={atualizarInformacoesHistorico}
								dataProximoPagamento={informacoesAdicionaisPlano.dataProximoPagamento}
								isMobile={isMobile}
								isLessHd={isLessHd}
							/>
						</Card>
					</Col>
				</>
			);
		}

		if (planoAtual && planoAtual.meioPagamento === MeioPagamento.BOLETO_BANCARIO) {
			return (
				<Col sm="12" md="6" lg="4" xl="4">
					<Card className="card-planos card-utilizacao" title="Utilização do plano">
						<CardUtilizacao planoAtual={planoAtual} className="step-planos-utilizacao" />
					</Card>
				</Col>
			);
		}

		return null;
	}

	function renderizarPlanos() {
		const planoAtual = planos.find((plano) => plano.aderido);

		if (planoAtual && planoAtual.meioPagamento === MeioPagamento.BOLETO_BANCARIO) {
			return (
				<Col key={planoAtual.id} sm="12" md="6" lg="6" xl="3">
					<CardPlano podeEditar={podeEditar} plano={planoAtual} onAderirClick={onAderirClick} />
				</Col>
			);
		}

		return planos.map((plano) => (
			<Col sm="12" md="6" lg="6" xl="3" key={plano.id} style={{ display: 'flex', justifyContent: 'center' }}>
				<div style={{ width: '320px' }}>
					<CardPlano podeEditar={podeEditar} plano={plano} onAderirClick={onAderirClick} />
				</div>
			</Col>
		));
	}

	function onHideModalDadosCartao() {
		setModalAdesaoVisible(false);
		atualizarDadosLocais();
		setActiveIndex(0);
	}

	return (
		<>
			<Tutorial
				steps={tutorialStepsPlanos}
				showSkipButton
				continuous
				disableScrolling
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>
			<ButtonCancelar estadoBotao={estadosBotaoCancelar.VOLTAR} onClick={onVoltarClick} />
			<LayoutMenuSuperior isModal>
				<Paper childsOnly={isModal}>
					<FormGroup
						style={
							isModal
								? {
										maxHeight: '40rem',
										overflowY: 'scroll',
										overflowX: 'hidden',
										width: '100%',
									}
								: {}
						}
					>
						<Grid justifyCenter style={{ gap: isMobile && '8px' }}>
							<If test={mensagemAviso && !novaAdesaoEfetuada}>
								<Col style={avisoPlanoExpiradoStyle}>{mensagemAviso}</Col>
							</If>

							{renderizarInformacoesPlano()}
						</Grid>
						<Grid className="step-planos-contratacao" style={{ marginTop: '16px' }} justifyCenter>
							{renderizarPlanos()}
						</Grid>
					</FormGroup>
				</Paper>
			</LayoutMenuSuperior>
			<If test={modalAdesaoVisible}>
				<ModalConfirmarAdesao
					visible={modalAdesaoVisible}
					onHide={() => onHideModalDadosCartao(false)}
					onConfirmarAdesaoClick={(e) => aderir(e)}
					valorPlanoAtual={informacoesAdicionaisPlano.valorPlanoAtual}
					novoPlano={novoPlano}
					dataProximoPagamento={informacoesAdicionaisPlano.dataProximoPagamento}
					valorPagoNoUltimoMes={informacoesAdicionaisPlano.valorPagoNoUltimoMes}
					efetuarPagamentoAoAderir={novoPlano.valor > 0 && informacoesAdicionaisPlano.efetuarPagamentoAoAderir}
					isAdesaoInstantanea={isAdesaoInstantanea}
					meioPagamento={meioPagamento}
					informacoesCartao={informacoesCartao}
				/>
			</If>
			<If test={modalCartaoVisible}>
				<ModalDadosCartao
					visible={modalCartaoVisible}
					onHide={() => setModalCartaoVisible(false)}
					isMobile={isMobile}
					isTablet={isTablet}
					isLessHd={isLessHd}
				/>
			</If>
		</>
	);
}

const mapStateToProps = (state) => ({
	dataAtualizacaoPlano: state.plano && state.plano.dataAtualizacaoPlano,
	possuiSuporteViaChat: state.possuiSuporteViaChat,
	isMobile: state.dispositivo.isMobile,
	isTablet: state.dispositivo.isTablet,
	isLessHd: state.dispositivo.isLessHd,
	isDesktop: state.dispositivo.isDesktop,
});

export const Planos = connect(mapStateToProps)(PlanosImpl);
