import { InputMoney, SingleSelectCategoria, SingleSelectConta, SingleSelectFormaPagamento } from 'components';
import { Field, useFormikContext } from 'formik';

function FieldsAVista({
	value,
	urls,
	length,
	disabledFields,
	informacoesPermissoes,
	handleChangeFormaPagamento,
	handleChangeConta,
	handleChangeCategoria,
	handleChangeValor,
	onBlurValor,
	indexPagamento,
}) {
	const { errors } = useFormikContext();

	return (
		<>
			<Field
				sm="12"
				md="6"
				lg="3"
				xl="3"
				name="formaPagamento"
				label="Forma de pagamento"
				helpMessage="Forma de pagamento"
				component={SingleSelectFormaPagamento}
				value={value.formaPagamento}
				onChange={handleChangeFormaPagamento}
				url={urls.formaPagamento}
				disabled={disabledFields}
				obrigatorio
				isClearable={false}
				colStyle={{ paddingBottom: '20px' }}
				touched
				errors={errors[indexPagamento]?.formaPagamento}
				useFormErrors={false}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="4"
				lg="2"
				xl="2"
				name="conta"
				label="Conta"
				helpMessage="Conta"
				placeholder="Pesquisar"
				component={SingleSelectConta}
				value={value.conta}
				onChange={handleChangeConta}
				url={urls.conta}
				disabled={disabledFields}
				obrigatorio
				isClearable={false}
				touched
				errors={errors[indexPagamento]?.conta}
				useFormErrors={false}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="5"
				lg="2"
				xl="2"
				label="Categoria(s)"
				name="categorias"
				helpMessage="Categoria(s)"
				placeholder="Pesquisar"
				component={SingleSelectCategoria}
				value={value.categorias}
				onChange={handleChangeCategoria}
				url={urls.categoria}
				disabled={disabledFields}
				obrigatorio
				isClearable={false}
				touched
				errors={errors[indexPagamento]?.categorias}
				useFormErrors={false}
				withList
				valueTotalList={value.valor}
				{...informacoesPermissoes}
				values={value}
			/>
			<Field
				sm="12"
				md="3"
				lg="2"
				xl="2"
				label="Valor"
				name="valor"
				helpMessage="Valor"
				component={InputMoney}
				value={value.valor}
				onChange={handleChangeValor}
				onBlur={onBlurValor}
				allowNegative={false}
				size={13}
				prefix="R$ "
				placeholder="R$ 0,00"
				obrigatorio
				colStyle={{ paddingRight: '12px' }}
				disabled={disabledFields || length === 1}
				touched
				errors={errors[indexPagamento]?.valor}
				useFormErrors={false}
				{...informacoesPermissoes}
			/>
		</>
	);
}

export { FieldsAVista };
