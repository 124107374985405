import { Field, useFormikContext, withFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import {
	buscarConfiguracaoUsuario,
	configuracoesUsuario,
	estadosCadastro,
	keyFilterConsultaRsql,
	mensagensDeValidacao,
	permissoes,
	recursos,
	salvarConfiguracaoUsuario,
	services,
	usuarioPossuiPermissao,
	validarUUID,
} from 'Common';
import {
	Dropdown,
	Form,
	FormActions,
	FormContent,
	Grid,
	InputField,
	Prompt,
	SingleSelectCategoria,
	SingleSelectConta,
	Tutorial,
	tutorialStepsFormaPagamento,
} from 'components';

import { atualizarUrl, metodosAtualizarUrl } from 'views/Util';
import { CADASTRO_URL, INITIAL_VALUES, FORMA_PAGAMENTO_FISCAL_OPTIONS } from '../Util/constantes';
import { readFormaPagamento } from '../Requests';
import { converterFormaPagamentoParaFormulario } from '../Util/formasPagamentoConverter';
import { ActionButtons } from './components/ActionButtons';

function FormaPagamentoFormImpl({ isModal, match, history, hideModal }) {
	const { values, dirty, resetForm, setFieldValue } = useFormikContext();
	const podeInserir = usuarioPossuiPermissao(recursos.FORMAS_PAGAMENTO, permissoes.INSERIR);
	const podeEditar = usuarioPossuiPermissao(recursos.FORMAS_PAGAMENTO, permissoes.EDITAR);
	const podeExcluir = usuarioPossuiPermissao(recursos.FORMAS_PAGAMENTO, permissoes.EXCLUIR);

	const [tutorialVisible, setTutorialVisible] = useState(false);
	const deveExibirTutorial = buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_FORMA_PAGAMENTO);

	const informacoesPermissoes = {
		estadoCadastro: values.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO,
		podeInserir: podeInserir,
		podeEditar: podeEditar,
		podeExcluir: podeExcluir,
	};

	useEffect(() => {
		if (match?.params) {
			const { id } = match.params;

			if (deveExibirTutorial !== false) {
				setTutorialVisible(true);
				salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_FORMA_PAGAMENTO, false, null, false);
			}

			if (validarUUID(id) && !isModal) {
				fetchRegistro(id);
			}
		}

		setTimeout(() => {
			document.getElementById('forma-pagamento-descricao')?.focus();
		}, 500);
	}, []);

	async function fetchRegistro(idForma) {
		await readFormaPagamento(idForma, ({ data: forma }) => {
			resetForm({ values: converterFormaPagamentoParaFormulario(forma) });

			if (!isModal) {
				atualizarUrl(history, CADASTRO_URL, forma.id, metodosAtualizarUrl.POP);
			}
		});
	}

	function handleChangeConta(e) {
		setFieldValue('conta', e);
	}

	function handleChangeCategoriaReceita(e) {
		setFieldValue('categoriaReceita', e);
	}

	function handleChangeCategoriaDespesa(e) {
		setFieldValue('categoriaDespesa', e);
	}

	function handleChangeFormaPagamentoFiscal(e) {
		setFieldValue('formaPagamentoFiscal', e.value);
	}

	return (
		<>
			<Prompt dirty={dirty} isModal={isModal} />
			<Tutorial
				steps={tutorialStepsFormaPagamento}
				showSkipButton
				continuous
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>
			<Form className="card-default screen-max-width" header="Cadastro de forma de pagamento" isModal={isModal}>
				<FormActions className="screen-max-width">
					<ActionButtons
						informacoesPermissoes={informacoesPermissoes}
						isModal={isModal}
						hideModal={hideModal}
						history={history}
					/>
				</FormActions>
				<FormContent>
					<Grid className="step-forma-pagamento-campos-principais">
						<Field
							sm="12"
							md="5"
							lg="5"
							xl="5"
							component={InputField}
							label="Descrição"
							obrigatorio
							keyfilter={keyFilterConsultaRsql}
							name="descricao"
							size={60}
							id="forma-pagamento-descricao"
							disabled={!values.podeExcluir}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="4"
							lg="4"
							xl="4"
							name="conta"
							label="Conta"
							component={SingleSelectConta}
							style={{ flexDirection: 'row', width: '100%' }}
							value={values.conta}
							onChange={handleChangeConta}
							url={`${services.GESTOR}/v1/formas_pagamento/relacoes/contas`}
						/>
						<Field
							xs="12"
							md="3"
							lg="3"
							xl="3"
							component={Dropdown}
							obrigatorio
							label="Forma fiscal"
							helpMessage="Forma de pagamento fiscal utilizada. Essa informação é utilizada na Nota Fiscal"
							name="forma"
							showClear={false}
							value={values.formaPagamentoFiscal}
							onChange={handleChangeFormaPagamentoFiscal}
							disabled={!values.podeExcluir}
							options={FORMA_PAGAMENTO_FISCAL_OPTIONS}
							{...informacoesPermissoes}
						/>
					</Grid>
					<Grid className="step-forma-pagamento-categorias">
						<Field
							sm="12"
							md="6"
							lg="6"
							xl="6"
							name="categoriaReceita"
							label="Categoria receita"
							component={SingleSelectCategoria}
							style={{ flexDirection: 'row', width: '100%' }}
							value={values.categoriaReceita}
							onChange={handleChangeCategoriaReceita}
							url={`${services.GESTOR}/v1/formas_pagamento/relacoes/categorias/receitas`}
						/>
						<Field
							sm="12"
							md="6"
							lg="6"
							xl="6"
							name="categoriaDespesa"
							label="Categoria despesa"
							component={SingleSelectCategoria}
							style={{ flexDirection: 'row', width: '100%' }}
							value={values.categoriaDespesa}
							onChange={handleChangeCategoriaDespesa}
							url={`${services.GESTOR}/v1/formas_pagamento/relacoes/categorias/despesas`}
						/>
					</Grid>
				</FormContent>
			</Form>
		</>
	);
}

const FormaPagamentoForm = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues() {
		return INITIAL_VALUES;
	},

	validationSchema: Yup.object().shape({
		descricao: Yup.string()
			.nullable()
			.required(mensagensDeValidacao.OBRIGATORIO)
			.max(60, 'O campo não pode ter mais de 60 caracteres.'),
	}),

	handleSubmit: () => {},
})(FormaPagamentoFormImpl);

export { FormaPagamentoForm };
