import { colors, gerarUUID } from 'Common';
import { tipoCampos } from 'components';
import { formatISO } from 'date-fns';
import { TIPO_PESSOA } from 'views/cadastros/Pessoas/Util/constantes';

export const DocumentosTipos = {
	CPF: 'CPF',
	CNPJ: 'CNPJ',
};

export const TiposAcessoriasOuDesconto = {
	PERCENTUAL: 'PERCENTUAL',
	VALOR: 'VALOR',
};

export const Condicoes = {
	A_VISTA: 'A_VISTA',
	A_PRAZO: 'A_PRAZO',
};

export const INITIAL_VALUES_PAGAMENTOS_NFCE = {
	id: gerarUUID(),
	sequencial: 1,
	conta: null,
	categorias: [],
	condicaoPagamento: null,
	formaPagamento: null,
	quantidadeParcelas: 0,
	valor: 0,
	valorRecebido: 0,
	valorTroco: 0,
	parcelas: [],
};

export const INITIAL_VALUES_NFCE = {
	id: null,
	status: 'NAO_ENVIADA',
	tipoOperacao: 'SAIDA',
	serie: null,
	numero: null,
	emissao: formatISO(new Date()),
	produtoSelecionado: null,
	produtos: [],
	quantidadeSelecionada: 1,
	cliente: {
		consumidorCadastrado: false,
		nome: null,
		documentoTipo: DocumentosTipos.CPF,
		tipoCliente: TIPO_PESSOA.FISICA,
		cpf: null,
		cnpj: null,
		clienteCadastrado: null,
	},
	operacaoFiscal: null,
	vendedor: null,
	observacao: null,

	acessorias: {
		tipo: TiposAcessoriasOuDesconto.VALOR,
		valor: 0,
	},
	desconto: {
		tipo: TiposAcessoriasOuDesconto.VALOR,
		valor: 0,
	},

	totalizadores: {
		totalProdutos: 0,
		totalDesconto: 0,
		totalAcessorias: 0,
		totalBaseCalculo: 0,
		totalIcms: 0,
		totalPis: 0,
		totalCofins: 0,
		totalLiquido: 0,
	},

	pagamentos: [INITIAL_VALUES_PAGAMENTOS_NFCE],

	validacaoRecarregaProdutoPeloCliente: false,
	venda: null,
};

export const infoStatusNfce = {
	REJEITADA: {
		name: 'Rejeitada',
		description: 'NFC-e rejeitada pelo Sefaz',
		lightColor: colors.vermelhoLight,
		strongColor: colors.vermelho,
	},
	TRANSMITIDA: {
		name: 'Transmitida',
		description: 'NFC-e transmitida para o Sefaz com sucesso',
		lightColor: colors.verdeLight,
		strongColor: colors.verde,
	},
	CANCELADA: {
		name: 'Cancelada',
		description: 'NFC-e cancelada',
		lightColor: colors.vermelhoLight,
		strongColor: colors.vermelho,
	},
	NAO_ENVIADA: {
		name: 'Não enviada',
		description: 'A NFC-e ainda não foi transmitida para o Sefaz',
		lightColor: colors.azulLight,
		strongColor: colors.azul,
	},
	DENEGADA: {
		name: 'Denegada',
		description: 'NFC-e denegada pelo Sefaz',
		lightColor: colors.cinzaBackground,
		strongColor: colors.cinzaDark,
	},
	AGUARDANDO_AUTORIZACAO: {
		name: 'Aguardando',
		description: 'A NFC-e está sendo processada pelo Sefaz',
		lightColor: '#FFEFE5',
		strongColor: '#9D7A0A',
	},
};

export const Status = {
	REJEITADA: 'REJEITADA',
	TRANSMITIDA: 'TRANSMITIDA',
	CANCELADA: 'CANCELADA',
	NAO_ENVIADA: 'NAO_ENVIADA',
	DENEGADA: 'DENEGADA',
	AGUARDANDO_AUTORIZACAO: 'AGUARDANDO_AUTORIZACAO',
};

export const optionsFiltroAvancado = [
	{ label: 'NFC-e', name: 'nro', type: tipoCampos.INTEGER },
	{ label: 'Cliente', name: 'destNome', type: tipoCampos.STRING },
	{ label: 'CPF', name: 'destCpf', type: tipoCampos.CPFCNPJ },
	{ label: 'CNPJ', name: 'destCnpj', type: tipoCampos.CPFCNPJ },
	{
		label: 'Situação',
		name: 'status',
		type: tipoCampos.SELECT,
		optionSelect: [
			{ label: 'Rejeitada', value: 'REJEITADA' },
			{ label: 'Transmitida', value: 'TRANSMITIDA' },
			{ label: 'Corrigida', value: 'CORRIGIDA' },
			{ label: 'Cancelada', value: 'CANCELADA' },
			{ label: 'Não enviada', value: 'NAO_ENVIADA' },
			{ label: 'Denegada', value: 'DENEGADA' },
			{ label: 'Aguardando autorização', value: 'AGUARDANDO_AUTORIZACAO' },
		],
	},
];

export const CONDICAO_PAGAMENTO_TIPO = {
	A_VISTA: 'A_VISTA',
	A_PRAZO: 'A_PRAZO',
};

export const Campo = {
	ACESSORIAS: 'acessoriasRateio',
	DESCONTO: 'descontoRateio',
};

export function quantidadeParcelasDropdown(minParcelas = 1, maxParcelas = 48) {
	let result = [];
	for (let i = minParcelas; i <= maxParcelas; i++) {
		result.push({ label: `${i}x`, value: i });
	}
	return result;
}
