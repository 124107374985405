import { useEffect, useRef, useState } from 'react';
import { useFormikContext, withFormik } from 'formik';
import { connect } from 'react-redux';
import { ratearValoresCategorias } from 'components/select/SingleSelectCategoria';
import { copiarObjeto, gerarUUID } from 'Common';
import { ButtonAdicionarItem, Col, Grid, If, Message } from 'components';
import {
	CONDICAO_PAGAMENTO_MOVIMENTACAO,
	CONDICAO_PAGAMENTO_TIPO,
} from 'views/cadastros/financas/CondicaoPagamento/Util/constantes';
import { useGenerateParcelas } from './Hooks/useGenerateParcelas';
import { FINANCEIRO_TIPO, INITIAL_VALUE_PAGAMENTO } from './Util/constantes';
import { divideValorPorQuantidade } from './Util/functions';
import { Pagamento } from './components/Pagamento';

function PagamentosImpl({
	idOrigem,
	pagamentos,
	urls,
	favoritos,
	informacoesPermissoes,
	disabledFields,
	dataBaseParcela,
	hideTabFinanceiro,
	valorTotal,
	financeiroTipo = FINANCEIRO_TIPO.CONTA_RECEBER,
	onChangePagamentos,
	onChangePagamento,
	onChangePagamentoField,
	handleClickEstornar,
	isMobile,
	isTablet,
	isLessHd,
	classNameTabView,
	hiddenButtonAdd,
	color,
	setRecalcularTodosOsProdutosComTabelaPreco,
	setRecalcularTodosOsServicosComTabelaPreco,
	stylePagamento,
	withList,
}) {
	const { values, errors, setValues } = useFormikContext();
	const [recalcularValoresPagamentos, setRecalcularValoresPagamentos] = useState(false);
	const prevPagamentos = useRef(pagamentos);
	const disabledButtonAdd = pagamentos[0]?.condicaoPagamento?.registro?.tipo === CONDICAO_PAGAMENTO_TIPO.SEM_PAGAMENTO;

	const indicacaoMovimento =
		financeiroTipo === FINANCEIRO_TIPO.CONTA_PAGAR
			? CONDICAO_PAGAMENTO_MOVIMENTACAO.ENTRADA
			: CONDICAO_PAGAMENTO_MOVIMENTACAO.SAIDA;

	useEffect(() => {
		if (prevPagamentos.current !== values) {
			onChangePagamentos(values);
		}
	}, [values.length]);

	const [generateParcelas] = useGenerateParcelas({
		dataBaseParcela,
		favoritos,
	});

	function onExcluirPagamento(index) {
		if (values.length > 1) {
			let newPagamentos = copiarObjeto(values);
			const { valorPorQuantidade, valorUltimaQuantidade } = divideValorPorQuantidade(valorTotal, values.length - 1);

			newPagamentos.splice(index, 1);

			if (index !== values.length - 1) {
				newPagamentos = newPagamentos.map((e, i) => {
					const valor = i === newPagamentos.length - 1 ? valorUltimaQuantidade : valorPorQuantidade;

					return {
						...e,
						sequencial: i + 1,
						valor,
						categorias: ratearValoresCategorias(e.categorias, valor, -1, true),
						parcelas: getParcelas(e, valor),
					};
				});
			} else {
				newPagamentos = newPagamentos.map((e, i) => {
					const valor = i === newPagamentos.length - 1 ? valorUltimaQuantidade : valorPorQuantidade;

					return {
						...e,
						valor,
						categorias: ratearValoresCategorias(e.categorias, valor, -1, true),
						parcelas: getParcelas(e, valor),
					};
				});
			}

			setValues(newPagamentos);
		}
	}

	function handleClickAdicionarFormaPagamento() {
		const hasCondicaoAPrazo =
			values.filter((e) => e.condicaoPagamento?.registro?.tipo === CONDICAO_PAGAMENTO_TIPO.A_PRAZO).length > 0;
		const isCondicaoFavoritaAPrazo = favoritos.condicaoPagamento?.registro?.tipo === CONDICAO_PAGAMENTO_TIPO.A_PRAZO;
		const parcelaPadraoCondicaoPagamento =
			favoritos.condicaoPagamento?.registro?.parcelaPadrao ?? favoritos.condicaoPagamento?.registro?.parcelaMinima ?? 1;
		const { valorPorQuantidade, valorUltimaQuantidade } = divideValorPorQuantidade(valorTotal, values.length + 1);
		const formasPagamentoComValorAjustado = [];

		const newFormaPagamento = {
			...INITIAL_VALUE_PAGAMENTO,
			tempKey: gerarUUID(),
			sequencial: values.length + 1,
			condicaoPagamento: hasCondicaoAPrazo && isCondicaoFavoritaAPrazo ? null : favoritos.condicaoPagamento,
			quantidadeParcelas: {
				value: parcelaPadraoCondicaoPagamento,
				label: `${parcelaPadraoCondicaoPagamento}x`,
			},
			conta: favoritos.conta,
			formaPagamento: favoritos.formaPagamento,
			categorias: [{ categoria: favoritos.categoria, valor: valorUltimaQuantidade, percentual: 100 }],
			valor: valorUltimaQuantidade,
		};

		newFormaPagamento.parcelas = getParcelas(newFormaPagamento, valorUltimaQuantidade);

		values.forEach((e) => {
			formasPagamentoComValorAjustado.push({
				...e,
				valor: valorPorQuantidade,
				categorias: ratearValoresCategorias(e.categorias, valorPorQuantidade, -1, true),
				parcelas: getParcelas(e, valorPorQuantidade),
			});
		});

		setValues([...formasPagamentoComValorAjustado, newFormaPagamento]);
	}

	function getParcelas(e, valorPagamento) {
		const isAPrazo = e.condicaoPagamento?.registro?.tipo === CONDICAO_PAGAMENTO_TIPO.A_PRAZO;
		return isAPrazo
			? generateParcelas({
					qtdParcelas: e.quantidadeParcelas?.value,
					parcelas: e.parcelas,
					valorPagamento: valorPagamento,
				})
			: [];
	}

	function createTextMessage() {
		const message =
			errors[0]?.semFormaPagamento ??
			errors[0]?.vencimentoParcela ??
			errors[0]?.valorParcela ??
			errors[0]?.formaPagamentoParcela ??
			errors[0]?.contaParcela ??
			errors[0]?.valorTotal;

		return <span>{message}</span>;
	}

	function renderMessage() {
		return (
			<If test={errors?.length > 0 && errors[0].showMessage}>
				<Message severity="warn" text={createTextMessage()} />
			</If>
		);
	}

	useEffect(() => {
		if (recalcularValoresPagamentos) {
			const { valorNaoRateado, quantidadePagamentosNaoRateados } = buscarValoresNaoRateado();

			ratearNovoValorPagamentos(valorNaoRateado, quantidadePagamentosNaoRateados);

			setRecalcularValoresPagamentos(false);
		}
	}, [recalcularValoresPagamentos]);

	function buscarValoresNaoRateado() {
		let valorNaoRateado = valorTotal;
		let quantidadePagamentosNaoRateados = 0;

		values?.forEach((pagamento) => {
			if (pagamento.alterouValor) {
				valorNaoRateado -= pagamento.valor;
			} else {
				quantidadePagamentosNaoRateados++;
			}
		});

		return { valorNaoRateado, quantidadePagamentosNaoRateados };
	}

	function ratearNovoValorPagamentos(valorNaoRateado, quantidadePagamentosNaoRateados) {
		const { valorPorQuantidade, valorUltimaQuantidade } = divideValorPorQuantidade(
			valorNaoRateado,
			quantidadePagamentosNaoRateados
		);
		const pagamentos = copiarObjeto(values);

		pagamentos?.forEach((pagamento, index) => {
			if (!pagamento.alterouValor) {
				if (index === pagamentos.length - 1) {
					pagamentos[index].valor = valorNaoRateado <= 0 ? 0 : valorUltimaQuantidade;
				} else {
					pagamentos[index].valor = valorNaoRateado <= 0 ? 0 : valorPorQuantidade;
				}
			}
		});

		setValues(pagamentos);
		onChangePagamentos(pagamentos);
	}

	return (
		<>
			{renderMessage()}
			<div style={{ marginTop: '4px' }}>
				{values.length > 0 &&
					values.map((value, index) => (
						<Pagamento
							idOrigem={idOrigem}
							valorTotalPagamentos={valorTotal}
							key={value.tempKey}
							value={value}
							indexPagamento={index}
							length={values.length}
							urls={urls}
							favoritos={favoritos}
							dataBaseParcela={dataBaseParcela}
							disabledFields={disabledFields}
							informacoesPermissoes={informacoesPermissoes}
							classNameTabView={classNameTabView}
							onExcluirPagamento={onExcluirPagamento}
							onChangePagamento={onChangePagamento}
							onChangePagamentoField={onChangePagamentoField}
							handleClickEstornar={handleClickEstornar}
							hideTabFinanceiro={hideTabFinanceiro}
							financeiroTipo={financeiroTipo}
							indicacaoMovimento={indicacaoMovimento}
							isMobile={isMobile}
							isTablet={isTablet}
							isLessHd={isLessHd}
							color={color}
							setRecalcularValoresPagamentos={setRecalcularValoresPagamentos}
							setRecalcularTodosOsProdutosComTabelaPreco={setRecalcularTodosOsProdutosComTabelaPreco}
							setRecalcularTodosOsServicosComTabelaPreco={setRecalcularTodosOsServicosComTabelaPreco}
							stylePagamento={stylePagamento}
							withList={withList}
						/>
					))}
			</div>
			<Grid style={{ paddingTop: '10px' }} hidden={hiddenButtonAdd}>
				<Col sm="8" md="8" lg="8" xl="8" style={{ padding: '8px 0px 0px 0px' }}>
					<ButtonAdicionarItem
						label="Adicionar forma de pagamento"
						style={{
							background: 'none',
							border: 'none',
							fontWeight: 'bold',
							boxShadow: 'none',
							padding: '0rem 1.2rem',
							color: color,
						}}
						onClick={handleClickAdicionarFormaPagamento}
						disabled={!informacoesPermissoes?.podeInserir || disabledButtonAdd}
					/>
				</Col>
			</Grid>
		</>
	);
}

const PagamentosFormik = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues({ pagamentos }) {
		return pagamentos;
	},

	mapPropsToErrors({ errors }) {
		return errors ?? [];
	},

	handleSubmit: () => {},
})(PagamentosImpl);

function mapStateToProps(state) {
	return {
		isMobile: state.dispositivo.isMobile,
		isTablet: state.dispositivo.isTablet,
		isLessHd: state.dispositivo.isLessHd,
	};
}

export const Pagamentos = connect(mapStateToProps)(PagamentosFormik);
