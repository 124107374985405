import { OverlayPanel } from 'primereact/overlaypanel';
import { formatos, inserirMascara } from 'Common';
import { useEffect, useState } from 'react';
import { asyncGetDocumento } from '../../Requests';

function OverlayPanelInformacoesComplementares({
	documento,
	panelInformacoesComplementares,
	isMobile,
	setDocumentoSelecionado,
}) {
	const [partes, setPartes] = useState(null);
	const [produtos, setProdutos] = useState(null);
	const [valor, setValor] = useState(null);

	useEffect(() => {
		if (documento && documento.id) {
			asyncGetDocumento(documento.id, ({ data }) => {
				if (data) {
					if (data.partes && data.partes.length > 0) {
						setPartes(montarPartes(data.partes));
					} else {
						setPartes(null);
					}

					if (data.produtos && data.produtos.length > 0) {
						setProdutos(montarProdutos(data.produtos));
					} else {
						setProdutos(null);
					}
				} else {
					setPartes(null);
					setProdutos(null);
					setValor(null);
				}
			});
		}
	}, [documento]);

	function formatarCpfCnpj(cpfCnpj) {
		if (cpfCnpj.length === 11) {
			return inserirMascara(cpfCnpj, formatos.CPF);
		} else {
			return inserirMascara(cpfCnpj, formatos.CNPJ);
		}
	}

	function montarPartes(data) {
		let partes = '';

		if (data) {
			data.forEach((parte) => {
				partes = partes
					.concat(partes !== '' ? ', ' : '')
					.concat(
						parte.cpf
							? formatarCpfCnpj(parte.cpf).concat(' - ')
							: parte.cnpj
								? formatarCpfCnpj(parte.cnpj).concat(' - ')
								: ''
					)
					.concat(parte.nome);
			});
		}

		return partes;
	}

	function montarProdutos(data) {
		let produtos = '';

		if (data) {
			data.forEach((produto) => {
				produtos = produtos
					.concat(produtos !== '' ? ', ' : '')
					.concat(produto.codigo.toString().concat(' - ').concat(produto.nome));
			});
		}

		return produtos;
	}

	return (
		<OverlayPanel
			ref={panelInformacoesComplementares}
			id="overlayPanel-informacoes-complementares"
			style={{
				width: isMobile ? '90%' : '100%',
				maxWidth: '320px',
				marginLeft: isMobile ? '1rem' : null,
				marginTop: '0px',
			}}
			onHide={() => {
				setDocumentoSelecionado(null);
				setPartes(null);
				setProdutos(null);
			}}
			showCloseIcon
		>
			<div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
				<div style={{ marginBottom: '12px' }}>{valor || '-'}</div>
				<div style={{ fontWeight: 'bold' }}>Parte(s):</div>
				<div style={{ marginBottom: '12px' }}>{partes || '-'}</div>
				<div style={{ fontWeight: 'bold' }}>Produto(s):</div>
				<div style={{ textAlign: 'center' }}>{produtos || '-'}</div>
			</div>
		</OverlayPanel>
	);
}

export { OverlayPanelInformacoesComplementares };
