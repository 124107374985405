import { format } from 'date-fns';

import Nfe from 'Common/dfe/NFe';
import { gerarUUID } from 'Common';

import { calcularConversao, calcularTotal, concatenate } from './functions';
import { asyncConsultaDadosXml } from './requests';
import {
	FINALIDADE_NFE,
	FORMA_PAGAMENTO,
	INDICACAO_PAGAMENTO,
	INDICADOR_TOTAL,
	MODALIDADE_TRANSPORTE,
	MODULOS_IMPORTACAO,
	ORIGEM_PRODUTO,
} from './constantes';

export default async function converteXmlParaFormulario(xml, modulo = MODULOS_IMPORTACAO.COMPRAS) {
	let nfe = Nfe(xml);

	if (nfe.modelo() === '55') {
		const dadosXmlMsys = await consultaDadosNfe(nfe, modulo);

		let nfeConvertida = {
			numero: nfe.nrNota(),
			serie: nfe.serie(),
			modelo: dadosXmlMsys.modelo,
			operacaoFiscal: dadosXmlMsys.operacaoFiscal,
			emitente: await converteParticipante(nfe.emitente(), dadosXmlMsys.emitente),
			destinatario: await converteParticipante(nfe.destinatario(), dadosXmlMsys.destinatario),
			dataEmissao: converteDataHora(nfe.dataEmissao()).data,
			horaEmissao: converteDataHora(nfe.dataEmissao()).hora,
			dataEntrada: format(new Date(), 'yyyy-MM-dd'),
			horaEntrada: format(new Date(), 'HH:mm'),
			situacao: 'PENDENTE',
			chaveAcesso: nfe.chave(),
			itens: await converteItens(nfe, dadosXmlMsys.itens),
			cobranca: await converteCobranca(nfe),
			pagamentos: await convertePagamentos(nfe, dadosXmlMsys.cobranca),
			totais: await converteTotais(nfe),
			transporte: await converteTransporte(nfe, dadosXmlMsys.transporte),
			xmlImportado: true,
			chaveAcessoCadastrada: dadosXmlMsys.chaveAcessoCadastrada,
			idNotaCadastrada: dadosXmlMsys.idNotaCadastrada,
			destinatarioOrganizacao: dadosXmlMsys.destinatarioOrganizacao,
		};
		return nfeConvertida;
	} else {
		console.error('Modelo de documento não suportado: ' + nfe.modelo());
		return null;
	}
}

async function consultaDadosNfe(nfe, modulo) {
	let dadosXmlMsys = {};

	let dadosXml = {
		modelo: nfe.modelo(),
		chaveAcesso: nfe.chave(),
		emitente: {
			nome: nfe.emitente().nome(),
			nomeFantasia: nfe.emitente().fantasia(),
			cpf: nfe.emitente().cpf(),
			cnpj: nfe.emitente().cnpj(),
			inscricaoEstadual: nfe.emitente().inscricaoEstadual(),
			telefone: nfe.emitente().telefone(),
			municipio: nfe.emitente().codigoMunicipio(),
			logradouro: nfe.emitente().logradouro(),
			bairro: nfe.emitente().bairro(),
			cep: nfe.emitente().cep(),
			numero: nfe.emitente().numero(),
			complemento: nfe.emitente().complemento(),
		},
		destinatario: {
			nome: nfe.destinatario().nome(),
			nomeFantasia: nfe.destinatario().fantasia(),
			cpf: nfe.destinatario().cpf(),
			cnpj: nfe.destinatario().cnpj(),
			inscricaoEstadual: nfe.destinatario().inscricaoEstadual(),
			telefone: nfe.destinatario().telefone(),
			municipio: nfe.destinatario().codigoMunicipio(),
			logradouro: nfe.destinatario().logradouro(),
			bairro: nfe.destinatario().bairro(),
			cep: nfe.destinatario().cep(),
			numero: nfe.destinatario().numero(),
			complemento: nfe.destinatario().complemento(),
		},
		transporte: {
			transportadora: nfe.transporte()?.transportador()
				? {
						municipio: nfe.transporte()?.transportador()?.codigoMunicipio(),
						cnpj: nfe.transporte()?.transportador()?.cnpj(),
						cpf: nfe.transporte()?.transportador()?.cpf(),
					}
				: null,
			veiculo: nfe.transporte()?.veiculo()
				? {
						estado: nfe.transporte()?.veiculo().uf(),
					}
				: null,
		},
		itens: await itensXml(nfe),
	};

	await asyncConsultaDadosXml(dadosXml, modulo === MODULOS_IMPORTACAO.COMPRAS, async ({ data: dadosMsys }) => {
		dadosXmlMsys = await converteDadosXmlParaFormulario(dadosMsys);
	});

	return dadosXmlMsys;
}

async function itensXml(nfe) {
	const countItens = nfe.nrItens();
	let itens = [];

	for (let i = 1; i <= countItens; i++) {
		const item = nfe.item(i);

		itens.push({
			item: i,
			codigo: item.codigo(),
			codigoBarras: item.ean(),
			ncm: item.ncm(),
			unidadeCompra: item.unidadeComercial(),
			cest: item.cest(),
			cfop: item.cfop(),
			cstIcms:
				nfe.emitente().regimeTributario() === '1' ? item.imposto().icms()?.csosn() : item.imposto().icms()?.cst(),
			enquadramentoIpi: item?.enquadramentoIPI() ? item.enquadramentoIPI() : null,
			cstIpi: item.ipi() ? item.ipi()?.cst() : null,
			cstPis: item.pis() ? item.pis()?.cst() : null,
			cstCofins: item.cofins() ? item.cofins()?.cst() : null,
		});
	}
	return itens;
}

async function converteDadosXmlParaFormulario(dados) {
	let dadosConvertidos = {
		modelo: concatenate(dados.modelo),
		chaveAcessoCadastrada: dados.chaveAcessoCadastrada,
		idNotaCadastrada: dados.idChaveAcesso,
		destinatarioOrganizacao: dados.destinatarioOrganizacao,
		operacaoFiscal: dados.operacaoFiscal ? concatenate(dados.operacaoFiscal) : null,
		emitente: {
			municipio: dados.emitente?.municipio,
			pessoa: dados.emitente?.pessoa,
		},
		destinatario: {
			municipio: dados.destinatario?.municipio,
			pessoa: dados.destinatario?.pessoa,
		},
		transporte: {
			transportadora: dados.transporte?.transportadora
				? {
						label: dados.transporte.transportadora?.transportadora
							? dados.transporte.transportadora.transportadora.codigo +
								' - ' +
								dados.transporte.transportadora.transportadora.nome
							: null,
						transportadora: dados.transporte.transportadora.transportadora
							? dados.transporte.transportadora.transportadora
							: null,
						municipio: dados.transporte.transportadora?.municipio
							? {
									...dados.transporte.transportadora?.municipio,
									value: dados.transporte.transportadora?.municipio.id,
									label:
										dados.transporte.transportadora?.municipio.nome +
										' - ' +
										dados.transporte.transportadora?.municipio.estado.sigla,
								}
							: null,
					}
				: null,
			veiculo: dados.transporte?.veiculo
				? {
						estado: dados.transporte?.veiculo?.estado
							? {
									value: dados.transporte.veiculo?.estado.id,
									label: dados.transporte.veiculo?.estado.nome + ' - ' + dados.transporte.veiculo.estado?.sigla,
								}
							: null,
					}
				: null,
		},
		itens: await converteItensXmlParaFormulario(dados.itens),
		cobranca: {
			categoria: dados.cobranca?.categoria
				? {
						...dados.cobranca.categoria,
						value: dados.cobranca.categoria.id,
						label: dados.cobranca.categoria.nome,
						registro: { aparencia: dados.cobranca.categoria?.aparencia ?? null },
					}
				: null,
			conta: dados.cobranca?.conta
				? {
						...dados.cobranca.conta,
						value: dados.cobranca.conta.id,
						label: dados.cobranca.conta.nome,
					}
				: null,
		},
	};
	return dadosConvertidos;
}

async function converteItensXmlParaFormulario(itens) {
	let itensConvertidos = [];
	if (itens?.length > 0) {
		itens?.forEach((item) => {
			itensConvertidos.push({
				item: item.item,
				produto: item.produto ? concatenate(item.produto) : null,
				ncm: item.ncm ? concatenate(item.ncm) : null,
				cfop: item.cfop ? concatenate(item.cfop) : null,
				unidadeEstoque: item.unidadeEstoque ? concatenate(item.unidadeEstoque) : null,
				unidadeCompra: item.unidadeCompra ? concatenate(item.unidadeCompra) : null,
				cest: item.cest ? concatenate(item.cest) : null,
				fatorConversao: item.fatorConversao ? item.fatorConversao : 1,
				operacaoFatorConversao: item.operacaoFatorConversao ? item.operacaoFatorConversao : 'MULTIPLICACAO',
				margemLucro: item.margemLucro ? item.margemLucro : 0,
				margemLucroAtacado: item.margemLucroAtacado ? item.margemLucroAtacado : 0,
				icms: item.icms
					? {
							cst: item.icms.cst ? concatenate(item.icms.cst) : null,
						}
					: null,
				ipi: item.ipi
					? {
							enquadramento: item.ipi.enquadramento ? concatenate(item.ipi.enquadramento) : null,
							cst: item.ipi.cst ? concatenate(item.ipi.cst) : null,
						}
					: null,
				pis: item.pis
					? {
							cst: item.pis.cst ? concatenate(item.pis.cst) : null,
						}
					: null,
				cofins: item.cofins
					? {
							cst: item.cofins.cst ? concatenate(item.cofins.cst) : null,
						}
					: null,
				vinculo: item.vinculo ?? false,
			});
		});
	}

	return itensConvertidos;
}

async function converteParticipante(emitenteXml, emitente) {
	let emitenteConvertido = {
		nome: emitenteXml.nome().replace('&amp;', '&'),
		nomeFantasia: emitenteXml.fantasia().replace('&amp;', '&'),
		cpf: emitenteXml.cpf().trim() ? emitenteXml.cpf() : null,
		cnpj: emitenteXml.cnpj().trim() ? emitenteXml.cnpj() : null,
		telefone: emitenteXml.telefone(),
		inscricaoEstadual: emitenteXml.inscricaoEstadual(),
		inscricaoMunicipal: emitenteXml.inscricaoMunicipal(),
		logradouro: emitenteXml.logradouro(),
		municipio: emitente?.municipio,
		bairro: emitenteXml.bairro(),
		cep: emitenteXml.cep(),
		numero: emitenteXml.numero(),
		complemento: emitenteXml.complemento(),
		regimeTributario: emitenteXml.regimeTributario() ? emitenteXml.regimeTributario() : '1',
		pessoa: emitente?.pessoa
			? {
					...emitente.pessoa,
					codigo: emitente.pessoa.codigo,
				}
			: null,
		label: emitente?.pessoa ? `${emitente.pessoa.codigo} - ${emitente.pessoa.nome}` : null,
	};
	return emitenteConvertido;
}

function converteDataHora(dateTimeXml) {
	const [dateValue, timeValue] = dateTimeXml.split('T');
	const [year, month, day] = dateValue.split('-');
	const [time] = timeValue.split('-');
	const [hours, minutes, seconds] = time.split(':');

	let date = new Date(+year, month - 1, +day, +hours, +minutes, +seconds);
	let data = format(date, 'yyyy-MM-dd');
	let hora = format(date, 'HH:mm');
	return { data, hora };
}

async function converteItens(nfe, itensMsys) {
	const countItens = nfe.nrItens();
	let itens = [];

	for (let i = 1; i <= countItens; i++) {
		let indexMsys = i - 1;
		const item = nfe?.item(i);
		if (itensMsys && item) {
			itens.push({
				id: gerarUUID(),
				item: itensMsys[indexMsys]?.item,
				produto: itensMsys[indexMsys]?.produto ? itensMsys[indexMsys].produto : null,
				codigoProduto: item.codigo(),
				cadastrar: itensMsys[indexMsys]?.produto ? false : true,
				descricaoFornecedor: item.descricao(),
				ncm: itensMsys[indexMsys]?.ncm,
				cfop: itensMsys[indexMsys]?.cfop,
				unidadeCompra: itensMsys[indexMsys]?.unidadeCompra,
				unidadeEstoque: itensMsys[indexMsys]?.unidadeEstoque,
				margemLucro: itensMsys[indexMsys]?.margemLucro,
				margemLucroAtacado: itensMsys[indexMsys]?.margemLucroAtacado,
				fatorConversao: itensMsys[indexMsys]?.fatorConversao,
				operacaoFatorConversao: itensMsys[indexMsys]?.operacaoFatorConversao,
				quantidade: converteStringToNumber(item.quantidadeComercial()),
				quantidadeConvertida: calcularConversao(
					converteStringToNumber(item.quantidadeComercial()),
					itensMsys[indexMsys]?.fatorConversao,
					itensMsys[indexMsys]?.operacaoFatorConversao
				),
				valorUnitario: converteStringToNumber(item.valorUnitario()),
				valorFrete: converteStringToNumber(item.valorFrete()),
				valorSeguro: converteStringToNumber(item.valorSeguro()),
				valorDesconto: converteStringToNumber(item.valorDesconto()),
				valorAcessorias: converteStringToNumber(item.valorOutrasDespesas()),
				subTotal: calcularTotal(
					itensMsys[indexMsys]?.operacaoFatorConversao,
					converteStringToNumber(item.valorUnitario()),
					converteStringToNumber(item.quantidadeComercial()),
					converteStringToNumber(item.valorIcmsST()),
					converteStringToNumber(item.valorIPI()),
					converteStringToNumber(item.valorFrete()),
					converteStringToNumber(item.valorSeguro()),
					converteStringToNumber(item.valorOutrasDespesas()),
					converteStringToNumber(item.valorDesconto())
				),
				codigoBarras: item.ean(),
				pedidoCompra: item.numeroPedidoCompra(),
				itemPedidoCompra: item.itemPedidoCompra(),
				indicadorTotal: converteIndicadorTotal(item.indicadorTotal()),
				origem: converteOrigem(item.origem()),
				vinculo: itensMsys[indexMsys]?.vinculo,
				pesoLiquido: item.pesoLiquido(),
				pesoBruto: item.pesoBruto(),
				/* ICMS */
				cst: itensMsys[indexMsys]?.icms.cst ? itensMsys[indexMsys].icms.cst : null,
				baseCalculoIcms: converteStringToNumber(item.icms().baseCalculo()),
				icmsPorcentagem: converteStringToNumber(item.porcetagemIcms()),
				valorIcms: converteStringToNumber(item.valorIcms()),
				/* ICMS-ST */
				baseCalculoIcmsSt: converteStringToNumber(item.baseCalculoIcmsST()),
				icmsStPorcentagem: converteStringToNumber(item.porcetagemIcmsST()),
				valorIcmsSt: converteStringToNumber(item.valorIcmsST()),
				/* IPI */
				enquadramentoIpi: itensMsys[indexMsys]?.ipi.enquadramento ? itensMsys[indexMsys].ipi.enquadramento : null,
				cstIpi: itensMsys[indexMsys]?.ipi.cst ? itensMsys[indexMsys].ipi.cst : null,
				baseCalculoIpi: item.ipi() ? converteStringToNumber(item.ipi()?.baseCalculo()) : null,
				ipiPorcentagem: item.ipi() ? converteStringToNumber(item.ipi()?.porcentagemIPI()) : null,
				valorIpi: converteStringToNumber(item.valorIPI()),
				/* PIS */
				cstPis: itensMsys[indexMsys]?.pis.cst ? itensMsys[indexMsys].pis.cst : null,
				pisPorcentagem: converteStringToNumber(item.pis()?.porcentagemPIS()),
				baseCalculoPis: converteStringToNumber(item.pis()?.baseCalculo()),
				valorPis: converteStringToNumber(item.valorPIS()),
				/* PIS-ST */
				baseCalculoPisSt: item.pisSt() ? converteStringToNumber(item.pisSt()?.baseCalculo()) : null,
				pisStPorcentagem: item.pisSt() ? converteStringToNumber(item.pisSt()?.porcentagemPIS()) : null,
				valorPisSt: item.pisSt() ? converteStringToNumber(item.pisSt()?.valorPIS()) : null,
				/* COFINS */
				cstCofins: itensMsys[indexMsys]?.cofins.cst ? itensMsys[indexMsys].cofins.cst : null,
				baseCalculoCofins: converteStringToNumber(item.cofins()?.baseCalculo()),
				cofinsPorcentagem: converteStringToNumber(item.cofins()?.porcentagemCOFINS()),
				valorCofins: converteStringToNumber(item.valorCOFINS()),
				/* COFINS-ST */
				baseCalculoCofinsSt: item.cofinsSt() ? converteStringToNumber(item.cofinsSt()?.baseCalculo()) : null,
				cofinsStPorcentagem: item.cofinsSt() ? converteStringToNumber(item.cofinsSt()?.porcentagemCOFINS()) : null,
				valorCofinsSt: item.cofinsSt() ? converteStringToNumber(item.cofinsSt()?.valorCOFINS()) : null,
			});
		}
	}
	return itens;
}

async function converteCobranca(nfe) {
	let cobranca = {
		numeroFatura: nfe.numeroFatura(),
		valorOrigem: converteStringToNumber(nfe.valorOrigem()),
		valorDesconto: converteStringToNumber(nfe.valorOutrasDespesas()),
		valorLiquido: converteStringToNumber(nfe.valorLiquido()),
	};
	return cobranca;
}

async function convertePagamentos(nfe, cobrancaMsys) {
	let condicaoPagamento = converteIndicacaoPagamento(
		nfe.indicacaoPagamento(),
		nfe.nrDuplicatas(),
		nfe.finalidadeNfe(),
		nfe.valorLiquido()
	);
	let pagamentos = [
		{
			tempKey: gerarUUID(),
			sequencial: 1,
			quantidadeParcelas: { value: nfe.nrDuplicatas(), label: `${nfe.nrDuplicatas()}x` },
			condicaoPagamento: condicaoPagamento,
			valor: converteStringToNumber(nfe.total().valorNota()),
			categorias: [{categoria: cobrancaMsys?.categoria, percentual: 100, valor: converteStringToNumber(nfe.total().valorNota())}],
			conta: condicaoPagamento === INDICACAO_PAGAMENTO.A_VISTA ? cobrancaMsys.conta : null,
			parcelas: await converteDuplicatas(nfe, cobrancaMsys),
			formaPagamento: condicaoPagamento === INDICACAO_PAGAMENTO.A_VISTA ? converteFormaPagamento('01') : null,
		},
	];

	return pagamentos;
}

async function converteDuplicatas(nfe, cobrancaMsys) {
	let parcelas = [];
	let countDuplicatas = nfe.nrDuplicatas();
	if (countDuplicatas > 0) {
		for (let i = 1; i <= countDuplicatas; i++) {
			parcelas.push({
				tempKey: gerarUUID(),
				sequencial: i,
				numero: nfe.duplicata(i).numeroDuplicata(),
				valor: converteStringToNumber(nfe.duplicata(i).valorDuplicata()),
				vencimento: nfe.duplicata(i).vencimentoDuplicata(),
				financeiroStatus: 'NAO_PAGA',
				valorRestante: converteStringToNumber(nfe.duplicata(i).valorDuplicata()),
				formaPagamento: converteFormaPagamento('01'),
				conta: cobrancaMsys?.conta,
			});
		}
	}
	return parcelas;
}

async function converteTotais(nfe) {
	let totais = {
		valorBaseCalculo: converteStringToNumber(nfe.total().baseCalculo()),
		valorIcms: converteStringToNumber(nfe.total().valorIcms()),
		valorBaseCalculoSt: converteStringToNumber(nfe.total().baseCalculoIcmsST()),
		valorSt: converteStringToNumber(nfe.total().valorIcmsST()),
		valorProdutos: converteStringToNumber(nfe.total().valorProdutos()),
		valorFrete: converteStringToNumber(nfe.total().valorFrete()),
		valorSeguro: converteStringToNumber(nfe.total().valorSeguro()),
		valorDesconto: converteStringToNumber(nfe.total().valorDesconto()),
		valorAcessorias: converteStringToNumber(nfe.total().valorOutrasDespesas()),
		valorNfe: converteStringToNumber(nfe.total().valorNota()),
		valorTributos: converteStringToNumber(nfe.total().valorTotalTributos()),
		valorIcmsUfDestino: converteStringToNumber(nfe.total().valorIcmsUfDestinatario()),
		valorIcmsUfRemetente: converteStringToNumber(nfe.total().valorIcmsUfRemetente()),
		valorFcp: converteStringToNumber(nfe.total().valorCombatePobreza()),
		valorFcpSt: converteStringToNumber(nfe.total().valorCombatePobrezaSt()),
		valorFcpStRetido: converteStringToNumber(nfe.total().valorCombatePobrezaStRetido()),
		valorIpiDevolucao: converteStringToNumber(nfe.total().ipiDevolvido()),
		icmsDesonerado: converteStringToNumber(nfe.total().valorIcmsDesonerado()),
		valorIi: converteStringToNumber(nfe.total().valorII()),
		valorIpi: converteStringToNumber(nfe.total().valorIPI()),
		valorPis: converteStringToNumber(nfe.total().valorPIS()),
		valorCofins: converteStringToNumber(nfe.total().valorCOFINS()),
	};

	return totais;
}

async function converteTransporte(nfe, transporteMsys) {
	let transporte = {
		modalidadeFrete: converteModalidadeTransporte(nfe.transporte()?.modalidadeFrete()),
		transportadora: converteTransportadora(nfe.transporte(), transporteMsys),
		veiculo: nfe.transporte()?.veiculo()
			? {
					placa: nfe.transporte()?.veiculo().placa(),
					rntc: nfe.transporte()?.veiculo().antt(),
					estado: transporteMsys.veiculo?.estado,
				}
			: null,
		volume: nfe.transporte()?.volume()
			? {
					quantidade: nfe.transporte()?.volume().quantidadeVolumes(),
					especie: nfe.transporte()?.volume().especie(),
					marca: nfe.transporte()?.volume().marca(),
					numeracao: nfe.transporte()?.volume().numeracao(),
					pesoBruto: nfe.transporte()?.volume().pesoBruto(),
					pesoLiquido: nfe.transporte()?.volume().pesoLiquido(),
				}
			: null,
	};

	return transporte;
}

function converteStringToNumber(value) {
	return parseFloat(value.trim() !== '' ? value : 0);
}

function converteIndicadorTotal(value) {
	switch (value) {
		case '0':
			return INDICADOR_TOTAL.NAO_COMPOEM_TOTAL_NFE;
		case '1':
			return INDICADOR_TOTAL.COMPOEM_TOTAL_NFE;
		default:
			return INDICADOR_TOTAL.COMPOEM_TOTAL_NFE;
	}
}

function converteOrigem(value) {
	switch (value) {
		case '0':
			return ORIGEM_PRODUTO.NACIONAL;
		case '1':
			return ORIGEM_PRODUTO.ESTRANGEIRA;
		case '2':
			return ORIGEM_PRODUTO.ESTRANGEIRA_ADQUIRIDA_MERCADO_INTERNO;
		case '3':
			return ORIGEM_PRODUTO.NACIONAL_IMPORTACAO_ENTRE_40_E_70_PORCENTO;
		case '4':
			return ORIGEM_PRODUTO.NACIONAL_COM_PROCESSO_PRODUTIVO;
		case '5':
			return ORIGEM_PRODUTO.NACIONAL_IMPORTACAO_INFERIOR_40_PORCENTO;
		case '6':
			return ORIGEM_PRODUTO.ESTRANGEIRA_IMPORTACAO_DIRETA;
		case '7':
			return ORIGEM_PRODUTO.ESTRANGEIRA_ADQUIR_MERCADO_INTERNO_SEM_SIMILAR_NAC;
		case '8':
			return ORIGEM_PRODUTO.NACIONAL_IMPORTACAO_SUPERIOR_70_PORCENTO;
		default:
			return ORIGEM_PRODUTO.NACIONAL;
	}
}

function converteIndicacaoPagamento(value, nrDuplicatas, finalidadeNfe = FINALIDADE_NFE.NORMAL, valorLiquidoCobranca) {
	switch (value) {
		case '0':
			return INDICACAO_PAGAMENTO.A_VISTA;
		case '1' && nrDuplicatas > 0:
			return INDICACAO_PAGAMENTO.A_PRAZO;
		case '2':
			return INDICACAO_PAGAMENTO.SEM_PAGAMENTO;
		default: {
			return nrDuplicatas > 0
				? INDICACAO_PAGAMENTO.A_PRAZO
				: finalidadeNfe === FINALIDADE_NFE.COMPLEMENTAR || finalidadeNfe === FINALIDADE_NFE.DEVOLUCAO
					? INDICACAO_PAGAMENTO.SEM_PAGAMENTO
					: valorLiquidoCobranca > 0
						? INDICACAO_PAGAMENTO.A_VISTA
						: INDICACAO_PAGAMENTO.SEM_PAGAMENTO;
		}
	}
}

function converteModalidadeTransporte(value) {
	switch (value) {
		case '0':
			return MODALIDADE_TRANSPORTE.EMITENTE;
		case '1':
			return MODALIDADE_TRANSPORTE.DESTINATARIO_REMETENTE;
		case '2':
			return MODALIDADE_TRANSPORTE.TERCEIROS;
		case '3':
			return MODALIDADE_TRANSPORTE.SEM_FRETE;
		case '9':
			return MODALIDADE_TRANSPORTE.SEM_FRETE;
		default:
			return MODALIDADE_TRANSPORTE.SEM_FRETE;
	}
}

function converteTransportadora(transporte, transporteMsys) {
	const cnpj = transporte.transportador() ? transporte.transportador()?.cnpj() : null;
	const cpf = transporte.transportador() ? transporte.transportador()?.cpf() : null;
	const ie = transporte.transportador() ? transporte.transportador().inscricaoEstadual() : null;

	const transportadora =
		(cnpj !== null || cpf !== null) && transporteMsys.transportadora?.transportadora !== null
			? {
					cnpj: cnpj,
					cpf: cpf,
					nome: transporte.transportador().nome()?.replace('&amp;', '&')?.substring(0, 60),
					inscricaoEstadual: ie,
					endereco: transporte.transportador().logradouro(),
					municipio: transporteMsys.transportadora?.municipio,
					transportadora: transporteMsys.transportadora ? transporteMsys.transportadora?.transportadora : null,
					label: `${transporteMsys.transportadora?.transportadora?.codigo} - ${transporteMsys.transportadora?.transportadora?.nome}`,
				}
			: null;
	return transportadora;
}

function converteFormaPagamento(value) {
	switch (value) {
		case '01':
			return FORMA_PAGAMENTO[0];
		case '02':
			return FORMA_PAGAMENTO[1];
		case '03':
			return FORMA_PAGAMENTO[2];
		case '04':
			return FORMA_PAGAMENTO[3];
		case '05':
			return FORMA_PAGAMENTO[4];
		case '10':
			return FORMA_PAGAMENTO[5];
		case '11':
			return FORMA_PAGAMENTO[6];
		case '12':
			return FORMA_PAGAMENTO[7];
		case '13':
			return FORMA_PAGAMENTO[8];
		case '15':
			return FORMA_PAGAMENTO[9];
		case '16':
			return FORMA_PAGAMENTO[10];
		case '17':
			return FORMA_PAGAMENTO[11];
		case '18':
			return FORMA_PAGAMENTO[12];
		case '19':
			return FORMA_PAGAMENTO[13];
		case '90':
			return FORMA_PAGAMENTO[14];
		case '99':
			return FORMA_PAGAMENTO[14];
		default:
			return FORMA_PAGAMENTO[0];
	}
}
