import { copiarObjeto, gerarUUID } from 'Common';
import { addMonths, formatISO } from 'date-fns';
import { NOVO_PRODUTO_VENDA } from 'components/Produtos/Util/constantes';
import { ColorsCard as ColorsCardConsts, Status, Tipo } from '../../Util/constantes';

export const helpMessage = {
	codigo: 'Código de identificação do documento',
	pessoa: 'Cliente da venda ou orçamento',
	emissao: 'Data de emissão da venda ou orçamento',
	validade: 'Data de vencimento do orçamento',
	status: '',
	condicao: '',
	quantidade: '',
	informacaoComplementar: 'Estas informações serão incluídas na impressão do pedido',
	observacaoInterna: 'Estas informações NÃO serão incluídas na impressão do pedido',
	vendedor: 'Usuário do sistema responsável pelo orçamento ou venda',
	previsaoEntrega: 'Data prevista para entrega da venda ou orçamento',
};

export const condicaoDropdown = [
	{ label: 'À vista', value: 'A_VISTA' },
	{ label: 'A prazo', value: 'A_PRAZO' },
	{ label: 'Sem pagamento', value: 'SEM_PAGAMENTO' },
];

export const statusDropdown = [
	{
		label: 'Orçamento',
		value: 'ORCAMENTO',
		lightColor: ColorsCardConsts[Tipo.ORCAMENTO].lightColor,
		strongColor: ColorsCardConsts[Tipo.ORCAMENTO].strongColor,
	},
	{
		label: 'Venda',
		value: 'VENDA',
		lightColor: ColorsCardConsts[Tipo.PEDIDO_VENDA].lightColor,
		strongColor: ColorsCardConsts[Tipo.PEDIDO_VENDA].strongColor,
	},
	{
		label: 'Cancelada',
		value: 'CANCELADA',
		lightColor: ColorsCardConsts[Status.CANCELADA].lightColor,
		strongColor: ColorsCardConsts[Status.CANCELADA].strongColor,
	},
];

export const quantidadeParcelasDropdown = [
	{ label: '1x', value: 1 },
	{ label: '2x', value: 2 },
	{ label: '3x', value: 3 },
	{ label: '4x', value: 4 },
	{ label: '5x', value: 5 },
	{ label: '6x', value: 6 },
	{ label: '7x', value: 7 },
	{ label: '8x', value: 8 },
	{ label: '9x', value: 9 },
	{ label: '10x', value: 10 },
	{ label: '11x', value: 11 },
	{ label: '12x', value: 12 },
	{ label: '13x', value: 13 },
	{ label: '14x', value: 14 },
	{ label: '15x', value: 15 },
	{ label: '16x', value: 16 },
	{ label: '17x', value: 17 },
	{ label: '18x', value: 18 },
	{ label: '19x', value: 19 },
	{ label: '20x', value: 20 },
	{ label: '21x', value: 21 },
	{ label: '22x', value: 22 },
	{ label: '23x', value: 23 },
	{ label: '24x', value: 24 },
	{ label: '25x', value: 25 },
	{ label: '26x', value: 26 },
	{ label: '27x', value: 27 },
	{ label: '28x', value: 28 },
	{ label: '29x', value: 29 },
	{ label: '30x', value: 30 },
	{ label: '31x', value: 31 },
	{ label: '32x', value: 32 },
	{ label: '33x', value: 33 },
	{ label: '34x', value: 34 },
	{ label: '35x', value: 35 },
	{ label: '36x', value: 36 },
	{ label: '37x', value: 37 },
	{ label: '38x', value: 38 },
	{ label: '39x', value: 39 },
	{ label: '40x', value: 40 },
	{ label: '41x', value: 41 },
	{ label: '42x', value: 42 },
	{ label: '43x', value: 43 },
	{ label: '44x', value: 44 },
	{ label: '45x', value: 45 },
	{ label: '46x', value: 46 },
	{ label: '47x', value: 47 },
	{ label: '48x', value: 48 },
];

export const contaReceberStatus = {
	NAO_RECEBIDA: 'NAO_RECEBIDA',
	PARCIALMENTE_RECEBIDA: 'PARCIALMENTE_RECEBIDA',
	RECEBIDA: 'RECEBIDA',
};

export const Condicoes = {
	SEM_PAGAMENTO: 'SEM_PAGAMENTO',
	A_VISTA: 'A_VISTA',
	A_PRAZO: 'A_PRAZO',
};

export const TypeErrors = {
	PRODUTO_INATIVO: 'PRODUTO_INATIVO',
};

export const INITIAL_VALUES = {
	id: null,
	numero: '',
	status: '',
	tipo: 'PEDIDO_VENDA',
	tipoOperacao: 'SAIDA',
	pessoa: null,
	dataEmissao: formatISO(new Date()),
	validade: formatISO(addMonths(new Date(), 1)),
	previsaoEntrega: formatISO(addMonths(new Date(), 1)),
	operacaoFiscal: null,
	tipoDesconto: 'VALOR',
	orcamentoId: null,

	produtos: [{ ...copiarObjeto(NOVO_PRODUTO_VENDA), id: gerarUUID() }],

	totalizadores: {
		totalProdutos: 0,
		totalFrete: 0,
		totalSeguro: 0,
		totalTributos: 0,
		totalAcessorias: 0,
		percentualDesconto: null,
		totalDesconto: 0,
		totalIcms: 0,
		totalIcmsSt: 0,
		totalIpi: 0,
		totalFcpSt: 0,
		totalLiquido: 0,
		totalBaseCalculoIcms: 0,
		totalBaseCalculoIcmsSt: 0,
		totalBaseCalculoIpi: 0,
	},
	pagamentos: [
		{
			id: null,
			tempKey: gerarUUID(),
			sequencial: 1,
			condicaoPagamento: null,
			conta: null,
			formaPagamento: null,
			categorias: [],
			quantidadeParcelas: 0,
			valor: 0,
			valorRecebido: 0,
			parcelas: [],
		},
	],
	informacoesComplementares: {
		vendedor: null,
		tabelaPreco: null,
		usuario: null,
		setor: null,
		informacaoComplementar: '',
		observacaoInterna: '',
		setorId: null,
	},
	motivoCancelamento: null,

	validacaoRecarregaProdutoPeloCliente: false,
};
