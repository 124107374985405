import { useEffect, useState } from 'react';
import { Field, useFormikContext, withFormik } from 'formik';
import { formatISO, isValid, parseISO } from 'date-fns';
import { withRouter } from 'react-router';
import * as Yup from 'yup';

import { formatarMonetario, mensagensDeValidacao, parseFloatNumber, renderizarValidacao, services } from 'Common';
import {
	AutoProgressBar,
	ButtonCancelar,
	ButtonSalvar,
	Col,
	estadosBotaoCancelar,
	estadosBotaoSalvar,
	Fieldset,
	Form,
	FormActions,
	FormContent,
	Grid,
	InputDate,
	InputMoney,
	InputSelectPercentualOrValor,
	Modal,
	SingleSelectConta,
	SingleSelectFormaPagamento,
	TextArea,
	tipos,
} from 'components';

import { buscarContaFavoritaDespesa } from 'views/financas/ContasPagar/Requests';
import { aplicarPercentual, validarFormulario } from '../../../../../../Util';
import { helpMessage } from './Util/constantes';

const INITIAL_VALUES = {
	id: null,
	idTemporario: null,
	conta: null,
	data: formatISO(new Date()),
	desconto: 0,
	juros: 0,
	multa: 0,
	valor: null,
};

const parteStyle = {
	fontSize: '13px',
	color: '#000000',
	margin: '0px 0px',
};

const totalStyle = {
	fontSize: '20px',
	color: '#000000',
	margin: '10px 0px 0px 0px',
};

function ModalPagamentoView({
	isModal,
	visible,
	onSalvar,
	onHide,
	formaPagamento,
	conta,
	informacoesPermissoes,
	...props
}) {
	const { values, dirty, resetForm, setFieldValue, initialValues, errors, handleSubmit } = useFormikContext();

	const [tipoDesconto, setTipoDesconto] = useState(tipos.VALOR);
	const [percentualDesconto, setPercentualDesconto] = useState(0);
	const [tipoJuros, setTipoJuros] = useState(tipos.VALOR);
	const [percentualJuros, setPercentualJuros] = useState(0);
	const [tipoMulta, setTipoMulta] = useState(tipos.VALOR);
	const [percentualMulta, setPercentualMulta] = useState(0);
	const [restart, setRestart] = useState(false);

	const total = values.valor + values.juros + values.multa - values.desconto;

	useEffect(async () => {
		setTimeout(() => {
			document.getElementById('valorPagamento')?.focus();
		}, 500);

		if (!values.id && !values.idTemporario) {
			await buscarContaFavoritaDespesa(({ data }) => {
				if (data.page.totalElements > 0) {
					resetForm({
						values: {
							...initialValues,
							conta: {
								label: data.content[0].nome,
								value: data.content[0].id,
								registro: data.content[0],
							},
						},
					});
				}
			});
			setFieldValue('formaPagamento', formaPagamento);
			setFieldValue('conta', conta);
		}
	}, []);

	function onChangeValor(e) {
		const valor = e.target.value;
		setFieldValue('valor', parseFloatNumber(valor));

		// CALCULAR  PERCENTUAIS
		if (tipoDesconto === tipos.PERCENTUAL) {
			setFieldValue('desconto', aplicarPercentual(valor, percentualDesconto));
		}

		if (tipoJuros === tipos.PERCENTUAL) {
			setFieldValue('juros', aplicarPercentual(valor, percentualJuros));
		}

		if (tipoMulta === tipos.PERCENTUAL) {
			setFieldValue('multa', aplicarPercentual(valor, percentualMulta));
		}
	}

	async function salvar() {
		handleSubmit();

		if (await validarFormulario(props)) {
			if (values && isValid) {
				onSalvar(values);
			}
		}
	}

	function cancelar() {
		if (dirty) {
			setRestart(true);
			resetForm({ values: initialValues });
		} else {
			resetForm({ values: INITIAL_VALUES });
			onHide();
		}
	}

	return (
		<Modal header={values.id ? 'Editar pagamento' : 'Novo pagamento'} visible={visible} onHide={onHide}>
			<AutoProgressBar />
			<Form>
				<FormActions>
					<ButtonCancelar
						{...informacoesPermissoes}
						estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
						onClick={(e) => cancelar(e)}
					/>
					<ButtonSalvar
						{...informacoesPermissoes}
						estadoBotao={estadosBotaoSalvar.CONFIRMAR}
						onClick={(e) => salvar(e)}
					/>
				</FormActions>
				<FormContent>
					<Grid>
						<Field
							sm="12"
							md="6"
							lg="4"
							xl="4"
							id="valorPagamento"
							component={InputMoney}
							label="Valor pago"
							name="valor"
							obrigatorio
							autofocus
							value={values.valor}
							helpMessage={helpMessage.valor}
							onChange={(e) => onChangeValor(e)}
							size={15}
							allowNegative={false}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="6"
							lg="4"
							xl="4"
							component={InputDate}
							label="Data do pagamento"
							name="data"
							obrigatorio
							onChange={(e) => setFieldValue('data', e.target.value)}
							value={values.data}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="6"
							lg="4"
							xl="4"
							name="formaPagamento"
							label="Forma de pagamento"
							obrigatorio
							component={SingleSelectFormaPagamento}
							value={values.formaPagamento}
							helpMessage={helpMessage.formaPagamento}
							onChange={(e) => setFieldValue('formaPagamento', e)}
							url={`${services.GESTOR}/v1/contas_pagar/relacoes/formas_pagamento`}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="6"
							lg="4"
							xl="4"
							name="conta"
							label="Conta"
							obrigatorio
							component={SingleSelectConta}
							value={values.conta}
							helpMessage={helpMessage.conta}
							onChange={(e) => setFieldValue('conta', e)}
							url={`${services.GESTOR}/v1/contas_pagar/relacoes/contas`}
							{...informacoesPermissoes}
						/>
						<Fieldset legend="Informações complementares" style={{ width: '100%' }} className="fieldset">
							<Grid col="12">
								<Col sm="12" md="6" lg="4" xl="4">
									<Field
										component={InputSelectPercentualOrValor}
										label="Descontos"
										name="desconto"
										value={values.desconto}
										valueBase={values.valor}
										errors={errors.desconto}
										restart={restart}
										onRestart={() => {
											setRestart(false);
										}}
										helpMessage={helpMessage.desconto}
										onChange={(tipo, value, percentual) => {
											setTipoDesconto(tipo);
											setPercentualDesconto(percentual);
											setFieldValue('desconto', value);
										}}
										size={15}
										informacoesPermissoes={informacoesPermissoes}
									/>
								</Col>
								<Col sm="12" md="6" lg="4" xl="4">
									<Field
										component={InputSelectPercentualOrValor}
										label="Juros"
										name="juros"
										value={values.juros}
										valueBase={values.valor}
										restart={restart}
										onRestart={() => {
											setRestart(false);
										}}
										helpMessage={helpMessage.juros}
										onChange={(tipo, value, percentual) => {
											setTipoJuros(tipo);
											setPercentualJuros(percentual);
											setFieldValue('juros', value);
										}}
										size={15}
										informacoesPermissoes={informacoesPermissoes}
									/>
								</Col>
								<Col sm="12" md="6" lg="4" xl="4">
									<Field
										component={InputSelectPercentualOrValor}
										label="Multa"
										name="multa"
										value={values.multa}
										valueBase={values.valor}
										restart={restart}
										onRestart={() => {
											setRestart(false);
										}}
										helpMessage={helpMessage.multa}
										onChange={(tipo, value, percentual) => {
											setTipoMulta(tipo);
											setPercentualMulta(percentual);
											setFieldValue('multa', value);
										}}
										size={15}
										informacoesPermissoes={informacoesPermissoes}
									/>
								</Col>
								<Field
									sm="12"
									component={TextArea}
									placeholder="Escreva sua observação aqui"
									label="Observação"
									name="observacao"
									helpMessage={helpMessage.observacao}
									maxLength={4096}
									onChange={(e) => setFieldValue('observacao', e.target.value)}
								/>
							</Grid>
						</Fieldset>
						<Col>
							<p style={parteStyle}>{`Descontos: ${formatarMonetario(values.desconto)}`}</p>
							<p style={parteStyle}>{`Juros: ${formatarMonetario(values.juros)}`}</p>
							<p style={parteStyle}>{`Multa: ${formatarMonetario(values.multa)}`}</p>
							<p style={totalStyle}>{`Total: ${formatarMonetario(total)}`}</p>
							{errors.total && renderizarValidacao(errors.total, true)}
						</Col>
					</Grid>
				</FormContent>
			</Form>
		</Modal>
	);
}

const ModalPagamento = withFormik({
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues(props) {
		if (props.registroSelecionado) {
			return props.registroSelecionado;
		} else if (props.valorAPagar > 0) {
			return { ...INITIAL_VALUES, valor: parseFloatNumber(props.valorAPagar) };
		} else {
			return { ...INITIAL_VALUES };
		}
	},

	validate(values) {
		const errors = {};
		const valorTotal = values.valor + values.juros + values.multa - values.desconto;

		if (values.valor <= 0) {
			errors.valor = 'O valor deve ser maior que zero';
		}

		if (valorTotal < 0) {
			errors.desconto = 'O desconto não pode exceder o valor total';
		}

		if (values.data && !isValid(parseISO(values.data))) {
			errors.data = mensagensDeValidacao.DATA_INVALIDA;
		}

		return errors;
	},

	validationSchema: Yup.object().shape({
		data: Yup.string().nullable().required(mensagensDeValidacao.DATA_INVALIDA),
		valor: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		conta: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
	}),
	handleSubmit: () => {},
})(ModalPagamentoView);

export default withRouter(ModalPagamento);
