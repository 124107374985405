export const modalidadeICMSST = {
  PRECO_TABELADO: "PRECO_TABELADO",
  LISTA_NEGATIVA: "LISTA_NEGATIVA",
  LISTA_POSITIVA: "LISTA_POSITIVA",
  LISTA_NEUTRA: "LISTA_NEUTRA",
  MARGEM_VALOR_AGREGADO: "MARGEM_VALOR_AGREGADO",
  PAUTA: "PAUTA",
  VALOR_OPERACAO: "VALOR_OPERACAO",
};

export const optionsIndicadorPresenca = [
  { label: "0 - Não se aplica", value: "NAO_SE_APLICA" },
  { label: "1 - Operação presencial", value: "OPERACAO_PRESENCIAL" },
  { label: "2 - Operação pela internet", value: "OPERACAO_PELA_INTERNET" },
  {
    label: "3 - Operação por teleatendimento",
    value: "OPERACAO_POR_TELEATENDIMENTO",
  },
  {
    label: "5 - Operação presencial fora do estabelecimento",
    value: "OPERACAO_PRESENCIAL_FORA_DO_ESTABELECIMENTO",
  },
  { label: "9 - Outros", value: "OUTROS" },
];

export const optionsOperacao = [
  { label: "0 - Entrada", value: "ENTRADA" },
  { label: "1 - Saída", value: "SAIDA" },
];

export const optionsTipoEmissao = [
  { label: "1 - Normal", value: "NORMAL" },
  { label: "2 - Complementar", value: "COMPLEMENTAR" },
  { label: "3 - Ajuste", value: "AJUSTE" },
  { label: "4 - Devolução", value: "DEVOLUCAO_MERCADORIA" },
];

export const optionsNaturezaOperacao = [
  { label: "Venda de mercadoria", value: "VENDA" },
  { label: "Venda de produto", value: "VENDA_PRODUTO" },
  { label: "Compra de mercadoria", value: "COMPRA" },
  { label: "Devolução de mercadoria", value: "DEVOLUCAO" },
  { label: "Remessa de mercadoria", value: "REMESSA" },
  { label: "Remessa de troca", value: "REMESSA_TROCA" },
  { label: "Retorno de mercadoria", value: "RETORNO" },
  { label: "Complemento de ICMS-ST", value: "COMPLEMENTAR_ICMS_ST" },
  { label: "Bonificação", value: "BONIFICACAO" },
  { label: "Transferência", value: "TRANSFERENCIA" },
  { label: "Remessa para depósito", value: "DEPOSITO" },
  { label: "Industrialização efetuada para outra empresa", value: "INDUSTRIALIZACAO_PARA_OUTRA_EMPRESA" },
  { label: "Estorno de NF-e não cancelada no prazo legal", value: "ESTORNO_CANCELAMENTO" },
  { label: "Remessa simples faturamento", value: "REMESSA_SIMPLES_FATURAMENTO" },
  { label: "Remessa de entrega futura", value: "REMESSA_ENTREGA_FUTURA" },
];

export const optionsDocumentosReferenciados = [
  { label: "NF-e/NFC-e/SAT/CT-e", value: "DOCUMENTOS_ELETRONICOS" },
  { label: "Cupom fiscal", value: "CUPOM_FISCAL" },
  { label: "Nota fiscal", value: "NOTA_FISCAL" },
  { label: "NF produtor rural", value: "NF_PRODUTOR_RURAL" },
];

export const optionsModalidadeFrete = [
  { label: "0 - Por conta do emitente", value: "EMITENTE" },
  { label: "1 - Por conta do destinatário", value: "DESTINATARIO" },
  { label: "2 - Por conta de terceiros", value: "TERCEIROS" },
  { label: "9 - Sem frete", value: "SEM_FRETE" },
];
