import { formatISO } from 'date-fns';
import { buscarDadosLoginLocalStorage, gerarUUID } from '../../../../../Common';
import { addZeros } from '../../../../../Common/Funcoes';
import { Condicoes, DocumentosTipos, TiposAcessoriasOuDesconto } from './constantes';
import { TIPO_PESSOA } from 'views/cadastros/Pessoas/Util/constantes';
import { removerAcentos } from 'Common/ManipulacaoDeString';

function converterNFCeParaApi(valuesFormulario) {
	return {
		status: valuesFormulario.status,
		indPres: valuesFormulario?.indicadorPresenca ?? 'OPERACAO_PRESENCIAL',
		indFinal: valuesFormulario?.ConsumidorFinal ?? 'CONSUMIDOR_FINAL',
		dest: converterClienteParaApi(valuesFormulario.cliente),
		det: converterProdutosParaApi(valuesFormulario.produtos, valuesFormulario.operacaoFiscal),
		pagamentos: converterPagamentosParaApi(valuesFormulario.pagamentos),
		total: converterTotalizadoresParaApi(valuesFormulario),
		vendedor: { id: valuesFormulario.vendedor?.value },
		operacaoFiscal: { id: valuesFormulario.operacaoFiscal?.value },
		infCpl: valuesFormulario.observacao,
		venda: valuesFormulario.venda ? valuesFormulario.venda : null,
	};
}

function converterClienteParaApi(cliente) {
	if (!cliente?.cnpj && !cliente?.cpf) {
		return null;
	}

	if (!cliente) {
		return null;
	}
	return {
		cliente: cliente.clienteCadastrado?.value ?? null,
		tipo: cliente.tipoCliente,
		CNPJ: cliente.cnpj ?? null,
		CPF: cliente.cpf ?? null,
		xNome: cliente.nome,
		limiteCredito: cliente.clienteCadastrado?.registro?.limiteCredito ?? null,
	};
}

function converterProdutosParaApi(produtos, operacaoFiscal) {
	let codigoNaNfce = buscarDadosLoginLocalStorage()?.filialConectada?.parametrosVendas?.codigoNaNfce;
	let produtoConvertido = [];
	produtos.forEach((produto) => {
		produtoConvertido.push({
			id: produto.id ?? null,
			item: produto.item,
			prod: {
				idProd: produto.produto.value,
				cProd:
					codigoNaNfce === 'REFERENCIA' &&
					produto.produto.registro.sku &&
					produto.produto.registro.sku.toString().trim() !== ''
						? produto.produto.registro.sku
						: produto.produto.registro.codigo,
				xProd: produto.descricao?.slice(0, 120),
				cEAN:
					produto.produto.registro.codigoBarras && produto.produto.registro.codigoBarras.toString().length > 7
						? produto.produto.registro.codigoBarras
						: 'SEM GTIN',
				idNCM: produto.ncm.value,
				idCFOP: produto.cfop.value,
				idCom: produto.unidadeMedida.value,
				idCEST: produto.cest?.value ?? null,
				qCom: produto.quantidade,
				vUnCom: produto.valor,
				vProd: parseFloat((produto.quantidade * produto.valor).toFixed(2)),
				vDesc: produto.desconto,
				vDescItem: produto.descontoItem,
				vDescRateio: produto.descontoRateio,
				vOutro: produto.acessorias,
				vOutroItem: produto.acessoriasItem,
				vOutroRateio: produto.acessoriasRateio,
				cProdANP: produto.produto.registro.codigoAnp,
				utilizouTabelaPromocional: produto.utilizouTabelaPromocional ?? false,
			},
			operacaoFiscal: { id: operacaoFiscal?.value },
			imposto: {
				icms: {
					orig: produto.tributos.icms.origemProduto,
					cstCsosn: { id: produto.tributos.icms.cstCsosn?.value },
					percentualBaseCalculo: produto.tributos.icms.percentualBaseIcms,
					percentualReducaoBaseCalculo: parseFloat(
						(produto.tributos.icms.percentualBaseIcms < 100
							? 100 - produto.tributos.icms.percentualBaseIcms
							: 0
						).toFixed(2)
					),
					modalidadeCalculo: produto.tributos.icms.modalidadeCalculo,
					valorBaseCalculo: produto.tributos.icms.valorIcms > 0 ? produto.tributos.icms.baseIcms ?? 0 : 0,
					aliquota: produto.tributos.icms.percentualIcms ?? 0,
					valor: produto.tributos.icms.valorIcms ?? 0,
					quantidadeBaseMonofasicoRetido: produto.tributos.icms.quantidadeBaseIcmsMonofasicoRetido ?? 0,
					percentualMonofasicoRetido: produto.tributos.icms.percentualIcmsMonofasicoRetido ?? 0,
					valorMonofasicoRetido: produto.tributos.icms.valorIcmsMonofasicoRetido ?? 0,
				},
				pis: {
					id: produto.tributos.pis.id ?? null,
					cst: { id: produto.tributos.pis.cst.value },
					percentualBaseCalculo: produto.tributos.pis.percentualBasePis ?? 0,
					valorBaseCalculo: produto.tributos.pis.basePis ?? 0,
					aliquota: produto.tributos.pis.percentualPis ?? 0,
					valor: produto.tributos.pis.valorPis ?? 0,
				},
				cofins: {
					id: produto.tributos.cofins.id ?? null,
					cst: { id: produto.tributos.cofins.cst.value },
					percentualBaseCalculo: produto.tributos.cofins.percentualBaseCofins ?? 0,
					valorBaseCalculo: produto.tributos.cofins.baseCofins ?? 0,
					aliquota: produto.tributos.cofins.percentualCofins ?? 0,
					valor: produto.tributos.cofins.valorCofins ?? 0,
				},
			},
		});
	});

	return produtoConvertido;
}

function converterPagamentoCategoriasParaApi(values){
	const categorias = [];
	if (values) {
		values.forEach(registro =>{
			const nfceCategoria = {
				categoria: {id: registro.categoria.value},
				valor: registro.valor,
				percentual: registro.percentual,
			};
			categorias.push(nfceCategoria);
		});
	}
	return categorias;
}

function converterPagamentosParaApi(pagamentos) {
	let pagamentosConvertidos = pagamentos?.map((pagamento, index) => {
		return {
			id: pagamento.id ?? null,
			sequencial: index + 1,
			valor: pagamento.valor,
			valorRecebido: pagamento.valorRecebido > 0 ? pagamento.valorRecebido : pagamento.valor,
			valorTroco: pagamento.valorTroco ?? 0,
			condicaoPagamento: pagamento.condicaoPagamento?.value ? { id: pagamento.condicaoPagamento?.value } : null,
			formaPagamento: pagamento.formaPagamento?.value ? { id: pagamento.formaPagamento?.value } : null,
			conta: pagamento.conta?.value ? { id: pagamento.conta.value } : null,
			quantidadeParcelas: pagamento.quantidadeParcelas ?? 0,
			categorias: converterPagamentoCategoriasParaApi(pagamento.categorias),
			parcelas:
				pagamento.condicaoPagamento?.registro?.tipo === Condicoes.A_PRAZO
					? converterParcelasparaApi(pagamento.parcelas)
					: [],
		};
	});

	return pagamentosConvertidos;
}

function converterParcelasparaApi(parcelas) {
	return parcelas?.map((parcela, index) => {
		return {
			id: parcela.id ?? null,
			sequencial: index + 1,
			vencimento: parcela.vencimento,
			formaPagamento: parcela.formaPagamento.value ? { id: parcela.formaPagamento.value } : null,
			conta: parcela.conta?.value ? { id: parcela.conta.value } : null,
			valor: parcela.valor,
			valorRecebido: parcela.valorRecebido ?? 0,
			descricao: parcela.descricao ?? null,
			status: parcela.status ?? 'NAO_RECEBIDA',
			valorAReceber: parcela.valorAReceber ?? 0,
		};
	});
}

function converterTotalizadoresParaApi(valuesFormulario) {
	const { totalizadores, produtos } = valuesFormulario;

	let totalizadorProdutos = 0;
	let descontoProdutos = 0;
	let descontoRateado = 0;
	let acessoriasRateado = 0;

	let totalBcIcms = 0;
	let totalIcms = 0;
	let totalPis = 0;
	let totalCofins = 0;

	produtos.forEach((produto) => {
		totalizadorProdutos += parseFloat((produto.quantidade * produto.valor).toFixed(2));
		descontoProdutos += produto.descontoItem;
		descontoRateado += produto.descontoRateio;
		acessoriasRateado += produto.acessoriasRateio;
		totalBcIcms += produto.tributos.icms.valorIcms > 0 ? produto.tributos.icms?.baseIcms : 0;
		totalIcms += produto.tributos.icms?.valorIcms;
		totalPis += produto.tributos.pis?.valorPis;
		totalCofins += produto.tributos.cofins?.valorCofins;
	});

	return {
		id: null,
		vProd: totalizadorProdutos ?? 0,
		vBC: totalBcIcms ?? 0,
		vICMS: totalIcms ?? 0,
		vPIS: totalPis ?? 0,
		vCOFINS: totalCofins ?? 0,
		vDesc: descontoProdutos + descontoRateado ?? 0,
		vOutro: acessoriasRateado ?? 0,
		vNF: totalizadores.totalLiquido ?? 0,
	};
}

function converterNfceParaFormulario(nfceApi) {
	let descontoRateado = 0;
	let acessoriasRateado = 0;

	nfceApi.det?.forEach((produto) => {
		descontoRateado += produto.prod.vDescRateio;
		acessoriasRateado += produto.prod.vOutroRateio;
	});

	return {
		id: nfceApi.id,
		status: nfceApi.status,
		serie: nfceApi.serie,
		numero: nfceApi.nnf,
		emissao: nfceApi.dhEmi,
		produtoSelecionado: null,
		quantidadeSelecionada: 1,
		chaveAcesso: nfceApi.chNfce,
		produtos: converterDetParaFormulario(nfceApi.det),
		cliente: {
			consumidorCadastrado: nfceApi.dest?.cliente ? true : false,
			nome: nfceApi.dest?.xNome ?? null,
			documentoTipo: nfceApi.dest?.CPF ? DocumentosTipos.CPF : DocumentosTipos.CNPJ,
			cpf: nfceApi.dest?.CPF ?? null,
			cnpj: nfceApi.dest?.CNPJ ?? null,
			clienteCadastrado: {
				label: nfceApi.dest?.xNome,
				registro: nfceApi.dest,
				value: nfceApi.dest?.cliente,
			},
			tipoCliente: nfceApi.dest?.CPF ? TIPO_PESSOA.FISICA : TIPO_PESSOA.JURIDICA,
		},
		operacaoFiscal: {
			label: `${nfceApi.operacaoFiscal.codigo} - ${nfceApi.operacaoFiscal.descricao}`,
			registro: nfceApi.operacaoFiscal,
			value: nfceApi.operacaoFiscal.id,
		},
		vendedor: {
			label: nfceApi.vendedor.nome,
			registro: nfceApi.vendedor,
			value: nfceApi.vendedor.id,
		},
		observacao: nfceApi.infCpl,
		indicadorPresenca: nfceApi.indPres,
		consumidorFinal: nfceApi.indFinal,

		acessorias: {
			tipo: TiposAcessoriasOuDesconto.VALOR,
			valor: acessoriasRateado,
		},
		desconto: {
			tipo: TiposAcessoriasOuDesconto.VALOR,
			valor: descontoRateado,
		},

		totalizadores: {
			totalProdutos: nfceApi.total.vProd,
			totalBaseCalculo: nfceApi.total.vBC,
			totalIcms: nfceApi.total.vICMS,
			totalPis: nfceApi.total.vPIS,
			totalCofins: nfceApi.total.vCOFINS,
			totalLiquido: nfceApi.total.vNF,
			totalDesconto: nfceApi.total.vDesc,
			totalAcessorias: nfceApi.total.vOutro,
		},

		pagamentos: converterPagParaFormulario(nfceApi.pagamentos),

		justificativaCancelamento: nfceApi.xjustCancelamento,
		dataCancelamento: nfceApi.dhCancelamento,

		validacaoRecarregaProdutoPeloCliente: false,
	};
}

function converterDetParaFormulario(det) {
	let produtos = det?.map((produto, index) => {
		return {
			id: produto.id,
			item: index + 1,
			produto: {
				label: `${produto.prod.codigo} - ${produto.prod.xProd}`,
				registro: {
					id: produto.prod.idProd,
					codigo: produto.prod.codigo,
					nome: produto.prod.xProd,
					ncm: produto.prod.idNCM
						? {
								label: `${produto.prod.NCM} - ${produto.prod.xNCM}`,
								registro: {
									codigo: produto.prod.NCM,
									descricao: produto.prod.xNCM,
									id: produto.prod.idNCM,
								},
								value: produto.prod.idNCM,
							}
						: null,
					unidadeMedida: produto.prod.idCom
						? {
								label: `${produto.prod.uCom} - ${produto.prod.xCom}`,
								registro: {
									codigo: produto.prod.uCom,
									descricao: produto.prod.xCom,
									id: produto.prod.idCom,
								},
								value: produto.prod.idCom,
							}
						: null,
					preco: produto.prod.vProdVenda,
					precoAtacado: produto.prod.vProdAtacado,
					origem: produto.imposto.icms.orig ?? null,
				},
				value: produto.prod.idProd,
				item: produto.item,
			},
			criadoEm: produto.prod.criadoEm,
			codigo: produto.prod.codigo,
			descricao: produto.prod.xProd,

			ncm: produto.prod.idNCM
				? {
						label: `${produto.prod.NCM} - ${produto.prod.xNCM}`,
						registro: {
							codigo: produto.prod.NCM,
							descricao: produto.prod.xNCM,
							id: produto.prod.idNCM,
						},
						value: produto.prod.idNCM,
					}
				: null,
			cest: produto.prod.idCEST
				? {
						label: `${produto.prod.CEST} - ${produto.prod.xCEST}`,
						registro: {
							codigo: produto.prod.CEST,
							descricao: produto.prod.xCEST,
							id: produto.prod.idCEST,
						},
						value: produto.prod.idCEST,
					}
				: null,
			cfop: produto.prod.idCFOP
				? {
						label: `${produto.prod.CFOP} - ${produto.prod.xCFOP}`,
						registro: {
							codigo: produto.prod.CFOP,
							descricao: produto.prod.xCFOP,
							id: produto.prod.idCFOP,
						},
						value: produto.prod.idCFOP,
					}
				: null,
			operacaoFiscal: produto.prod.operacaoFiscal
				? {
						label: `${produto.prod.operacaoFiscal.codigo} - ${produto.prod.operacaoFiscal.descricao}`,
						registro: produto.prod.operacaoFiscal,
						value: produto.prod.operacaoFiscal.id,
					}
				: null,
			unidadeMedida: produto.prod.idCom
				? {
						label: `${produto.prod.uCom} - ${produto.prod.xCom}`,
						registro: {
							codigo: produto.prod.uCom,
							descricao: produto.prod.xCom,
							id: produto.prod.idCom,
						},
						value: produto.prod.idCom,
					}
				: null,

			quantidade: produto.prod.qCom,
			acessoriasItem: produto.prod.vOutroItem,
			acessoriasRateio: produto.prod.vOutroRateio,
			acessorias: produto.prod.vOutro,
			descontoItem: produto.prod.vDescItem,
			descontoRateio: produto.prod.vDescRateio,
			desconto: produto.prod.vDesc,
			valor: produto.prod.vUnCom,
			utilizouTabelaPromocional: produto.prod.utilizouTabelaPromocional ?? false,

			tributos: {
				icms: {
					cstCsosn: {
						label: `${produto.imposto.icms.cstCsosn.codigo} - ${produto.imposto.icms.cstCsosn.descricao}`,
						registro: produto.imposto.icms.cstCsosn,
						value: produto.imposto.icms.cstCsosn.id,
					},
					origemProduto: produto.imposto.icms.orig ?? null,
					percentualCreditoSimplesNacional: 0,
					valorCreditoSimplesNacional: 0,
					percentualBaseIcms: produto.imposto.icms.percentualBaseCalculo,
					percentualReducaoBaseIcms: produto.imposto.icms.percentualReducaoBaseCalculo,
					baseIcms: produto.imposto.icms.valorBaseCalculo,
					percentualIcms: produto.imposto.icms.aliquota,
					valorIcms: produto.imposto.icms.valor,
					quantidadeBaseIcmsMonofasicoRetido: produto.imposto.icms?.quantidadeBaseMonofasicoRetido ?? 0,
					percentualIcmsMonofasicoRetido: produto.imposto.icms?.percentualMonofasicoRetido ?? 0,
					valorIcmsMonofasicoRetido: produto.imposto.icms?.valorMonofasicoRetido ?? 0,
				},
				pis: {
					cst: {
						label: `${produto.imposto.pis.cst.codigo} - ${produto.imposto.pis.cst.descricao}`,
						registro: produto.imposto.pis.cst,
						value: produto.imposto.pis.cst.id,
					},
					percentualBasePis: produto.imposto.pis.percentualBaseCalculo,
					basePis: produto.imposto.pis.valorBaseCalculo,
					percentualPis: produto.imposto.pis.aliquota,
					valorPis: produto.imposto.pis.valor,
				},
				cofins: {
					cst: {
						label: `${produto.imposto.cofins.cst.codigo} - ${produto.imposto.cofins.cst.descricao}`,
						registro: produto.imposto.cofins.cst,
						value: produto.imposto.cofins.cst.id,
					},
					percentualBaseCofins: produto.imposto.cofins.percentualBaseCalculo,
					baseCofins: produto.imposto.cofins.valorBaseCalculo,
					percentualCofins: produto.imposto.cofins.aliquota,
					valorCofins: produto.imposto.cofins.valor,
				},
			},

			subTotal: produto.prod.vUnCom * produto.prod.qCom - produto.prod.vDesc + produto.prod.vOutro,
			baseCalculo: produto.prod.vUnCom * produto.prod.qCom - produto.prod.vDesc + produto.prod.vOutro,
		};
	});
	return produtos;
}

function converterPagParaFormulario(pagamentos) {
	return pagamentos?.map((pagamento) => {
		return {
			id: pagamento.id,
			valor: pagamento.valor,
			valorRecebido: pagamento.valorRecebido,
			valorTroco: pagamento.valorTroco,
			formaPagamento: pagamento.formaPagamento
				? {
						label: pagamento.formaPagamento?.descricao,
						registro: pagamento.formaPagamento,
						value: pagamento.formaPagamento?.id,
					}
				: null,
			condicaoPagamento: pagamento.condicaoPagamento
				? {
						label: pagamento.condicaoPagamento?.descricao,
						registro: pagamento.condicaoPagamento,
						value: pagamento.condicaoPagamento?.id,
					}
				: null,
			conta: pagamento.conta
				? {
						label: pagamento.conta?.nome,
						registro: pagamento.conta,
						value: pagamento.conta?.id,
					}
				: null,
			quantidadeParcelas: pagamento.quantidadeParcelas,
			categorias: converterPagamentoCategoriasParaFormulario(pagamento.categorias),
			parcelas: converterParcelasParaFormulario(pagamento.parcelas),
			lancamento: pagamento.lancamento
				? { id: pagamento.lancamento?.id, valor: pagamento.lancamento?.valor, pixId: pagamento.lancamento?.pix?.id }
				: null,
		};
	});
}

function converterPagamentoCategoriasParaFormulario(values){
	const categorias = [];
	if (values) {
		values?.forEach(registro =>{
			const nfceCategoria = {
				categoria: converterCategoriaParaFormulario(registro.categoria),
				valor: registro.valor,
				percentual: registro.percentual,
			};
			categorias.push(nfceCategoria);
		});
	}
	return categorias;
}

function converterCategoriaParaFormulario(categoriaApi) {
	if (categoriaApi) {
		return {
			label: categoriaApi.nome,
			value: categoriaApi.id,
			registro: categoriaApi,
		};
	}
	return null;
}

function converterParcelasParaFormulario(parcelas) {
	return parcelas?.map((parcela) => {
		return {
			id: parcela.id,
			vencimento: parcela.vencimento,
			formaPagamento: parcela.formaPagamento
				? {
						label: parcela.formaPagamento?.descricao,
						registro: parcela.formaPagamento,
						value: parcela.formaPagamento?.id,
					}
				: null,
			conta: parcela.conta
				? {
						label: parcela.conta?.nome,
						registro: parcela.conta,
						value: parcela.conta?.id,
					}
				: null,
			valor: parcela.valor,
			descricao: parcela.descricao,
			contasReceber: parcelas.contasReceber,
		};
	});
}

function converterCancelamentoParaApi(justificativa) {
	if (justificativa) {
		let textoCancelamento = String(justificativa).trim();
		textoCancelamento = removerAcentos(textoCancelamento);
		return {
			xJust: textoCancelamento,
		};
	}
	return {
		xJust: '',
	};
}

function converterPedidoParaNfce(pedido) {
	return {
		id: null,
		numero: null,
		serie: null,
		status: 'NAO_ENVIADA',
		tipoOperacao: 'SAIDA',
		emissao: formatISO(new Date()),
		produtoSelecionado: null,
		produtos: converterProdutosPedidoParaNfce(pedido.itens),
		quantidadeSelecionada: 1,
		cliente: converterPessoaPedidoParaNfce(pedido.cliente),
		operacaoFiscal: {
			label: `${pedido.operacaoFiscal.codigo} - ${pedido.operacaoFiscal.descricao}`,
			registro: pedido.operacaoFiscal,
			value: pedido.operacaoFiscal.id,
		},
		vendedor: {
			label: pedido.vendedor.nome,
			registro: pedido.vendedor,
			value: pedido.vendedor.id,
		},
		observacao: converteObsPedidoParaNfce(pedido),
		acessorias: {
			tipo: TiposAcessoriasOuDesconto.VALOR,
			valor: pedido.valorDespesasAcessorias + pedido.valorFrete + pedido.valorSeguro,
		},
		desconto: {
			tipo: TiposAcessoriasOuDesconto.VALOR,
			valor: pedido.desconto,
		},

		totalizadores: {
			totalProdutos: pedido.totalItens,
			totalDesconto: pedido.desconto,
			totalAcessorias: pedido.valorDespesasAcessorias,
			totalLiquido: pedido.totalLiquido,
		},
		pagamentos: converterPagamentoPedidoParaNfce(pedido.pagamentos),
		validacaoRecarregaProdutoPeloCliente: false,
		venda: pedido.id
			? {
					id: pedido.id,
				}
			: null,
	};
}

function converterPagamentoPedidoParaNfce(pagamentos) {
	const newPagamentos = [];
	if (pagamentos.length > 0) {
		pagamentos.forEach((pagamento) => {
			newPagamentos.push({
				id: null,
				condicaoPagamento: {
					label: pagamento.condicaoPagamento?.descricao,
					registro: pagamento.condicaoPagamento,
					value: pagamento.condicaoPagamento?.id,
				},
				conta: {
					label: pagamento.conta?.nome,
					registro: pagamento.conta,
					value: pagamento.conta?.id,
				},
				categorias:converterPagamentoCategoriasParaFormulario(pagamento.categorias),
				formaPagamento: {
					label: pagamento?.formaPagamento
						? pagamento?.formaPagamento?.descricao
						: pagamento.condicaoPagamento?.formaPagamento?.descricao,
					registro: pagamento?.formaPagamento ? pagamento?.formaPagamento : pagamento.condicaoPagamento?.formaPagamento,
					value: pagamento?.formaPagamento
						? pagamento?.formaPagamento?.id
						: pagamento.condicaoPagamento?.formaPagamento?.id,
				},
				quantidadeParcelas: pagamento.quantidadeParcelas,
				sequencial: pagamento.sequencial,
				valor: pagamento.valor,
				parcelas: converterParcelasPedidoParaNfce(pagamento.parcelas),
			});
		});

		return newPagamentos;
	}
}

function converterParcelasPedidoParaNfce(parcelas) {
	return parcelas?.map((parcela) => {
		return {
			id: null,
			vencimento: parcela.vencimento,
			formaPagamento: parcela.formaPagamento
				? {
						label: parcela.formaPagamento?.descricao,
						registro: parcela.formaPagamento,
						value: parcela.formaPagamento?.id,
					}
				: null,
			conta: parcela.conta
				? {
						label: parcela.conta?.nome,
						registro: parcela.conta,
						value: parcela.conta?.id,
					}
				: null,
			valor: parcela.valor,
			descricao: parcela.descricao,
			contasReceber: parcelas.contasReceber,
		};
	});
}

function converteObsPedidoParaNfce(pedido) {
	return `DAV ${addZeros(pedido.numero, 10)}${pedido.observacaoInterna ? ' - ' + pedido.observacaoInterna : ''}`;
}

function converterPessoaPedidoParaNfce(pessoa) {
	return {
		consumidorCadastrado: Boolean(pessoa),
		nome: pessoa.nome,
		documentoTipo: pessoa.cpf ? DocumentosTipos.CPF : DocumentosTipos.CNPJ,
		tipoCliente: pessoa.tipo,
		cpf: pessoa.cpf,
		cnpj: pessoa.cnpj,
		clienteCadastrado: {
			label: pessoa.nome,
			registro: pessoa,
			value: pessoa.id,
		},
	};
}

function converterProdutosPedidoParaNfce(produtos) {
	const newProdutos = [];
	if (produtos.length > 0) {
		produtos.forEach((produto) => {
			let newProduto = {
				id: gerarUUID(),
				item: produto.item,
				produto: produto.produto
					? {
							...produto.produto,
							registro: produto.produto,
							label: produto.produto.codigo + ' - ' + produto.produto.nome,
							value: produto.produto.id,
						}
					: null,
				codigo: produto.produto?.codigo,
				descricao: produto.produto?.nome,
				ncm: produto.produto?.ncm
					? {
							label: `${produto.produto?.ncm.codigo} - ${produto.produto?.ncm.descricao}`,
							registro: produto.produto?.ncm,
							value: produto.produto?.ncm.id,
						}
					: null,
				cest: produto.produto?.cest
					? {
							label: `${produto.produto?.cest.codigo} - ${produto.produto?.cest.descricao}`,
							registro: produto.produto?.cest,
							value: produto.produto?.cest.id,
						}
					: null,
				cfop: produto.cfop
					? {
							value: produto.cfop.id,
							label: produto.cfop.codigo,
							registro: produto.cfop,
						}
					: null,
				operacaoFiscal: produto.operacaoFiscal
					? {
							label: produto.operacaoFiscal.codigo + ' - ' + produto.operacaoFiscal.descricao,
							registro: produto.operacaoFiscal,
							value: produto.operacaoFiscal.id,
						}
					: null,
				unidadeMedida: produto.unidadeMedida
					? {
							label: produto.unidadeMedida.label
								? produto.unidadeMedida.label
								: `${produto.unidadeMedida.unidade} - ${produto.unidadeMedida.descricao}`,
							registro: produto.unidadeMedida,
							value: produto.unidadeMedida.value ? produto.unidadeMedida.value : produto.unidadeMedida.id,
						}
					: null,
				quantidade: produto.quantidade,
				desconto: produto.desconto,
				descontoItem: 0,
				descontoRateio: produto.desconto,
				acessorias: produto.acessorias + produto.frete + produto.seguro,
				acessoriasItem: 0,
				acessoriasRateio: produto.acessorias + produto.frete + produto.seguro,
				valor: produto.valor,
				utilizouTabelaPromocional: produto.utilizouTabelaPromocional ?? false,
				tributos: {
					icms: {
						cstCsosn: produto.icms.cstCsosn
							? {
									label: produto.icms.cstCsosn?.codigo + ' - ' + produto.icms.cstCsosn?.descricao,
									registro: produto.icms.cstCsosn,
									value: produto.icms.cstCsosn?.id,
								}
							: null,
						origemProduto: produto.produto?.origem ? produto.produto?.origem : null,
						percentualCreditoSimplesNacional: produto.icms.aliquotaCreditoSimplesNacional,
						valorCreditoSimplesNacional: produto.icms.valorCreditoSimplesNacional,
						percentualBaseIcms: produto.icms.percentualBaseCalculo,
						percentualReducaoBaseIcms: produto.icms.percentualReducaoBaseCalculo,
						baseIcms: produto.icms.valorBaseCalculo,
						percentualIcms: produto.icms.aliquota,
						valorIcms: produto.icms.valor,
						quantidadeBaseIcmsMonofasicoRetido: produto.icms?.quantidadeBaseMonofasicoRetido ?? 0,
						percentualIcmsMonofasicoRetido: produto.icms?.percentualMonofasicoRetido ?? 0,
						valorIcmsMonofasicoRetido: produto.icms?.valorMonofasicoRetido ?? 0,
					},
					pis: {
						cst: produto.pis.cst
							? {
									label: produto.pis.cst?.codigo + ' - ' + produto.pis.cst?.descricao,
									registro: produto.pis.cst,
									value: produto.pis.cst?.id,
								}
							: null,
						percentualBasePis: produto.pis.percentualBaseCalculo,
						basePis: produto.pis.valorBaseCalculo,
						percentualPis: produto.pis.aliquota,
						valorPis: produto.pis.valor,
					},
					cofins: {
						cst: produto.cofins.cst
							? {
									label: produto.cofins.cst.codigo + ' - ' + produto.cofins.cst.descricao,
									value: produto.cofins.cst.id,
								}
							: null,
						percentualBaseCofins: produto.cofins.percentualBaseCalculo,
						baseCofins: produto.cofins.valorBaseCalculo,
						percentualCofins: produto.cofins.aliquota,
						valorCofins: produto.cofins.valor,
					},
				},
				subTotal:
					produto.quantidade * produto.valor + produto.acessorias + produto.frete + produto.seguro - produto.desconto,
				baseCalculo:
					produto.quantidade * produto.valor + produto.acessorias + produto.frete + produto.seguro - produto.desconto,
			};

			newProdutos.push(newProduto);
		});
	}
	return newProdutos;
}

function converterValueParaSelect(value, label) {
	return value
		? {
				value: value.id ?? null,
				label: label ?? value.nome ?? value.descricao,
				registro: value,
			}
		: null;
}

export {
	converterNFCeParaApi,
	converterNfceParaFormulario,
	converterCancelamentoParaApi,
	converterPedidoParaNfce,
	converterValueParaSelect,
};
