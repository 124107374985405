import { useCallback, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {
	Modal,
	Form,
	FormActions,
	FormContent,
	ButtonCancelar,
	estadosBotaoCancelar,
	NenhumRegistroEncontrado,
	Paginacao,
} from '../../../../../../../../../components';
import { construirUrl, services } from '../../../../../../../../../Common';
import { asyncGetHistorico } from '../../../../../Requests';

export default function NFCeHistorico(props) {
	const { idNFCe, visible, onHide, isFullScreen } = props;

	const [registros, setRegistros] = useState([]);
	const [totalElements, setTotalElements] = useState(0);
	const [page, setPage] = useState(0);
	const [sortField, setSortField] = useState('dataHora');
	const [sortOrder, setSortOrder] = useState(-1);
	const [rows, setRows] = useState(10);

	const pesquisarCallback = useCallback(() => {
		pesquisar();
	});

	useEffect(() => {
		pesquisar();
	}, []);

	useEffect(() => {
		pesquisarCallback();
	}, [page, rows, sortOrder]);

	async function pesquisar() {
		const url = construirUrl(
			`${services.GESTOR}/v1/nfce/${idNFCe}/historico`,
			'?query=()',
			rows,
			page,
			sortOrder > 0 ? `${sortField},asc` : `${sortField},desc`
		);

		await asyncGetHistorico(url, ({ data: historico }) => {
			setRegistros(historico.content);
			setTotalElements(historico.page.totalElements);
		});
	}

	function onSort(event) {
		setSortOrder(event.sortOrder);
		setSortField(event.sortField);
	}

	function onPageChange(event) {
		setRows(event.rows);
		setPage(event.page);
	}

	function renderUsuario(row) {
		if (row.nome && row.nome) {
			if (row.nome) {
				return `${row.nome} (${row.email})`;
			} else {
				return `${row.email}`;
			}
		} else {
			return 'Usuário não cadastrado no sistema';
		}
	}

	function renderData(row) {
		if (row.dataHora) {
			return <span>{format(new Date(row.dataHora), 'dd/MM/yyyy HH:mm:ss')}</span>;
		}
		return '';
	}

	function renderOperacao(row) {
		return row.acao;
	}

	function montarTituloModal() {
		return 'Histórico da NFC-e';
	}

	return (
		<Modal
			header={montarTituloModal()}
			visible={visible}
			onHide={onHide}
			container={isFullScreen ? document.getElementsByClassName('layout-fullscreen')[0] : null}
		>
			<Form>
				<FormActions>
					<ButtonCancelar estadoBotao={estadosBotaoCancelar.VOLTAR} onClick={onHide} />
				</FormActions>
				<FormContent>
					<DataTable
						className="table"
						responsive
						value={registros}
						sortField={sortField}
						sortOrder={sortOrder}
						onSort={onSort}
						emptyMessage={<NenhumRegistroEncontrado />}
					>
						<Column field="dataHora" header="Data" body={row => renderData(row)} sortable />
						<Column field="nome" header="Usuário" body={renderUsuario} />
						<Column field="acao" header="Ação" body={renderOperacao} />
					</DataTable>
					<Paginacao totalElements={totalElements} rows={rows} page={page} onPageChange={onPageChange} />
				</FormContent>
			</Form>
		</Modal>
	);
}
