import { useState, useEffect, useCallback } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import Form from '../Form';
import FormActions from '../FormActions';
import FormContent from '../FormContent';
import Grid from '../Grid';
import InputSearch from '../input/InputSearch';
import NenhumRegistroEncontrado from '../NenhumRegistroEncontrado';
import ButtonImprimirTable from '../Button/ButtonImprimirTable';
import { construirUrl } from '../../Common/Rsql';
import { services } from '../../Common/Constantes/api';
import { asyncGetPesquisaRelatorios } from './Requests';
import { alert } from '../Toast';
import { connect } from 'react-redux';
import Paginacao from '../Paginacao';

function ListagemRelatorios(props) {
	const { header, modulo, metodosImprimir } = props;

	const [registros, setRegistros] = useState([]);
	const [valorPesquisa, setValorPesquisa] = useState('');
	const [page, setPage] = useState(0);
	const [sortField, setSortField] = useState('identificacao');
	const [sortOrder, setSortOrder] = useState(1);
	const [rows, setRows] = useState(10);
	const [totalElements, setTotalElements] = useState(0);
	const [firstRender, setFirstRender] = useState(true);

	const pesquisarCallback = useCallback(e => {
		if (!firstRender){      
			pesquisar(); 
		}
	});

	useEffect(() => {
		pesquisar();

		setTimeout(() => {
			document.getElementById('RelatoriosInputSearch')?.focus();
		}, 500);
	}, []);

	useEffect(() => {
		setPage(0);
	}, [valorPesquisa]);

	useEffect(() => {
		pesquisarCallback();
	}, [sortField, sortOrder, page, rows]);

	async function pesquisar(firstPage = null) {
		if (firstPage !== null) {
			//setPage(firstPage);
		}

		const url = construirUrl(
			`${services.GESTOR}/v1/relatorios/modulos/${modulo}`,
			`?filtro=${valorPesquisa}`,
			rows,
			firstPage !== null ? firstPage : page,
			sortOrder > 0 ? `${sortField},asc` : `${sortField},desc`
		);

		await asyncGetPesquisaRelatorios(url, ({ data: relatorios }) => {
			setRegistros(relatorios.content);
			setTotalElements(relatorios.page.totalElements);
			setFirstRender(false);
		});
	}

	function onSort(element) {
		setSortField(element.sortField);
		setSortOrder(element.sortOrder);
	}

	function onPageChange(element) {
		setPage(element.page);
		setRows(element.rows);
	}

	function onImprimir(nome) {
		if (metodosImprimir.filter(relatorio => relatorio.nome === nome)[0]?.mensagemBloqueioRelatorio) {
			alert('Atenção', metodosImprimir.filter(relatorio => relatorio.nome === nome)[0].mensagemBloqueioRelatorio);
		} else if (metodosImprimir.filter(relatorio => relatorio.nome === nome)[0]) {
			if (metodosImprimir.filter(relatorio => relatorio.nome === nome)[0]) {
				metodosImprimir.filter(relatorio => relatorio.nome === nome)[0].onImprimir();
			} else {
				console.error('Método imprimir para o relatório "' + nome + '" não encontrado.');
			}
		} else {
			console.error('Os métodos não foram informados.');
		}
	}

	function renderIdentificacao(row) {
		return row.identificacao;
	}

	function renderDescricao(row) {
		return <span style={{ paddingLeft: props.isMobile ? '20%' : '0' }}>{row.descricao}</span>;
	}

	function renderOpcoes(row) {
		return (
			<div>
				<ButtonImprimirTable onClick={() => onImprimir(row.nome)} />
			</div>
		);
	}

	return (
		<Form header={header}>
			<FormActions></FormActions>
			<FormContent>
				<Grid justifyCenter>
					<InputSearch
						onPesquisar={() => pesquisar()}
						value={valorPesquisa}
						onChange={value => setValorPesquisa(value)}
						id="RelatoriosInputSearch"
					/>
				</Grid>
				<DataTable
					className="table"
					responsive
					value={registros}
					sortField={sortField}
					sortOrder={sortOrder}
					onSort={onSort}
					emptyMessage={<NenhumRegistroEncontrado />}
				>
					<Column
						field="identificacao"
						header="Identificação"
						body={row => renderIdentificacao(row)}
						sortable={true}
						style={{ width: '12em' }}
					/>
					<Column field="descricao" header="Descrição" body={row => renderDescricao(row)} sortable={true} />
					<Column body={renderOpcoes} header="Ações" style={{ width: '7em' }} />
				</DataTable>
				<Paginacao totalElements={totalElements} rows={rows} page={page} onPageChange={onPageChange} />
			</FormContent>
		</Form>
	);
}

const mapStateToProps = state => ({
	isMobile: state.dispositivo.isMobile,
});

export default connect(mapStateToProps)(ListagemRelatorios);
