import { montarLabelPessoa } from '../../../../../../components/select/SingleSelectPessoa/Util';

function converterLancamentoReceitaCategoriasParaFormulario(values){
	const categorias = [];
	if (values) {
		values.forEach(registro =>{
			const lancamentoCategoria = {
				categoria: converterCategoriaParaFormulario(registro.categoria),
				valor: registro.valor,
				percentual: registro.percentual,
			};
			categorias.push(lancamentoCategoria);
		});
	}
	return categorias;
}

export function converterLancamentoReceitaParaFormulario(values) {
	return {
		...values,
		formaPagamento: converterFormaParaFormulario(values.formaPagamento),
		conta: converterContaParaFormulario(values.conta),
		pessoa: converterPessoaParaFormulario(values.pessoa),
		categorias: converterLancamentoReceitaCategoriasParaFormulario(values.categorias),
	};
}

function converterCategoriaParaFormulario(categoriaApi) {
	if (categoriaApi) {
		return {
			label: categoriaApi.nome,
			value: categoriaApi.id,
			registro: categoriaApi,
		};
	}
	return null;
}

function converterFormaParaFormulario(formaApi) {
	if (formaApi) {
		return {
			label: formaApi.descricao,
			value: formaApi.id,
			registro: formaApi,
		};
	}
	return null;
}

function converterContaParaFormulario(contaApi) {
	if (contaApi) {
		return {
			label: contaApi.nome,
			value: contaApi.id,
			registro: contaApi,
		};
	}
	return null;
}

function converterPessoaParaFormulario(pessoaApi) {
	if (pessoaApi) {
		return {
			label: montarLabelPessoa(pessoaApi),
			value: pessoaApi.id,
			registro: pessoaApi,
		};
	}
	return null;
}

function converterLancamentoReceitaCategoriasParaApi(values){
	const categorias = [];
	if (values) {
		values.forEach(registro =>{
			const lancamentoCategoria = {
				categoria: {id: registro.categoria.value},
				valor: registro.valor,
				percentual: registro.percentual,
			};
			categorias.push(lancamentoCategoria);
		});
	}
	return categorias;
}

export function converterLancamentoReceitaParaApi(values) {
	return {
		...values,
		formaPagamento: values.formaPagamento ? { id: values.formaPagamento.value } : null,
		conta: values.conta ? { id: values.conta.value } : null,
		pessoa: values.pessoa ? { id: values.pessoa.value } : null,
		categorias: converterLancamentoReceitaCategoriasParaApi(values.categorias),		
	};
}
