import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { format, formatISO } from 'date-fns';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';

import {
	construirUrl,
	services,
	formatarParaPesquisarTiposEnumerados,
	baixarArquivo,
	salvarConfiguracaoUsuario,
	configuracoesUsuario,
	buscarConfiguracaoUsuario,
	usuarioPossuiPermissao,
	permissoes,
	recursos,
} from 'Common';
import {
	Col,
	Grid,
	PesquisaAvancada,
	DateInterval,
	InputSearch,
	DescricaoFiltroAvancado,
	Form,
	FormContent,
	FormActions,
	Button,
	Tutorial,
	tutorialStepsListagens,
	ModalInutilizacao,
	If,
	ModalLoadingTransmissao,
} from 'components';

import { useContextPesquisa } from 'views/Util/Context/ContextPesquisa';

import {
	asyncDeleteNfe,
	asyncGetResumoNotas,
	imprimirDANFE,
	imprimirXMLCorrecao,
	imprimirXMLNfe,
	imprimirCorrecao,
	asyncGetTotalizadoresCard,
	asyncGetDownloadXmls,
} from './Requests';

import CardTotalizadorListagem from './components/CardTotalizadorListagem';
import TabelaNfe from './components/TabelaNFe';

import { optionsFiltroAvancado, colorsStatus, StatusNFeCards, CADASTROURL, Status } from './Util/constantes';
import { atualizarUrl } from '../../../Util';
import { confirmarExclusao } from '../../../Util/ExclusaoDeRegistros';

import { TipoInutilizacao } from '../../../../components/ModalInutilizacao/Util/constantes';
import ModalPedidoImportacao from '../../../../components/ModalPedidoImportacao';
import { ModuloImportacao } from '../../../../components/ModalPedidoImportacao/Util/Constantes';

function NFe(props) {
	const { isMobile, isTablet, history } = props;

	const {
		valorPesquisa,
		setValorPesquisa,
		interval,
		setInterval,
		sortField,
		setSortField,
		sortOrder,
		setSortOrder,
		page,
		setPage,
		rows,
		setRows,
		size,
		setSize,
		totalRecords,
		setTotalRecords,
		filtroAvancado,
		setFiltroAvancado,
		descricaoFiltroAvancado,
		setDescricaoFiltroAvancado,
		selectedCard,
		setSelectedCard,
	} = useContextPesquisa();

	const [cards, setCards] = useState({
		notasNaoEnviadas: {
			valor: 0,
			quantidade: 0,
		},
		notasRejeitadas: {
			valor: 0,
			quantidade: 0,
		},
		notasCanceladas: {
			valor: 0,
			quantidade: 0,
		},
		notasAutorizadas: {
			valor: 0,
			quantidade: 0,
		},
	});
	const [registros, setRegistros] = useState([]);
	const [valorCard, setValorCard] = useState('');
	const [optsFiltroAvancado] = useState(optionsFiltroAvancado);
	const [podeInserir] = useState(usuarioPossuiPermissao(recursos.VENDAS_NOTAS, permissoes.INSERIR));
	const [tutorialVisible, setTutorialVisible] = useState(false);
	const [deveExibirTutorial, setDeveExibirTutorial] = useState(
		buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS)
	);
	const [filtroData, setFiltroData] = useState(
		`emissao>=${formatISO(interval.dataInicial, {
			representation: 'date',
		})};emissao<=${formatISO(interval.dataFinal, {
			representation: 'date',
		})}`
	);
	const [limparFiltroPesquisaAvancada, setLimparFiltroPesquisaAvancada] = useState(false);
	const valorTiposEnumerados = formatarParaPesquisarTiposEnumerados(valorPesquisa);
	const [visibleModalPedidoImportacao, setVisibleModalPedidoImportacao] = useState(false);
	const [modalInutilizacao, setModalInutilizacao] = useState(false);
	const [mostrarLoading, setMostrarLoading] = useState(false);
	const [firstRender, setFirstRender] = useState(true);

	const pesquisarCallback = useCallback(() => {
		if (!firstRender) {
			pesquisar();
		}
	});

	useEffect(() => {
		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			setDeveExibirTutorial(false);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS, false, null, false);
		}
		pesquisar();
		setTimeout(() => {
			document.getElementById('NfeInputSearch')?.focus();
		}, 500);
	}, []);

	useEffect(() => {
		pesquisarCallback();
	}, [page, rows, sortOrder, sortField, filtroAvancado, interval, selectedCard]);

	function onPesquisarFiltroAvancado(e) {
		setFiltroAvancado(e);
		setLimparFiltroPesquisaAvancada(false);
	}

	function buscarFiltro() {
		let filtroRSQL = String('?query=(')
			.concat(`nro=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
			.concat(`serie=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
			.concat(`destNome=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
			.concat(`vendaNumero=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
			.concat(`status=contains="*${valorTiposEnumerados.replaceAll('&', '%26')}*")`)
			.concat(`;(${filtroData})`);

		if (filtroAvancado) {
			filtroRSQL = filtroRSQL.concat(`;(${filtroAvancado})`);
		}
		if (valorCard) {
			filtroRSQL = filtroRSQL.concat(`;(${valorCard})`);
		}

		return filtroRSQL;
	}

	async function pesquisar() {
		if (interval.dataInicial != null && interval.dataFinal != null) {
			const filtro = buscarFiltro();
			let campoOrdenacao = sortField;
			let sentidoOrdenacao = sortOrder;
			if (firstRender) {
				setSortField('emissao');
				setSortOrder(-1);
				campoOrdenacao = 'emissao';
				sentidoOrdenacao = -1;
			}
			const url = construirUrl(
				`${services.GESTOR}/v1/nfes/resumo`,
				filtro || '?',
				size,
				page,
				sentidoOrdenacao > 0 ? `${campoOrdenacao},asc` : `${campoOrdenacao},desc`
			);

			asyncGetTotalizadoresCard(interval, ({ data: totais }) => {
				const newCards = {};

				totais &&
					totais.forEach((element) => {
						if (element.status === StatusNFeCards.CANCELADA) {
							newCards.notasCanceladas = {
								quantidade: element.quantidade,
								valor: element.valor,
							};
						} else if (element.status === StatusNFeCards.AUTORIZADA) {
							newCards.notasAutorizadas = {
								quantidade: element.quantidade,
								valor: element.valor,
							};
						} else if (element.status === StatusNFeCards.REJEITADA) {
							newCards.notasRejeitadas = {
								quantidade: element.quantidade,
								valor: element.valor,
							};
						} else if (element.status === StatusNFeCards.NAO_ENVIADA) {
							newCards.notasNaoEnviadas = {
								quantidade: element.quantidade,
								valor: element.valor,
							};
						}
					});
				setCards(newCards);
			});

			asyncGetResumoNotas(url, ({ data: notas }) => {
				setRegistros(notas.content);
				setTotalRecords(notas.page.totalElements);
				setFirstRender(false);
			});
		}
	}

	function handleChangeInterval(interval) {
		setInterval(interval);
		setFiltroData(
			`emissao>=${formatISO(interval.dataInicial, {
				representation: 'date',
			})};emissao<=${formatISO(interval.dataFinal, {
				representation: 'date',
			})}`
		);
	}

	function onPesquisar() {
		pesquisar();
	}

	function handleSelectCardNotasNaoEnviadas() {
		const card = Status.NAO_ENVIADA;
		const filtroRSQL = 'status==NAO_ENVIADA';
		pesquisarRSQLCardTotais(card, filtroRSQL);
	}

	function handleSelectCardNotasRejeitadas() {
		const card = Status.REJEITADA;
		const filtroRSQL = 'status==REJEITADA';
		pesquisarRSQLCardTotais(card, filtroRSQL);
	}

	function handleSelectCardNotasAutorizadas() {
		const card = Status.TRANSMITIDA;
		const filtroRSQL = 'status==TRANSMITIDA,status==CORRIGIDA';
		pesquisarRSQLCardTotais(card, filtroRSQL);
	}

	function handleSelectCardNotasCancelada() {
		const card = Status.CANCELADA;
		const filtroRSQL = 'status==CANCELADA,status==DENEGADA';
		pesquisarRSQLCardTotais(card, filtroRSQL);
	}

	function pesquisarRSQLCardTotais(cardSelected, valueCard) {
		if (selectedCard === cardSelected) {
			setSelectedCard('');
			setValorCard('');
		} else {
			setSelectedCard(cardSelected);
			setValorCard(valueCard);
		}
	}

	function editarNotaFiscal(item) {
		atualizarUrl(history, `${CADASTROURL}/${item.id}`);
	}

	function removerNotaFiscal(item) {
		confirmarExclusao(async () => {
			await asyncDeleteNfe(item.id, 'Registro excluído com sucesso', () => pesquisar());
		});
	}

	function baixarArquivosXml() {
		setMostrarLoading(true);
		asyncGetDownloadXmls(
			buscarFiltro(),
			({ data }) => {
				baixarArquivo(
					data,
					`ArquivosXmlNFe_${format(interval.dataInicial, 'dd-MM-yyyy')}_${format(interval.dataFinal, 'dd-MM-yyyy')}.zip`
				);
				setMostrarLoading(false);
			},
			() => {
				setMostrarLoading(false);
			}
		);
	}

	function imprimirPDFDANFE(item, download = false) {
		imprimirDANFE(item.id, ({ data: file }) => {
			if (download) {
				baixarArquivo(file, `${item.chave}.pdf`);
			} else {
				const arquivo = new Blob([file], { type: 'application/pdf' });
				const arquivoURL = URL.createObjectURL(arquivo);
				const danfe = window.open(arquivoURL);
				danfe.onload = () => {
					setTimeout(() => {
						danfe.document.title = `NF-e Nº ${item.nro}.pdf`;
					}, 250);
				};
			}
		});
	}

	function baixarXMLCorrecao(item) {
		imprimirXMLCorrecao(item.id, ({ data: file }) => {
			baixarArquivo(file, `${item.chave}-correção`, 'application/xml');
		});
	}

	function imprimirCartaCorrecao(item) {
		imprimirCorrecao(item.id, ({ data: file }) => {
			const arquivo = new Blob([file], { type: 'application/pdf' });
			const arquivoURL = URL.createObjectURL(arquivo);
			const danfe = window.open(arquivoURL);
			danfe.onload = () => {
				setTimeout(() => {
					danfe.document.title = `${item.chave}-correção.pdf`;
				}, 250);
			};
		});
	}

	function baixarXMLNotaFiscal(item) {
		imprimirXMLNfe(item.id, ({ data: file }) => {
			baixarArquivo(file, item.chave, 'application/xml');
		});
	}

	function montarHeaderListagemNotas() {
		return (
			<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				<span>{isMobile ? 'NF-e' : 'Notas fiscais (NF-e)'}</span>
			</div>
		);
	}

	return (
		<>
			<ModalLoadingTransmissao visible={mostrarLoading} message="Gerando arquivo com os XMLs..." />
			<Tutorial
				steps={tutorialStepsListagens}
				showSkipButton
				continuous
				disableScrolling
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>
			<Form header={montarHeaderListagemNotas()}>
				<FormActions>
					<Link to={podeInserir ? { pathname: '/nfes/cadastro' } : { pathname: '/nfes' }}>
						<Button
							className="step-listagem-novo p-button-primary"
							label="Nova NF-e"
							icon="fa fa-plus"
							title="Inserir uma nova NF-e"
							onClick={null}
							podeInserir={podeInserir}
							disabled={!podeInserir}
							style={{ margin: '5px', width: isMobile ? '95%' : 'auto' }}
						/>
					</Link>
					<Button
						className="step-listagem-novo"
						label={isMobile || isTablet ? 'Importar pedido' : 'Importar pedido de venda'}
						color="success"
						icon="pi pi-download"
						disabled={!podeInserir}
						onClick={() => setVisibleModalPedidoImportacao(true)}
					/>
					<Button
						label="Inutilização de NF-e"
						color="danger"
						icon={<Icon icon="akar-icons:circle-minus-fill" style={{ marginRight: '0.5rem' }} />}
						title="Inutilizar uma NF-e"
						podeInserir={podeInserir}
						disabled={!podeInserir}
						style={{ margin: '5px' }}
						onClick={() => setModalInutilizacao(true)}
					/>
					<Button
						label="Download XMLs"
						className="step-listagem-novo p-button-primary"
						icon="fa fa-file-archive-o"
						title="Download dos arquivos XMLs das NF-es filtradas"
						style={{ margin: '5px' }}
						onClick={() => baixarArquivosXml()}
					/>{' '}
				</FormActions>
				<FormContent>
					<Grid justifyCenter verticalAlignCenter>
						<span style={{ padding: '12px' }}>
							<DateInterval onChange={handleChangeInterval} interval={interval} />
						</span>
						<InputSearch
							onPesquisar={onPesquisar}
							className="step-listagem-input-search"
							value={valorPesquisa}
							onChange={(value) => setValorPesquisa(value)}
							removerEComercial={false}
							id="NfeInputSearch"
						/>
						<span style={{ padding: '12px' }} className="step-listagem-filtro-avancado">
							<PesquisaAvancada
								optionsFiltros={optsFiltroAvancado}
								onPesquisarClick={onPesquisarFiltroAvancado}
								onChangeFiltroRsql={(rsql) => setFiltroAvancado(rsql)}
								onChangeDescricaoFiltro={(e) => setDescricaoFiltroAvancado(e)}
								limparFiltro={limparFiltroPesquisaAvancada}
							/>
						</span>
					</Grid>
					<Grid justifyBetween>
						<Col xs="12" sm="6" md="6" lg="3" xl="3">
							<CardTotalizadorListagem
								name="cardNotasNaoEnviadas"
								title="Não enviadas"
								helpMessage={
									isMobile ? '' : 'Exibe o valor total e o número de notas fiscais ainda não enviadas para o sefaz'
								}
								titleFiltro="Clique para filtrar pelas notas fiscais não enviadas"
								colors={colorsStatus.NAO_ENVIADA}
								value={cards.notasNaoEnviadas.valor}
								selectable
								numberOfElements={cards.notasNaoEnviadas.quantidade}
								selected={selectedCard === Status.NAO_ENVIADA}
								onSelect={handleSelectCardNotasNaoEnviadas}
							/>
						</Col>
						<Col xs="12" sm="6" md="6" lg="3" xl="3">
							<CardTotalizadorListagem
								name="cardNotasRejeitadas"
								title="Rejeitadas"
								helpMessage={
									isMobile
										? ''
										: 'Exibe o valor total e o número de notas fiscais que tiveram tentativa de transmissão mas foram rejeitadas pelo sefaz'
								}
								titleFiltro="Clique para filtrar pelas notas fiscais rejeitadas"
								colors={colorsStatus.REJEITADA}
								value={cards.notasRejeitadas.valor}
								selectable
								numberOfElements={cards.notasRejeitadas.quantidade}
								selected={selectedCard === Status.REJEITADA}
								onSelect={handleSelectCardNotasRejeitadas}
							/>
						</Col>
						<Col xs="12" sm="6" md="6" lg="3" xl="3">
							<CardTotalizadorListagem
								name="cardNotasCanceladas"
								title="Canceladas"
								helpMessage={isMobile ? '' : 'Exibe o valor total e o número de notas fiscais canceladas ou denegadas'}
								titleFiltro="Clique para filtrar pelas notas fiscais canceladas/denegadas"
								colors={colorsStatus.CANCELADA}
								value={cards.notasCanceladas.valor}
								selectable
								numberOfElements={cards.notasCanceladas.quantidade}
								selected={selectedCard === Status.CANCELADA}
								onSelect={handleSelectCardNotasCancelada}
							/>
						</Col>
						<Col xs="12" sm="6" md="6" lg="3" xl="3">
							<CardTotalizadorListagem
								name="cardNotasAutorizadas"
								title="Transmitidas"
								helpMessage={isMobile ? '' : 'Exibe o valor total e o número de notas fiscais transmitidas com sucesso'}
								titleFiltro="Clique para filtrar pelas notas fiscais transmitidas"
								colors={colorsStatus.AUTORIZADA}
								value={cards.notasAutorizadas.valor}
								selectable
								numberOfElements={cards.notasAutorizadas.quantidade}
								selected={selectedCard === Status.TRANSMITIDA}
								onSelect={handleSelectCardNotasAutorizadas}
							/>
						</Col>
					</Grid>
					<Grid style={{ paddingTop: '10px' }} justifyCenter verticalAlignCenter>
						<DescricaoFiltroAvancado texto={descricaoFiltroAvancado} />
						<TabelaNfe
							registros={registros}
							totalRecords={totalRecords}
							rows={size}
							page={page}
							sortField={sortField}
							sortOrder={sortOrder}
							setSortField={(sortField) => setSortField(sortField)}
							setSortOrder={(sortOrder) => {
								setSortOrder(sortOrder);
							}}
							editarNotaFiscal={editarNotaFiscal}
							removerNotaFiscal={removerNotaFiscal}
							onPageChange={(e) => {
								setSize(e.rows);
								setRows(e.rows);
								setPage(e.page);
							}}
							imprimirDANFE={imprimirPDFDANFE}
							imprimirCorrecao={imprimirCartaCorrecao}
							baixarXMLNotaFiscal={baixarXMLNotaFiscal}
							baixarXMLCorrecao={baixarXMLCorrecao}
						/>
					</Grid>
				</FormContent>
			</Form>
			<If test={visibleModalPedidoImportacao}>
				<ModalPedidoImportacao
					visible={visibleModalPedidoImportacao}
					onHide={() => setVisibleModalPedidoImportacao(false)}
					isMobile={isMobile}
					isTablet={isTablet}
					history={props.history}
					moduloImportacao={ModuloImportacao.NFE}
				/>
			</If>
			<If test={modalInutilizacao}>
				<ModalInutilizacao
					{...props}
					header="Inutilização de NF-e"
					visible={modalInutilizacao}
					onHide={() => setModalInutilizacao(false)}
					tipoInutilizacao={TipoInutilizacao.NFE}
					podeInserir={podeInserir}
				/>
			</If>
		</>
	);
}

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
	isTablet: state.dispositivo.isTablet,
});

export default connect(mapStateToProps)(NFe);
