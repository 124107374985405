import { format } from 'date-fns';

function converterOrdemServicoParaApi(value) {
	return {
		id: value.id ?? null,
		numero: value.numero,
		situacao: value.situacao,
		cliente: converterObjectParaApi(value.cliente),
		dataAbertura: value.dataAbertura,
		horaAbertura: value.horaAbertura === null ? converterHoraParaApi(format(new Date(), 'HH:mm')) : converterHoraParaApi(value.horaAbertura),
		dataFechamento: value.dataFechamento,
		horaFechamento: value.dataFechamento !== null && value.horaFechamento === null ? converterHoraParaApi(format(new Date(), 'HH:mm')) : converterHoraParaApi(value.horaFechamento),
		objetoServico: converterObjectParaApi(value.objetoServico),
		operacaoFiscal: converterObjectParaApi(value.operacaoFiscal),
		quilometragemAtual: value.quilometragemAtual,
		problemaRelatado: value.problemaRelatado ?? null,
		servicos: converterServicosParaApi(value.servicos),
		produtos: converterProdutosParaApi(value.produtos),
		totalServico: converterTotalServicoParaApi(value.totalServicos),
		totalProduto: converterTotalProdutoParaApi(value.totalProdutos),
		comissaoTotalPercentual: value.comissaoTotalPercentual,
		comissaoTotalValor: value.comissaoTotalValor,
		valorTotal: value.valorTotal,
		pagamentos: converterPagamentoParaApi(value.pagamentos),
		informacaoComplementar: converterInformacaoComplementarParaApi(value.informacoesComplementares),
		tecnico: converterObjectParaApi(value.informacoesComplementares.tecnico),
		motivoCancelamento: value.motivoCancelamento,
	};
}

function converterServicosParaApi(values) {
	const servicos = [];

	if (values.length > 0) {
		values.forEach((value, index) => {
			if (value.servico) {
				servicos.push({
					id: value.id ?? null,
					item: value.item ?? index + 1,
					servico: converterObjectParaApi(value.servico),
					descricao: value.descricao,
					quantidade: value.quantidade ?? 0,
					valor: value.valor ?? 0,
					valorOriginal: value.valorOriginal ?? 0,
					subtotal: value.subtotal ?? 0,
					operacaoFiscal: converterObjectParaApi(value.operacaoFiscal),
					desconto: value.desconto ?? 0,
					unidadeMedida: converterObjectParaApi(value.unidadeMedida),
					codigoServico: converterObjectParaApi(value.codigoServico),
					cfop: converterObjectParaApi(value.cfop),
					impostoServico: converterImpostoServicoParaApi(value),
					utilizouTabelaPromocional: value.utilizouTabelaPromocional,
					comissaoPercentual: value.comissaoPercentual ?? 0,
					comissaoValor: value.comissaoValor ?? 0,
					descontoMaximoPercentual: value.descontoMaximoPercentual ?? 0,
					descontoMaximoVendedor: value.descontoMaximoVendedor ?? 0,
					descontoMaximoTabela: value.descontoMaximoTabela ?? 0,
					percentualAdicionalTabelaPreco: value.adicionalTabelaPreco?.percentual,
					tipoPercentualAdicionalTabelaPreco: value.adicionalTabelaPreco?.tipo,
				});
			}
		});
	}

	return servicos;
}

function converterImpostoServicoParaApi(value) {
	const impostoServico = {
		id: value?.impostoServico?.id ?? null,

		cstIss: converterObjectParaApi(value.tributos?.iss?.cst),
		percentualBaseCalculoIss: value.tributos?.iss.percentualBase ?? 0,
		valorBaseCalculoIss: value.tributos?.iss.valorBase ?? 0,
		aliquotaIss: value.tributos?.iss.percentual ?? 0,
		valorIss: value.tributos?.iss.valor ?? 0,

		percentualBaseCalculoIr: value.tributos?.ir.percentualBase ?? 0,
		valorBaseCalculoIr: value.tributos?.ir.valorBase ?? 0,
		aliquotaIr: value.tributos?.ir.percentual ?? 0,
		valorIr: value.tributos?.ir.valor ?? 0,

		percentualBaseCalculoCsll: value.tributos?.csll.percentualBase ?? 0,
		valorBaseCalculoCsll: value.tributos?.csll.valorBase ?? 0,
		aliquotaCsll: value.tributos?.csll.percentual ?? 0,
		valorCsll: value.tributos?.csll.valor ?? 0,

		percentualBaseCalculoInss: value.tributos?.inss.percentualBase ?? 0,
		valorBaseCalculoInss: value.tributos?.inss.valorBase ?? 0,
		aliquotaInss: value.tributos?.inss.percentual ?? 0,
		valorInss: value.tributos?.inss.valor ?? 0,

		cstPis: converterObjectParaApi(value.tributos?.pis.cst),
		percentualBaseCalculoPis: value.tributos?.pis.percentualBase ?? 0,
		valorBaseCalculoPis: value.tributos?.pis.valorBase ?? 0,
		aliquotaPis: value.tributos?.pis.percentual ?? 0,
		valorPis: value.tributos?.pis.valor ?? 0,

		cstCofins: converterObjectParaApi(value.tributos?.cofins.cst),
		percentualBaseCalculoCofins: value.tributos?.cofins.percentualBase ?? 0,
		valorBaseCalculoCofins: value.tributos?.cofins.valorBase ?? 0,
		aliquotaCofins: value.tributos?.cofins.percentual ?? 0,
		valorCofins: value.tributos?.cofins.valor ?? 0,
	};

	return impostoServico;
}

function converterProdutosParaApi(values) {
	const produtos = [];

	if (values.length > 0) {
		values.forEach((value, index) => {
			if (value.produto) {
				produtos.push({
					id: value.id ?? null,
					item: value.item ?? index + 1,
					produto: converterObjectParaApi(value.produto),
					descricao: value.descricao,
					quantidade: value.quantidade ?? 0,
					valor: value.valor ?? 0,
					valorOriginal: value.valorOriginal ?? 0,
					subtotal: value.subtotal ?? 0,
					desconto: value.desconto ?? 0,
					acessorias: value.acessorias ?? 0,
					frete: value.frete ?? 0,
					seguro: value.seguro ?? 0,
					codigoBarras: value.codigoBarras ?? null,
					codigoBeneficiario: value.codigoBeneficiario ?? null,
					numeroPedidoCompra: value.numeroPedidoCompra ?? null,
					numeroItemPedidoCompra: value.itemPedidoCompra ?? null,
					operacaoFiscal: converterObjectParaApi(value.operacaoFiscal),
					unidadeMedida: converterObjectParaApi(value.unidadeMedida),
					ncm: converterObjectParaApi(value.ncm),
					cest: converterObjectParaApi(value.cest),
					cfop: converterObjectParaApi(value.cfop),
					icms: converterIcmsParaApi(value.tributos?.icms),
					ipi: converterIpiParaApi(value.tributos?.ipi),
					pis: converterPisParaApi(value.tributos?.pis),
					cofins: converterCofinsParaApi(value.tributos?.cofins),
					utilizouTabelaPromocional: value.utilizouTabelaPromocional,
					comissaoPercentual: value.comissaoPercentual ?? 0,
					comissaoValor: value.comissaoValor ?? 0,
					descontoMaximoPercentual: value.descontoMaximoPercentual ?? 0,
					descontoMaximoVendedor: value.descontoMaximoVendedor ?? 0,
					descontoMaximoTabela: value.descontoMaximoTabela ?? 0,
					percentualAdicionalTabelaPreco: value.adicionalTabelaPreco?.percentual,
					tipoPercentualAdicionalTabelaPreco: value.adicionalTabelaPreco?.tipo,
				});
			}
		});
	}

	return produtos;
}

function converterIcmsParaApi(value) {
	return {
		id: value.id ?? null,
		cstCsosn: converterObjectParaApi(value.cstCsosn),
		origem: value.origemProduto,
		modalidadeCalculo: value.modalidadeCalculo ?? 'VALOR_OPERACAO',
		percentualBaseCalculo: value.percentualBaseIcms ?? 0,
		percentualReducaoBaseCalculo: value.percentualReducaoBaseIcms ?? 0,
		valorBaseCalculo: value.baseIcms ?? 0,
		aliquota: value.percentualIcms ?? 0,
		valor: value.valorIcms ?? 0,
		modalidadeCalculoSt: value.modalidadeCalculoSt ?? null,
		percentualBaseCalculoSt: value.percentualBaseIcmsSt ?? 0,
		percentualReducaoBaseCalculoSt: value.percentualReducaoBaseIcmsSt ?? 0,
		valorBaseCalculoSt: value.baseIcmsSt ?? 0,
		aliquotaSt: value.percentualIcmsSt ?? 0,
		valorSt: value.valorIcmsSt ?? 0,
		percentualMva: value.percentualMva ?? 0,
		valorPauta: value.valorPauta ?? 0,
		percentualBaseCalculoStRetido: value.percentualBaseIcmsStRetido ?? 0,
		valorBaseCalculoStRetido: value.baseRetidoSt ?? 0,
		aliquotaStRetido: value.percentualIcmsStRetido ?? 0,
		valorStRetido: value.valorRetidoSt ?? 0,
		percentualDiferido: value.percentualDiferimento ?? 0,
		valorDiferido: value.valorDiferimento ?? 0,
		valorBaseCalculoFcPobreza: value.baseFundoCombatePobreza ?? 0,
		percentualFcPobreza: value.percentualFundoCombatePobreza ?? 0,
		valorFcPobreza: value.valorFundoCombatePobreza ?? 0,
		aliquotaCreditoSimplesNacional: value.percentualCreditoSimplesNacional ?? 0,
		valorCreditoSimplesNacional: value.valorCreditoSimplesNacional ?? 0,
		valorSubstituto: value.valorIcmsSubstituto ?? 0,
		quantidadeBaseMonofasicoRetido: value.quantidadeBaseIcmsMonofasicoRetido ?? 0,
		percentualMonofasicoRetido: value.percentualIcmsMonofasicoRetido ?? 0,
		valorMonofasicoRetido: value.valorIcmsMonofasicoRetido ?? 0,
	};
}

function converterIpiParaApi(value) {
	return {
		id: value.id ?? null,
		cst: converterObjectParaApi(value.cst),
		enquadramento: converterObjectParaApi(value.enquadramentoIpi),
		percentualBaseCalculo: value.percentualBaseIpi ?? 0,
		valorBaseCalculo: value.baseIpi ?? 0,
		aliquota: value.percentualIpi ?? 0,
		valor: value.valorIpi ?? 0,
	};
}

function converterPisParaApi(value) {
	return {
		id: value.id ?? null,
		cst: converterObjectParaApi(value.cst),
		percentualBaseCalculo: value.percentualBasePis ?? 0,
		valorBaseCalculo: value.basePis ?? 0,
		aliquota: value.percentualPis ?? 0,
		valor: value.valorPis ?? 0,
		percentualBaseCalculoSt: value.percentualBasePisSt ?? 0,
		valorBaseCalculoSt: value.basePisSt ?? 0,
		aliquotaSt: value.percentualPisSt ?? 0,
		valorSt: value.valorPisSt ?? 0,
	};
}

function converterCofinsParaApi(value) {
	return {
		id: value.id ?? null,
		cst: converterObjectParaApi(value.cst),
		percentualBaseCalculo: value.percentualBaseCOfins ?? 0,
		valorBaseCalculo: value.baseCofins ?? 0,
		aliquota: value.percentualCofins ?? 0,
		valor: value.valorCofins ?? 0,
		percentualBaseCalculoSt: value.percentualBaseCofinsSt ?? 0,
		valorBaseCalculoSt: value.baseCofinsSt ?? 0,
		aliquotaSt: value.percentualCofinsSt ?? 0,
		valorSt: value.valorCofinsSt ?? 0,
	};
}

function converterTotalServicoParaApi(value) {
	return {
		id: value.id ?? null,
		valorBruto: value.valorBruto ?? 0,
		tipoDesconto: value.tipoDesconto ?? 'VALOR',
		desconto: value.desconto ?? 0,
		valorLiquido: value.valorLiquido ?? 0,
		baseIss: value.baseIss ?? 0,
		valorIss: value.valorIss ?? 0,
		baseIr: value.baseIr ?? 0,
		valorIr: value.valorIr ?? 0,
		baseCsll: value.baseCsll ?? 0,
		valorCsll: value.valorCsll ?? 0,
		baseInss: value.baseInss ?? 0,
		valorInss: value.valorInss ?? 0,
		basePis: value.basePis ?? 0,
		valorPis: value.valorPis ?? 0,
		baseCofins: value.baseCofins ?? 0,
		valorCofins: value.valorCofins ?? 0,
	};
}

function converterTotalProdutoParaApi(value) {
	return {
		id: value.id ?? null,
		valorBruto: value.valorBruto ?? 0,
		frete: value.frete ?? 0,
		seguro: value.seguro ?? 0,
		acessorias: value.acessorias ?? 0,
		tipoDesconto: value.tipoDesconto ?? 'VALOR',
		desconto: value.desconto ?? 0,
		valorLiquido: value.valorLiquido ?? 0,
		baseIcms: value.baseIcms ?? 0,
		valorIcms: value.valorIcms ?? 0,
		baseSt: value.baseSt ?? 0,
		valorSt: value.valorSt ?? 0,
		baseIpi: value.baseIpi ?? 0,
		valorIpi: value.valorIpi ?? 0,
		basePis: value.basePis ?? 0,
		valorPis: value.valorPis ?? 0,
		baseCofins: value.baseCofins ?? 0,
		valorCofins: value.valorCofins ?? 0,
	};
}

function converterPagamentoParaApi(pagamentos) {
	const pagamentosToApi = [];

	if (pagamentos.length > 0) {
		pagamentos.forEach((pagamento) => {
			pagamentosToApi.push({
				id: pagamento.id,
				sequencial: pagamento.sequencial,
				condicaoPagamento: converterObjectParaApi(pagamento.condicaoPagamento),
				conta: converterObjectParaApi(pagamento.conta),
				formaPagamento: converterObjectParaApi(pagamento.formaPagamento),
				categorias: converterPagamentoCategoriasParaApi(pagamento.categorias),
				quantidadeParcelas: pagamento.quantidadeParcelas?.value ?? 0,
				valor: pagamento.valor,
				parcelas: converterParcelasParaApi(pagamento.parcelas),
			});
		});
	}

	return pagamentosToApi;
}

function converterPagamentoCategoriasParaApi(values){
	const categorias = [];
	if (values) {
		values.forEach(registro =>{
			const ordemCategoria = {
				categoria: {id: registro.categoria.value},
				valor: registro.valor,
				percentual: registro.percentual,
			};
			categorias.push(ordemCategoria);
		});
	}
	return categorias;
}

function converterParcelasParaApi(parcelas) {
	const parcelasToApi = [];

	if (parcelas.length > 0) {
		parcelas.forEach((parcela) => {
			parcelasToApi.push({
				id: parcela.id,
				sequencial: parcela.sequencial,
				vencimento: parcela.vencimento,
				formaPagamento: converterObjectParaApi(parcela.formaPagamento),
				conta: converterObjectParaApi(parcela.conta),
				descricao: parcela.descricao,
				valor: parcela.valor,
			});
		});
	}

	return parcelasToApi;
}

function converterInformacaoComplementarParaApi(value) {
	return {
		id: value.id ?? null,
		setor: converterObjectParaApi(value.setor),
		vendedor: converterObjectParaApi(value.vendedor),
		tabelaPreco: converterObjectParaApi(value.tabelaPreco),

		servicoInformacaoComplementar: value.servico.informacoesComplementares,
		servicoObservacaoInterna: value.servico.observacaoInterna,
		municipio: converterObjectParaApi(value.servico.municipio),

		produtoInformacaoComplementar: value.produto.informacoesComplementares,
		produtoObservacaoInterna: value.produto.observacaoInterna,
	};
}

function converterObjectParaApi(value) {
	return value
		? {
				id: value.value ?? value.id,
			}
		: null;
}

function converterHoraParaApi(value) {
	if (value?.length <= 5) {
		return value.concat(':00');
	}
	return value;
}

function converterRecebimentoParaApi(values) {
	return {
		...values,
		juros: values.juros ? values.juros : 0,
		multa: values.multa ? values.multa : 0,
		desconto: values.desconto ? values.desconto : 0,
		conta: values.conta ? { id: values.conta.value } : null,
	};
}

export { converterOrdemServicoParaApi, converterRecebimentoParaApi };
