import { get, del, exibirToast, put, post } from '../../../../Common/Requisicoes';
import { services } from '../../../../Common/Constantes/api';
import { formatISO, format } from 'date-fns';

export async function asyncGetContasReceber(url, onSuccess, onError) {
	return await get(url, null, onSuccess, onError, true);
}

export async function asyncGetContaReceber(idContaReceber, onSuccess, onError, exibirLoading = true) {
	return await get(`${services.GESTOR}/v1/contas_receber/${idContaReceber}`, null, onSuccess, onError, exibirLoading);
}

export async function asyncCreateContaReceber(data, onSuccess, onError) {
	await post(
		`${services.GESTOR}/v1/contas_receber`,
		data,
		null,
		exibirToast(onSuccess, 'Conta a receber criada com sucesso'),
		onError
	);
}

export async function asyncUpdateContaReceber(contaReceber, onSuccess, onError) {
	await put(
		`${services.GESTOR}/v1/contas_receber/${contaReceber.id}`,
		contaReceber,
		null,
		exibirToast(onSuccess, 'Conta a receber atualizada com sucesso'),
		onError
	);
}

export async function asyncDeleteContaReceber(contaReceberId, onSuccess, onError) {
	await del(
		`${services.GESTOR}/v1/contas_receber/${contaReceberId}`,
		null,
		exibirToast(onSuccess, 'Conta a receber removida com sucesso'),
		onError
	);
}

export async function asyncEditarContaReceberProximasEmAberto(contaReceber, onSuccess, onError) {
	await put(
		`${services.GESTOR}/v1/contas_receber/${contaReceber.id}/proximas_repeticoes_em_aberto`,
		contaReceber,
		null,
		exibirToast(onSuccess, 'Contas a receber alteradas com sucesso'),
		onError
	);
}

export async function asyncEditarContaReceberTodasEmAberto(contaReceber, onSuccess, onError) {
	await put(
		`${services.GESTOR}/v1/contas_receber/${contaReceber.id}/todas_repeticoes_em_aberto`,
		contaReceber,
		null,
		exibirToast(onSuccess, 'Contas a receber alteradas com sucesso'),
		onError
	);
}

export async function asyncDeleteContaReceberProximasEmAberto(idContaReceber, onSuccess, onError) {
	await del(
		`${services.GESTOR}/v1/contas_receber/${idContaReceber}/proximas_repeticoes_em_aberto`,
		null,
		exibirToast(onSuccess, 'Contas a receber removidas com sucesso'),
		onError
	);
}

export async function asyncDeleteContaReceberTodasEmAberto(idContaReceber, onSuccess, onError) {
	await del(
		`${services.GESTOR}/v1/contas_receber/${idContaReceber}/todas_repeticoes_em_aberto`,
		null,
		exibirToast(onSuccess, 'Contas a receber removidas com sucesso'),
		onError
	);
}

export async function asyncGetCategoriasReceita(onSuccess, onError) {
	return await get(
		`${services.GESTOR}/v1/contas_receber/relacoes/categorias/receitas?size=500`,
		null,
		onSuccess,
		onError,
		true
	);
}

export async function buscarCategoriaFavoritaReceita(onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/contas_receber/relacoes/categorias/receitas?query=favoritaReceita=="true";ativa=="true"`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function asyncGetContas(onSuccess, onError) {
	return await get(`${services.GESTOR}/v1/contas_receber/relacoes/contas?size=500`, null, onSuccess, onError, true);
}

export async function asyncGetTotalizadoresCards(filtro, onSuccess, onError) {
	return await get(
		`${services.GESTOR}/v1/contas_receber/totais?dataInicial=${formatISO(filtro.dataInicial, {
			representation: 'date',
		})}&dataFinal=${formatISO(filtro.dataFinal, { representation: 'date' })}`,
		null,
		onSuccess,
		onError,
		true
	);
}

export async function asyncEfetuarRecebimento(id, onSuccess, onError) {
	return await put(
		`${services.GESTOR}/v1/contas_receber/${id}/receber`,
		null,
		onSuccess,
		exibirToast(onSuccess, 'Recebimento efetuado com sucesso'),
		onError
	);
}

export async function buscarContaFavoritaReceita(onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/contas_receber/relacoes/contas?query=favoritaReceita=="true";situacao=="ATIVA"`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function buscarFormaPagamentoDinheiro(onSuccess, onError) {
	await get(
		`${services.GESTOR}/v1/contas_receber/relacoes/formas_pagamento?query=formaPagamentoFiscal=="DINHEIRO"`,
		null,
		onSuccess,
		onError,
		false
	);
}

export async function asyncBaixarRelatorioContasAReceberPorPeriodo(
	dataInicial,
	dataFinal,
	contaSituacao,
	onSuccess,
	onError
) {
	const dataInicialFormatada = format(dataInicial, 'yyyy-MM-dd');
	const dataFinalFormatada = format(dataFinal, 'yyyy-MM-dd');
	return await get(
		`${services.GESTOR}/v1/relatorios/financas/contas_a_receber_por_periodo/imprimir?dataInicial=${dataInicialFormatada}&dataFinal=${dataFinalFormatada}&contaSituacao=${contaSituacao}`,
		{ responseType: 'arraybuffer' },
		onSuccess,
		onError
	);
}

export async function buscarContaReceberPeloNumeroDocumento(numeroDocumento, onSuccess) {
	await get(`${services.GESTOR}/v1/contas_receber?query=numeroDocumento=='${numeroDocumento}'`, null, (data) =>
		onSuccess(data)
	);
}

export async function asyncGetImprimirRecibo(idRecebimento, onSuccess, onError) {
	return get(
		`${services.GESTOR}/v1/contas_receber/recibos/${idRecebimento}/pdf`,
		{ responseType: 'arraybuffer' },
		onSuccess,
		onError
	);
}

export async function asyncGetImprimirPromissoria(listaContaReceber, onSuccess, onError) {
	return get(
		`${services.GESTOR}/v1/contas_receber/promissorias/pdf?listaContaReceber=${listaContaReceber}`,
		{ responseType: 'arraybuffer' },
		onSuccess,
		onError
	);
}

export async function asyncGetProximoIdentificador(onSuccess, onError) {
	return get(`${services.GESTOR}/v1/contas_receber/proximo_identificador`, null, onSuccess, onError);
}

export async function asyncGerarCobrancas(contaId, contaReceberIds, onSuccess, onError) {
	return await post(
		`${services.GESTOR}/v1/pix/cobranca/contas/${contaId}/gerar`,
		contaReceberIds,
		{ responseType: 'arraybuffer' },
		onSuccess,
		onError,
		false
	);
}

export async function asyncGerarReparcelamentos(titulosAntigos, novosTitulos, valores, onSuccess, onError) {
	return await post(
		`${services.GESTOR}/v1/contas_receber/reparcelar_titulos`,
		{ titulosAntigos, novosTitulos, ...valores },
		{ responseType: 'arraybuffer' },
		onSuccess,
		onError,
		false
	);
}

export async function buscarTitulosNovosOuAntigosApi(idReparcelamento, tipo, onSuccess, onError) {
	return await get(
		`${services.GESTOR}/v1/contas_receber/titulos_novos_antigos?idReparcelamento=${idReparcelamento}&tipo=${tipo}`,
		null,
		onSuccess,
		onError
	);
}
