import { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { format, formatISO, parseISO } from 'date-fns';
import { Menu } from 'primereact/menu';

import {
	Prompt,
	Form,
	FormActions,
	FormContent,
	Grid,
	Col,
	If,
	ModalHistorico,
	Message,
	Tutorial,
	tutorialStepsGestaoDocumentos,
} from 'components';

import {
	validarUUID,
	recursos,
	permissoes,
	usuarioPossuiPermissao,
	mensagensDeValidacao,
	salvarConfiguracaoUsuario,
	configuracoesUsuario,
	buscarConfiguracaoUsuario,
	buscarDadosLoginLocalStorage,
} from 'Common';
import {
	converterUsuarioParaFormulario,
	converterSetorParaFormulario,
} from 'views/documentos/AssinaturaEletronica/Form/Util/documentoConverter';
import { statusGestaoDocumento, PESQUISAURL, tipoGestaoDocumento, CADASTROURL } from '../Util/constantes';
import { helpMessage, InfoStatusDocumento, INITIAL_VALUE } from './Util/constantes';
import DocumentoStatus from './components/FieldsArquivoDocumento/components/StatusDocumento';
import {
	converterAnexosParaFormulario,
	converterGestaoDocumentoParaFormulario,
} from './Util/gestaoDocumentosConverter';
import ActionButtons from './components/ActionButtons';
import { asyncBuscarResponsavel, asyncGetAnexo, asyncGetConfiguracoesEmpresa, asyncGetDocumento } from '../Requests';
import FieldsInformacoesGestaoDocumentos from './components/FieldsInformacoesGestaoDocumentos';
import FieldsArquivoDocumento from './components/FieldsArquivoDocumento';
import CardAnexo from './components/CardAnexos';
import { buscarEstadoCadastro, buscaritensOpcoes } from './Util/funcoes';
import { styleArquivado } from '../../Util/constantes';
import { statusDocumento, statusDocumentoPermiteExcluir } from '../../AssinaturaEletronica/Util/constantes';
import { atualizarUrl, metodosAtualizarUrl } from '../../../Util';
import { FieldsArquivoDocumentoDoCliente } from './components/FieldsArquivoDocumentoDoCliente';
import { Tabs } from './components/Tabs';

function GestaoDocumentos(props) {
	const {
		dirty,
		values,
		isModal,
		resetForm,
		idAnexo,
		tipoGestaoDocumentoParaAnexos,
		idDocumento,
		isModalAnexo = false,
		history,
		statusFinalDocumento,
		documentoRescindido,
		idFocus,
	} = props;

	const [podeInserir] = useState(usuarioPossuiPermissao(recursos.GESTAO_DOCUMENTOS, permissoes.INSERIR));
	const [podeEditar] = useState(usuarioPossuiPermissao(recursos.GESTAO_DOCUMENTOS, permissoes.EDITAR));
	const [podeExcluir] = useState(usuarioPossuiPermissao(recursos.GESTAO_DOCUMENTOS, permissoes.EXCLUIR));

	const [modalAnexoVisible, setModalAnexoVisible] = useState(isModalAnexo);
	const [tipoGestaoDocumentoAnexo, setTipoGestaoDocumentoAnexo] = useState(tipoGestaoDocumentoParaAnexos);
	const [isDocumentoRescindido, setIsDocumentoRescindido] = useState(documentoRescindido);
	const [adicionouArquivo, setAdicionouArquivo] = useState(false);
	const [deveAtualizarPagina, setDeveAtualizarPagina] = useState(false);
	const [dadosBase, setDadosBase] = useState(null);
	const [anexos, setAnexos] = useState([]);
	const [podeEditarDocumento, setPodeEditarDocumento] = useState(podeEditar);
	const [modalHistoricoVisible, setModalHistoricoVisible] = useState(false);
	const [tutorialVisible, setTutorialVisible] = useState(false);
	const [idFocusInitial, setIdFocusInitial] = useState('DocumentosInputIdentificador');
	const [disabledAlertaVigencia, setDisabledAlertaVigencia] = useState(false);

	const menu = useRef(null);

	const idURL = props.match?.params.id;

	const estadoCadastro = buscarEstadoCadastro(props);

	const informacoesPermissoes = {
		estadoCadastro: estadoCadastro,
		podeInserir: podeInserir,
		podeEditar: podeEditar,
		podeExcluir: podeExcluir,
		podeEditarDocumento: podeEditarDocumento,
	};

	const itensOpcoes = buscaritensOpcoes(
		{ ...props, informacoesPermissoes: informacoesPermissoes },
		setModalHistoricoVisible
	);

	const documentoAnexoAssinado =
		isModalAnexo && dadosBase?.arquivoDocumento?.registro?.status === statusDocumento.ASSINADO;
	const anexoDocumentoDisabled = ![statusGestaoDocumento.PENDENTE].includes(dadosBase?.status) || isDocumentoRescindido;
	const permiteExcluirAnexo =
		isModalAnexo && tipoGestaoDocumentoAnexo === tipoGestaoDocumento.CONTRATO
			? statusDocumentoPermiteExcluir.includes(dadosBase?.arquivoDocumento?.registro?.status)
			: [statusGestaoDocumento.PENDENTE].includes(dadosBase?.status);

	const getFavoritosIniciais = async () => {
		try {
			return await asyncBuscarResponsavel();
		} catch (error) {
			console.error('Error ao buscar responsável:', error);
			return null;
		}
	};

	useEffect(() => {
		function getAnexoSelecionado() {
			asyncGetAnexo(idAnexo, ({ data: anexo }) => {
				const anexoConvertido = converterAnexosParaFormulario(anexo, tipoGestaoDocumentoAnexo);
				anexoConvertido.tipoGestaoDocumento = tipoGestaoDocumentoAnexo;
				setDadosBase(anexoConvertido);
				resetForm({ values: anexoConvertido });
			});
		}

		function getDocumentoSelecionado() {
			asyncGetDocumento(
				idURL,
				({ data: documento }) => {
					const documentoConvertido = converterGestaoDocumentoParaFormulario(documento);
					resetForm({ values: documentoConvertido });
					setDadosBase(documentoConvertido);
					setAnexos(documentoConvertido.anexos);
				},
				() => {
					setDadosBase(null);
					setAnexos([]);
					resetForm({ values: INITIAL_VALUE });
					atualizarUrl(props.history, '/documentos/gestao_documentos/cadastro', null, metodosAtualizarUrl.POP);
				}
			);
		}

		function newAnexo() {
			resetForm({ values: { ...INITIAL_VALUE, tipoGestaoDocumento: tipoGestaoDocumentoAnexo } });
		}

		if (validarUUID(idURL ?? idAnexo)) {
			!modalAnexoVisible ? getDocumentoSelecionado() : idAnexo ? getAnexoSelecionado() : newAnexo();
		}

		deveAtualizarPagina && setDeveAtualizarPagina(false);
	}, [idURL, modalAnexoVisible, tipoGestaoDocumentoAnexo, idAnexo, deveAtualizarPagina, props.history]);

	useEffect(() => {
		if (values.status !== null && values.status !== statusGestaoDocumento.PENDENTE) {
			setPodeEditarDocumento(false);
		}
	}, [values.status]);

	useEffect(() => {
		INITIAL_VALUE.tipoGestaoDocumento =
			buscarDadosLoginLocalStorage().filialConectada.parametrosDocumentos.tipoGestaoDocumento ??
			tipoGestaoDocumento.CONTRATO;
		if (!isModalAnexo) {
			novo();
		}

		const deveExibirTutorial =
			buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_GESTAO_DOCUMENTOS) !== false;

		setTutorialVisible(deveExibirTutorial);

		if (deveExibirTutorial) {
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_GESTAO_DOCUMENTOS, false, null, false);
		}

		if (isModalAnexo) {
			setIdFocusInitial(idFocus);
		}

		setTimeout(() => {
			document.getElementById(idFocusInitial)?.focus();
		}, 500);
	}, []);

	async function novo() {
		let alertaPeriodoVigenciaEnabled;
		let diasAlertaVigencia;
		let usuarioFavorito;
		let setorFavorito;

		INITIAL_VALUE.tipoGestaoDocumento =
			buscarDadosLoginLocalStorage().filialConectada.parametrosDocumentos.tipoGestaoDocumento ??
			tipoGestaoDocumento.CONTRATO;

		try {
			const configuracoesEmpresaPromise = new Promise((resolve) => {
				asyncGetConfiguracoesEmpresa(({ data }) => {
					if (data) {
						setDisabledAlertaVigencia(!data.parametrosDocumentos.emailAlertaVigencia);
						alertaPeriodoVigenciaEnabled = data.parametrosDocumentos.emailAlertaVigencia;
						diasAlertaVigencia = data.parametrosDocumentos.diasAlertaVigencia ?? 0;
						resolve();
					} else {
						null;
					}
				});
			});

			const favoritosPromise = getFavoritosIniciais().then((result) => {
				usuarioFavorito = converterUsuarioParaFormulario(result.usuario);
				setorFavorito = converterSetorParaFormulario(result.setor);
			});

			await Promise.all([configuracoesEmpresaPromise, favoritosPromise]);

			resetForm({
				values: {
					...INITIAL_VALUE,
					pasta: props.history?.location?.state?.pastaId,
					usuario: usuarioFavorito,
					setor: setorFavorito,
					diasAlertaVigencia: diasAlertaVigencia,
					alertaPeriodoVigenciaEnabled: alertaPeriodoVigenciaEnabled,
				},
			});
		} catch (error) {
			console.error(error);
		}
	}

	function atualizarPagina() {
		setDeveAtualizarPagina(true);
	}

	function montarInformacaoParaDocumentoVigente() {
		return (
			<span>
				<b> Atenção:</b>
				<div>Documentos com status Vigente não permitem alteração de informação, somente a inclusão de anexos.</div>
			</span>
		);
	}

	return (
		<>
			<Tutorial
				steps={tutorialStepsGestaoDocumentos}
				showSkipButton
				continuous
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>
			<Prompt dirty={dirty} isModal={isModal || modalAnexoVisible} />

			<Menu model={itensOpcoes} style={{ minWidth: '230px' }} popup ref={menu} id="popup_menu" />

			<Form
				header={'Cadastro gestão de documentos'.concat(values.arquivado ? ' (Arquivado)' : '')}
				isModal={isModal || isModalAnexo}
				className="card-default screen-max-width"
				style={values.arquivado ? styleArquivado : null}
			>
				<FormActions className="screen-max-width">
					<ActionButtons
						{...props}
						informacoesPermissoes={informacoesPermissoes}
						CADASTROURL={CADASTROURL}
						PESQUISAURL={PESQUISAURL}
						initialValue={INITIAL_VALUE}
						dadosBase={dadosBase}
						setDadosBase={setDadosBase}
						menu={menu}
						itensOpcoes={itensOpcoes}
						setPodeEditarDocumento={setPodeEditarDocumento}
						idAnexo={idAnexo}
						idDocumento={idURL ?? idDocumento}
						modalAnexoVisible={isModalAnexo}
						atualizarPagina={() => atualizarPagina()}
						permiteExcluirAnexo={permiteExcluirAnexo}
						setAnexos={setAnexos}
						adicionouArquivo={adicionouArquivo}
						setAdicionouArquivo={setAdicionouArquivo}
						anexoDocumentoDisabled={anexoDocumentoDisabled}
						novo={novo}
					/>
				</FormActions>
				<If test={values.status === statusGestaoDocumento.VIGENTE && !isModalAnexo}>
					<Message severity="info" text={montarInformacaoParaDocumentoVigente()} />
				</If>
				<FormContent>
					<If test={!modalAnexoVisible}>
						<Grid justifyBetween verticalAlignCenter style={{ margin: '20px 0px' }}>
							<Col sm="12" md="4" lg="4" xl="4">
								<DocumentoStatus
									backgroundColor={values.status ? InfoStatusDocumento[values.status]?.lightColor : null}
									color={values.status ? InfoStatusDocumento[values.status]?.strongColor : null}
									name={values.status ? InfoStatusDocumento[values.status]?.name : null}
									Description={values.status ? InfoStatusDocumento[values.status]?.description : null}
								/>
							</Col>
						</Grid>
					</If>
					<FieldsInformacoesGestaoDocumentos
						{...props}
						informacoesPermissoes={informacoesPermissoes}
						helpMessage={helpMessage}
						dadosBase={dadosBase}
						documentoAnexoAssinado={documentoAnexoAssinado}
						anexoDocumentoDisabled={anexoDocumentoDisabled}
						statusFinalDocumento={statusFinalDocumento}
						idFocusInitial={idFocusInitial}
					/>
					<If test={values.tipoGestaoDocumento === tipoGestaoDocumento.CONTRATO}>
						<FieldsArquivoDocumento
							{...props}
							informacoesPermissoes={informacoesPermissoes}
							helpMessage={helpMessage}
							isModalAnexo={modalAnexoVisible}
							documentoAnexoAssinado={documentoAnexoAssinado}
							anexoDocumentoDisabled={anexoDocumentoDisabled}
							statusFinalDocumento={statusFinalDocumento}
						/>
					</If>
					<If test={values.tipoGestaoDocumento === tipoGestaoDocumento.DOCUMENTO}>
						<FieldsArquivoDocumentoDoCliente
							{...props}
							informacoesPermissoes={informacoesPermissoes}
							isModalAnexo={modalAnexoVisible}
							statusFinalDocumento={statusFinalDocumento}
							anexoDocumentoDisabled={anexoDocumentoDisabled}
							adicionouArquivo={adicionouArquivo}
							setAdicionouArquivo={setAdicionouArquivo}
						/>
					</If>
					<If test={!isModalAnexo}>
						<CardAnexo
							{...props}
							setModalAnexoVisible={setModalAnexoVisible}
							anexos={anexos}
							informacoesPermissoes={informacoesPermissoes}
							idDocumento={idURL ?? idDocumento}
							status={values.status}
							historyDocumento={history}
							atualizarPagina={() => atualizarPagina()}
							setTipoGestaoDocumentoAnexo={setTipoGestaoDocumentoAnexo}
							setIsDocumentoRescindido={setIsDocumentoRescindido}
							tipoGestaoDocumentoParaAnexos={values.tipoGestaoDocumento}
						/>
					</If>
					<If test={!isModalAnexo}>
						<Tabs informacoesPermissoes={informacoesPermissoes} disabledAlertaVigencia={disabledAlertaVigencia} />
					</If>
					<If test={modalHistoricoVisible}>
						<ModalHistorico
							header="Histórico do Documento"
							mapping="documentos/gestao_documentos"
							id={values.id}
							visible={modalHistoricoVisible}
							onHide={() => setModalHistoricoVisible(false)}
						/>
					</If>
				</FormContent>
			</Form>
		</>
	);
}

const GestaoDocumentosForm = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues(props) {
		const initialValue = { ...INITIAL_VALUE };

		if (props.isNewAnexo) {
			initialValue.tipoGestaoDocumento = props.tipoGestaoDocumentoParaAnexos;
		}
		if (props.history?.location?.state?.pastaId) {
			initialValue.pasta = props.history?.location?.state?.pastaId;
		}

		return initialValue;
	},

	validate(values) {
		const errors = {};

		const naoDeveSerValidado = false;

		const {
			identificacao,
			vigenciaInicio,
			vigenciaFinal,
			documentoValor,
			documentoValorMensal,
			// valor default anexoValor é false, ou seja, nao deve ser validado, apenas será validado quando anexo valor vier no value
			anexoValor = naoDeveSerValidado,
			anexoValorMensal = naoDeveSerValidado,
		} = values;

		if (!identificacao) {
			errors.identificacao = mensagensDeValidacao.OBRIGATORIO;
		}

		if (!vigenciaInicio) {
			errors.vigenciaInicio = mensagensDeValidacao.OBRIGATORIO;
		}

		if (parseISO(vigenciaInicio) > parseISO(vigenciaFinal)) {
			errors.vigenciaInicio = 'Data inicial não pode ser maior que a data final.';
		}

		if (documentoValor < 0) {
			errors.documentoValor = 'Valor do documento não pode ser negativo';
		}

		if (documentoValorMensal < 0) {
			errors.documentoValorMensal = 'Valor mensal do documento não pode ser negativo';
		}

		if (anexoValor < 0) {
			errors.anexoValor = 'Valor do anexo não pode ser negativo';
		}

		if (anexoValorMensal < 0) {
			errors.anexoValorMensal = 'Valor mensal do anexo não pode ser negativo';
		}

		if (values.tipoGestaoDocumento === tipoGestaoDocumento.CONTRATO) {
			if (!vigenciaFinal) {
				errors.vigenciaFinal = mensagensDeValidacao.OBRIGATORIO;
			}

			if (vigenciaFinal < format(parseISO(formatISO(new Date())), 'yyyy-MM-dd')) {
				errors.vigenciaFinal = 'Data final não pode ser menor que a data atual.';
			}
		}

		return errors;
	},

	handleSubmit: () => {},
})(GestaoDocumentos);

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
});

export default withRouter(connect(mapStateToProps)(GestaoDocumentosForm));
