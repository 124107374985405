import {
	DOCUMENTOFISCAL,
	TiposPercentualValor,
	buscarDadosLoginLocalStorage,
	calcularImpostosProduto,
	colors,
	copiarObjeto,
	formatarMonetarioDecimais,
	mensagensDeValidacao,
	permissoes,
	recursos,
	usuarioPossuiPermissao,
} from 'Common';
import {
	AutoProgressBar,
	Button,
	ButtonCancelar,
	Card,
	Col,
	Divider,
	Form,
	FormActions,
	FormContent,
	Grid,
	InputSelectPercentualOrValor,
	Modal,
	ToastTypes,
	estadosBotaoCancelar,
	notify,
} from 'components';
import { validateTotalCategorias } from 'components/select/SingleSelectCategoria';
import { Field, withFormik } from 'formik';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { aplicarPercentual, validarFormulario } from '../../../../../../Util';
import { useContextNFCe } from '../../../Context';
import { asyncTransmitirESalvarNFCe } from '../../../Requests';
import { converterNFCeParaApi, converterNfceParaFormulario } from '../../../Util/NFCeConverter';
import { Campo, CONDICAO_PAGAMENTO_TIPO, Condicoes, TiposAcessoriasOuDesconto } from '../../../Util/constantes';
import { defineColorStatus, rateioValorEntreProdutosNFCe } from '../../../Util/function';
import FormaPagamento from './components/FormaPagamento';
import { InformacoesComplementares } from './components/InformacoesComplementares';

function ModalFinalizacaoView(props) {
	const {
		values,
		setFieldValue,
		onHide,
		visible,
		isMobile,
		isTablet,
		dirty,
		resetForm,
		resetFormPrincipal,
		decimaisPreco,
		disabled,
		errors,
		submitCount,
		isFullScreen,
		imprimirNFCe,
	} = props;

	const {
		setExibirLoadingTransmissao,
		setExibirLoadingImprimir,
		setMessageLoadingTransmissao,
		transmissaoFlag,
		informacoesPermissoes,
		setPodeEditar,
		davImportado,
		setModalRecebimentoPixVisible,
		setModalFinalizacaoVisible,
		setDadosNFCe,
	} = useContextNFCe();

	const [activeIndex, setActiveIndex] = useState();
	const [restart, setRestart] = useState();
	const backupValores = useRef(null);
	const [atualizarPagamentos, setAtualizarPagamentos] = useState(false);

	useEffect(() => {
		document.addEventListener('keydown', keyDownWatched);
		setTimeout(() => {
			document.getElementById('input-valor-recebido-nfce')?.focus();
		}, 100);
	}, []);

	useLayoutEffect(
		() => () => {
			document.removeEventListener('keydown', keyDownWatched);
		},
		[]
	);

	useEffect(() => {
		setPodeEditar(usuarioPossuiPermissao(recursos.VENDAS_NOTAS_CONSUMIDOR, permissoes.EDITAR));
		backupValores.current = values;
	}, []);

	useEffect(() => {
		let totalProdutos = 0;
		let totalDesconto = 0;
		let totalAcessorias = 0;

		values.produtos.forEach((produto) => {
			totalProdutos += parseFloat((produto.quantidade * produto.valor)?.toFixed(2));
			totalDesconto += parseFloat(produto.desconto?.toFixed(2)) ?? 0;
			totalAcessorias += parseFloat(produto.acessorias?.toFixed(2)) ?? 0;
		});

		totalProdutos = parseFloat(totalProdutos.toFixed(2));
		totalDesconto = parseFloat(totalDesconto.toFixed(2)) ?? 0;
		totalAcessorias = parseFloat(totalAcessorias.toFixed(2)) ?? 0;

		const totalLiquido = parseFloat((totalProdutos - totalDesconto + totalAcessorias)?.toFixed(2));

		if (totalProdutos !== values.totalizadores.totalProdutos) {
			setFieldValue('totalizadores.totalProdutos', totalProdutos);
		}

		if (totalDesconto !== values.totalizadores.totalDesconto) {
			setFieldValue('totalizadores.totalDesconto', totalDesconto);
		}

		if (totalAcessorias !== values.totalizadores.totalAcessorias) {
			setFieldValue('totalizadores.totalAcessorias', totalAcessorias);
		}

		if (totalLiquido !== values.totalizadores.totalLiquido) {
			setFieldValue('totalizadores.totalLiquido', totalLiquido);
		}
	}, [values.desconto?.valor, values.acessorias?.valor]);

	useEffect(() => {
		if (errors.vendedor) {
			setActiveIndex(0);
		}
	}, [submitCount]);

	async function cancelar() {
		await resetForm({ values: props.initialValues ?? backupValores.current });
		if (!dirty) {
			onHide();
		}
	}

	async function keyDownWatched(event) {
		if (!transmissaoFlag.current) {
			if (event.code === 'F4') {
				document.getElementById('btn-transmitir').click();
			}
			if (event.code === 'Escape') {
				if (!document.getElementsByClassName('modal-parcelas-nfce')[0]) {
					cancelar();
				}
			}
		}
	}

	function possuiRecebimentoPix(nfce) {
		let retorno = false;
		nfce.pagamentos.forEach((pagamento) => {
			if (
				pagamento.condicaoPagamento.tipo === 'A_VISTA' &&
				pagamento.conta.recebePix &&
				pagamento.formaPagamento.descricao.includes('Pix')
			) {
				retorno = true;
			}
		});
		return retorno;
	}

	async function transmitir() {
		await props.handleSubmit();
		if (await validarFormulario(props)) {
			const dadosFormulario = converterNFCeParaApi(values);
			const { gerarFinanceiroNaNfe } = buscarDadosLoginLocalStorage().filialConectada.parametrosFinancas;
			transmissaoFlag.current = true;
			setExibirLoadingTransmissao(true);
			setPodeEditar(false);
			setMessageLoadingTransmissao('Transmitindo NFC-e...');

			asyncTransmitirESalvarNFCe(
				dadosFormulario,
				async ({ data: nfce }) => {
					resetFormPrincipal({ values: converterNfceParaFormulario(nfce) });
					setExibirLoadingTransmissao(false);
					if (possuiRecebimentoPix(nfce) && gerarFinanceiroNaNfe) {
						setDadosNFCe(nfce);
						setModalFinalizacaoVisible(false);
						setModalRecebimentoPixVisible(true);
					} else if (possuiRecebimentoPix(nfce) && !gerarFinanceiroNaNfe) {
						notify(
							'Não foi possivel receber por pix. NFC-e parametrizada para não gerar financeiro',
							ToastTypes.ERROR,
							5
						);
						await imprimirNFCe(nfce.id);
					} else {
						await imprimirNFCe(nfce.id);
					}
				},
				() => {
					setPodeEditar(usuarioPossuiPermissao(recursos.VENDAS_NOTAS_CONSUMIDOR, permissoes.EDITAR));
					setExibirLoadingTransmissao(false);
					setExibirLoadingImprimir(false);
					transmissaoFlag.current = false;
				}
			);
		}
	}

	function onChangeDescontoFinalizacao(tipo, value, percentual) {
		const desconto = {
			tipoDesconto: tipo,
			valor: tipo === TiposAcessoriasOuDesconto.PERCENTUAL ? percentual : value,
		};

		setFieldValue('desconto.valor', desconto.valor);
		calcularDescontoNfce(desconto);
	}

	async function calcularDescontoNfce(desconto) {
		let produtos = [];
		if (desconto.tipoDesconto === TiposPercentualValor.PERCENTUAL) {
			produtos = calcularImpostosTodosProdutos(
				await rateioValorEntreProdutosNFCe(
					values.produtos,
					values.totalizadores.totalProdutos,
					Campo.DESCONTO,
					aplicarPercentual(values.totalizadores.totalProdutos, desconto.valor)
				)
			);
		} else {
			produtos = calcularImpostosTodosProdutos(
				await rateioValorEntreProdutosNFCe(
					values.produtos,
					values.totalizadores.totalProdutos,
					Campo.DESCONTO,
					desconto.valor
				)
			);
		}

		await setFieldValue('produtos', produtos);
		setAtualizarPagamentos(true);
	}

	function onChangeAcessoriasFinalizacao(tipo, value, percentual) {
		const acessorias = {
			tipoAcessorias: tipo,
			valor: tipo === TiposAcessoriasOuDesconto.PERCENTUAL ? percentual : value,
		};

		setFieldValue('acessorias', acessorias);
		calcularAcessoriasNfce(acessorias);
	}

	async function calcularAcessoriasNfce(acessorias) {
		let produtos = [];
		if (acessorias.tipoAcessorias === TiposPercentualValor.PERCENTUAL) {
			produtos = calcularImpostosTodosProdutos(
				await rateioValorEntreProdutosNFCe(
					values.produtos,
					values.totalizadores.totalProdutos,
					Campo.ACESSORIAS,
					aplicarPercentual(values.totalizadores.totalProdutos, acessorias.valor)
				)
			);
		} else {
			produtos = calcularImpostosTodosProdutos(
				await rateioValorEntreProdutosNFCe(
					values.produtos,
					values.totalizadores.totalProdutos,
					Campo.ACESSORIAS,
					acessorias.valor
				)
			);
		}

		await setFieldValue('produtos', produtos);
		setAtualizarPagamentos(true);
	}

	function calcularImpostosTodosProdutos(produtos) {
		const itens = produtos;

		itens.map((item) => {
			let itemLocal = copiarObjeto(item);
			itemLocal.subTotal = parseFloat((item.quantidade * item.valor + item.acessorias - item.desconto).toFixed(2));
			itemLocal = calcularImpostosProduto(item, DOCUMENTOFISCAL.NFCE);
			return itemLocal;
		});

		return itens;
	}

	return (
		<Modal
			header={values.id ? 'Pagamentos' : 'Finalizar NFC-e'}
			onHide={onHide}
			visible={visible}
			styleModal={{
				minWidth: '60%',
			}}
			isMobile={isMobile}
			container={isFullScreen ? document.getElementsByClassName('layout-fullscreen')[0] : null}
			showCloseIcon={false}
			closeOnEsc={false}
		>
			<AutoProgressBar />
			<Form>
				<FormActions>
					<ButtonCancelar
						estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
						onClick={() => cancelar()}
					/>
					<Button
						id="btn-transmitir"
						className="p-button-success"
						label={isMobile || isTablet ? 'Transmitir' : 'Transmitir NFC-e [F4]'}
						icon="fa fa-check"
						onClick={() => transmitir()}
						disabled={disabled && !davImportado}
					/>
				</FormActions>
				<FormContent>
					<Divider
						label="Forma de pagamento"
						styleLabel={{ fontSize: '14px' }}
						styleContainer={{
							margin: values.pagamentos.length > 1 ? '0rem 0.5rem 0.2rem 0.5rem' : '0rem 0.5rem 0.5rem 0.5rem',
						}}
					/>
					<FormaPagamento
						atualizarPagamentos={atualizarPagamentos}
						setAtualizarPagamentos={setAtualizarPagamentos}
						isMobile={isMobile}
						isTablet={isTablet}
						resetFormPrincipal={resetFormPrincipal}
						isFullScreen={isFullScreen}
						davImportado={davImportado}
						backupValores={backupValores}
					/>
					<Divider
						styleContainer={{
							margin: values.pagamentos.length > 1 ? '0rem 0.5rem 0rem 0.5rem' : '1rem 0.5rem 0.5rem ',
						}}
					/>
					<Accordion
						className="tab-accordion-lite"
						activeIndex={activeIndex}
						onTabChange={(event) => setActiveIndex(event.index)}
					>
						<AccordionTab header="Informações complementares">
							<InformacoesComplementares
								disabled={disabled}
								setFieldValue={setFieldValue}
								errors={errors}
								values={values}
							/>
						</AccordionTab>
					</Accordion>
					<Divider label="Total da NFC-e" styleLabel={{ fontSize: '14px' }} />
					<Card style={{ paddingTop: '0px' }}>
						<Grid style={{ justifyContent: 'flex-end' }}>
							<Col
								sm={12}
								md={12}
								lg={6}
								xl={6}
								style={{
									paddingBottom: '0px',
									paddingTop: values.pagamentos.length > 1 ? '0px' : '0.5rem',
								}}
							>
								<Grid style={{ width: '100%', height: '100%', alignItems: 'flex-end' }}>
									<Field
										sm="12"
										md="6"
										lg="6"
										xl="6"
										name="desconto"
										component={InputSelectPercentualOrValor}
										label="Desconto NFC-e"
										value={values.desconto?.valor}
										restart={restart}
										onRestart={() => {
											setRestart(false);
										}}
										onChange={(tipo, value, percentual) => onChangeDescontoFinalizacao(tipo, value, percentual)}
										sizeValor={9}
										placeholder={formatarMonetarioDecimais(0, decimaisPreco)}
										decimalScale={decimaisPreco}
										allowNegative={false}
										disabled={disabled}
										informacoesPermissoes={informacoesPermissoes}
									/>
									<Field
										sm="12"
										md="6"
										lg="6"
										xl="6"
										name="acessorias"
										component={InputSelectPercentualOrValor}
										label="Acessórias NFC-e"
										value={values.acessorias?.valor}
										restart={restart}
										onRestart={() => {
											setRestart(false);
										}}
										onChange={(tipo, value, percentual) => onChangeAcessoriasFinalizacao(tipo, value, percentual)}
										sizeValor={9}
										placeholder={formatarMonetarioDecimais(0, decimaisPreco)}
										decimalScale={decimaisPreco}
										allowNegative={false}
										disabled={disabled}
										informacoesPermissoes={informacoesPermissoes}
									/>
								</Grid>
							</Col>
							<Col
								sm={12}
								md={12}
								lg={6}
								xl={6}
								style={{ paddingTop: values.pagamentos.length > 1 ? '0px' : '0.5rem' }}
							>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'flex-end',
										fontSize: values.pagamentos.length > 1 ? '12px' : '16px',
									}}
								>
									<div>
										{`Valor dos produtos: ${formatarMonetarioDecimais(
											values.totalizadores.totalProdutos,
											decimaisPreco
										)}`}
									</div>
									<div>
										{`Desconto: ${formatarMonetarioDecimais(values.totalizadores.totalDesconto, decimaisPreco)}`}
									</div>
									<div>
										{`Acessórias: ${formatarMonetarioDecimais(values.totalizadores.totalAcessorias, decimaisPreco)}`}
									</div>
									<div
										style={{
											fontSize: '36px',
											fontWeight: 'bold',
											color: defineColorStatus(values.status),
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'flex-end',
											paddingLeft: isMobile || isTablet ? '12px' : '0px',
											marginLeft: isMobile || isTablet ? '0px' : '8px',
										}}
									>
										{'Total: '}
										{formatarMonetarioDecimais(values?.totalizadores?.totalLiquido, decimaisPreco)}
										{errors.totalLiquido && (
											<p
												style={{
													fontSize: '12px',
													color: colors.vermelho,
													margin: '0px',
													justifyContent: 'flex-end',
													display: 'flex',
												}}
											>
												{errors.totalLiquido}
											</p>
										)}
									</div>
								</div>
							</Col>
						</Grid>
					</Card>
				</FormContent>
			</Form>
		</Modal>
	);
}

const ModalFinalizacao = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues(props) {
		if (props.values.status === 'NAO_ENVIADA') {
			return {
				...props.values,
				pagamentos: [{ ...props.values.pagamentos[0], valor: props.values.totalizadores?.totalLiquido }],
			};
		}
		return props.values;
	},

	validate(values) {
		const errors = {};
		const errorsPagamentos = [];
		let valorTotalPagamentos = 0;

		if (values.pagamentos.length > 0) {
			values.pagamentos.forEach((pagamento, index) => {
				const isSemPagamento = pagamento.condicaoPagamento?.registro.tipo === CONDICAO_PAGAMENTO_TIPO.SEM_PAGAMENTO;
				valorTotalPagamentos += pagamento.valor;

				let errorPagamento = {};
				if (!pagamento.condicaoPagamento) {
					errorPagamento = { ...errorPagamento, condicaoPagamento: mensagensDeValidacao.OBRIGATORIO };
				}

				if ((!pagamento.categorias || pagamento.categorias.length === 0) && !isSemPagamento) {
					errorsPagamentos[index] = {
						...errorsPagamentos[index],
						categorias: mensagensDeValidacao.OBRIGATORIO,
					};
				}

				if (
					pagamento.categorias &&
					pagamento.categorias.length > 0 &&
					!validateTotalCategorias(pagamento.categorias, pagamento.valor)
				) {
					errorsPagamentos[index] = {
						...errorsPagamentos[index],
						categorias: 'O somatório das categorias deve ser igual ao valor do pagamento',
					};
				}

				if (!pagamento.valor || pagamento.valor === 0) {
					errorPagamento = { ...errorPagamento, valor: mensagensDeValidacao.OBRIGATORIO };
				}

				if (pagamento.condicaoPagamento?.registro?.tipo === Condicoes.A_VISTA) {
					if (!pagamento.formaPagamento) {
						errorPagamento = { ...errorPagamento, formaPagamento: mensagensDeValidacao.OBRIGATORIO };
					}

					if (!pagamento.conta) {
						errorPagamento = { ...errorPagamento, conta: mensagensDeValidacao.OBRIGATORIO };
					}
					if (pagamento.valorTroco < 0) {
						errorPagamento = { ...errorPagamento, valorRecebido: 'Valor menor que a parcela' };
					}
				}

				if (Object.keys(errorPagamento).length > 0) {
					errorsPagamentos[index] = errorPagamento;
				}
			});
		}

		if (parseFloat(valorTotalPagamentos.toFixed(2)) > parseFloat(values?.totalizadores?.totalLiquido.toFixed(2))) {
			errors.totalLiquido = 'Valor da forma de pagamento é maior que o da NFC-e';
		}

		if (parseFloat(valorTotalPagamentos.toFixed(2)) < parseFloat(values?.totalizadores?.totalLiquido.toFixed(2))) {
			errors.totalLiquido = 'Valor da forma de pagamento é menor que o da NFC-e';
		}

		if (
			parseFloat(values.totalizadores?.totalDesconto.toFixed(2)) >
			parseFloat(values?.totalizadores?.totalProdutos.toFixed(2))
		) {
			errors.totalLiquido = 'Valor do desconto é maior que o da NFC-e';
		}

		if (parseFloat(values?.totalizadores?.totalLiquido).toFixed(2) <= 0) {
			errors.totalLiquido = 'Valor da NFC-e não pode ser zero ou negativo';
		}

		if (errorsPagamentos.length > 0) {
			errors.pagamentos = errorsPagamentos;
		}

		if (!values.vendedor) {
			errors.vendedor = mensagensDeValidacao.OBRIGATORIO;
		}

		return errors;
	},
	handleSubmit: () => {},
})(ModalFinalizacaoView);

export default ModalFinalizacao;
