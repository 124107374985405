import { addMonths, formatISO } from 'date-fns';
import { converterQuantidadeParcelas } from 'components/body/Vendas/Util/functions';
import { gerarUUID } from 'Common';
import { montarLabelPessoa } from '../../../../../../components/select/SingleSelectPessoa/Util';
import { SITUACAO_DEVOLUCAO, Status, Tipo } from '../../Util/constantes';
import { converterValueParaSelect } from './functions';

function converterVendaParaFormulario(values) {
	return {
		id: values.id,
		numero: values.numero,
		status: values.status,
		situacaoDevolucao: values.situacaoDevolucao,
		tipo: values.tipo,
		pessoa: converterPessoaParaFormulario(values.cliente),
		dataEmissao: values.emissao,
		validade: values.validade ?? null,
		previsaoEntrega: values.previsaoEntrega ?? null,
		operacaoFiscal: converterOperacaoFiscalParaFormulario(values.operacaoFiscal),
		tipoDesconto: values.tipoDesconto === 'NAO_APLICADO' ? 'VALOR' : values.tipoDesconto,
		orcamentoId: values.orcamentoId ?? null,
		gerouFinanceiro: values.gerouFinanceiro ? values.gerouFinanceiro : false,

		produtos: converterItensParaFormulario(values.itens),

		totalizadores: {
			totalProdutos: values.totalItens,
			totalTributos: values.tipo !== Tipo.ORCAMENTO ? values.totalIpi + values.totalIcmsSt ?? 0 : 0,
			totalFrete: values.valorFrete ?? 0,
			totalSeguro: values.valorSeguro ?? 0,
			totalAcessorias: values.valorDespesasAcessorias ?? 0,
			percentualDesconto: values.tipoDesconto === 'PERCENTUAL' ? values.percentualDesconto : 0,
			totalDesconto: values.tipoDesconto === 'VALOR' ? values.desconto : 0,
			totalLiquido: values.totalLiquido,
		},
		pagamentos: converterPagamentosParaFormulario(values.pagamentos, values.cliente),
		informacoesComplementares: {
			vendedor: converterSelectParaFormulario(values.vendedor),
			tabelaPreco: converterSelectParaFormulario(values.tabelaPreco),
			setor: converterSetorParaFormulario(values.setor),
			informacaoComplementar: values.informacaoComplementar ?? null,
			observacaoInterna: values.observacaoInterna ?? null,
		},
		historico: values.historico,
		motivoCancelamento: values.motivoCancelamento ?? null,
		validacaoRecarregaProdutoPeloCliente: false,
		nfe: values.nfe,
		nfce: values.nfce,
		pedido: values.pedido,
		documentoAssinaturaId: values.documentoAssinaturaId,
		comissaoTotalPercentual: values.comissaoTotalPercentual,
		comissaoTotalValor: values.comissaoTotalValor,
	};
}

export function converterSetorParaFormulario(setor) {
	if (setor) {
		return {
			label: setor.nome,
			value: setor.id,
		};
	}
	return null;
}

function converterPagamentosParaFormulario(pagamentos, cliente) {
	const pagamentosFormulario = pagamentos.map((pagamento) => ({
		tempKey: gerarUUID(),
		sequencial: pagamento.sequencial,
		condicaoPagamento: converterValueParaSelect(pagamento.condicaoPagamento),
		quantidadeParcelas: converterQuantidadeParcelas(pagamento.quantidadeParcelas),
		categorias: converterPagamentoCategoriasParaFormulario(pagamento.categorias),
		formaPagamento: converterValueParaSelect(pagamento.formaPagamento),
		conta: converterValueParaSelect(pagamento.conta),
		valor: pagamento.valor,
		parcelas: converterParcelasParaFormulario(pagamento.parcelas, cliente),
	}));
	return pagamentosFormulario;
}

function converterPessoaParaFormulario(pessoaApi) {
	if (pessoaApi) {
		return {
			label: montarLabelPessoa(pessoaApi),
			value: pessoaApi.id,
			registro: pessoaApi,
		};
	}
	return null;
}

function converterOperacaoFiscalParaFormulario(OperacaoFiscalApi) {
	if (OperacaoFiscalApi?.registro?.id) {
		return OperacaoFiscalApi;
	}

	return converterSelectParaFormulario(OperacaoFiscalApi);
}

function converterPagamentoCategoriasParaFormulario(values){
	const categorias = [];
	if (values) {
		values?.forEach(registro =>{
			const vendaCategoria = {
				categoria: converterCategoriaParaFormulario(registro.categoria),
				valor: registro.valor,
				percentual: registro.percentual,
			};
			categorias.push(vendaCategoria);
		});
	}
	return categorias;
}

function converterCategoriaParaFormulario(categoriaApi) {
	if (categoriaApi) {
		return {
			label: categoriaApi.nome,
			value: categoriaApi.id,
			registro: categoriaApi,
		};
	}
	return null;
}

function converterParcelasParaFormulario(parcelas, cliente) {
	if (parcelas?.length > 0) {
		const parcelasConvertidas = [];
		parcelas.forEach((parcela) => {
			parcelasConvertidas.push({
				id: parcela.id,
				tempKey: gerarUUID(),
				sequencial: parcela.sequencial,
				vencimento: parcela.vencimento,
				formaPagamento: converterValueParaSelect(parcela.formaPagamento),
				conta: converterValueParaSelect(parcela.conta),
				descricao: parcela.descricao,
				valor: parcela.valor,
				temBoleto: parcela.temBoleto,
				temPix: parcela.temPix,
				financeiroIdentificador: parcela.financeiroIdentificador,
				financeiroId: parcela.financeiroId,
				financeiroStatus: parcela.financeiroStatus,
				valorRestante: parcela.valorRestante,
				pessoaNome: cliente.nome,
				pessoaCodigo: cliente.codigo,
				pessoaEmail: converterClienteEmailParaFormulario(cliente.emails),
			});
		});
		return parcelasConvertidas;
	}
	return [];
}

function converterClienteEmailParaFormulario(emails) {
	let emailFavorito = '';
	if (emails?.length > 0) {
		emails?.forEach((email) => {
			emailFavorito = email.favorito === true ? `${email.email}` : null;
		});
	}

	if (emailFavorito) {
		return emailFavorito;
	} else if (emails?.length > 0) {
		return emails[0]?.email;
	}
	return null;
}

function converterItensParaFormulario(itensApi) {
	const itensFormulario = itensApi?.map((item) => ({
			id: item.id,
			item: item.item,
			produto: converterProdutoParaFormulario(item.produto),
			descricao: item?.descricao ?? null,

			cfop: converterSelectParaFormulario(item.cfop),
			operacaoFiscal: converterSelectParaFormulario(item.operacaoFiscal),
			ncm: converterSelectParaFormulario(item.ncm),
			unidadeMedida: converterSelectParaFormulario(item.unidadeMedida),
			cest: converterSelectParaFormulario(item.cest) ?? null,
			codigoBarras: item.codigoBarras ?? null,
			codigoBeneficiario: item.codigoBeneficiario ?? null,
			numeroPedidoCompra: item.xped ?? null,
			itemPedidoCompra: item.nitemPed ?? null,
			utilizouTabelaPromocional: item.utilizouTabelaPromocional ?? false,

			quantidade: item.quantidade,
			desconto: item.desconto ?? 0,
			valor: item.valor ?? 0,
			valorOriginal: item.valorOriginal ?? 0,

			descontoMaximoPercentual: item.descontoMaximoPercentual ?? 0,
			descontoMaximoVendedor: item.descontoMaximoVendedor ?? 0,
			descontoMaximoTabela: item.descontoMaximoTabela ?? 0,
			comissaoValor: item.comissaoValor ?? 0,
			comissaoPercentual: item.comissaoPercentual ?? 0,
			adicionalTabelaPreco: {
				percentual: item.percentualAdicionalTabelaPreco ?? 0,
				tipo: item.tipoPercentualAdicionalTabelaPreco,
			},
			completamenteDevolvido: item.completamenteDevolvido,

			acessorias: item.acessorias ?? 0,
			seguro: item.seguro ?? 0,
			frete: item.frete ?? 0,

			tributos: {
				icms: {
					cstCsosn: converterSelectParaFormulario(item.icms?.cstCsosn),
					origemProduto: item.icms?.origem,
					percentualCreditoSimplesNacional: item.icms?.aliquotaCreditoSimplesNacional ?? 0,
					valorCreditoSimplesNacional: item.icms?.valorCreditoSimplesNacional ?? 0,
					percentualBaseIcms: item.icms?.percentualBaseCalculo ?? 0,
					percentualReducaoBaseIcms: item.icms?.percentualReducaobaseCalculo ?? 0,
					modalidadeCalculo: item.icms?.modalidadeCalculo ?? 0,
					baseIcms: item.icms?.valorBaseCalculo ?? 0,
					percentualIcms: item.icms?.aliquota ?? 0,
					valorIcms: item.icms?.valor ?? 0,
					valorIcmsSubstituto: item.icms?.valorSubstituto ?? 0,
					modalidadeCalculoSt: item.icms?.modalidadeCalculoSt ?? 0,
					percentualMva: item.icms?.percentualMva ?? 0,
					valorPauta: item.icms?.valorPauta ?? 0,
					percentualBaseIcmsSt: item.icms?.percentualBaseCalculoSt ?? 0,
					percentualReducaoBaseIcmsSt: item.icms?.percentualReducaoBaseCalculoSt ?? 0,
					baseIcmsSt: item.icms?.valorBaseCalculoSt ?? 0,
					percentualBaseIcmsStRetido: item.icms?.percentualBaseCalculoStRetido ?? 0,
					percentualIcmsStRetido: item.icms?.aliquotaStRetido ?? 0,
					percentualIcmsSt: item.icms?.aliquotaSt ?? 0,
					valorIcmsSt: item.icms?.valorSt ?? 0,
					baseRetidoSt: item.icms?.valorBaseCalculoStRetido ?? 0,
					valorRetidoSt: item.icms?.valorStRetido ?? 0,
					percentualDiferimento: item.icms?.percentualDiferido ?? 0,
					valorDiferimento: item.icms?.valorDiferido ?? 0,
					baseFundoCombatePobreza: item.icms?.valorBaseCalculoFcPobreza ?? 0,
					percentualFundoCombatePobreza: item.icms?.percentualFcPobreza ?? 0,
					valorFundoCombatePobreza: item.icms?.valorFcPobreza ?? 0,
					quantidadeBaseIcmsMonofasicoRetido: item.icms?.quantidadeBaseMonofasicoRetido ?? 0,
					percentualIcmsMonofasicoRetido: item.icms?.percentualMonofasicoRetido ?? 0,
					valorIcmsMonofasicoRetido: item.icms?.valorMonofasicoRetido ?? 0,
				},
				ipi: {
					enquadramentoIpi: converterSelectParaFormulario(item.ipi?.enquadramento),
					cst: converterSelectParaFormulario(item.ipi?.cst),
					percentualBaseIpi: item.ipi?.percentualBaseCalculo ?? 0,
					baseIpi: item.ipi?.valorBaseCalculo ?? 0,
					percentualIpi: item.ipi?.aliquota ?? 0,
					valorIpi: item.ipi?.valor ?? 0,
				},
				pis: {
					cst: converterSelectParaFormulario(item.pis?.cst),
					percentualBasePis: item.pis?.percentualBaseCalculo ?? 0,
					basePis: item.pis?.valorBaseCalculo ?? 0,
					percentualPis: item.pis?.aliquota ?? 0,
					valorPis: item.pis?.valor ?? 0,
					percentualBasePisSt: item.pis?.percentualBaseCalculoSt ?? 0,
					basePisSt: item.pis?.valorBaseCalculoSt ?? 0,
					percentualPisSt: item.pis?.aliquotaSt ?? 0,
					valorPisSt: item.pis?.valorSt ?? 0,
				},
				cofins: {
					cst: converterSelectParaFormulario(item.cofins?.cst),
					percentualBaseCofins: item.cofins?.percentualBaseCalculo ?? 0,
					baseCofins: item.cofins?.valorBaseCalculo ?? 0,
					percentualCofins: item.cofins?.aliquota ?? 0,
					valorCofins: item.cofins?.valor ?? 0,
					percentualBaseCofinsSt: item.cofins?.percentualBaseCalculoSt ?? 0,
					baseCofinsSt: item.cofins?.valorBaseCalculoSt ?? 0,
					percentualCofinsSt: item.cofins?.aliquotaSt ?? 0,
					valorCofinsSt: item.cofins?.valorSt ?? 0,
				},
			},

			subtotal: item.subtotal ?? 0,
			baseCalculo:
				parseFloat(
					parseFloat(item.quantidade * item.valor + item.seguro + item.frete + item.acessorias - item.desconto).toFixed(
						2
					)
				) ?? 0,
		}));
	return itensFormulario;
}

function converterProdutoParaFormulario(produtoApi) {
	if (produtoApi) {
		return {
			value: produtoApi.id,
			label: `${produtoApi.codigo  } - ${  produtoApi.nome}`,
			registro: produtoApi,
		};
	}
	return null;
}

function converterVendaParaApi(values) {
	function validateValue(value) {
		return value > 0 && !isNaN(value) ? value : 0;
	}

	return {
		id: values.id,
		numero: values.numero,
		status: values.status,
		situacaoDevolucao: values.situacaoDevolucao || SITUACAO_DEVOLUCAO.NAO_DEVOLVIDO,
		tipo: values.tipo,
		cliente: values.pessoa ? { id: values.pessoa.value } : null,
		emissao: values.dataEmissao,
		validade: values.validade,
		previsaoEntrega: values.previsaoEntrega,
		vendedor: values.informacoesComplementares.vendedor
			? { id: values.informacoesComplementares.vendedor?.value }
			: null,
		tabelaPreco: values.informacoesComplementares.tabelaPreco
			? { id: values.informacoesComplementares.tabelaPreco?.value }
			: null,
		setor: values.informacoesComplementares.setor?.value?.id
			? { id: values.informacoesComplementares.setor?.value.id }
			: values.informacoesComplementares.setor?.value
				? { id: values.informacoesComplementares.setor?.value }
				: null,
		informacaoComplementar: values.informacoesComplementares.informacaoComplementar,
		observacaoInterna: values.informacoesComplementares.observacaoInterna,

		itens: converterItensParaApi(values.produtos),

		totalItens: validateValue(values.totalizadores.totalProdutos),
		valorFrete: validateValue(values.totalizadores.totalFrete),
		valorSeguro: validateValue(values.totalizadores.totalSeguro),
		valorDespesasAcessorias: validateValue(values.totalizadores.totalAcessorias),
		totalIcmsSt: validateValue(values.totalizadores.totalIcmsSt),
		totalFcpSt: validateValue(values.totalizadores.totalFcpSt),
		tipoDesconto:
			!values.totalizadores.totalDesconto && !values.totalizadores.percentualDesconto
				? 'NAO_APLICADO'
				: values.tipoDesconto,
		desconto: validateValue(values.totalizadores.totalDesconto),
		percentualDesconto: validateValue(values.totalizadores.percentualDesconto),
		totalLiquido: validateValue(values.totalizadores.totalLiquido),
		totalIpi: validateValue(values.totalizadores.totalIpi),
		pagamentos: converterPagamentosParaApi(values.pagamentos),

		motivoCancelamento: values.motivoCancelamento,
		operacaoFiscal: { id: values.operacaoFiscal?.value },
		orcamentoId: values.orcamentoId ?? null,
		comissaoTotalPercentual: values.comissaoTotalPercentual,
		comissaoTotalValor: values.comissaoTotalValor,
	};
}

function converterPagamentoCategoriasParaApi(values){
	const categorias = [];
	if (values) {
		values.forEach(registro =>{
			const vendaCategoria = {
				categoria: {id: registro.categoria.value},
				valor: registro.valor,
				percentual: registro.percentual,
			};
			categorias.push(vendaCategoria);
		});
	}
	return categorias;
}

function converterPagamentosParaApi(pagamentos) {
	const pagamentosApi = pagamentos.map((pagamento) => ({
			sequencial: pagamento.sequencial,
			condicaoPagamento: { id: pagamento.condicaoPagamento.value },
			quantidadeParcelas: pagamento.quantidadeParcelas ? pagamento.quantidadeParcelas.value : null,
			categorias: converterPagamentoCategoriasParaApi(pagamento.categorias),
			formaPagamento: pagamento.formaPagamento ? { id: pagamento.formaPagamento.value } : null,
			conta: pagamento.conta ? { id: pagamento.conta.value } : null,
			valor: pagamento.valor,
			parcelas: converterPagamentosParcelasParaApi(pagamento.parcelas),
		}));
	return pagamentosApi;
}

function converterPagamentosParcelasParaApi(parcelas) {
	const pagamentoParcelasApi = parcelas.map((parcela) => ({
		sequencial: parcela.sequencial,
		vencimento: parcela.vencimento,
		formaPagamento: parcela.formaPagamento ? { id: parcela.formaPagamento.value } : null,
		conta: parcela.conta ? { id: parcela.conta.value } : null,
		valor: parcela.valor,
		descricao: parcela.descricao,
	}));
	return pagamentoParcelasApi;
}

function converterItensParaApi(itens) {
	const itensApi = itens
		.filter((item) => item.produto)
		.map((item) => ({
				...item,
				item: item.item,
				produto: {
					id: item.produto.value,
					tipo: item.produto.registro.tipo,
					controlarEstoque: item.produto.registro.controlarEstoque,
				},
				unidadeMedida: { id: item.unidadeMedida?.value },
				valor: item.valor ?? 0,
				valorOriginal: item.valorOriginal ?? 0,
				comissaoPercentual: item.comissaoPercentual ?? 0,
				comissaoValor: item.comissaoValor ?? 0,
				descontoMaximoPercentual: item.descontoMaximoPercentual ?? 0,
				descontoMaximoVendedor: item.descontoMaximoVendedor ?? 0,
				descontoMaximoTabela: item.descontoMaximoTabela ?? 0,
				percentualAdicionalTabelaPreco: item.adicionalTabelaPreco?.percentual,
				tipoPercentualAdicionalTabelaPreco: item.adicionalTabelaPreco?.tipo,
				completamenteDevolvido: item.completamenteDevolvido,
				cfop: { id: item.cfop?.value },
				operacaoFiscal: { id: item.operacaoFiscal?.value },
				ncm: item.ncm ? { id: item.ncm.value } : null,
				cest: item.cest ? { id: item.cest.value } : null,
				codigoBarras: item.codigoBarras,
				codigoBeneficiario: item.codigoBeneficiario ?? null,
				xped: item.numeroPedidoCompra,
				nitemPed: item.itemPedidoCompra,
				utilizouTabelaPromocional: item.utilizouTabelaPromocional,
				icms: {
					cstCsosn: { id: item.tributos.icms.cstCsosn?.value } ?? null,
					origem: item.tributos.icms.origemProduto,
					aliquotaCreditoSimplesNacional: item.tributos.icms.percentualCreditoSimplesNacional ?? 0,
					valorCreditoSimplesNacional: item.tributos.icms.valorCreditoSimplesNacional ?? 0,
					percentualBaseCalculo: item.tributos.icms.percentualBaseIcms ?? 0,
					percentualReducaoBaseCalculo: item.tributos.icms.percentualReducaoBaseIcms ?? 0,
					modalidadeCalculo: item.tributos.icms.modalidadeCalculo,
					valorBaseCalculo: item.tributos.icms.baseIcms ?? 0,
					aliquota: item.tributos.icms.percentualIcms ?? 0,
					valor: item.tributos.icms.valorIcms ?? 0,
					valorSubstituto: item.tributos.icms.valorIcmsSubstituto ?? 0,
					modalidadeCalculoSt: item.tributos.icms.modalidadeCalculoSt,
					percentualMva: item.tributos.icms.percentualMva ?? 0,
					valorPauta: item.tributos.icms.valorPauta ?? 0,
					percentualBaseCalculoSt: item.tributos.icms.percentualBaseIcmsSt ?? 0,
					percentualReducaoBaseCalculoSt: item.tributos.icms.percentualReducaoBaseIcmsSt ?? 0,
					valorBaseCalculoSt: item.tributos.icms.baseIcmsSt ?? 0,
					aliquotaSt: item.tributos.icms.percentualIcmsSt ?? 0,
					valorSt: item.tributos.icms.valorIcmsSt ?? 0,
					percentualBaseCalculoStRetido: item.tributos.icms.percentualBaseIcmsStRetido ?? 0,
					aliquotaStRetido: item.tributos.icms.percentualIcmsStRetido ?? 0,
					valorBaseCalculoStRetido: item.tributos.icms.baseRetidoSt ?? 0,
					valorStRetido: item.tributos.icms.valorRetidoSt ?? 0,
					percentualDiferido: item.tributos.icms.percentualDiferimento ?? 0,
					valorDiferido: item.tributos.icms.valorDiferimento ?? 0,
					valorBaseCalculoFcPobreza: item.tributos.icms.baseFundoCombatePobreza ?? 0,
					percentualFcPobreza: item.tributos.icms.percentualFundoCombatePobreza ?? 0,
					valorFcPobreza: item.tributos.icms.valorFundoCombatePobreza ?? 0,
					quantidadeBaseMonofasicoRetido: item.tributos.icms.quantidadeBaseIcmsMonofasicoRetido ?? 0,
					percentualMonofasicoRetido: item.tributos.icms.percentualIcmsMonofasicoRetido ?? 0,
					valorMonofasicoRetido: item.tributos.icms.valorIcmsMonofasicoRetido ?? 0,
				},
				ipi: {
					cst: { id: item.tributos.ipi.cst?.value } ?? null,
					enquadramento: { id: item.tributos.ipi.enquadramentoIpi?.value },
					percentualBaseCalculo: item.tributos.ipi.percentualBaseIpi ?? 0,
					valorBaseCalculo: item.tributos.ipi.baseIpi ?? 0,
					aliquota: item.tributos.ipi.percentualIpi ?? 0,
					valor: item.tributos.ipi.valorIpi ?? 0,
				},
				pis: {
					cst: { id: item.tributos.pis.cst?.value } ?? null,
					percentualBaseCalculo: item.tributos.pis.percentualBasePis ?? 0,
					valorBaseCalculo: item.tributos.pis.basePis ?? 0,
					aliquota: item.tributos.pis.percentualPis ?? 0,
					valor: item.tributos.pis.valorPis ?? 0,
					percentualBaseCalculoSt: item.tributos.pis.percentualBasePisSt ?? 0,
					valorBaseCalculoSt: item.tributos.pis.basePisSt ?? 0,
					aliquotaSt: item.tributos.pis.percentualPisSt ?? 0,
					valorSt: item.tributos.pis.valorPisSt ?? 0,
				},
				cofins: {
					cst: { id: item.tributos.cofins.cst?.value } ?? null,
					percentualBaseCalculo: item.tributos.cofins.percentualBaseCofins ?? 0,
					valorBaseCalculo: item.tributos.cofins.baseCofins ?? 0,
					aliquota: item.tributos.cofins.percentualCofins ?? 0,
					valor: item.tributos.cofins.valorCofins ?? 0,
					percentualBaseCalculoSt: item.tributos.cofins.percentualBaseCofinsSt ?? 0,
					valorBaseCalculoSt: item.tributos.cofins.baseCofinsSt ?? 0,
					aliquotaSt: item.tributos.cofins.percentualCofinsSt ?? 0,
					valorSt: item.tributos.cofins.valorCofinsSt ?? 0,
				},
			}));
	return itensApi;
}

function converterParaGerarVenda(values) {
	return {
		id: null,
		orcamentoId: values.id,
		numero: null,
		status: values.status,
		situacaoDevolucao: SITUACAO_DEVOLUCAO.NAO_DEVOLVIDO,
		tipo: Tipo.PEDIDO_VENDA,
		pessoa: values.pessoa ?? null,
		dataEmissao: formatISO(new Date()),
		validade: values.validade ?? null,
		previsaoEntrega: formatISO(addMonths(new Date(), 1)),
		operacaoFiscal: values.operacaoFiscal ?? null,
		tipoDesconto: values.tipoDesconto === 'NAO_APLICADO' ? 'VALOR' : values.tipoDesconto,

		produtos: converterProdutosParaGerarVenda(values.produtos),

		totalizadores: {
			totalProdutos: values.totalizadores?.totalProdutos ?? null,
			totalFrete: values.totalizadores?.totalFrete ?? null,
			totalSeguro: values.totalizadores?.totalSeguro ?? 0,
			totalTributos: values.totalizadores?.totalTributos ?? 0,
			totalAcessorias: values.totalizadores?.totalAcessorias ?? 0,
			percentualDesconto:
				values.totalizadores?.tipoDesconto === 'PERCENTUAL' ? values.totalizadores?.percentualDesconto : 0,
			totalDesconto: values.totalizadores?.tipoDesconto === 'VALOR' ? values.totalizadores?.totalDesconto : 0,
			totalLiquido: values.totalizadores?.totalLiquido ?? 0,
		},
		pagamentos: values.pagamentos,
		informacoesComplementares: {
			vendedor: values.informacoesComplementares.vendedor ?? null,
			tabelaPreco: values.informacoesComplementares.tabelaPreco ?? null,
			informacaoComplementar: values.informacoesComplementares.informacaoComplementar ?? null,
			observacaoInterna: values.informacoesComplementares.observacaoInterna ?? null,
			setor: values.informacoesComplementares.setor ?? null,
		},
		historico: values.historico ?? null,
		motivoCancelamento: values.motivoCancelamento ?? null,
		validacaoRecarregaProdutoPeloCliente: false,
	};
}

function converterProdutosParaGerarVenda(values) {
	const itensFormulario = values
		.filter((item) => item.produto)
		.map((item) => ({
				id: item.id,
				produto: item.produto,
				item: item.item,
				descricao: item.descricao,

				cfop: item.cfop ?? null,
				operacaoFiscal: item.operacaoFiscal ?? null,
				ncm: item.ncm ?? null,
				unidadeMedida: item.unidadeMedida ?? null,
				cest: item.cest ?? null,
				codigoBarras: item.codigoBarras ?? null,
				codigoBeneficiario: item.codigoBeneficiario ?? null,
				numeroPedidoCompra: item.numeroPedidoComrpa ?? null,
				itemPedidoCompra: item.itemPedidoCompra ?? null,
				utilizouTabelaPromocional: item.utilizouTabelaPromocional ?? false,
				completamenteDevolvido: false,

				quantidade: item.quantidade,
				desconto: item.desconto ?? 0,
				valor: item.valor,
				valorOriginal: item.valorOriginal,

				descontoMaximoPercentual: item.descontoMaximoPercentual ?? 0,
				descontoMaximoVendedor: item.descontoMaximoVendedor ?? 0,
				descontoMaximoTabela: item.descontoMaximoTabela ?? 0,
				comissaoValor: item.comissaoValor ?? 0,
				comissaoPercentual: item.comissaoPercentual ?? 0,
				adicionalTabelaPreco: item.adicionalTabelaPreco,

				acessorias: item.acessorias ? item.acessorias : 0,
				seguro: item.seguro ? item.seguro : 0,
				frete: item.frete ? item.frete : 0,

				tributos: {
					icms: {
						cstCsosn: item.tributos?.icms?.cstCsosn ?? null,
						origemProduto: item.tributos?.icms?.origemProduto ?? null,
						percentualCreditoSimplesNacional: item.tributos?.icms?.percentualCreditoSimplesNacional,
						valorCreditoSimplesNacional: item.tributos?.icms?.valorCreditoSimplesNacional,
						percentualBaseIcms: item.tributos?.icms?.percentualBaseIcms,
						percentualReducaoBaseIcms: item.tributos?.icms?.percentualReducaoBaseIcms,
						modalidadeCalculo: item.tributos?.icms?.modalidadeCalculo,
						baseIcms: item.tributos?.icms?.baseIcms,
						percentualIcms: item.tributos?.icms?.percentualIcms,
						valorIcms: item.tributos?.icms?.valorIcms,
						valorIcmsSubstituto: item.tributos?.icms?.valorIcmsSubstituto,
						modalidadeCalculoSt: item.tributos?.icms?.modalidadeCalculoSt,
						percentualMva: item.tributos?.icms?.percentualMva,
						valorPauta: item.tributos?.icms?.valorPauta,
						percentualBaseIcmsSt: item.tributos?.icms?.percentualBaseIcmsSt,
						percentualReducaoBaseIcmsSt: item.tributos?.icms?.percentualReducaoBaseIcmsSt,
						baseIcmsSt: item.tributos?.icms?.baseIcmsSt,
						percentualIcmsSt: item.tributos?.icms?.percentualIcmsSt,
						valorIcmsSt: item.tributos?.icms?.valorIcmsSt,
						baseRetidoSt: item.tributos?.icms?.baseRetidoSt,
						ValorRetido: item.tributos?.icms?.ValorRetido,
						valorDiferimento: item.tributos?.icms?.valorDiferimento,
						baseFundoCombatePobreza: item.tributos?.icms?.baseFundoCombatePobreza,
						percentualFundoCombatePobreza: item.tributos?.icms?.percentualFundoCombatePobreza,
						valorFundoCombatePobreza: item.tributos?.icms?.valorFundoCombatePobreza,
					},
					ipi: {
						enquadramentoIpi: item.tributos?.ipi?.enquadramentoIpi ?? null,
						cst: item.tributos?.ipi?.cst ?? null,
						percentualBaseIpi: item.tributos?.ipi?.percentualBaseIpi,
						baseIpi: item.tributos?.ipi?.baseIpi,
						percentualIpi: item.tributos?.ipi?.percentualIpi,
						valorIpi: item.tributos?.ipi?.valorIpi,
					},
					pis: {
						cst: item.tributos?.pis?.cst ?? null,
						percentualBasePis: item.tributos?.pis?.percentualBasePis,
						basePis: item.tributos?.pis?.basePis,
						percentualPis: item.tributos?.pis?.percentualPis,
						valorPis: item.tributos?.pis?.valorPis,
						percentualBasePisSt: item.tributos?.pis?.percentualBasePisSt,
						basePisSt: item.tributos?.pis?.basePisSt,
						percentualPisSt: item.tributos?.pis?.percentualPisSt,
						valorPisSt: item.tributos?.pis?.valorPisSt,
					},
					cofins: {
						cst: item.tributos?.cofins?.cst ?? null,
						percentualBaseCofins: item.tributos?.cofins?.percentualBaseCofins,
						baseCofins: item.tributos?.cofins?.baseCofins,
						percentualCofins: item.tributos?.cofins?.percentualCofins,
						valorCofins: item.tributos?.cofins?.valorCofins,
						percentualBaseCofinsSt: item.tributos?.cofins?.percentualBaseCofinsSt,
						baseCofinsSt: item.tributos?.cofins?.baseCofinsSt,
						percentualCofinsSt: item.tributos?.cofins?.percentualCofinsSt,
						valorCofinsSt: item.tributos?.cofins?.valorCofinsSt,
					},
				},

				subtotal: item.subtotal ?? 0,
			}));
	return itensFormulario;
}

function converterParaDuplicar(values) {
	return {
		...values,
		id: null,
		status: Status.PENDENTE,
		situacaoDevolucao: SITUACAO_DEVOLUCAO.NAO_DEVOLVIDO,
		criadoEm: null,
		dataEmissao: formatISO(new Date()),
		previsaoEntrega: formatISO(addMonths(new Date(), 1)),
		produtos: values.produtos?.map((produto) => ({
				...produto,
				completamenteDevolvido: false,
			})),
		parcelas: values.parcelas?.map((parcela) => ({
				...parcela,
				id: null,
				status: null,
				valorAReceber: parcela.valor,
			})),
		pagamento: {
			...values.pagamento,
			parcelas: [],
		},
		nfe: null,
		nfce: null,
		motivoCancelamento: null,
	};
}

function converterSelectParaFormulario(selectApi) {
	if (selectApi) {
		return {
			label: montarLabelSelect(selectApi),
			value: selectApi.id,
			registro: selectApi,
		};
	}
	return null;
}
function montarLabelSelect(select) {
	let texto = '';
	if (select.codigo) {
		texto = `${select.codigo  } - ${  select.descricao ? select.descricao : select.nome}`;
	} else if (select.unidade) {
		texto = `${select.unidade  } - ${  select.descricao ? select.descricao : select.nome}`;
	} else {
		texto = select.descricao ? select.descricao : select.nome;
	}
	return texto;
}

function converterProdutosDevolucaoParaForm(produtos) {
	if (produtos?.length > 0) {
		return produtos.reduce((newArrayProdutos, produto) => {
			if (!produto.completamenteDevolvido) {
				newArrayProdutos.push({
					id: produto.id,
					item: produto.item,
					produto: produto.produto?.label,
					quantidade: produto.quantidade,
					valor: produto.subtotal / produto.quantidade,
					subtotal: produto.subtotal,
				});
			}
			return newArrayProdutos;
		}, []);
	}

	return [];
}

function converterProdutosDevolucaoParaApi(produtos) {
	if (produtos?.length > 0) {
		return produtos?.map((produto) => ({
				id: null,
				vendaItemId: produto.id,
				quantidade: produto.quantidade,
				valor: produto.valor,
				subtotal: produto.subtotal,
				data: null,
			}));
	}

	return [];
}

export {
	converterVendaParaFormulario,
	converterVendaParaApi,
	converterParaGerarVenda,
	converterProdutosParaGerarVenda,
	converterParaDuplicar,
	converterProdutosDevolucaoParaForm,
	converterProdutosDevolucaoParaApi,
};
