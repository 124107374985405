import { copiarObjeto, permissoes, recursos, usuarioPossuiPermissao } from 'Common';
import {
	Button,
	ButtonCancelar,
	ButtonExcluir,
	ButtonNovo,
	ButtonSalvar,
	confirm,
	estadosBotaoCancelar,
	estadosBotaoNovo,
	If,
	NenhumRegistroEncontrado,
} from 'components';
import { FaPrint } from 'react-icons/fa';
import { useFormikContext } from 'formik';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useRef, useState } from 'react';
import { IoDocumentTextOutline } from 'react-icons/io5';
import {
	asyncCreateContaReceber,
	asyncDeleteContaReceber,
	asyncGetImprimirPromissoria,
	asyncUpdateContaReceber,
	buscarTitulosNovosOuAntigosApi,
} from 'views/financas/ContasReceber/Requests';
import montarMensagemExclusaoTitulos from 'views/financas/Util/functions';
import { CONTA_RECEBER_STATUS } from 'views/financas/components/ModalReparcelamentoTitulos/Utils/constantes';
import { INITIAL_VALUES } from '../../Util/constantes';
import { converterContaReceberParaApi } from '../../Util/contaReceberConverter';
import {
	exibirConfirmacaoMesmoNumeroDocumento,
	jaExisteContaReceberComMesmoNumeroDocumentoOrigem,
	saoIguais,
} from '../../Util/util';


function ActionButtons({
	informacoesPermissoes,
	isModal,
	isMobile,
	onHide,
	setExibirModalExclusao,
	filialConectadaId,
	onNovoClick,
	history,
	setRegistroSelecionado,
	atribuirValoresIniciais,
	setExibirModalConfirmacaoEdicao,
}) {
	const { values, dirty, resetForm, initialValues, validateForm, handleSubmit } = useFormikContext();

	const [podeVisualizarVenda] = useState(usuarioPossuiPermissao(recursos.VENDAS_PEDIDOS, permissoes.VISUALIZAR));
	const [podeVisualizarNFe] = useState(usuarioPossuiPermissao(recursos.VENDAS_NOTAS, permissoes.VISUALIZAR));
	const [podeVisualizarNFCe] = useState(
		usuarioPossuiPermissao(recursos.VENDAS_NOTAS_CONSUMIDOR, permissoes.VISUALIZAR)
	);
	const [podeVisualizarOrdem] = useState(
		usuarioPossuiPermissao(recursos.SERVICOS_ORDEM_SERVICO, permissoes.VISUALIZAR)
	);
	const [podeVisualizarNFSe] = useState(usuarioPossuiPermissao(recursos.SERVICOS_NFSE, permissoes.VISUALIZAR));
	const [podeVisualizarCTe] = useState(usuarioPossuiPermissao(recursos.TRANSPORTE_CTE, permissoes.VISUALIZAR));
	const [titulos, setTitulos] = useState([]);

	const isFromFilialConectada = values.filialId === filialConectadaId;
	const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;
	const onClickNovo = dirty ? (e) => salvar(e, novo) : novo;
	const panelDetalhes = useRef(null);

	function excluir() {
		const { id, itemRepeticao, status, origem } = values;
		if (itemRepeticao) {
			setExibirModalExclusao(true);
		} else {
			confirm('Confirmação', montarMensagemExclusaoTitulos(status, origem), async () => {
				await asyncDeleteContaReceber(id, () => onHide());
			});
		}
	}

	function cancelar() {
		if (dirty) {
			resetForm({ values: initialValues });
		} else {
			onHide();
		}
	}

	function novo() {
		onNovoClick && onNovoClick();
		resetForm({ values: copiarObjeto(INITIAL_VALUES) });
		atribuirValoresIniciais();
	}

	async function salvar(e, novoOnSuccess) {
		const currentErrors = await validateForm();

		if (Object.keys(currentErrors).length === 0) {
			handleSubmit();
			if (values) {
				if (
					values.numeroDocumento &&
					values.numeroDocumento !== initialValues.numeroDocumento &&
					(await jaExisteContaReceberComMesmoNumeroDocumentoOrigem(values.id, values.numeroDocumento))
				) {
					exibirConfirmacaoMesmoNumeroDocumento(() => {
						salvarOuAtualizar(values, novoOnSuccess);
					});
				} else {
					salvarOuAtualizar(values, novoOnSuccess);
				}
			}
		}
	}

	async function salvarOuAtualizar(values, novoOnSuccess) {
		if (values.id) {
			if (values.itemRepeticao && !alterouApenasRepeticoes()) {
				setExibirModalConfirmacaoEdicao(true);
			} else {
				await asyncUpdateContaReceber(converterContaReceberParaApi(values), () => {
					if (novoOnSuccess) {
						novoOnSuccess();
					} else {
						resetForm({ values: values });
						onHide(false);
					}
				});
			}
		} else {
			await asyncCreateContaReceber(converterContaReceberParaApi(values), () => {
				if (novoOnSuccess) {
					novoOnSuccess();
				} else {
					resetForm({ values: values });
					onHide(false);
				}
			});
		}
	}

	async function buscarTitulosNovosOuAntigos(idReparcelamento, tipo) {
		buscarTitulosNovosOuAntigosApi(idReparcelamento, tipo, (response) => {
			setTitulos(response.data);
		});
	}

	function handleOverlayPanel(e, tipo) {
		setTimeout(() => {
			panelDetalhes.current.toggle(e);
		}, 100);

		if (titulos.length <= 0 || (values.reparcelamentoOrigem && values.reparcelamentoDestino)) {
			if (tipo === 'DESTINO') {
				buscarTitulosNovosOuAntigos(values.reparcelamentoOrigem.id, 'ORIGEM');
			} else if (tipo === 'ORIGEM') {
				buscarTitulosNovosOuAntigos(values.reparcelamentoDestino.id, 'DESTINO');
			}
		}
	}

	function alterouApenasRepeticoes() {
		const valuesLocal = { ...values, recebimentos: [] };
		const initialValuesLocal = { ...initialValues, recebimentos: [] };

		return saoIguais(valuesLocal, initialValuesLocal);
	}

	function desabilitarExcluir() {
		const isOrigemVenda = values.vendaId || values.origem === 'VENDA';
		const isBoletoGerado = !!values.boleto && !['FALHA', 'REJEITADO'].includes(values.boleto?.situacao);
		const isPixGerado = !!values.pix;
		const isTituloReparcelado = values.status === CONTA_RECEBER_STATUS.REPARCELADA;

		if (isOrigemVenda || isBoletoGerado || isPixGerado || isTituloReparcelado) {
			return true;
		}
		return false;
	}

	function renderIdentificador(value) {
		return <p>{value.identificador}</p>;
	}

	function renderEmissao(value) {
		return <p>{value.emissao}</p>;
	}

	function renderValor(value) {
		return <p>{value.valor}</p>;
	}

	function handleSelectTitulo(e) {
		setRegistroSelecionado(e.value);
		setTitulos([]);

		setTimeout(() => {
			panelDetalhes.current.toggle(e);
		}, 100);
	}

	async function imprimirPromissoria(contaReceber) {
			await asyncGetImprimirPromissoria(contaReceber, async ({ data: arquivo }) => {
				const arquivoPDF = new Blob([arquivo], { type: 'application/pdf' });
				const arquivoURL = URL.createObjectURL(arquivoPDF);
				const promissoria = window.open(arquivoURL);
				if (promissoria) {
					promissoria.onload = function onLoadPromissoria() {
						setTimeout(() => {
							promissoria.document.title = 'Nota promissória';
						}, 250);
					};
				}
			});
		}

	return (
		<>
			<ButtonCancelar
				{...informacoesPermissoes}
				hidden={isModal && values.id && !dirty}
				estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
				onClick={() => cancelar()}
			/>
			<ButtonSalvar {...informacoesPermissoes} disabled={!dirty} onClick={() => salvar()} />
			<ButtonNovo
				onClick={() => onClickNovo()}
				hidden={!dirty && !values.id}
				estadoBotao={estadoBotaoNovo}
				{...informacoesPermissoes}
			/>
			<ButtonExcluir
				hidden={!values.id || isModal}
				{...informacoesPermissoes}
				disabled={desabilitarExcluir()}
				onClick={() => excluir()}
				title={values.vendaId ? 'Não é possível excluir uma conta a receber de uma venda' : 'Excluir'}
			/>
			<If test={values.vendaId && podeVisualizarVenda && isFromFilialConectada}>
				<Button
					className="p-button-success"
					style={{ marginTop: '5px' }}
					label={!isMobile ? 'Visualizar pedido' : 'Pedido'}
					icon="fa fa-handshake-o"
					onClick={() => history.push(`/pedido_venda/cadastro/${values.vendaId}`)}
				/>
			</If>
			<If test={values.nfeId && podeVisualizarNFe && isFromFilialConectada}>
				<Button
					className="p-button-success"
					style={{ marginTop: '5px' }}
					label={!isMobile ? 'Visualizar NF-e' : 'NF-e'}
					icon="fa fa-handshake-o"
					onClick={() => history.push(`/nfes/cadastro/${values.nfeId}`)}
				/>
			</If>
			<If test={values.nfceId && podeVisualizarNFCe && isFromFilialConectada}>
				<Button
					className="p-button-success"
					style={{ marginTop: '5px' }}
					label={!isMobile ? 'Visualizar NFC-e' : 'NFC-e'}
					icon="fa fa-handshake-o"
					onClick={() => history.push(`/nfces/cadastro/${values.nfceId}`)}
				/>
			</If>
			<If test={values.ordemId && podeVisualizarOrdem && isFromFilialConectada}>
				<Button
					className="p-button-success"
					style={{ marginTop: '5px' }}
					label={!isMobile ? 'Visualizar ordem' : 'Ordem'}
					icon="fa fa-handshake-o"
					onClick={() => history.push(`/ordem_servico/cadastro/${values.ordemId}`)}
				/>
			</If>
			<If test={values.nfseId && podeVisualizarNFSe && isFromFilialConectada}>
				<Button
					className="p-button-success"
					style={{ marginTop: '5px' }}
					label={!isMobile ? 'Visualizar NFS-e' : 'NFS-e'}
					icon="fa fa-handshake-o"
					onClick={() => history.push(`/nfses/cadastro/${values.nfseId}`)}
				/>
			</If>
			<If test={values.cteId && podeVisualizarCTe && isFromFilialConectada}>
				<Button
					className="p-button-success"
					style={{ marginTop: '5px' }}
					label={!isMobile ? 'Visualizar CT-e' : 'CT-e'}
					icon="fa fa-handshake-o"
					onClick={() => history.push(`/ctes/cadastro/${values.cteId}`)}
				/>
			</If>
			<If test={values.reparcelamentoOrigem}>
				<Button
					className="p-button-info"
					style={{ marginTop: '5px' }}
					label="Acessar títulos novos"
					icon={<IoDocumentTextOutline size={20} style={{ marginRight: '5px' }} />}
					onClick={(e) => handleOverlayPanel(e, 'DESTINO')}
				/>
			</If>
			<If test={values.reparcelamentoDestino}>
				<Button
					className="p-button-info"
					style={{ marginTop: '5px' }}
					label="Acessar títulos antigos"
					icon={<IoDocumentTextOutline size={20} style={{ marginRight: '5px' }} />}
					onClick={(e) => handleOverlayPanel(e, 'ORIGEM')}
				/>
			</If>
			<If test={values.id && !(values.status === CONTA_RECEBER_STATUS.RECEBIDA || values.status === CONTA_RECEBER_STATUS.REPARCELADA) }>
				<Button
					className="p-button-primary"
					style={{ marginTop: '5px' }}
					label={!isMobile ? 'Imprimir promissória' : 'Promissória'}
					icon={<FaPrint size={20} style={{ paddingRight: 5 }} />}
					onClick={() => imprimirPromissoria(`'${values.id}'`)}
				/>
			</If>
			<OverlayPanel style={isMobile ? { right: '12px' } : null} ref={panelDetalhes} showCloseIcon>
				<DataTable
					className="table"
					responsive
					value={titulos}
					emptyMessage={<NenhumRegistroEncontrado />}
					selectionMode="single"
					onSelectionChange={(e) => handleSelectTitulo(e)}
				>
					<Column header="Identificador" field="identificador" body={renderIdentificador} />
					<Column header="Emissão" field="emissao" body={renderEmissao} />
					<Column header="Valor" field="valor" body={renderValor} />
				</DataTable>
			</OverlayPanel>
		</>
	);
}

export { ActionButtons };
