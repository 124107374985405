import { estadosCadastro, services } from 'Common';
import { Checkbox, Dropdown, Grid, InputDouble, InputMoney, SingleSelectCategoria } from 'components';
import { InputDateWithAddingDays } from 'components/input/InputDateWithAddingDays';
import { Field, useFormikContext } from 'formik';
import {
	CONDICAO_PAGAMENTO_COMPENSACAO_TIPO,
	CONDICAO_PAGAMENTO_COMPENSACAO_TIPO_OPTIONS,
	CONDICAO_PAGAMENTO_TAXA_TIPO,
	CONDICAO_PAGAMENTO_TAXA_TIPO_OPTIONS,
	FORMA_PAGAMENTO_FISCAL,
	TIPO_CONTA_RECEBER,
} from '../../Util/constantes';

function FormCompensacaoAutomaticaETaxa({ informacoesPermissoes, isReparcelada }) {
	const { values, errors, setFieldValue } = useFormikContext();

	const isBoleto = values.formaPagamento?.registro?.formaPagamentoFiscal === FORMA_PAGAMENTO_FISCAL.BOLETO_BANCARIO;
	const isContaParcelada = values.tipo === TIPO_CONTA_RECEBER.VALOR_PARCELADO;

	function onChangeTipoTaxa(value) {
		setFieldValue('tipoTaxa', value);
	}
	function handleAdicionarAliquotaTaxa(valorAliquota) {
		setFieldValue('taxaAliquota', valorAliquota);
	}

	function onBlurAdicionarAliquotaTaxa() {
		let { valor } = values;

		if (isContaParcelada && informacoesPermissoes.estadoCadastro === estadosCadastro.INCLUSAO) {
			valor /= values.quantidadeRepeticoes;
		}

		setFieldValue('taxaValor', (valor * values.taxaAliquota) / 100);
	}

	function handleAdicionarValorTaxa(valorTaxa) {
		setFieldValue('taxaValor', valorTaxa);
	}

	function onBlurAdicionarValorTaxa() {
		setFieldValue('taxaAliquota', (values.taxaValor * 100) / values.valor);
	}

	return (
		<Grid>
			<Field
				sm="12"
				md="12"
				lg="4"
				xl="4"
				component={Checkbox}
				colStyle={{
					display: 'flex',
					alignItems: 'flex-end',
					marginBottom: '7px',
					paddingBottom: '8px',
				}}
				label="Compensação automática"
				name="compensacaoAutomatica"
				checked={values.compensacaoAutomatica}
				onChange={() => setFieldValue('compensacaoAutomatica', !values.compensacaoAutomatica)}
				showClear={false}
				disabled={isBoleto || isReparcelada}
				{...informacoesPermissoes}
			/>

			<Field
				sm="12"
				md="6"
				lg="4"
				xl="4"
				obrigatorio
				component={Dropdown}
				label="Tipo de compensação"
				name="tipoCompensacao"
				value={values.tipoCompensacao}
				errors={errors?.tipoCompensacao}
				onChange={(event) => setFieldValue('tipoCompensacao', event?.value)}
				showClear={false}
				options={CONDICAO_PAGAMENTO_COMPENSACAO_TIPO_OPTIONS}
				disabled={!values.compensacaoAutomatica || isBoleto || isReparcelada}
				touched
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="6"
				lg="4"
				xl="4"
				obrigatorio
				component={InputDateWithAddingDays}
				label="Data compensação"
				name="dataCompensacao"
				value={values.dataCompensacao}
				dataBase={
					values.tipoCompensacao === CONDICAO_PAGAMENTO_COMPENSACAO_TIPO.VENCIMENTO ? values.vencimento : values.emissao
				}
				errors={errors?.dataCompensacao}
				onChange={(event) => setFieldValue('dataCompensacao', event.target.value)}
				disabled={!values.compensacaoAutomatica || isBoleto || isReparcelada}
				touched
			/>

			<Field
				sm="12"
				md="12"
				lg={isContaParcelada ? '4' : '5'}
				xl={isContaParcelada ? '4' : '5'}
				obrigatorio
				name="taxaCategoria"
				label="Categoria taxa"
				component={SingleSelectCategoria}
				style={{ flexDirection: 'row', width: '100%' }}
				value={values.taxaCategoria}
				onChange={(event) => setFieldValue('taxaCategoria', event)}
				url={`${services.GESTOR}/v1/contas_receber/relacoes/categorias/despesas`}
				disabled={isBoleto || isReparcelada}
				errors={errors?.taxaCategoria}
				touched={!!errors.taxaCategoria}
				useFormErrors={false}
				useFormTouched={false}
			/>
			<Field
				sm="12"
				md="6"
				lg="3"
				xl="3"
				obrigatorio
				component={Dropdown}
				label="Tipo de taxa aplicada"
				name="tipoTaxa"
				value={values.tipoTaxa}
				errors={errors?.tipoTaxa}
				touched={!!errors?.tipoTaxa}
				useFormErrors={false}
				useFormTouched={false}
				onChange={(event) => onChangeTipoTaxa(event?.value)}
				showClear={false}
				options={CONDICAO_PAGAMENTO_TAXA_TIPO_OPTIONS}
				disabled={isBoleto || isReparcelada}
				{...informacoesPermissoes}
			/>
			<Field
				sm="12"
				md="6"
				lg="2"
				xl="2"
				obrigatorio
				label="Aliquota taxa"
				name="taxaAliquota"
				component={InputDouble}
				decimalScale={2}
				size={13}
				allowNegative={false}
				value={values.taxaAliquota}
				onChange={(e) => handleAdicionarAliquotaTaxa(e.target.value)}
				onBlur={onBlurAdicionarAliquotaTaxa}
				errors={errors?.taxaAliquota}
				touched={!!errors?.taxaAliquota}
				useFormErrors={false}
				useFormTouched={false}
				disabled={isBoleto || values.tipoTaxa === CONDICAO_PAGAMENTO_TAXA_TIPO.VALOR || isReparcelada}
				{...informacoesPermissoes}
			/>

			<Field
				sm="12"
				md="6"
				lg={isContaParcelada ? '3' : '2'}
				xl={isContaParcelada ? '3' : '2'}
				obrigatorio
				label={isContaParcelada ? 'Valor taxa da parcela' : 'Valor taxa'}
				name="taxaValor"
				component={InputMoney}
				decimalScale={2}
				size={13}
				allowNegative={false}
				value={values.taxaValor}
				onChange={(e) => handleAdicionarValorTaxa(e.target.value)}
				onBlur={onBlurAdicionarValorTaxa}
				errors={errors?.taxaValor}
				touched={!!errors?.taxaValor}
				useFormErrors={false}
				useFormTouched={false}
				disabled={isBoleto || values.tipoTaxa === CONDICAO_PAGAMENTO_TAXA_TIPO.PERCENTUAL || isReparcelada}
				{...informacoesPermissoes}
			/>
		</Grid>
	);
}

export { FormCompensacaoAutomaticaETaxa };
