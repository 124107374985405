import { useState } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';

import { Button, Checkbox, notify, SingleSelectPastas, ToastTypes } from 'components';

import { atualizarDocumentosParaNovaPasta, limparPastasDocumentos } from 'views/documentos/GestaoDocumento/Requests';
import { getSelectedDocumentIds } from '../../Utils/functions';

function OverlayAlteracaoPastaEmLote({
	documentosSelecionados,
	panelAlteracaoPastaEmLote,
	isMobile,
	atualizarListagem,
}) {
	const [pastaSelecionada, setPastaSelecionada] = useState(null);
	const [checkboxLimparPastasRegistros, setCheckboxLimparPastasRegistros] = useState(false);

	function handleAtualizarDocumentos(e) {
		if (pastaSelecionada) {
			try {
				atualizarDocumentosParaNovaPasta(getSelectedDocumentIds(documentosSelecionados), pastaSelecionada, () => {
					panelAlteracaoPastaEmLote?.current?.hide(e);
					notify('Pastas alteradas com sucesso!', ToastTypes.SUCCESS);
					setPastaSelecionada(null);
					setCheckboxLimparPastasRegistros(null);
					atualizarListagem();
				});
			} catch (error) {
				notify('Erro ao mover documento(s)', ToastTypes.ERROR);
			}
		} else if (checkboxLimparPastasRegistros) {
			try {
				limparPastasDocumentos(getSelectedDocumentIds(documentosSelecionados), () => {
					panelAlteracaoPastaEmLote?.current?.hide(e);
					notify('Pastas alteradas com sucesso!', ToastTypes.SUCCESS);
					setPastaSelecionada(null);
					setCheckboxLimparPastasRegistros(null);
					atualizarListagem();
				});
			} catch (error) {
				notify('Erro ao mover documento(s)', ToastTypes.ERROR);
			}
		}
	}

	return (
		<OverlayPanel
			ref={panelAlteracaoPastaEmLote}
			id="overlayPanel-informacoes-complementares"
			style={{
				width: isMobile ? '90%' : '100%',
				maxWidth: '320px',
				marginLeft: isMobile ? '1rem' : null,
				marginTop: '0px',
			}}
			onHide={() => {
				setPastaSelecionada(null);
			}}
			showCloseIcon
		>
			<div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
				<SingleSelectPastas
					name="pasta"
					label="Pasta"
					value={pastaSelecionada}
					onChange={(selectedNode) => {
						setPastaSelecionada(selectedNode?.value);
					}}
					disabled={checkboxLimparPastasRegistros}
				/>
				<Checkbox
					label="Limpar pasta dos registros"
					inputId="checkboxLimparPastasRegistros"
					title="Marque esta opção para limpar as pastas dos registros selecionados"
					onChange={() => {
						setCheckboxLimparPastasRegistros(!checkboxLimparPastasRegistros);
						setPastaSelecionada(null);
					}}
					checked={checkboxLimparPastasRegistros}
					colStyle={{ padding: '0rem 0.5rem 0.5rem 0.5rem' }}
				/>

				<Button
					label="Salvar"
					onClick={(e) => handleAtualizarDocumentos(e)}
					style={{ width: '95%', margin: '0px 8px' }}
					styleContentButton={{ width: '100%' }}
					disabled={!checkboxLimparPastasRegistros && !pastaSelecionada}
				/>
			</div>
		</OverlayPanel>
	);
}

export { OverlayAlteracaoPastaEmLote };
