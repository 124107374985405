/* eslint-disable no-param-reassign */
import { permissoes, recursos, usuarioPossuiPermissao } from 'Common';
import {
	AutoProgressBar,
	ButtonEditarTable,
	ButtonExcluirTable,
	ButtonNovo,
	ButtonNovoTable,
	confirm,
	Form,
	FormActions,
	FormContent,
	If,
	NenhumRegistroEncontrado,
	notify,
	ToastTypes,
	Tree,
} from 'components';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { confirmarExclusao } from 'views/Util/ExclusaoDeRegistros';
import {
	asyncDeletarPastas,
	asyncGetPastasNivelFilho,
	asyncGetPastasNivelRaiz,
	asyncPutDesvincularDocumentos,
} from './Requests';
import { ModalPastas } from './components/ModalPastas';
import { getProximoNivelPasta, getProximoNivelRaizPasta, updateExpandedKeys, updateNodeIcon } from './Utils/functions';

const styleTreeView = {
	width: '100%',
	border: '0px ',
};

function Pastas({ isModal, isMobile }) {
	const [nodes, setNodes] = useState([]);
	const [selectedKey, setSelectedKey] = useState(null);
	const [visibleModal, setVisibleModal] = useState(false);
	const [selectedNode, setSelectedNode] = useState(null);
	const [headerModal, setHeaderModal] = useState('Nova pasta');
	const [novoNivel, setNovoNivel] = useState('');
	const [codigoNivel, setCodigoNivel] = useState(0);
	const [pastaPai, setPastaPai] = useState('');
	const [pastaEdicao, setPastaEdicao] = useState('');
	const [expandedKeys, setExpandedKeys] = useState({});
	const podeInserir = usuarioPossuiPermissao(recursos.GESTAO_DOCUMENTOS, permissoes.INSERIR);

	useEffect(() => {
		carregarNivelRaiz();
	}, []);

	const nodeTemplate = (node) => {
		let label = node.filhos === 0 ? node.label : <b>{node.label}</b>;

		if (node.url) {
			label = <a href={node.url}>{node.label}</a>;
		}

		return (
			<span
				style={{
					alignItems: 'center',
					justifyContent: 'space-between',
					width: '100%',
					display: 'flex',
					borderBottom: '1px solid #d5d5d5',
				}}
			>
				<span
					style={
						isMobile
							? {
									whiteSpace: 'nowrap',
									overflow: 'hidden',
									maxWidth: '140px',
									textOverflow: 'ellipsis',
								}
							: {}
					}
				>
					{label}
				</span>
				<span style={{ paddingBottom: '3px', paddingTop: '3px' }}>
					<ButtonNovoTable title="Nova subPasta" onClick={() => novoSubnivel(node)} />
					<ButtonEditarTable onClick={() => editarNivel(node)} />
					<ButtonExcluirTable onClick={() => deletarNivel(node)} />
				</span>
			</span>
		);
	};

	async function novoSubnivel(node) {
		if (node) {
			await asyncGetPastasNivelFilho(node.id, (event) => {
				const filhos = event.data.map((register) => ({
					key: register.nivel,
					codigoNivel: register.codigoNivel,
					label: `${register.nivel} - ${register.descricao}`,
					icon: register.filhos === 0 ? 'pi pi-fw pi-file' : 'pi pi-fw pi-inbox',
					vinculados: register.vinculados,
					filhos: register.filhos,
					id: register.id,
					descricao: register.descricao,
					leaf: register.filhos === 0 ? null : false,
					children: [],
					pastaPai: node,
				}));
				node.children = filhos;
			});
			const novo = getProximoNivelPasta(node);
			setHeaderModal('Nova subpasta');
			setCodigoNivel(novo);
			setPastaEdicao(null);
			setNovoNivel(`${node.key}.${novo}`);
			setPastaPai(node);
			onShowModal();
		}
	}

	function editarNivel(node) {
		if (node) {
			setHeaderModal('Editar pasta');
			setPastaEdicao(node);
			setNovoNivel(`${node.key}`);
			setCodigoNivel(node.codigoNivel);
			setPastaPai(node.pastaPai);
			onShowModal();
		}
	}

	async function confirmarLimparDocumentos(node, mensagem, funcao) {
		confirm('Confirmação', mensagem, async () => {
			asyncPutDesvincularDocumentos(node.id, () => {
				funcao();
			});
		});
	}

	async function deletarNivel(node) {
		if (node) {
			if (node.filhos > 0) {
				notify('Pasta/Subpasta possui registros filhos e não pode ser excluído', ToastTypes.ERROR, 5);
				return;
			}
			const excluir = () => {
				asyncDeletarPastas(node.id, () => {
					atualizarNivelPai(node, node.id);
					setSelectedNode(null);
					carregarNivelRaiz(updateExpandedKeys(node, expandedKeys), node);
					setVisibleModal(false);
				});
			};

			if (node.vinculados === 0) {
				confirmarExclusao(excluir);
			} else {
				confirmarLimparDocumentos(
					node,
					'Pasta/Subpasta referenciado em um ou mais documentos e não pode ser excluído. Deseja desvincular o(s) documento(s) e excluir?',
					excluir
				);
			}
		}
	}

	function atualizarNivelPai(node, id) {
		if (node.pastaPai) {
			node.pastaPai.children = node.pastaPai.children.filter((r) => r.id !== id);
			node.pastaPai.filhos = node.pastaPai.children.length;
			if (node.pastaPai.children.length === 0) {
				node.pastaPai.icon = 'pi pi-fw pi-file';
				node.pastaPai.leaf = null;
			}
			const value = [...nodes];
			setNodes(value);
		} else {
			setNodes(nodes.filter((r) => r.id !== id));
		}
	}

	async function carregarNivelRaiz(_expandedKeys = {}, node = null) {
		await asyncGetPastasNivelRaiz((event) => {
			setTimeout(async () => {
				setNodes(
					event.data.map((register) => ({
						key: register.nivel,
						codigoNivel: register.codigoNivel,
						label: `${register.nivel} - ${register.descricao}`,
						icon: 'fa fa-folder-o',
						vinculados: register.vinculados,
						filhos: register.filhos,
						id: register.id,
						descricao: register.descricao,
						leaf: register.filhos === 0 ? null : false,
						children: [],
					}))
				);
				if (Object.keys(_expandedKeys).length !== 0 && node && node.pastaPai) {
					expandNode(node.pastaPai, _expandedKeys);
				} else {
					setExpandedKeys({});
				}
			}, 100);
		});
	}

	async function loadFilhos(nodePai) {
		const node = nodePai;
		node.children = [];
		await asyncGetPastasNivelFilho(node.id, (event) => {
			const filhos = event.data.map((register) => ({
				key: register.nivel,
				codigoNivel: register.codigoNivel,
				label: `${register.nivel} - ${register.descricao}`,
				icon: 'fa fa-folder-o',
				vinculados: register.vinculados,
				filhos: register.filhos,
				id: register.id,
				descricao: register.descricao,
				leaf: register.filhos === 0 ? null : false,
				pastaPai: node,
				children: [],
			}));
			node.children = filhos;
			node.icon = 'fa fa-folder-open-o';
		});
	}

	const loadOnExpand = (event) => {
		setTimeout(async () => {
			await loadFilhos(event.node);
			const value = [...nodes];
			setNodes(value);
		}, 100);
	};

	const expandNode = async (node, _expandedKeys) => {
		if (node?.filhos > 0) {
			await loadFilhos(node);

			if (node?.children.length) {
				node.children.forEach((child) => {
					if (_expandedKeys[child.key]) {
						expandNode(child, _expandedKeys);
					}
				});
			}

			_expandedKeys[node.key] = true;
			setExpandedKeys({ ..._expandedKeys });
		}
		const value = [...nodes];
		setNodes(value);
	};

	function onCollapse(event) {
		removeExpandedKeys(event.node);
	}

	function removeExpandedKeys(node) {
		const keys = { ...expandedKeys };

		if (Object.prototype.hasOwnProperty.call(keys, node.key)) {
			delete keys[node.key];

			const updatedNode = { ...node, icon: 'fa fa-folder-o' };

			if (node.children?.length) {
				updatedNode.children = node.children.map((child) => removeExpandedKeys(child));
			}

			setExpandedKeys(keys);

			const updatedNodes = nodes.map((n) => (n.key === updatedNode.key ? updatedNode : n));
			setNodes(updatedNodes);

			return updatedNode;
		}

		return node;
	}

	function novoNivelRaiz() {
		const novo = getProximoNivelRaizPasta();
		setNovoNivel(novo);
		setCodigoNivel(novo);
		setPastaEdicao(null);
		setPastaPai(null);
		setHeaderModal('Nova pasta');
		onShowModal();
	}

	function onSelect(event) {
		setSelectedNode(event.node);
	}

	function onHideModal() {
		setVisibleModal(false);
	}

	function onShowModal() {
		setVisibleModal(true);
	}

	function inserirPasta(pasta) {
		if (pasta.pastaPai == null) {
			const value = nodes;
			const node = {
				key: String(pasta.nivel),
				codigoNivel: pasta.codigoNivel,
				label: `${pasta.nivel} - ${pasta.descricao}`,
				icon: 'pi pi-fw pi-file',
				vinculados: 0,
				filhos: 0,
				id: pasta.id,
				descricao: pasta.descricao,
				leaf: null,
				children: [],
			};
			value.push(node);
			value.sort((a, b) => {
				if (a.codigoNivel > b.codigoNivel) {
					return 1;
				}
				if (a.codigoNivel < b.codigoNivel) {
					return -1;
				}
				return 0;
			});
			setNodes(value);
			setSelectedNode(node);
			setSelectedKey(node.key);
			setPastaEdicao(null);
			setPastaPai(null);
			setNovoNivel(null);
			setCodigoNivel(null);
		} else {
			const node = {
				key: pasta.nivel,
				codigoNivel: pasta.codigoNivel,
				label: `${pasta.nivel} - ${pasta.descricao}`,
				icon: 'pi pi-fw pi-file',
				vinculados: 0,
				filhos: 0,
				id: pasta.id,
				descricao: pasta.descricao,
				leaf: null,
				pastaPai: selectedNode,
				children: [],
			};
			selectedNode.children.push(node);
			selectedNode.filhos = selectedNode.children.length;
			selectedNode.children.sort((a, b) => {
				if (a.codigoNivel > b.codigoNivel) {
					return 1;
				}
				if (a.codigoNivel < b.codigoNivel) {
					return -1;
				}
				return 0;
			});
			selectedNode.icon = 'pi pi-fw pi-inbox';

			const value = [...nodes];
			setNodes(value);
			setSelectedNode(node);
			setSelectedKey(node.key);
			expandNode(selectedNode, expandedKeys);
			setPastaEdicao(null);
			setPastaPai(null);
			setNovoNivel(null);
			setCodigoNivel(null);
		}
	}

	function editarPasta({ descricao }) {
		selectedNode.label = `${selectedNode.key} -  ${descricao}`;
		selectedNode.descricao = descricao;
		const value = [...nodes];
		setNodes(value);
		setPastaEdicao(null);
		setPastaPai(null);
		setNovoNivel(null);
		setCodigoNivel(null);
	}

	function handleExpand(event) {
		if (event.node.icon === 'fa fa-folder-o') {
			updateNodeIcon(nodes, event.node.key, 'fa fa-folder-open-o');
			setNodes([...nodes]);
		}

		loadOnExpand(event);
	}

	function handleCollapse(event) {
		if (event.node.icon === 'fa fa-folder-open-o') {
			updateNodeIcon(nodes, event.node.key, 'fa fa-folder-o');
			setNodes([...nodes]);
		}

		onCollapse(event);
	}

	return (
		<>
			<Form header="Cadastro de pastas" isModal={false}>
				<If test={isModal}>
					<AutoProgressBar />
				</If>
				<FormActions>
					<ButtonNovo label="Nova pasta" disabled={!podeInserir} onClick={novoNivelRaiz} />
				</FormActions>
				<FormContent>
					<div style={{ display: 'flex', justifyContent: 'center' }} className="p-tree-select">
						<If test={nodes.length}>
							<Tree
								value={nodes}
								selectionMode="single"
								selectionKeys={selectedKey}
								onSelectionChange={(event) => setSelectedKey(event.value)}
								onExpand={handleExpand}
								onCollapse={handleCollapse}
								onSelect={onSelect}
								style={styleTreeView}
								nodeTemplate={nodeTemplate}
								hidden={false}
								expandedKeys={expandedKeys}
								onToggle={(event) => setExpandedKeys(event.value)}
							/>
						</If>
						<If test={!nodes.length}>
							<NenhumRegistroEncontrado />
						</If>
					</div>
				</FormContent>
			</Form>
			<If test={visibleModal}>
				<ModalPastas
					visible={visibleModal}
					onHide={onHideModal}
					header={headerModal}
					novoNivel={novoNivel}
					codigoNivel={codigoNivel}
					pastaPai={pastaPai}
					pastaEdicao={pastaEdicao}
					inserirPasta={inserirPasta}
					editarPasta={editarPasta}
					deletarNivel={deletarNivel}
				/>
			</If>
		</>
	);
}

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
});

export default connect(mapStateToProps)(Pastas);
