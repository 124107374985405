import { gerarUUID } from 'Common';
import { CTE_TOMADOR, CTE_UNIDADE_MEDIDA_OPTIONS } from './constantes';

function converterCTeDocumentoNFesParaForm(data) {
	let nfes = [];
	if (data) {
		data.forEach((nfe) => {
			nfes.push({
				id: nfe.id,
				chaveAcesso: nfe.chaveAcesso,
			});
		});
	}
	return nfes;
}

function converterCTeDocumentoNotasFiscaisParaForm(data) {
	let notasFiscais = [];
	if (data) {
		data.forEach((notaFiscal) => {
			notasFiscais.push({
				id: notaFiscal.id,
				modeloDocumento: notaFiscal.tipoDocumento,
				emissao: notaFiscal.emissao,
				cfop: converterCTeSingleSelectParaForm(
					notaFiscal.cfop,
					`${notaFiscal.cfop?.codigo} - ${notaFiscal.cfop?.descricao}`
				),
				serie: notaFiscal.serie,
				numero: notaFiscal.numero,
				numeroRomaneio: notaFiscal.numeroRomaneio,
				numeroPedido: notaFiscal.numeroPedido,
				valorBaseCalculoIcms: notaFiscal.valorBaseCalculoIcms,
				valorIcms: notaFiscal.valorIcms,
				valorBaseCalculoIcmsSt: notaFiscal.valorBaseCalculoIcmsSt,
				valorIcmsSt: notaFiscal.valorIcmsSt,
				peso: notaFiscal.peso,
				valorTotalProdutos: notaFiscal.totalProdutos,
				valorTotalNotaFiscal: notaFiscal.totalNotaFiscal,
			});
		});
	}
	return notasFiscais;
}

function converterCTeDocumentoOutrosParaForm(data) {
	let outrosDocumentos = [];
	if (data) {
		data.forEach((documento) => {
			outrosDocumentos.push({
				id: documento.id,
				tipoDocumento: documento.tipoDocumento,
				numero: documento.numero,
				valor: documento.valor,
				emissao: documento.emissao,
				descricao: documento.descricao,
			});
		});
	}
	return outrosDocumentos;
}

function converterCTeDocumentoParaForm(data) {
	return {
		id: data.id,
		tipoDocumento: data.tipoDocumento,
		nfes: converterCTeDocumentoNFesParaForm(data.nfes),
		notasFiscais: converterCTeDocumentoNotasFiscaisParaForm(data.notasFiscais),
		outros: converterCTeDocumentoOutrosParaForm(data.outros),
	};
}

function converterCteDocumentoAnteriorCtesParaForm(data) {
	let ctes = [];
	if (data) {
		data.forEach((cte) => {
			ctes.push({
				id: cte.id,
				chaveAcesso: cte.chaveAcesso,
			});
		});
	}
	return ctes;
}

function converterCteDocumentoAnteriorDocumentosPapelParaForm(data) {
	let documentosPapel = [];
	if (data) {
		data.forEach((documento) => {
			documentosPapel.push({
				id: documento.id,
				tipoDocumento: documento.tipoDocumento,
				serie: documento.serie,
				subSerie: documento.subSerie,
				numeroDocumento: documento.numero,
				emissao: documento.emissao,
			});
		});
	}
	return documentosPapel;
}

function converterCTeDocumentoAnteriorParaForm(data) {
	return {
		id: data.id,
		emissor: converterCTeEmissorDocumentoAnteriorParaForm(data.emissorDocumentoAnterior),
		tipoDocumento: data.tipoDocumentoAnterior,
		ctes: converterCteDocumentoAnteriorCtesParaForm(data.ctes),
		documentosPapel: converterCteDocumentoAnteriorDocumentosPapelParaForm(data.outros),
	};
}

function converterCTeSubstituicaoParaForm(data) {
	return {
		chaveAcesso: data.chaveSubstituto || '',
		tomadorInformadoDiferente: data.tomadorDiferenteCteSubstituido,
	};
}

function converterCTeComplementoValoresParaForm(data) {
	if (data) {
		return {
			chaveAcesso: data.chaveComplementoValor,
		};
	}
	return {
		chaveAcesso: '',
	};
}

function converterInformacaoCargaQuantidadeParaForm(data) {
	let quantidades = [];
	if (data) {
		data.forEach((quantidade) => {
			quantidades.push({
				id: quantidade.id,
				unidadeMedida: CTE_UNIDADE_MEDIDA_OPTIONS.filter((unidade) => unidade.value === quantidade.unidadeMedida)[0],
				tipoMedida: quantidade.tipoMedida,
				quantidade: quantidade.quantidade,
			});
		});
	}
	return quantidades;
}

function converterInformacaoCargaParaForm(data) {
	if (data) {
		return {
			produtoPredominante: converterCTeSingleSelectParaForm(
				data.produtoPredominante,
				`${data.produtoPredominante?.codigo} - ${data.produtoPredominante?.nome}`
			),
			outraCaracteristicaCarga: data.outraCaracteristicaCarga,
			valorCarga: data.valorCarga,
			quantidades: converterInformacaoCargaQuantidadeParaForm(data.quantidades),
		};
	}
	return {
		produtoPredominante: '',
		outraCaracteristicaCarga: '',
		valorCarga: 0,
		quantidades: [],
	};
}

function converterCTeImpostosParaForm(data) {
	return {
		cstCsosn: converterCTeSingleSelectParaForm(data.cstCsosn, `${data.cstCsosn?.codigo} - ${data.cstCsosn?.descricao}`),
		percentualBaseCalculoIcms: data.percentualBaseCalculoIcms,
		valorBaseCalculoIcms: data.valorBaseCalculoIcms,
		aliquotaIcms: data.aliquotaIcms,
		valorIcms: data.valorIcms,
	};
}

function converterCTeSingleSelectParaForm(data, label) {
	if (data) {
		return {
			value: data.id ?? null,
			label: label ?? data.nome ?? data.descricao,
			registro: data,
		};
	}
	return null;
}

function converterCTeTotalParaForm(data) {
	return {
		totalFrete: data.valorTotalServico,
		totalCarga: data.valorTotalCarga,
		totalDesconto: data.valorPagamentoDesconto,
		totalLiquido: data.valorPagamentoLiquido,
	};
}

function converterCTeQuantidadeParcelas(data) {
	return { value: data, label: `${data}x` };
}

function converterCTeClienteEmailParaForm(data) {
	let emailFavorito = '';
	if (data?.length > 0) {
		data?.forEach((email) => {
			emailFavorito = email.favorito === true ? `${email.email}` : null;
		});
	}

	if (emailFavorito) {
		return emailFavorito;
	} else if (data?.length > 0) {
		return data[0]?.email;
	}
	return null;
}

function converterCTePagamanetosParcelasParaForm(data, participante) {
	if (data?.length > 0) {
		const parcelasConvertidas = [];
		data.forEach((parcela) => {
			parcelasConvertidas.push({
				id: parcela.id,
				tempKey: gerarUUID(),
				sequencial: parcela.sequencial,
				vencimento: parcela.vencimento,
				formaPagamento: converterCTeSingleSelectParaForm(parcela.formaPagamento),
				conta: converterCTeSingleSelectParaForm(parcela.conta),
				descricao: parcela.descricao,
				valor: parcela.valor,
				temBoleto: parcela.temBoleto,
				temPix: parcela.temPix,
				financeiroIdentificador: parcela.financeiroIdentificador,
				financeiroId: parcela.financeiroId,
				financeiroStatus: parcela.financeiroStatus,
				valorRestante: parcela.valorRestante,
				pessoaNome: participante.nome,
				pessoaCodigo: participante.codigo,
				pessoaEmail: converterCTeClienteEmailParaForm(participante.emails),
			});
		});
		return parcelasConvertidas;
	}
	return [];
}

function getParticipanteParaForm(data) {
	if (CTE_TOMADOR.REMETENTE === data.tipoTomador) {
		return data.remetente?.pessoa;
	} else if (CTE_TOMADOR.DESTINATARIO === data.tipoTomador) {
		return data.destinatario?.pessoa;
	} else if (CTE_TOMADOR.RECEBEDOR === data.tipoTomador) {
		return data.recebedor?.pessoa;
	} else if (CTE_TOMADOR.EXPEDIDOR === data.tipoTomador) {
		return data.expedidor?.pessoa;
	}
	return data.outroParticipante?.pessoa;
}

function converterPagamentoCategoriasParaFormulario(values){
	const categorias = [];
	if (values) {
		values?.forEach(registro =>{
			const cteCategoria = {
				categoria: converterCTeSingleSelectParaForm(registro.categoria),
				valor: registro.valor,
				percentual: registro.percentual,
			};
			categorias.push(cteCategoria);
		});
	}
	return categorias;
}

function converterCTePagamentosParaForm(data, participante) {
	const pagamentosFormulario = data.map((pagamento) => ({
		id: pagamento.id,
		tempKey: gerarUUID(),
		sequencial: pagamento.sequencial,
		condicaoPagamento: converterCTeSingleSelectParaForm(pagamento.condicaoPagamento),
		quantidadeParcelas: converterCTeQuantidadeParcelas(pagamento.quantidadeParcelas),
		categorias: converterPagamentoCategoriasParaFormulario(pagamento.categorias),
		formaPagamento: converterCTeSingleSelectParaForm(pagamento.formaPagamento),
		conta: converterCTeSingleSelectParaForm(pagamento.conta),
		valor: pagamento.valor,
		parcelas: converterCTePagamanetosParcelasParaForm(pagamento.parcelas, participante),
	}));
	return pagamentosFormulario;
}

function converterCTeInformacaoComplementarParaForm(data) {
	return {
		usuario: converterCTeSingleSelectParaForm(data.usuario),
		setor: converterCTeSingleSelectParaForm(data.setor),
		informacaoComplementar: data.informacaoComplementar,
		observacaoInterna: data.observacaoInterna,
		recebedorRetira: data.recebedorRetira,
		recebedorRetiraDetalhes: data.recebedorRetiraDetalhes,
	};
}

function converterCTeServicosParaForm(data) {
	let servicos = [];
	if (data) {
		data.forEach((servico) => {
			servicos.push({
				id: servico.id,
				componente: converterCTeSingleSelectParaForm(servico.transporteComponenteValorPrestacao),
				valor: servico.valor,
			});
		});
	}
	return servicos;
}

function converterCTeCorrecoesCamposParaForm(correcoes) {
	let correcoesItens = [];
	if (correcoes?.length > 0) {
		correcoesItens = correcoes?.map((correcao) => {
			return {
				id: correcao.id,
				item: correcao.item,
				grupo: correcao.grupo,
				campo: correcao.campo,
				valor: correcao.valor,
			};
		});
	}

	return correcoesItens;
}

function converterCTeCorrecoesParaForm(values) {
	let correcoes = [];
	if (values?.length > 0) {
		correcoes = values?.map((correcao) => {
			return {
				id: correcao.id,
				numeroSequencial: correcao.numeroSequencial,
				emissao: correcao.emissao,
				arquivoXml: correcao.arquivoXml,
				protocolo: correcao.protocolo,
				correcoes: converterCTeCorrecoesCamposParaForm(correcao.correcoes),
			};
		});
	}

	return correcoes;
}

function converterCTeParticipanteParaForm(data, field) {
	if (data) {
		return {
			value: data.pessoa.id,
			label: `${data.pessoa.codigo} - ${data.pessoa.nome}`,
			registro: {
				id: data.pessoa.id,
				tipoPessoa: data.tipoPessoa,
				participante: field,
				cnpj: data.cnpj,
				cpf: data.cpf,
				indicadorInscricaoEstadual: data.pessoa.indicadorInscricaoEstadual,
				inscricaoEstadual: data.inscricaoEstadual,
				nome: data.nome,
				logradouro: data.endereco?.logradouro,
				numero: data.endereco?.numero,
				bairro: data.endereco?.bairro,
				complemento: data.endereco?.complemento,
				municipio: converterCTeSingleSelectParaForm(
					data.endereco?.municipio,
					`${data.endereco?.municipio?.nome} - ${data.endereco?.municipio?.estado?.sigla}`
				),
				cep: data.endereco?.cep,
				telefone: data.endereco?.fone,
				email: data.endereco?.email,
			},
		};
	}
	return null;
}

function converterCTeEmissorDocumentoAnteriorParaForm(data) {
	if (data) {
		return {
			label: `${data.pessoa.codigo} - ${data.pessoa.nome}`,
			value: data.id,
			registro: {
				id: data.id,
				nome: data.nome,
				pessoa: converterCTeSingleSelectParaForm(data.pessoa, `${data.pessoa?.codigo} - ${data.pessoa?.nome}`),
				municipio: converterCTeSingleSelectParaForm(
					data.municipio,
					`${data.municipio?.nome} - ${data.municipio?.estado?.sigla}`
				),
				tipoPessoa: data.tipoPessoa,
				cnpj: data.cnpj,
				cpf: data.cpf,
				inscricaoEstadual: data.inscricaoEstadual,
			},
		};
	}
	return null;
}

function converterCTeParaForm(data) {
	return {
		id: data.id,
		numero: data.numero,
		serie: data.serie,
		chave: data.chave,
		protocolo: data.protocolo,
		situacao: data.situacao,
		tipo: data.tipo,
		finalidade: data.finalidade,
		tomador: data.tipoTomador,
		remetente: converterCTeParticipanteParaForm(data.remetente, 'remetente'),
		destinatario: converterCTeParticipanteParaForm(data.destinatario, 'destinatario'),
		expedidor: converterCTeParticipanteParaForm(data.expedidor, 'expedidor'),
		recebedor: converterCTeParticipanteParaForm(data.recebedor, 'recebedor'),
		outros: converterCTeParticipanteParaForm(data.outroParticipante, 'outros'),
		cfop: converterCTeSingleSelectParaForm(data.cfop, `${data.cfop?.codigo} - ${data.cfop?.descricao}`),
		emissao: data.emissao,
		rntrc: data.rntrc,
		localEmissao: converterCTeSingleSelectParaForm(
			data.municipioTransmitido,
			`${data.municipioTransmitido?.nome} - ${data.municipioTransmitido?.estado?.sigla}`
		),
		localInicioPrestacao: converterCTeSingleSelectParaForm(
			data.municipioInicioViagem,
			`${data.municipioInicioViagem?.nome} - ${data.municipioInicioViagem?.estado?.sigla}`
		),
		localFimPrestacao: converterCTeSingleSelectParaForm(
			data.municipioFimViagem,
			`${data.municipioFimViagem?.nome} - ${data.municipioFimViagem?.estado?.sigla}`
		),
		cteGlobalizado: data.cteGlobalizado,
		documento: converterCTeDocumentoParaForm(data.documento),
		documentoAnterior: converterCTeDocumentoAnteriorParaForm(data.documentoAnterior),
		cteSubstituicao: converterCTeSubstituicaoParaForm(data.substituicao),
		cteComplemento: converterCTeComplementoValoresParaForm(data.complementoValores),
		servicos: converterCTeServicosParaForm(data.servicos),
		informacaoCarga: converterInformacaoCargaParaForm(data.informacaoCarga),
		impostos: converterCTeImpostosParaForm(data.impostos),
		totalizadores: converterCTeTotalParaForm(data.total),
		pagamentos: converterCTePagamentosParaForm(data.pagamentos, getParticipanteParaForm(data)),
		informacoesComplementares: converterCTeInformacaoComplementarParaForm(data.informacaoComplementar),
		errors: data.erros.length > 0 ? data.erros[0] : [],
		motivoCancelamento: data.motivoCancelamento,

		correcoes: converterCTeCorrecoesParaForm(data.correcoes),
	};
}

function converterCTeEmissorDocumentoAnteriorParaApi(values) {
	if (values) {
		return {
			pessoa: { id: values.registro.pessoa?.value },
			municipio: { id: values.registro.municipio?.value },
			nome: values.registro.nome,
			tipoPessoa: values.registro.tipoPessoa,
			cnpj: values.registro.cnpj,
			cpf: values.registro.cpf,
			inscricaoEstadual: values.registro.inscricaoEstadual,
		};
	}
	return null;
}

function converterCTeParticipanteParaApi(values) {
	if (values) {
		return {
			pessoa: { id: values.registro.id },
			tipoPessoa: values.registro.tipoPessoa,
			cnpj: values.registro.cnpj,
			cpf: values.registro.cpf,
			inscricaoEstadual: values.registro.inscricaoEstadual,
			nome: values.registro.nome,
			endereco: {
				logradouro: values.registro.logradouro,
				numero: values.registro.numero,
				bairro: values.registro.bairro,
				complemento: values.registro.complemento,
				municipio: converterCTeSingleSelectParaApi(values.registro.municipio),
				cep: values.registro.cep,
				fone: values.registro.telefone,
				email: values.registro.email,
			},
		};
	}
	return null;
}

function converterCTeDocumentoNFesParaApi(values) {
	let nfes = [];
	if (values) {
		values.forEach((nfe) => {
			nfes.push({
				chaveAcesso: nfe.chaveAcesso,
			});
		});
	}
	return nfes;
}

function converterCTeDocumentoNotasFiscaisParaApi(values) {
	let notasFiscais = [];
	if (values) {
		values.forEach((notaFiscal) => {
			notasFiscais.push({
				tipoDocumento: notaFiscal.modeloDocumento,
				emissao: notaFiscal.emissao,
				cfop: notaFiscal.cfop ? { id: notaFiscal.cfop.value } : null,
				serie: notaFiscal.serie,
				numero: notaFiscal.numero,
				numeroRomaneio: notaFiscal.numeroRomaneio,
				numeroPedido: notaFiscal.numeroPedido,
				valorBaseCalculoIcms: notaFiscal.valorBaseCalculoIcms,
				valorIcms: notaFiscal.valorIcms,
				valorBaseCalculoIcmsSt: notaFiscal.valorBaseCalculoIcmsSt,
				valorIcmsSt: notaFiscal.valorIcmsSt,
				peso: notaFiscal.peso,
				totalProdutos: notaFiscal.valorTotalProdutos,
				totalNotaFiscal: notaFiscal.valorTotalNotaFiscal,
			});
		});
	}
	return notasFiscais;
}

function converterCTeDocumentoOutrosParaApi(values) {
	let outrosDocumentos = [];
	if (values) {
		values.forEach((documento) => {
			outrosDocumentos.push({
				tipoDocumento: documento.tipoDocumento.value,
				numero: documento.numero,
				valor: documento.valor,
				emissao: documento.emissao,
				descricao: documento.descricao,
			});
		});
	}
	return outrosDocumentos;
}

function converterCTeDocumentoParaApi(values) {
	return {
		tipoDocumento: values.tipoDocumento,
		nfes: converterCTeDocumentoNFesParaApi(values.nfes),
		notasFiscais: converterCTeDocumentoNotasFiscaisParaApi(values.notasFiscais),
		outros: converterCTeDocumentoOutrosParaApi(values.outros),
	};
}

function converterCteDocumentoAnteriorCtesParaApi(values) {
	let ctes = [];
	if (values) {
		values.forEach((cte) => {
			ctes.push({
				chaveAcesso: cte.chaveAcesso,
			});
		});
	}
	return ctes;
}

function converterCteDocumentoAnteriorDocumentosPapelParaApi(values) {
	let documentosPapel = [];
	if (values) {
		values.forEach((documento) => {
			documentosPapel.push({
				tipoDocumento: documento.tipoDocumento,
				serie: documento.serie,
				subSerie: documento.subSerie,
				numero: documento.numeroDocumento,
				emissao: documento.emissao,
			});
		});
	}
	return documentosPapel;
}

function converterCTeDocumentoAnteriorParaApi(values) {
	return {
		emissorDocumentoAnterior: converterCTeEmissorDocumentoAnteriorParaApi(values.emissor),
		tipoDocumentoAnterior: values.tipoDocumento,
		ctes: converterCteDocumentoAnteriorCtesParaApi(values.ctes),
		outros: converterCteDocumentoAnteriorDocumentosPapelParaApi(values.documentosPapel),
	};
}

function converterCTeSubstituicaoParaApi(values) {
	return {
		chaveSubstituto: values.chaveAcesso,
		tomadorDiferenteCteSubstituido: values.tomadorInformadoDiferente,
	};
}

function converterCTeComplementoValoresParaApi(values) {
	return {
		chaveComplementoValor: values.chaveAcesso,
	};
}

function converterCTeServicosParaApi(values) {
	let servicos = [];
	if (values) {
		values.forEach((servico) => {
			servicos.push({
				transporteComponenteValorPrestacao: converterCTeSingleSelectParaApi(servico.componente),
				valor: servico.valor,
			});
		});
	}
	return servicos;
}

function converterInformacaoCargaQuantidadeParaApi(values) {
	let quantidades = [];
	if (values) {
		values.forEach((quantidade) => {
			quantidades.push({
				unidadeMedida: quantidade.unidadeMedida.value,
				tipoMedida: quantidade.tipoMedida,
				quantidade: quantidade.quantidade,
			});
		});
	}
	return quantidades;
}

function converterInformacaoCargaParaApi(values) {
	return {
		produtoPredominante: converterCTeSingleSelectParaApi(values.produtoPredominante),
		outraCaracteristicaCarga: values.outraCaracteristicaCarga,
		valorCarga: values.valorCarga,
		quantidades: converterInformacaoCargaQuantidadeParaApi(values.quantidades),
	};
}

function converterCTeImpostosParaApi(values) {
	return {
		cstCsosn: converterCTeSingleSelectParaApi(values.cstCsosn),
		percentualBaseCalculoIcms: values.percentualBaseCalculoIcms,
		valorBaseCalculoIcms: values.valorBaseCalculoIcms,
		aliquotaIcms: values.aliquotaIcms,
		valorIcms: values.valorIcms,
	};
}

function converterCTeTotalParaApi(values) {
	return {
		valorTotalServico: values.totalFrete,
		valorTotalCarga: values.totalCarga,
		valorPagamentoDesconto: values.totalDesconto,
		valorPagamentoLiquido: values.totalLiquido,
	};
}

function converterCTePagamentosParcelasParaApi(values) {
	const pagamentoParcelas = values.map((parcela) => ({
		sequencial: parcela.sequencial,
		vencimento: parcela.vencimento,
		formaPagamento: converterCTeSingleSelectParaApi(parcela.formaPagamento),
		conta: converterCTeSingleSelectParaApi(parcela.conta),
		valor: parcela.valor,
		descricao: parcela.descricao,
	}));
	return pagamentoParcelas;
}

function converterPagamentoCategoriasParaApi(values){
	const categorias = [];
	if (values) {
		values.forEach(registro =>{
			const cteCategoria = {
				categoria: {id: registro.categoria.value},
				valor: registro.valor,
				percentual: registro.percentual,
			};
			categorias.push(cteCategoria);
		});
	}
	return categorias;
}

function converterCTePagamentosParaApi(values) {
	const pagamentos = values.map((pagamento) => {
		return {
			sequencial: pagamento.sequencial,
			condicaoPagamento: converterCTeSingleSelectParaApi(pagamento.condicaoPagamento),
			quantidadeParcelas: pagamento.quantidadeParcelas ? pagamento.quantidadeParcelas.value : null,
			categorias: converterPagamentoCategoriasParaApi(pagamento.categorias),
			formaPagamento: converterCTeSingleSelectParaApi(pagamento.formaPagamento),
			conta: converterCTeSingleSelectParaApi(pagamento.conta),
			valor: pagamento.valor,
			parcelas: converterCTePagamentosParcelasParaApi(pagamento.parcelas),
		};
	});
	return pagamentos;
}

function converterCTeCorrecoesCamposParaApi(correcoes) {
	let correcoesItens = [];
	if (correcoes?.length > 0) {
		correcoesItens = correcoes?.map((correcao) => {
			return { item: correcao.item, grupo: correcao.grupo, campo: correcao.campo, valor: correcao.valor };
		});
	}

	return correcoesItens;
}

function converterCTeCorrecoesParaApi(values) {
	let correcoes = [];
	if (values?.length > 0) {
		correcoes = values?.map((correcao) => {
			return {
				numeroSequencial: correcao.numeroSequencial,
				emissao: correcao.emissao,
				arquivoXml: correcao.arquivoXml,
				protocolo: correcao.protocolo,
				correcoes: converterCTeCorrecoesCamposParaApi(correcao.correcoes),
			};
		});
	}

	return correcoes;
}

function converterCTeInformacaoComplementarParaApi(values) {
	return {
		usuario: { id: values.usuario?.value },
		setor: { id: values.setor?.value },
		informacaoComplementar: values.informacaoComplementar,
		observacaoInterna: values.observacaoInterna,
		recebedorRetira: values.recebedorRetira,
		recebedorRetiraDetalhes: values.recebedorRetiraDetalhes,
	};
}

function converterCTeSingleSelectParaApi(values) {
	if (values) {
		return { id: values.value };
	}
	return null;
}

function converterCTeParaApi(values) {
	return {
		id: values.id,
		numero: values.numero,
		serie: values.serie,
		chave: values.chave,
		protocolo: values.protocolo,
		situacao: values.situacao,
		tipo: values.tipo,
		finalidade: values.finalidade,
		tipoTomador: values.tomador,
		remetente: converterCTeParticipanteParaApi(values.remetente),
		destinatario: converterCTeParticipanteParaApi(values.destinatario),
		expedidor: converterCTeParticipanteParaApi(values.expedidor),
		recebedor: converterCTeParticipanteParaApi(values.recebedor),
		outroParticipante: converterCTeParticipanteParaApi(values.outros),
		cfop: converterCTeSingleSelectParaApi(values.cfop),
		emissao: values.emissao,
		rntrc: values.rntrc,
		municipioTransmitido: converterCTeSingleSelectParaApi(values.localEmissao),
		municipioInicioViagem: converterCTeSingleSelectParaApi(values.localInicioPrestacao),
		municipioFimViagem: converterCTeSingleSelectParaApi(values.localFimPrestacao),
		cteGlobalizado: values.cteGlobalizado,
		documento: converterCTeDocumentoParaApi(values.documento),
		documentoAnterior: converterCTeDocumentoAnteriorParaApi(values.documentoAnterior),
		substituicao: converterCTeSubstituicaoParaApi(values.cteSubstituicao),
		complementoValores: converterCTeComplementoValoresParaApi(values.cteComplemento),
		servicos: converterCTeServicosParaApi(values.servicos),
		informacaoCarga: converterInformacaoCargaParaApi(values.informacaoCarga),
		impostos: converterCTeImpostosParaApi(values.impostos),
		total: converterCTeTotalParaApi(values.totalizadores),
		pagamentos: converterCTePagamentosParaApi(values.pagamentos),
		informacaoComplementar: converterCTeInformacaoComplementarParaApi(values.informacoesComplementares),

		correcoes: converterCTeCorrecoesParaApi(values.correcoes),
	};
}

async function converterEmissorDocumentoAnteriorParaForm(data) {
	if (data) {
		const enderecoFavorito = await converterPessoaEnderecoFavoritoParaForm(data?.enderecos);

		return {
			label: `${data.codigo} - ${data.nome}`,
			value: data.id,
			registro: {
				nome: data.nome,
				pessoa: { label: `${data.codigo} - ${data.nome}`, value: data.id, registro: data },
				municipio: enderecoFavorito?.municipio,
				tipoPessoa: data.tipo,
				cnpj: data.pessoaJuridica ? data.pessoaJuridica?.cnpj : null,
				cpf: data.pessoaFisica ? data.pessoaFisica?.cpf : null,
				inscricaoEstadual: data.inscricaoEstadual || null,
			},
		};
	}

	return null;
}

async function converterPessoaParaForm(field, data, municipioXml) {
	if (data) {
		const emailFavorito = await converterPessoaEmailFavoritoParaForm(data?.emails);
		const telefoneFavorito = await converterPessoaTelefoneFavoritoParaForm(data?.telefones);
		const enderecoFavorito = await converterPessoaEnderecoFavoritoParaForm(data?.enderecos, municipioXml);

		return {
			label: `${data.codigo} - ${data.nome}`,
			value: data.id,
			registro: {
				id: data.id,
				participante: field,
				nome: data.nome,
				tipoPessoa: data.tipo,
				cnpj: data.pessoaJuridica ? data.pessoaJuridica?.cnpj : null,
				cpf: data.pessoaFisica ? data.pessoaFisica?.cpf : null,
				indicadorInscricaoEstadual: data.indicadorInscricaoEstadual || null,
				inscricaoEstadual: data.inscricaoEstadual || null,
				email: emailFavorito || null,
				telefone: telefoneFavorito || null,
				cep: enderecoFavorito?.cep || null,
				logradouro: enderecoFavorito?.logradouro || null,
				bairro: enderecoFavorito?.bairro || null,
				numero: enderecoFavorito?.numero || null,
				complemento: enderecoFavorito?.complemento || null,
				municipio: enderecoFavorito?.municipio || null,
			},
		};
	}

	return null;
}

async function converterPessoaEmailFavoritoParaForm(emails) {
	if (emails?.length > 0) {
		const emailFavorito = emails.filter((email) => email.favorito);

		if (emailFavorito?.length > 0) {
			return emailFavorito[0]?.email || null;
		} else {
			return emails[0]?.email || null;
		}
	}

	return null;
}

async function converterPessoaTelefoneFavoritoParaForm(telefones) {
	if (telefones?.length > 0) {
		const telefoneFavorito = telefones.filter((telefone) => telefone.favorito);

		if (telefoneFavorito?.length > 0) {
			return telefoneFavorito[0]?.numero || null;
		} else {
			return telefones[0]?.numero || null;
		}
	}

	return null;
}

async function converterPessoaEnderecoFavoritoParaForm(enderecos, municipioXml) {
	if (enderecos?.length > 0) {
		const { cep, logradouro, bairro, numero, complemento, municipio } =
			enderecos.find((endereco) => endereco.favorito) || enderecos[0];

		return {
			id: null,
			cep: cep || null,
			logradouro: logradouro || null,
			bairro: bairro || null,
			numero: numero || null,
			complemento: complemento || null,
			municipio: municipioXml
				? {
						label: `${municipioXml?.nome} - ${municipioXml?.estado?.sigla}`,
						value: `${municipioXml?.id}`,
						registro: municipioXml,
					}
				: municipio
					? {
							label: `${municipio?.nome} - ${municipio?.estadoSigla}`,
							value: `${municipio?.id}`,
							registro: municipio,
						}
					: null,
		};
	}

	return null;
}

async function converterCTeNFesParaForm(nfes) {
	let notas = [];
	if (nfes?.length > 0) {
		notas = await nfes?.map((nfe) => {
			return {
				id: nfe.id,
				chave: nfe.chave,
				emissao: nfe.emissao,
				numero: nfe.numero,
				serie: nfe.serie,
				destinatario: {
					municipio: nfe.destinatario?.endereco?.municipio,
					pessoa: {
						id: nfe.destinatario?.id,
						codigo: nfe.destinatario?.codigo,
						nome: nfe.destinatario?.nome,
						tipo: nfe.destinatario?.tipo,
						pessoaJuridica: { cnpj: nfe.destinatario?.cnpj || null },
						pessoaFisica: { cpf: nfe.destinatario?.cpf || null },
						indicadorInscricaoEstadual: nfe.destinatario?.indicadorIE || null,
						inscricaoEstadual: nfe.destinatario?.ie || null,
						emails: nfe.destinatario?.email ? [{ email: nfe.destinatario?.email, favorito: true }] : [],
						telefone: nfe.destinatario?.telefone ? [{ numero: nfe.destinatario?.telefone, favorito: true }] : [],
						enderecos: [
							{
								cep: nfe.destinatario?.endereco?.cep || null,
								logradouro: nfe.destinatario?.endereco?.logradouro || null,
								bairro: nfe.destinatario?.endereco?.bairro || null,
								numero: nfe.destinatario?.endereco?.numero || null,
								complemento: nfe.destinatario?.endereco?.complemento || null,
								municipio: nfe.destinatario?.endereco?.municipio || null,
								favorito: true,
							},
						],
					},
				},
				emitente: {
					id: nfe.emitente?.id,
					cpf: nfe.emitente?.cpf || null,
					cnpj: nfe.emitente?.cnpj || null,
				},
				transporte: {
					modalidadeFrete: nfe.volumes?.modalidadeFrete,
					volume: {
						quantidade: nfe.volumes?.quantidade,
						pesoLiquido: nfe.volumes?.pesoLiquido,
					},
				},
				totais: {
					valorFrete: nfe.total?.totalFrete,
					valorNfe: nfe.total?.valorTotal,
				},
			};
		});
	}

	return notas;
}

export {
	converterCTeParaForm,
	converterPessoaParaForm,
	converterPessoaEnderecoFavoritoParaForm,
	converterEmissorDocumentoAnteriorParaForm,
	converterCTeSingleSelectParaForm,
	converterCTeParaApi,
	converterCTeNFesParaForm,
};
