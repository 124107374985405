import { format, parseISO } from 'date-fns';
import { Field, withFormik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';

import { mensagensDeValidacao } from '../../../../../../../Common';
import {
	Button,
	Grid,
	If,
	InputField,
	Modal,
	ModalLoadingTransmissao,
	TextArea,
} from '../../../../../../../components';

import { validarFormulario } from '../../../../../../Util';
import { asyncCancelarNfe } from '../../../Requests';
import { Status } from '../../../Util/constantes';
import { removerAcentos } from 'Common/ManipulacaoDeString';

function ModalCancelamento(props) {
	const { onHide, handleSubmit, values, visible, xJust, dataCancelamento, setFieldValue, statusNfe, idNfe } = props;
	const [mostrarLoadingTransmissao, setMostrarLoadingTransmissao] = useState(false);
	const notaFiscalCancelada = statusNfe === Status.CANCELADA;

	function cancelar() {
		onHide();
	}

	async function confirmar() {
		handleSubmit();

		if (await validarFormulario(props)) {
			setMostrarLoadingTransmissao(true);
			asyncCancelarNfe(
				idNfe,
				converterParaApi(),
				() => {
					setMostrarLoadingTransmissao(false);
					onHide(values);
				},
				() => {
					setMostrarLoadingTransmissao(false);
					onHide(values);
				}
			);
		}
	}

	function converterParaApi() {
		if (values.xJust) {
			let textoCancelamento = String(values.xJust).trim();
			textoCancelamento = removerAcentos(textoCancelamento);
			return {
				xJust: textoCancelamento,
			};
		}
		return { ...values };
	}

	return (
		<Modal header="Cancelar nota fiscal (NF-e)" visible={visible} onHide={onHide}>
			<ModalLoadingTransmissao visible={mostrarLoadingTransmissao} message="Cancelando nota fiscal..." />
			<Grid>
				<If test={notaFiscalCancelada}>
					<Field
						sm="12"
						component={InputField}
						label="Data e hora do evento"
						value={dataCancelamento ? format(parseISO(dataCancelamento, new Date()), 'dd/MM/yyyy HH:mm') : ''}
						name="dhEvento"
						disabled
					/>
				</If>
				<Field
					sm="12"
					component={TextArea}
					label="Justificativa do cancelamento"
					name="xJust"
					maxLength={255}
					errors={props.errors?.xJust}
					touched={!!props.errors?.xJust}
					disabled={notaFiscalCancelada}
					value={xJust ? xJust : values.xJust}
					onChange={(e) => setFieldValue('xJust', e.target.value)}
				/>
			</Grid>
			<Grid justifyEnd>
				<span style={{ padding: '0.5em' }}>
					<Button color="secondary" icon="fa fa-arrow-left" label="Voltar" onClick={cancelar} />
					<Button
						color="danger"
						icon={notaFiscalCancelada ? 'fa fa-check' : 'fa fa-send'}
						label={notaFiscalCancelada ? 'Nota fiscal cancelada' : 'Cancelar NF-e'}
						disabled={notaFiscalCancelada}
						onClick={confirmar}
						style={{ marginLeft: '10px' }}
					/>
				</span>
			</Grid>
		</Modal>
	);
}

ModalCancelamento = withFormik({
	enableReinitialize: true,
	validateOnChange: false,

	mapPropsToValues(props) {
		return {
			xJust: props.xJust || '',
		};
	},

	validationSchema: Yup.object().shape({
		xJust: Yup.string()
			.trim()
			.min(15, 'A descrição deve ter mais de 15 caracteres')
			.required(mensagensDeValidacao.OBRIGATORIO),
	}),
	handleSubmit: () => {},
})(ModalCancelamento);

export default ModalCancelamento;
