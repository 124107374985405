import { useCallback, useEffect, useRef, useState } from 'react';
import { useUpdateEffect } from 'react-use';
import { endOfMonth, format, formatISO, parse, startOfMonth } from 'date-fns';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Menu } from 'primereact/menu';

import {
	Button,
	ButtonEditarTable,
	Checkbox,
	Col,
	confirm,
	DateInterval,
	DescricaoFiltroAvancado,
	Grid,
	If,
	InputSearch,
	ModalHistorico,
	NenhumRegistroEncontrado,
	notify,
	Paginacao,
	PesquisaAvancada,
	ToastTypes,
} from 'components';
import {
	buscarConfiguracaoUsuario,
	configuracoesUsuario,
	construirUrl,
	formatarMonetario,
	formatarParaPesquisarTiposEnumerados,
	permissoes,
	recursos,
	salvarConfiguracaoUsuario,
	services,
	usuarioPossuiPermissao,
} from 'Common';

import { useContextPesquisa } from 'views/Util/Context/ContextPesquisa';
import { atualizarUrl } from 'views/Util';
import { aplicarEstiloArquivado, buscarMensagemExcluirDocumento, renderStatus } from '../../Util/funcoesList';
import { buttonTableStyle, optionsFiltroAvancado } from '../../Util/constantes';
import {
	asyncArquivarGestaoDocumento,
	asyncDesarquivarGestaoDocumento,
	asyncExcluirDocumento,
	asyncGetPesquisaDocumentos,
	asyncGetTotalizadores,
} from '../../Requests';
import { CardsStatusGestaoDocumento } from '../CardsStatus';
import { OverlayPanelInformacoesComplementares } from '../OverlayPanelInformacoesComplementares';

function ListagemTable({ isMobile, isLessHd, location, history, setTutorialVisible }) {
	const {
		valorPesquisa,
		setValorPesquisa,
		interval,
		setInterval,
		sortField,
		setSortField,
		sortOrder,
		setSortOrder,
		page,
		setPage,
		rows,
		setRows,
		filtroAvancado,
		setFiltroAvancado,
		descricaoFiltroAvancado,
		setDescricaoFiltroAvancado,
		filtroData,
		setFiltroData,
		exibirBloqueadas,
		setExibirBloqueadas,
		selectedCard,
		setSelectedCard,
		valorCard,
		setValorCard,
	} = useContextPesquisa();

	const [limparFiltroPesquisaAvancada, setLimparFiltroPesquisaAvancada] = useState(false);
	const [overlayInformacoesComplementares, setOverlayInformacoesComplementares] = useState(null);
	const [registroSelecionado, setRegistroSelecionado] = useState(null);
	const [modalDocumentoHistoricoVisible, setModalDocumentoHistoricoVisible] = useState(false);
	const [registros, setRegistros] = useState([]);
	const [documentosSelecionados, setDocumentosSelecionados] = useState([]);
	const [totalElements, setTotalElements] = useState(0);
	const [firstRender, setFirstRender] = useState(true);
	const [cards, setCards] = useState({
		pendente: 0,
		rescindido: 0,
		vigenciaExpirada: 0,
		vigente: 0,
	});

	const menuOpcoes = useRef(null);
	const panelInformacoesComplementares = useRef(null);

	const podeExcluir = usuarioPossuiPermissao(recursos.GESTAO_DOCUMENTOS, permissoes.EXCLUIR);
	const podeEditar = usuarioPossuiPermissao(recursos.GESTAO_DOCUMENTOS, permissoes.EDITAR);
	const deveExibirTutorial = buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS);
	const acoesStyle = !isMobile && { textAlign: 'end' };

	const pesquisarCallback = useCallback(() => {
		if (!firstRender) {
			pesquisar();
		}
	});

	useEffect(() => {
		pesquisar();
		const { state } = location;

		if (state && state.pesquisaPadrao) {
			setValorPesquisa(state.pesquisaPadrao);
		}

		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS, false, null, false);
		}

		setTimeout(() => {
			document.getElementById('DocumentosInputSearch')?.focus();
		}, 500);
	}, []);

	useUpdateEffect(() => {
		pesquisarCallback();
	}, [exibirBloqueadas, page, rows, sortOrder, sortField, filtroAvancado, interval, selectedCard]);

	function pesquisar(exibirLoading) {
		const filtro = buscarFiltro();
		let campoOrdenacao = sortField;
		let sentidoOrdenacao = sortOrder;
		if (firstRender) {
			setSortField('vigenciaInicio');
			setSortOrder(-1);
			campoOrdenacao = 'vigenciaInicio';
			sentidoOrdenacao = -1;
		}
		const url = construirUrl(
			`${services.GESTOR}/v1/documentos/gestao_documentos/resumo`,
			filtro,
			rows,
			page,
			sentidoOrdenacao > 0 ? `${campoOrdenacao},asc` : `${campoOrdenacao},desc`
		);

		asyncGetTotalizadores(interval, exibirBloqueadas, ({ data: totais }) => {
			setCards(totais);
		});

		asyncGetPesquisaDocumentos(
			url,
			({ data: gestaoDocumento }) => {
				setRegistros(gestaoDocumento.content);
				setTotalElements(gestaoDocumento.page.totalElements);
				setFirstRender(false);
			},
			null,
			exibirLoading
		);
	}

	function buscarFiltro() {
		const valorTiposEnumerados = formatarParaPesquisarTiposEnumerados(valorPesquisa.replaceAll('&', ''));
		const currentDate = new Date();
		const startOfTheMonth = startOfMonth(currentDate);
		const endOfTheMonth = endOfMonth(currentDate);

		let filtroRSQL = String('?query=(')
			.concat(`identificacao=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
			.concat(`status=contains="*${valorTiposEnumerados}*")`);

		if (filtroData) {
			filtroRSQL = filtroRSQL.concat(`;(${filtroData})`);
		} else {
			filtroRSQL = filtroRSQL.concat(
				`;(${`criadoEm>=${formatISO(startOfTheMonth, {
					representation: 'date',
				})};criadoEm<=${formatISO(endOfTheMonth, {
					representation: 'date',
				})}`})`
			);
		}
		if (filtroAvancado) {
			filtroRSQL = filtroRSQL.concat(`;(${filtroAvancado})`);
		}
		if (!exibirBloqueadas) {
			filtroRSQL = filtroRSQL.concat(`;arquivado==${false}`);
		}
		if (valorCard) {
			filtroRSQL = filtroRSQL.concat(`;(${valorCard})`);
		}

		return filtroRSQL;
	}

	function handleChangeInterval(interval) {
		setInterval(interval);
		setFiltroData(`criadoEm>=${formatISO(interval.dataInicial, {representation: 'date'})};criadoEm<=${formatISO(interval.dataFinal, {representation: 'date',})}`);
	}

	function onPageChange(e) {
		setPage(e.page);
		setRows(e.rows);
	}

	function onSort(e) {
		setSortField(e.sortField);
		setSortOrder(e.sortOrder);
	}

	function getHeaderTable() {
		return (
			<Grid justifyBetween>
				<Col col="5" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', minHeight: '50px' }}>
					<span style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
						<Checkbox
							checked={exibirBloqueadas}
							name="exibirBloqueadas"
							label="Exibir documentos arquivados"
							onChange={(e) => onChangeCheckboxExibirBloqueadas(e)}
						/>
					</span>
				</Col>
				<Col col="7" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', minHeight: '50px' }}>
					<If test={documentosSelecionados.length > 0}>
						<Button
							className="p-button-primary"
							type="button"
							label="Arquivar"
							icon="fa fa-archive"
							disabled={!podeEditar}
							title={!podeEditar ? 'Você não possui permissão para executar essa ação' : ''}
							style={{ marginRight: '6px' }}
							onClick={() => arquivarSelecionados(documentosSelecionados)}
						/>
						<Button
							className="p-button-primary"
							type="button"
							hidden={exibirBloqueadas === false}
							disabled={!podeEditar}
							title={!podeEditar ? 'Você não possui permissão para executar essa ação' : ''}
							label="Desarquivar"
							icon="fa fa-archive"
							onClick={() => desarquivarSelecionados(documentosSelecionados)}
						/>
					</If>
				</Col>
			</Grid>
		);
	}

	function onChangeCheckboxExibirBloqueadas(e) {
		limparRegistrosSelecionados();
		setExibirBloqueadas(e.checked);
	}

	function arquivarSelecionados(documentos) {
		confirm(
			'Tem certeza que deseja arquivar estes documentos?',
			'Um documento arquivado não aparecerá mais na listagem, para visualizá-los marque o item "Exibir documentos arquivados" na listagem.',
			async () => {
				const promises = documentos.map(
					(documento) =>
						new Promise((resolve, reject) => {
							asyncArquivarGestaoDocumento(
								documento.id,
								() => {
									resolve();
								},
								() => {
									reject();
								},
								null,
								false
							);
						})
				);

				await Promise.all(promises);
				pesquisar(false);
				limparRegistrosSelecionados();
				notify('Documentos arquivados com sucesso');
			}
		);
	}

	function desarquivarSelecionados(documentos) {
		confirm('Confirmação', 'Tem certeza que deseja desarquivar este(s) documento(s)?', async () => {
			const promises = documentos.map(
				(documento) =>
					new Promise((resolve, reject) => {
						asyncDesarquivarGestaoDocumento(
							documento.id,
							() => {
								resolve();
							},
							() => {
								reject();
							},
							null,
							false
						);
					})
			);

			await Promise.all(promises);
			pesquisar(false);
			limparRegistrosSelecionados();
			notify('Documentos desarquivados com sucesso');
		});
	}

	function limparRegistrosSelecionados() {
		setDocumentosSelecionados([]);
	}

	function onPesquisarFiltroAvancado(e) {
		setFiltroAvancado(e);
		setLimparFiltroPesquisaAvancada(false);
	}

	function renderIdentificacao(el) {
		const { identificacao } = el;
		return aplicarEstiloArquivado(el.arquivado, identificacao, isMobile);
	}

	function renderDataVigenciaInicio(el) {
		const vigenciaInicio = format(parse(el.vigenciaInicio, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy');
		return aplicarEstiloArquivado(el.arquivado, vigenciaInicio, isMobile);
	}

	function renderDataVigenciaFim(el) {
		if (el.vigenciaFinal) {
			const vigenciaFinal = format(parse(el.vigenciaFinal, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy');
			return aplicarEstiloArquivado(el.arquivado, vigenciaFinal, isMobile);
		}

		return null;
	}

	function renderValorField(el) {
		const valor = formatarMonetario(el.documentoValor);
		return aplicarEstiloArquivado(el.arquivado, valor, isMobile);
	}

	function renderValorMensalField(el) {
		const valor = formatarMonetario(el.documentoValorMensal);
		return aplicarEstiloArquivado(el.arquivado, valor, isMobile);
	}

	function renderOpcoes(registroSelecionadoOpcoes) {
		const acoesDoBotao = buscarAcoes(registroSelecionadoOpcoes);
		return (
			<div style={{ display: 'flex', padding: 0 }}>
				<Button
					style={{
						borderRadius: '50%',
						padding: '5px',
						width: '30px',
						height: '30px',
						marginRight: '3px',
					}}
					className="p-button p-button-primary"
					title="Informações complementares"
					icon="fa fa-info"
					onClick={(e) => handleShowOverlay(e, registroSelecionadoOpcoes)}
				/>

				<ButtonEditarTable onClick={() => onEditar(registroSelecionadoOpcoes)} />
				<Button
					color="secondary"
					style={buttonTableStyle}
					icon="fa fa-ellipsis-v"
					size="icon"
					disabled={Boolean(acoesDoBotao.length === 0)}
					title={acoesDoBotao.length === 0 ? 'Nenhuma operação possível para este documento' : null}
					onClick={(event) => {
						menuOpcoes.current.toggle(event);
						setRegistroSelecionado(registroSelecionadoOpcoes);
					}}
				/>
			</div>
		);
	}

	function buscarAcoes(registroSelecionado) {
		const itens = [];

		itens.push({
			label: 'Histórico',
			icon: 'fa fa-history',
			command: () => setModalDocumentoHistoricoVisible(true),
		});

		if (registroSelecionado && registroSelecionado.arquivado && podeEditar) {
			itens.push({
				label: 'Desarquivar',
				icon: 'fa fa-archive',
				disabled: !podeEditar,
				command: () => asyncDesarquivarDocumento(),
			});
		}

		if (registroSelecionado && !registroSelecionado.arquivado && podeEditar) {
			itens.push({
				label: 'Arquivar',
				icon: 'fa fa-archive',
				disabled: !podeEditar,
				command: () => asyncArquivarDocumento(),
			});
		}

		if (podeExcluir) {
			itens.push({
				label: 'Excluir',
				icon: 'fa fa-trash',
				command: () => asyncExcluirRegistro(),
			});
		}

		return itens;
	}

	function asyncArquivarDocumento() {
		const message = `Tem certeza que deseja arquivar este documento? Um documento arquivado não aparecerá mais na listagem, para visualizá-los marque o item "Exibir documentos arquivados" na listagem.`;
		confirm('Atenção', message, () => {
			asyncArquivarGestaoDocumento(
				registroSelecionado.id,
				() => {
					pesquisar(false);
				},
				null
			);
		});
	}

	function asyncDesarquivarDocumento() {
		const message = 'Tem certeza que deseja desarquivar este documento?';
		confirm('Atenção', message, () => {
			asyncDesarquivarGestaoDocumento(
				registroSelecionado.id,
				() => {
					pesquisar(false);
				},
				null
			);
		});
	}

	function asyncExcluirRegistro() {
		confirm('Atenção', buscarMensagemExcluirDocumento(registroSelecionado), () => {
			asyncExcluirDocumento(registroSelecionado.id, () => {
				notify('Documento excluído', ToastTypes.SUCCESS);
				pesquisar(false);
			});
		});
	}

	function handleShowOverlay(e, row) {
		document
			.getElementById('overlayPanel-informacoes-complementares')
			?.getElementsByClassName('p-overlaypanel-close p-link')[0]
			?.click();

		setOverlayInformacoesComplementares(row);
		panelInformacoesComplementares.current?.toggle(e);
	}

	function onEditar(row) {
		atualizarUrl(history, `/documentos/gestao_documentos/cadastro/${row.id}`);
	}

	return (
		<>
			<Menu model={buscarAcoes(registroSelecionado)} popup style={{ minWidth: '230px' }} ref={menuOpcoes} />
			<Grid justifyCenter verticalAlignCenter>
				<span style={{ padding: '12px' }}>
					<DateInterval onChange={(e) => handleChangeInterval(e)} interval={interval} />
				</span>
				<InputSearch
					className="step-listagem-input-search"
					onPesquisar={() => pesquisar()}
					value={valorPesquisa}
					onChange={(value) => setValorPesquisa(value)}
					removerEComercial={false}
					id="DocumentosInputSearch"
				/>
				<span style={{ padding: '12px' }}>
					<PesquisaAvancada
						optionsFiltros={optionsFiltroAvancado}
						onPesquisarClick={(e) => onPesquisarFiltroAvancado(e)}
						onChangeFiltroRsql={(rsql) => setFiltroAvancado(rsql)}
						onChangeDescricaoFiltro={(e) => setDescricaoFiltroAvancado(e)}
						limparFiltro={limparFiltroPesquisaAvancada}
					/>
				</span>
			</Grid>
			<CardsStatusGestaoDocumento
				cards={cards}
				selectedCard={selectedCard}
				setSelectedCard={setSelectedCard}
				setValorCard={setValorCard}
				setPage={setPage}
				isMobile={isMobile}
			/>
			<DescricaoFiltroAvancado texto={descricaoFiltroAvancado} />
			<DataTable
				className="table table-assinaturas"
				responsive
				selectionMode="checkbox"
				value={registros}
				sortField={sortField}
				sortOrder={sortOrder}
				onSort={(e) => onSort(e)}
				selection={documentosSelecionados}
				header={getHeaderTable()}
				onSelectionChange={(e) => setDocumentosSelecionados(e.value)}
				emptyMessage={<NenhumRegistroEncontrado />}
			>
				<Column header={isMobile ? 'Seleção' : ''} selectionMode="multiple" style={{ width: '45px' }} />
				<Column
					field="identificacao"
					header={isMobile || isLessHd ? 'Identific.' : 'Identificação'}
					sortable
					body={(e) => renderIdentificacao(e)}
					style={{ overflow: 'hidden', overflowWrap: 'break-word' }}
				/>
				<Column
					field="vigenciaInicio"
					header={isLessHd ? 'Inicio vigência' : 'Inicio da vigência'}
					sortable
					body={(e) => renderDataVigenciaInicio(e)}
				/>
				<Column
					field="vigenciaFinal"
					header={isLessHd ? 'Fim vigência' : 'Fim da vigência'}
					sortable
					body={(e) => renderDataVigenciaFim(e)}
				/>
				<Column
					field="documentoValor"
					header={isLessHd ? 'Valor doc.' : 'Valor do documento'}
					style={{ width: '200px' }}
					body={(e) => renderValorField(e)}
					sortable
				/>
				<Column
					field="documentoValorMensal"
					header="Valor mensal"
					style={{ width: '200px' }}
					body={(e) => renderValorMensalField(e)}
					sortable
				/>
				<Column field="status" header="Status" style={{ width: '220px' }} body={(row) => renderStatus(row)} sortable />
				<Column
					className="step-listagem-acoes"
					body={(e) => renderOpcoes(e)}
					header="Ações"
					style={{ width: '100px', ...acoesStyle }}
				/>
			</DataTable>
			<Paginacao totalElements={totalElements} rows={rows} page={page} onPageChange={(e) => onPageChange(e)} />
			<If test={modalDocumentoHistoricoVisible}>
				<ModalHistorico
					header="Histórico do documento"
					mapping="documentos/gestao_documentos"
					id={registroSelecionado?.id}
					visible={modalDocumentoHistoricoVisible}
					onHide={() => setModalDocumentoHistoricoVisible(false)}
				/>
			</If>
			<OverlayPanelInformacoesComplementares
				documento={overlayInformacoesComplementares}
				panelInformacoesComplementares={panelInformacoesComplementares}
				isMobile={isMobile}
				setDocumentoSelecionado={setOverlayInformacoesComplementares}
			/>
		</>
	);
}

export { ListagemTable };
