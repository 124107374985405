import { useCallback, useState, useEffect } from 'react';
import { asyncGetPesquisaOrganizacoes, asyncDeleteOrganizacao } from './Requests';
import { atualizarUrl } from '../../Util';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { connect } from 'react-redux';

import {
	recursos,
	permissoes,
	formatarParaPesquisarTiposEnumerados,
	removerCaracteres,
	inserirMascara,
	formatos,
	usuarioPossuiPermissao,
	services,
	construirUrl,
	colors,
} from 'Common';

import {
	ButtonNovo,
	ButtonEditarTable,
	ButtonExcluirTable,
	InputSearch,
	confirm,
	Form,
	FormActions,
	FormContent,
	NenhumRegistroEncontrado,
	Paginacao,
	Grid,
} from 'components';
import { useEffectOnce } from 'react-use';
import { useContextPesquisa } from 'views/Util/Context/ContextPesquisa';

function AgentesAutorizados(props) {
	const { history, isMobile } = props;

	const [podeInserir, setPodeInserir] = useState(
		usuarioPossuiPermissao(recursos.AGENTES_AUTORIZADOS, permissoes.INSERIR)
	);
	const [podeExcluir, setPodeExcluir] = useState(
		usuarioPossuiPermissao(recursos.AGENTES_AUTORIZADOS, permissoes.EXCLUIR)
	);
	const {
		valorPesquisa,
		setValorPesquisa,
		sortField,
		setSortField,
		sortOrder,
		setSortOrder,
		page,
		setPage,
		rows,
		setRows,
	} = useContextPesquisa();
	const [registros, setRegistros] = useState([]);

	const [totalElements, setTotalElements] = useState(0);

	const pesquisarCallback = useCallback(() => {
		pesquisar();
	});

	useEffectOnce(() => {
		setTimeout(() => {
			document.getElementById('AgentesAutorizadosInputSearch')?.focus();
		}, 500);
	}, []);

	useEffect(() => {
		pesquisarCallback();
	}, [page, rows, sortOrder, sortField]);

	function buscarFiltro() {
		const pesquisaCodigo = removerCaracteres(valorPesquisa.replaceAll('&', ''), ['.', '/', '-']);
		const pesquisarSituacao = formatarParaPesquisarTiposEnumerados(valorPesquisa.replaceAll('&', ''));

		return `?query=(nome=contains="*${valorPesquisa.replaceAll(
			'&',
			'%26'
		)}*",cpf=contains="*${pesquisaCodigo}*",cnpj=contains="*${pesquisaCodigo}*",email=contains="*${valorPesquisa.replaceAll(
			'&',
			'%26'
		)}*",situacao=contains="${pesquisarSituacao}*")`;
	}

	async function pesquisar() {
		const filtro = buscarFiltro();
		const url = construirUrl(
			`${services.GESTOR}/v1/agentes_autorizados/resumo`,
			filtro,
			rows,
			page,
			sortOrder > 0 ? `${sortField},asc` : `${sortField},desc`
		);

		asyncGetPesquisaOrganizacoes(url, ({ data: agentes }) => {
			setRegistros(agentes.content);
			setTotalElements(agentes.page.totalElements);
		});
	}

	function onPesquisar() {
		pesquisar();
	}

	function onPageChange(e) {
		setPage(e.page);
		setRows(e.rows);
	}

	function onSort(e) {
		setSortField(e.sortField);
		setSortOrder(e.sortOrder);
	}

	function onEditar(row) {
		atualizarUrl(history, '/agentes_autorizados/cadastro', row.id);
	}

	function onExcluir(row) {
		confirm('Confirmação', 'Deseja excluir o registro?', () => {
			asyncExcluirRegistro(row);
		});
	}

	function asyncExcluirRegistro(registro) {
		asyncDeleteOrganizacao(registro.id, () => {
			pesquisar();
		});
	}

	function renderOpcoes(row) {
		return (
			<div style={{ display: 'flex' }}>
				<ButtonEditarTable onClick={() => onEditar(row)} />
				<ButtonExcluirTable title={null} disabled={false} podeExcluir={podeExcluir} onClick={() => onExcluir(row)} />
			</div>
		);
	}

	function renderInativado(row, content) {
		const styleInactive = {
			fontStyle: 'italic',
			opacity: '0.8',
			wordBreak: 'break-word',
			maxWidth: props.isMobile ? '70%' : '100%',
			textAlign: props.isMobile ? 'end' : 'start',
		};

		const styleActive = {
			wordBreak: 'break-word',
			maxWidth: props.isMobile ? '70%' : '100%',
			textAlign: props.isMobile ? 'end' : 'start',
		};

		if (row.inativacao) {
			return <span style={styleInactive}>{content}</span>;
		}
		return <span style={styleActive}>{content}</span>;
	}

	function renderNome(row) {
		return renderInativado(row, row.nome);
	}

	function renderCpfCnpj(row) {
		let content = '';
		if (row.cpf) {
			content = inserirMascara(row.cpf, formatos.CPF);
		}
		if (row.cnpj) {
			content = inserirMascara(row.cnpj, formatos.CNPJ);
		}

		return renderInativado(row, content);
	}

	function renderEmail(row) {
		return renderInativado(row, row.email);
	}

	function renderSituacao(row) {
		const label = row.situacao === 'ATIVO' ? 'Ativo' : 'Inativo';
		const strongColor = row.situacao === 'ATIVO' ? colors.verde : colors.vermelho;
		const lightColor = row.situacao === 'ATIVO' ? colors.verdeLight : colors.vermelhoLight;
		const styleBackground = row.situacao === 'INATIVO' ? '0.2rem 1.2rem' : '0.2rem 1.5rem';

		return (
			<span
				style={{
					backgroundColor: lightColor,
					color: strongColor,
					fontWeight: 'bold',
					fontSize: '13px',
					borderRadius: '20px',
					padding: styleBackground,
				}}
			>
				<span style={{ width: '100%', textAlign: 'center' }}>{label}</span>
			</span>
		);
	}

	return (
		<Form header="Agentes autorizados">
			<FormActions>
				<ButtonNovo
					label="Novo agente"
					onClick={() => history.push('/agentes_autorizados/cadastro')}
					podeInserir={podeInserir}
				/>
			</FormActions>
			<FormContent>
				<Grid justifyCenter>
					<InputSearch
						onPesquisar={() => onPesquisar()}
						value={valorPesquisa}
						onChange={(value) => setValorPesquisa(value)}
						removerEComercial={false}
						id="AgentesAutorizadosInputSearch"
					/>
				</Grid>
				<DataTable
					className="table"
					responsive
					value={registros}
					sortField={sortField}
					sortOrder={sortOrder}
					onSort={onSort}
					emptyMessage={<NenhumRegistroEncontrado />}
				>
					<Column
						field="nome"
						header="Nome"
						body={(row) => renderNome(row)}
						sortable={true}
						style={{ width: '350px' }}
					/>
					<Column
						field="email"
						header="E-mail"
						sortable={true}
						body={(row) => renderEmail(row)}
						style={{ overflow: 'hidden' }}
					/>
					<Column
						field="cpf"
						header="CPF/CNPJ"
						body={(row) => renderCpfCnpj(row)}
						sortable={true}
						style={{ width: '160px' }}
					/>
					<Column
						field="situacao"
						header="Situação"
						sortable={true}
						body={(row) => renderSituacao(row)}
						style={{ width: '110px' }}
					/>
					<Column body={(row) => renderOpcoes(row)} header="Ações" style={{ width: '7em' }} />
				</DataTable>
				<Paginacao totalElements={totalElements} rows={rows} page={page} onPageChange={(e) => onPageChange(e)} />
			</FormContent>
		</Form>
	);
}

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
});

export default connect(mapStateToProps)(AgentesAutorizados);
