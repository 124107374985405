import { buscarTributacaoServico } from './Requests';

export function calcularIss(item) {
	item.tributos.iss.valorBase = parseFloat(((item.baseCalculo * item.tributos?.iss.percentualBase) / 100).toFixed(2));
	item.tributos.iss.valor = parseFloat(
		((item.tributos?.iss.valorBase * item.tributos?.iss.percentual) / 100).toFixed(2)
	);

	if (item.tributos?.iss.valor === 0) {
		item.tributos.iss.valorBase = 0;
	}
}

export function calcularIr(item) {
	item.tributos.ir.valorBase = parseFloat(((item.baseCalculo * item.tributos?.ir.percentualBase) / 100).toFixed(2));
	item.tributos.ir.valor = parseFloat(((item.tributos?.ir.valorBase * item.tributos?.ir.percentual) / 100).toFixed(2));

	if (item.tributos?.ir.valor === 0) {
		item.tributos.ir.valorBase = 0;
	}
}

export function calcularCsll(item) {
	item.tributos.csll.valorBase = parseFloat(((item.baseCalculo * item.tributos?.csll.percentualBase) / 100).toFixed(2));
	item.tributos.csll.valor = parseFloat(
		((item.tributos?.csll.valorBase * item.tributos?.csll.percentual) / 100).toFixed(2)
	);

	if (item.tributos?.csll.valor === 0) {
		item.tributos.csll.valorBase = 0;
	}
}

export function calcularInss(item) {
	item.tributos.inss.valorBase = parseFloat(((item.baseCalculo * item.tributos?.inss.percentualBase) / 100).toFixed(2));
	item.tributos.inss.valor = parseFloat(
		((item.tributos?.inss.valorBase * item.tributos?.inss.percentual) / 100).toFixed(2)
	);

	if (item.tributos?.inss.valor === 0) {
		item.tributos.inss.valorBase = 0;
	}
}

export function calcularPis(item) {
	item.tributos.pis.valorBase = parseFloat(((item.baseCalculo * item.tributos?.pis.percentualBase) / 100).toFixed(2));
	item.tributos.pis.valor = parseFloat(
		((item.tributos?.pis.valorBase * item.tributos?.pis.percentual) / 100).toFixed(2)
	);

	if (item.tributos?.pis.valor === 0) {
		item.tributos.pis.valorBase = 0;
	}
}

export function calcularCofins(item) {
	item.tributos.cofins.valorBase = parseFloat(
		((item.baseCalculo * item.tributos?.cofins.percentualBase) / 100).toFixed(2)
	);
	item.tributos.cofins.valor = parseFloat(
		((item.tributos?.cofins.valorBase * item.tributos?.cofins.percentual) / 100).toFixed(2)
	);

	if (item.tributos?.cofins.valor === 0) {
		item.tributos.cofins.valorBase = 0;
	}
}

export function calcularBaseCalculo(item) {
	item.baseCalculo = parseFloat((item?.valor * item?.quantidade - item?.desconto).toFixed(2));
}

export function calcularImpostosServico(servico) {
	calcularBaseCalculo(servico);
	calcularIss(servico);
	calcularIr(servico);
	calcularCsll(servico);
	calcularInss(servico);
	calcularPis(servico);
	calcularCofins(servico);
	return servico;
}

export async function montarCalcularImpostosServico(municipioId, servico) {
	let item = servico;

	function montarIss(tributacoes) {
		item.tributos.iss.cst = converterValueParaSelect(
			tributacoes.iss.cst,
			`${tributacoes.iss.cst?.codigo} - ${tributacoes.iss.cst?.descricao}`
		);
		item.tributos.iss.percentualBase = tributacoes.iss.percentualBaseCalculo;
		item.tributos.iss.percentual = tributacoes.iss.aliquota;
	}

	function montarIr(tributacoes) {
		item.tributos.ir.percentualBase = tributacoes.ir.percentualBaseCalculo;
		item.tributos.ir.percentual = tributacoes.ir.aliquota;
	}

	function montarCsll(tributacoes) {
		item.tributos.csll.percentualBase = tributacoes.csll.percentualBaseCalculo;
		item.tributos.csll.percentual = tributacoes.csll.aliquota;
	}

	function montarInss(tributacoes) {
		item.tributos.inss.percentualBase = tributacoes.inss.percentualBaseCalculo;
		item.tributos.inss.percentual = tributacoes.inss.aliquota;
	}

	function montarPis(tributacoes) {
		item.tributos.pis.cst = converterValueParaSelect(
			tributacoes.pis.cst,
			`${tributacoes.pis.cst?.codigo} - ${tributacoes.pis.cst?.descricao}`
		);
		item.tributos.pis.percentualBase = tributacoes.pis.percentualBaseCalculo;
		item.tributos.pis.percentual = tributacoes.pis.aliquota;
	}

	function montarCofins(tributacoes) {
		item.tributos.cofins.cst = converterValueParaSelect(
			tributacoes.cofins.cst,
			`${tributacoes.cofins.cst?.codigo} - ${tributacoes.cofins.cst?.descricao}`
		);
		item.tributos.cofins.percentualBase = tributacoes.cofins.percentualBaseCalculo;
		item.tributos.cofins.percentual = tributacoes.cofins.aliquota;
	}

	function montarImpostos(tributacoes) {
		montarIss(tributacoes);
		montarIr(tributacoes);
		montarCsll(tributacoes);
		montarInss(tributacoes);
		montarPis(tributacoes);
		montarCofins(tributacoes);
	}

	async function processarServico() {
		await buscarTributacaoServico(
			item.servico?.value || item.servico?.id,
			municipioId,
			item.operacaoFiscal?.value || item.operacaoFiscal?.id,
			({ data: tributacoes }) => {
				montarImpostos(tributacoes);
			}
		);
		item = calcularImpostosServico(item);
		return item;
	}

	return await processarServico();
}

function converterValueParaSelect(value, label) {
	return value
		? {
				value: value.id ?? null,
				label: label ?? value.nome ?? value.descricao,
				registro: value,
			}
		: null;
}
