import {
	buscarConfiguracaoUsuario,
	configuracoesUsuario,
	construirUrl,
	permissoes,
	recursos,
	removerElemento,
	salvarConfiguracaoUsuario,
	services,
	usuarioPossuiPermissao,
} from 'Common';
import {
	ButtonEditarTable,
	ButtonExcluirTable,
	ButtonNovo,
	Col,
	DescricaoFiltroAvancado,
	Form,
	FormActions,
	FormContent,
	Grid,
	InputSearch,
	NenhumRegistroEncontrado,
	Paginacao,
	PesquisaAvancada,
	Tutorial,
	tipoCampos,
	tutorialStepsListagens,
} from 'components';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useEffect, useState } from 'react';
import { atualizarUrl } from 'views/Util';
import { useContextPesquisa } from 'views/Util/Context/ContextPesquisa';
import { confirmarExclusao } from 'views/Util/ExclusaoDeRegistros';
import { deleteFormaPagamento, readContas, readFormasPagamento } from './Requests';
import { CADASTRO_URL, FORMA_PAGAMENTO_FISCAL_OPTIONS, OPTIONS_FILTRO_AVANCADO } from './Util/constantes';

function FormaPagamento({ history }) {
	const {
		valorPesquisa,
		setValorPesquisa,
		setSortField,
		sortField,
		setSortOrder,
		sortOrder,
		page,
		setPage,
		rows,
		setRows,
		filtroAvancado,
		setFiltroAvancado,
		descricaoFiltroAvancado,
		setDescricaoFiltroAvancado,
	} = useContextPesquisa();

	const [registros, setRegistros] = useState([]);
	const [totalElements, setTotalElements] = useState(1);
	const [tutorialVisible, setTutorialVisible] = useState(false);
	const [isFirstRender, setIsFirstRender] = useState(true);
	const [filtroAvancadoOptions, setFiltroAvancadoOptions] = useState(OPTIONS_FILTRO_AVANCADO);

	const deveExibirTutorial = buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS);
	const podeInserir = usuarioPossuiPermissao(recursos.FORMAS_PAGAMENTO, permissoes.INSERIR);
	const podeEditar = usuarioPossuiPermissao(recursos.FORMAS_PAGAMENTO, permissoes.EDITAR);
	const podeExcluir = usuarioPossuiPermissao(recursos.FORMAS_PAGAMENTO, permissoes.EXCLUIR);
	const sortFieldLocal = sortField?.length > 0 ? sortField : 'descricao';

	useEffect(async () => {
		if (isFirstRender) {
			setSortOrder(1);
		}

		fetch();
	}, [filtroAvancado, rows, page]);

	useEffect(() => {
		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS, false, null, false);
		}

		setTimeout(() => {
			document.getElementById('input-search-forma-pagamento')?.focus();
		}, 500);
	}, []);

	useEffect(() => {
		setPage(0);
	}, [valorPesquisa]);

	async function fetch() {
		const filtro = getFilter();
		const url = construirUrl(
			`${services.GESTOR}/v1/formas_pagamento/resumo`,
			filtro || '?',
			rows,
			page,
			sortOrder > 0 ? `${sortFieldLocal},asc` : `${sortFieldLocal},desc`
		);

		await readFormasPagamento(url, ({ data }) => {
			setRegistros(data.content);
			setTotalElements(data.page.totalElements);
			setIsFirstRender(false);
		});
	}

	function getFilter() {
		let filter = String('?query=(')
			.concat(`descricao=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
			.concat(`conta.nome=contains="*${valorPesquisa.replaceAll('&', '%26')}*")`);

		if (filtroAvancado) {
			filter = filter.concat(`;(${filtroAvancado})`);
		}

		return filter;
	}

	async function fetchFiltroAvancado(filtro) {
		setFiltroAvancado(filtro);
	}

	function onSort(event) {
		setSortOrder(event.sortOrder);
		setSortField(event.sortField);
	}

	function handleChangePage(event) {
		setRows(event.rows);
		setPage(event.page);
	}

	function renderOptions(row) {
		return (
			<div style={{ display: 'flex' }}>
				<ButtonEditarTable onClick={() => handleClickEditar(row)} disabled={!podeEditar} />
				<ButtonExcluirTable
					onClick={() => handleClickExcluir(row)}
					podeExcluir={podeExcluir}
					disabled={!row.podeExcluir}
				/>
			</div>
		);
	}

	function renderFormaFiscal(row) {
		return FORMA_PAGAMENTO_FISCAL_OPTIONS.filter((item) => item.value === row.formaPagamentoFiscal)[0]?.label;
	}

	function handleClickEditar(row) {
		atualizarUrl(history, CADASTRO_URL, row.id);
	}

	function handleClickExcluir(row) {
		confirmarExclusao(() => deleteRegistro(row));
	}

	async function deleteRegistro(registro) {
		await deleteFormaPagamento(registro.id, () => {
			setRegistros(removerElemento(registros, registro));
			setTotalElements(totalElements - 1);
		});
	}

	async function fetchOptionsFiltroAvancado() {
		const options = [...filtroAvancadoOptions];

		function addOption(newOption) {
			let indexOption = 0;
			const hasOption = options.find((e, i) => {
				if (e.name === newOption.name) {
					indexOption = i;
					return true;
				}
				return false;
			});

			if (hasOption) {
				options.splice(indexOption, 1);
			}

			options.push(newOption);
		}

		const promises = [
			readContas(({ data: response }) => {
				if (response.content) {
					const newOption = {
						label: 'Conta',
						name: 'conta.id',
						type: tipoCampos.SELECT,
						optionSelect: [],
					};
					response.content.forEach((option) => {
						newOption.optionSelect.push({ label: option.nome, value: option.id });
					});

					addOption(newOption);
				}
			}),
		];

		await Promise.all(promises).then(() => {
			setFiltroAvancadoOptions(
				options.sort((a, b) => {
					if (a.label < b.label) {
						return -1;
					}
					if (a.label > b.label) {
						return 1;
					}
					return 0;
				})
			);
		});
	}

	return (
		<>
			<Tutorial
				steps={tutorialStepsListagens}
				showSkipButton
				continuous
				disableScrolling
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>
			<Form header="Formas de pagamento">
				<FormActions>
					<ButtonNovo
						className="step-listagem-novo"
						label="Nova forma"
						onClick={() => history.push(CADASTRO_URL)}
						podeInserir={podeInserir}
						style={{ height: '32px' }}
					/>
				</FormActions>
				<FormContent>
					<Grid justifyCenter>
						<InputSearch
							id="input-search-forma-pagamento"
							className="step-listagem-input-search"
							onPesquisar={() => fetch(0)}
							value={valorPesquisa}
							onChange={setValorPesquisa}
						/>
						<Col sm="12" md="4" lg="3" xl="3" className="step-listagem-filtro-avancado">
							<PesquisaAvancada
								className="step-listagem-filtro-avancado"
								optionsFiltros={filtroAvancadoOptions}
								onPesquisarClick={fetchFiltroAvancado}
								onChangeFiltroRsql={setFiltroAvancado}
								onChangeDescricaoFiltro={setDescricaoFiltroAvancado}
								onOpen={fetchOptionsFiltroAvancado}
							/>
						</Col>
					</Grid>
					<DescricaoFiltroAvancado texto={descricaoFiltroAvancado} />
					<DataTable
						className="table"
						rowClassName="table-row"
						cellClassName="table-row-cell"
						responsive
						value={registros}
						sortField={sortFieldLocal}
						sortOrder={sortOrder}
						onSort={onSort}
						emptyMessage={<NenhumRegistroEncontrado />}
					>
						<Column
							field="descricao"
							header="Descriçao"
							body={(row) => row.descricao}
							style={{ width: '40%' }}
							sortable
						/>
						<Column
							field="conta.nome"
							header="Conta"
							body={(row) => row.conta?.nome ?? '—'}
							style={{ width: '30%' }}
							sortable
						/>
						<Column
							field="formaPagamentoFiscal"
							header="Forma fiscal"
							body={renderFormaFiscal}
							style={{ width: '24%' }}
							sortable
						/>
						<Column className="step-listagem-acoes" body={renderOptions} header="Ações" style={{ width: '6%' }} />
					</DataTable>
					<Paginacao totalElements={totalElements} rows={rows} page={page} onPageChange={handleChangePage} />
				</FormContent>
			</Form>
		</>
	);
}

export { FormaPagamento };
