import { construirUrl } from 'Common';
import { ButtonEditarTable, ButtonNovo, If, Modal, NenhumRegistroEncontrado, Paginacao } from 'components';
import { useFormikContext } from 'formik';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useEffect, useState } from 'react';
import { useContextMDFe } from 'views/transporte/MDFe/Context';
import { MDFE_SITUACAO, MDFE_TIPO_EMITENTE } from 'views/transporte/MDFe/Form/Util/constantes';
import { readDFesMDFe } from 'views/transporte/MDFe/Requests';
import { ModalDadosDFe } from './components/ModalDadosDFe';

function ModalDFe({ visible, onHide }) {
	const { values } = useFormikContext();
	const { informacoesPermissoes } = useContextMDFe();

	const [registros, setRegistros] = useState([]);
	const [registroSelecionado, setRegistroSelecionado] = useState({});
	const [sortOrder, setSortOrder] = useState(-1);
	const [sortField, setSortField] = useState('sequenciaEvento');
	const [totalElements, setTotalElements] = useState(-1);
	const [page, setPage] = useState(0);
	const [rows, setRows] = useState(10);
	const [visibleModalDados, setVisibleModalDados] = useState(false);

	const isTransmitido = Boolean(values.situacao === MDFE_SITUACAO.TRANSMITIDO);

	useEffect(() => {
		fetchDFes();
	}, [rows, page]);

	async function fetchDFes() {
		await readDFesMDFe(
			values.id,
			construirUrl('', '?', rows, page, sortOrder > 0 ? `${sortField},asc` : `${sortField},desc`),
			({ data: condutores }) => {
				setRegistros(condutores.content);
				setTotalElements(condutores.page.totalElements);
			}
		);
	}

	function handleClickSort(event) {
		setSortOrder(event.sortOrder);
		setSortField(event.sortField);
	}

	function handleChangePage(event) {
		setRows(event.rows);
		setPage(event.page);
	}

	function handleClickVerDados(row) {
		setRegistroSelecionado(row);
		setVisibleModalDados(true);
	}

	function renderOptions(row) {
		return (
			<div style={{ display: 'flex' }}>
				<ButtonEditarTable
					className="p-button p-button-primary"
					icon="fa fa-eye"
					title="Visualizar dados"
					onClick={() => handleClickVerDados(row)}
				/>
			</div>
		);
	}

	function handleClickNovo() {
		setVisibleModalDados(true);
	}

	function onHideModalDados(refresh = false) {
		setVisibleModalDados(false);
		setRegistroSelecionado({});
		if (refresh) {
			setPage(0);
			fetchDFes();
		}
	}

	return (
		<Modal header="Documentos fiscais eletrônico " visible={visible} onHide={onHide} styleModal={{ maxWidth: '650px' }}>
			<div>
				<ButtonNovo
					label="Incluir DF-e"
					onClick={handleClickNovo}
					disabled={!isTransmitido}
					{...informacoesPermissoes}
				/>
			</div>
			<DataTable
				className="table"
				rowClassName="table-row"
				cellClassName="table-row-cell"
				responsive
				value={registros}
				sortField={sortField}
				sortOrder={sortOrder}
				onSort={handleClickSort}
				emptyMessage={<NenhumRegistroEncontrado />}
			>
				<Column
					field="sequenciaEvento"
					header="Sequencial"
					body={(row) => row.sequenciaEvento}
					style={{ width: '15%' }}
					sortable
				/>
				<Column
					field="carregamentoMunicipio"
					header="Município carregamento"
					body={(row) => `${row.carregamentoMunicipio?.nome} - ${row.carregamentoMunicipio?.estado?.sigla}`}
					style={{ width: '53%' }}
					sortable
				/>
				<Column className="step-listagem-acoes" body={renderOptions} header="Ações" style={{ width: '7%' }} />
			</DataTable>
			<Paginacao totalElements={totalElements} rows={rows} page={page} onPageChange={handleChangePage} />
			<If test={visibleModalDados}>
				<ModalDadosDFe
					visible={visibleModalDados}
					onHide={onHideModalDados}
					values={registroSelecionado}
					canTransmitir={Object.keys(registroSelecionado)?.length === 0}
					proximoSequencial={registros.length + 1}
					id={values.id}
					siglaUfCarregamento={values.ufCarregamento?.registro?.sigla}
					municipioCarregamento={values.municipioCarregamento}
					isCargaPropria={values.tipoEmitente === MDFE_TIPO_EMITENTE.CARGA_PROPRIA}
				/>
			</If>
		</Modal>
	);
}

export { ModalDFe };
