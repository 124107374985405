import { permissoes, recursos, usuarioPossuiPermissao } from 'Common';
import { format, parseISO } from 'date-fns';
import { useEffect, useReducer } from 'react';
import { atualizarUrl } from 'views/Util';
import { CardList, TYPE_VALUE } from './components/CardList';
import { CardsResultado } from './components/CardsResultado';
import { ChartDocumentosPorDia } from './components/ChartDocumentosPorDia';
import { ChartDocumentosUltimosDozeMeses } from './components/ChartDocumentosUltimosDozeMeses';
import { dataReducer } from './reducer';
import {
	readDocumentosPorDia,
	readDocumentosUltimosDozeMeses,
	readRankingComponentesValorPrestacao,
	readRankingProdutosPredominantes,
	readRankingTomadores,
	readResultadoPeriodo,
} from './requests';
import {
	addDataRankingTomadores,
	addDataRankingComponentesValorPrestacao,
	addDataRankingProdutosPredominantes,
	setData,
} from './reducer/actions';

const { Col, Button } = require('components');

function DashboardCTe({ dataInicial, dataFinal, isMobile, history }) {
	const [
		{
			cardsResultado,
			rankingTomadores,
			rankingComponentesValorPrestacao,
			rankingProdutosPredominantes,
			documentosPorDia,
			documentosUltimosDozeMeses,
			podeInserirCTe,
			loading,
		},
		dispatch,
	] = useReducer(
		dataReducer,
		{
			cardsResultado: {},
			rankingTomadores: {},
			rankingComponentesValorPrestacao: {},
			rankingProdutosPredominantes: {},
			documentosPorDia: {},
			documentosUltimosDozeMeses: {},
			podeInserirCTe: false,
			loading: false, // passar true como padrao para o loading
		},
		(initialState) => ({
			...initialState,
			podeInserirCTe: usuarioPossuiPermissao(recursos.TRANSPORTE_MDFE, permissoes.INSERIR),
		})
	);

	const styleCards = isMobile ? { padding: '0.5em 0em', margin: '8px', flex: 'auto' } : { padding: '0.5em' };
	const styleContainerButton = {
		display: 'flex',
		justifyContent: isMobile ? 'center' : 'flex-start',
		padding: isMobile ? '15px 0 0 2px' : '0 0 0 2px',
	};
	const styleButton = {
		margin: isMobile ? '3px 5px' : '3px 7px',
		width: '185px',
	};

	useEffect(() => {
		fetchData();
	}, [dataInicial, dataFinal]);

	async function fetchData() {
		let newData = {
			loading: false,
		};
		const promises = [
			readResultadoPeriodo(dataInicial, dataFinal, ({ data }) => {
				const { periodoAtual, periodoAnterior } = data;
				const dataCards = {
					pendentes: {
						valor: periodoAtual.valorPendente,
						evolucao: calculateEvolution(periodoAtual.valorPendente, periodoAnterior.valorPendente),
					},
					finalizados: {
						valor: periodoAtual.valorTransmitido,
						evolucao: calculateEvolution(periodoAtual.valorTransmitido, periodoAnterior.valorTransmitido),
					},
					rejeitados: {
						valor: periodoAtual.valorRejeitado,
						evolucao: calculateEvolution(periodoAtual.valorRejeitado, periodoAnterior.valorRejeitado),
					},
					cancelados: {
						valor: periodoAtual.valorCancelado,
						evolucao: calculateEvolution(periodoAtual.valorCancelado, periodoAnterior.valorCancelado),
					},
				};
				newData = {
					...newData,
					cardsResultado: dataCards,
				};
			}),
			readRankingTomadores(dataInicial, dataFinal, 0, ({ data }) => {
				newData = {
					...newData,
					rankingTomadores: data,
				};
			}),
			readRankingComponentesValorPrestacao(dataInicial, dataFinal, 0, ({ data }) => {
				newData = {
					...newData,
					rankingComponentesValorPrestacao: data,
				};
			}),
			readRankingProdutosPredominantes(dataInicial, dataFinal, 0, ({ data }) => {
				newData = {
					...newData,
					rankingProdutosPredominantes: data,
				};
			}),
			readDocumentosPorDia(dataInicial, dataFinal, ({ data }) => {
				const dias = [];
				const pendentes = [];
				const transmitidos = [];
				const rejeitados = [];
				const cancelados = [];
				data.forEach((day) => {
					dias.push(format(parseISO(day.data), 'dd/MM/yyyy'));
					pendentes.push(day.valorPendente);
					transmitidos.push(day.valorTransmitido);
					rejeitados.push(day.valorRejeitado);
					cancelados.push(day.valorCancelado);
				});
				newData = {
					...newData,
					documentosPorDia: { dias, pendentes, transmitidos, rejeitados, cancelados },
				};
			}),
			readDocumentosUltimosDozeMeses(({ data }) => {
				const meses = [];
				const pendentes = [];
				const transmitidos = [];
				const rejeitados = [];
				const cancelados = [];
				data.forEach((day) => {
					meses.push(day.competencia);
					pendentes.push(day.valorPendente);
					transmitidos.push(day.valorTransmitido);
					rejeitados.push(day.valorRejeitado);
					cancelados.push(day.valorCancelado);
				});
				newData = {
					...newData,
					documentosUltimosDozeMeses: {
						meses,
						pendentes,
						transmitidos,
						rejeitados,
						cancelados,
					},
				};
			}),
		];
		await Promise.all(promises).then(() => dispatch(setData(newData)));
	}

	function handleClickNovaOrdem() {
		atualizarUrl(history, '/ctes/cadastro');
	}

	function calculateEvolution(value, oldValue) {
		if (oldValue === 0) {
			return 0;
		}

		return ((value * 100) / oldValue - 100).toFixed(0);
	}

	async function handleClickVerMaisRankingTomadores(page) {
		await readRankingTomadores(dataInicial, dataFinal, page, ({ data }) => {
			dispatch(addDataRankingTomadores(data));
		});
	}

	async function handleClickVerMaisRankingComponentesValorPrestacao(page) {
		await readRankingComponentesValorPrestacao(dataInicial, dataFinal, page, ({ data }) => {
			dispatch(addDataRankingComponentesValorPrestacao(data));
		});
	}

	async function handleClickVerMaisRankingProdutosPredominantes(page) {
		await readRankingProdutosPredominantes(dataInicial, dataFinal, page, ({ data }) => {
			dispatch(addDataRankingProdutosPredominantes(data));
		});
	}

	return (
		<>
			<Col style={styleContainerButton}>
				<Button
					label="Nova CT-e"
					onClick={handleClickNovaOrdem}
					style={styleButton}
					disabled={!podeInserirCTe}
					title={!podeInserirCTe ? 'Você não possui permissão para inserir uma nova CT-e' : null}
				/>
			</Col>
			<CardsResultado data={cardsResultado} isMobile={isMobile} />
			<Col sm="6" md="4" lg="4" xl="4" style={styleCards}>
				<CardList
					title="Ranking de tomadores"
					helpMessage="Lista dos tomadores mais usados em CT-es"
					data={rankingTomadores?.content || []}
					fieldLabel="nome"
					fieldValue="quantidade"
					typeValue={TYPE_VALUE.INTEGER}
					loading={loading}
					visibleButtonVerMais={((rankingTomadores?.page?.number ?? 0) + 1) * 10 < (rankingTomadores?.page?.totalElements ?? 0)}
					onClickVerMais={handleClickVerMaisRankingTomadores}
					page={rankingTomadores?.page?.number ?? 0}
				/>
			</Col>
			<Col sm="6" md="4" lg="4" xl="4" style={styleCards}>
				<CardList
					title="Ranking de componentes valor prestação"
					helpMessage="Lista dos componentes mais usados em CT-es"
					data={rankingComponentesValorPrestacao?.content || []}
					fieldLabel="nome"
					typeValue={TYPE_VALUE.INTEGER}
					loading={loading}
					visibleButtonVerMais={((rankingComponentesValorPrestacao?.page?.number ?? 0) + 1) * 10 < (rankingComponentesValorPrestacao?.page?.totalElements ?? 0)}
					onClickVerMais={handleClickVerMaisRankingComponentesValorPrestacao}
					page={rankingComponentesValorPrestacao?.page?.number ?? 0}
				/>
			</Col>
			<Col sm="6" md="4" lg="4" xl="4" style={styleCards}>
				<CardList
					title="Ranking de produtos predominantes"
					helpMessage="Lista dos produtos predominantes mais usados em CT-es"
					data={rankingProdutosPredominantes?.content || []}
					fieldLabel="nome"
					typeValue={TYPE_VALUE.INTEGER}
					loading={loading}
					visibleButtonVerMais={((rankingProdutosPredominantes?.page?.number ?? 0) + 1) * 10 < (rankingProdutosPredominantes?.page?.totalElements ?? 0)}
					onClickVerMais={handleClickVerMaisRankingProdutosPredominantes}
					page={rankingProdutosPredominantes?.page?.number ?? 0}
				/>
			</Col>
			<Col style={styleCards}>
				<ChartDocumentosPorDia
					title="CT-es no período selecionado"
					helpMessage="Lista o valor total das CT-es por situação no período selecionado"
					data={documentosPorDia}
					loading={loading}
					isMobile={isMobile}
				/>
			</Col>
			<Col style={styleCards}>
				<ChartDocumentosUltimosDozeMeses
					title="CT-es nos últimos 12 meses"
					helpMessage="Lista o valor total das CT-es agrupadas por mês nos últimos doze meses"
					data={documentosUltimosDozeMeses}
					loading={loading}
					isMobile={isMobile}
				/>
			</Col>
		</>
	);
}

export { DashboardCTe };
