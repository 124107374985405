function getProximoNivelRaizPasta(nodes) {
	let codigo = 1;
	for (const i in nodes) {
		if (nodes[i].codigoNivel === codigo) {
			codigo += 1;
		} else {
			return codigo;
		}
	}
	return codigo;
}

function getProximoNivelPasta(node) {
	let codigo = 1;
	for (const i in node.children) {
		if (node.children[i].codigoNivel === codigo) {
			codigo += 1;
		} else {
			return codigo;
		}
	}
	return codigo;
}

function updateExpandedKeys(node, _expandedKeys) {
	let keys = _expandedKeys;

	if (Object.keys(keys).length !== 0) {
		const keysChildren = Object.keys(keys);

		keysChildren.forEach((key, index) => {
			const splitKey = key.split('.');
			const splitNodeKey = node.key.split('.');

			if (
				Number(splitKey[splitKey.length - 1]) > Number(splitNodeKey[splitNodeKey.length - 1]) &&
				splitKey?.length === splitNodeKey?.length
			) {
				splitKey[splitKey.length - 1] = splitKey[splitKey.length - 1] - 1;
				keysChildren[keysChildren.indexOf(keysChildren[index])] = splitKey.join('.');

				keysChildren.forEach((value, indexValue) => {
					const splitKeyChildren = value.split('.');

					if (value > node.key && splitKeyChildren?.length > splitNodeKey?.length) {
						if (value.includes(key)) {
							splitKeyChildren[splitKey.length - 1] = (splitKeyChildren[splitKey.length - 1] - 1).toString();
							keysChildren[indexValue] = splitKeyChildren.join('.');
						}
					}
				});
			}
		});
		keys = keysChildren.reduce(
			(obj, item) => ({
				...obj,
				[item]: true,
			}),
			{}
		);
		return keys;
	}

	return null;
}

function updateNodeIcon(nodes, key, newIcon) {
	for (const node of nodes) {
		if (node.key === key) {
			node.icon = newIcon;
			return;
		}
		if (node.children && node.children.length > 0) {
			updateNodeIcon(node.children, key, newIcon);
		}
	}
}

export { getProximoNivelRaizPasta, getProximoNivelPasta, updateExpandedKeys, updateNodeIcon };
