import { Field, useFormikContext, withFormik } from 'formik';
import { useEffect } from 'react';
import { estadosCadastro, permissoes, recursos, usuarioPossuiPermissao } from 'Common';
import { mensagensDeValidacao } from '../../../../../Common/Constantes/mensagens';
import ButtonCancelar, { estadosBotaoCancelar } from '../../../../../components/Button/ButtonCancelar';
import ButtonExcluir from '../../../../../components/Button/ButtonExcluir';
import ButtonSalvar, { estadosBotaoSalvar } from '../../../../../components/Button/ButtonSalvar';
import Form from '../../../../../components/Form';
import FormActions from '../../../../../components/FormActions';
import FormContent from '../../../../../components/FormContent';
import Grid from '../../../../../components/Grid';
import If from '../../../../../components/If';
import InputField from '../../../../../components/input/InputField';
import AutoProgressBar from '../../../../../components/Loading/AutoProgressBar';
import Modal from '../../../../../components/Modal';
import { asyncPostPastas, asyncPutPastas } from '../../Requests';
import { INITIAL_VALUES } from './Utils/constantes';

function ModalPastaView({
	visible,
	onHide,
	header,
	pastaPai,
	pastaEdicao,
	inserirPasta,
	editarPasta,
	deletarNivel,
	callBackNovaPasta = true,
	simplifyMode = false,
}) {
	const { values, handleReset, validateForm, dirty, isValid } = useFormikContext();

	const podeInserir = usuarioPossuiPermissao(recursos.GESTAO_DOCUMENTOS, permissoes.INSERIR);
	const podeEditar = usuarioPossuiPermissao(recursos.GESTAO_DOCUMENTOS, permissoes.EDITAR);
	const podeExcluir = usuarioPossuiPermissao(recursos.GESTAO_DOCUMENTOS, permissoes.EXCLUIR);
	const estadoCadastro = values?.id ? estadosCadastro.EDICAO : estadosCadastro.INCLUSAO;

	const informacoesPermissoes = {
		estadoCadastro: estadoCadastro,
		podeInserir: podeInserir,
		podeEditar: podeEditar,
		podeExcluir: podeExcluir,
	};

	useEffect(() => {
		setTimeout(() => {
			document.getElementById('inputDescricaoPasta').focus();
		}, 300);
	}, []);

	function onCancelar() {
		if (dirty) {
			setTimeout(() => {
				document.getElementById('inputDescricaoPasta').focus();
			}, 100);

			handleReset();
		} else {
			onHide();
		}
	}

	async function onSalvar() {
		await validateForm();
		if (isValid) {
			if (!values.id) {
				await asyncPostPastas(values, (e) => {
					if (typeof inserirPasta === 'function' && callBackNovaPasta) {
						inserirPasta({ ...values, id: e.data.id });
					}
					onHide();
				});
			} else {
				await asyncPutPastas(values.id, values).then(() => {
					if (typeof editarPasta === 'function') {
						editarPasta({ id: values.id, descricao: values.descricao });
					}
					onHide();
				});
			}
		}
	}

	function onExcluir() {
		deletarNivel(pastaEdicao);
	}

	return (
		<Modal header={header} visible={visible} onHide={onHide}>
			<AutoProgressBar />
			<Form>
				<FormActions>
					<Grid style={{ margin: '0' }}>
						<ButtonCancelar
							estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
							onClick={onCancelar}
							{...informacoesPermissoes}
						/>
						<ButtonSalvar
							estadoBotao={estadosBotaoSalvar.SALVAR}
							disabled={!dirty}
							onClick={onSalvar}
							{...informacoesPermissoes}
						/>

						<ButtonExcluir onClick={onExcluir} disabled={!pastaEdicao} {...informacoesPermissoes} />
					</Grid>
				</FormActions>
				<FormContent>
					<Grid>
						<If test={pastaPai != null && !simplifyMode}>
							<Field
								sm="12"
								md="12"
								lg="12"
								xl="12"
								component={InputField}
								label="Pasta Pai"
								autoFocus
								disabled
								name="nivelPai"
								size={255}
								{...informacoesPermissoes}
							/>
						</If>
						<If test={!simplifyMode}>
							<Field
								sm="12"
								md="12"
								lg="12"
								xl="12"
								component={InputField}
								label="Nível"
								autoFocus
								obrigatorio
								disabled
								name="nivel"
								size={255}
								{...informacoesPermissoes}
							/>
						</If>
						<Field
							sm="12"
							md="12"
							lg="12"
							xl="12"
							id="inputDescricaoPasta"
							component={InputField}
							label="Descrição"
							autoFocus
							obrigatorio
							name="descricao"
							touched={false}
							size={120}
							{...informacoesPermissoes}
						/>
					</Grid>
				</FormContent>
			</Form>
		</Modal>
	);
}

const ModalPastas = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues(props) {
		const dados = INITIAL_VALUES;
		dados.nivel = props.novoNivel;
		dados.codigoNivel = props.codigoNivel;
		if (props.pastaEdicao) {
			dados.id = props.pastaEdicao.id;
			dados.descricao = props.pastaEdicao.descricao;
			if (!props.novoNivel && props.codigoNivel) {
				dados.nivel = props.codigoNivel;
			}
		} else {
			dados.id = null;
			dados.descricao = '';
		}

		if (props.pastaPai) {
			dados.nivelPai = props.pastaPai.label;
			dados.pastaPai = props.pastaPai.id;
		} else {
			dados.nivelPai = '';
			dados.pastaPai = null;
		}

		return dados;
	},

	validate(values) {
		const errors = {};

		if (values.descricao && values.descricao.trim() === '') {
			errors.descricao = mensagensDeValidacao.OBRIGATORIO;
		} else if (values.descricao.trim()?.length > 120) {
			errors.descricao = 'A descrição não pode ultrapassar 120 caracteres';
		}

		return errors;
	},

	handleSubmit: () => {},
})(ModalPastaView);

export { ModalPastas };
