import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Menu } from 'primereact/menu';
import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import {
	buscarConfiguracaoUsuario,
	configuracoesUsuario,
	construirUrl,
	permissoes,
	recursos,
	removerElemento,
	salvarConfiguracaoUsuario,
	services,
	usuarioPossuiPermissao,
} from 'Common';
import {
	Badge,
	Button,
	ButtonEditarTable,
	ButtonExcluirTable,
	ButtonNovo,
	Col,
	DescricaoFiltroAvancado,
	Form,
	FormActions,
	FormContent,
	Grid,
	InputSearch,
	NenhumRegistroEncontrado,
	Paginacao,
	PesquisaAvancada,
	Tutorial,
	tutorialStepsListagens,
} from 'components';
import { tipoCampos } from 'components/PesquisaAvancada/Util/constantes';
import { atualizarUrl } from 'views/Util';
import { useContextPesquisa } from 'views/Util/Context/ContextPesquisa';
import { confirmarExclusao } from 'views/Util/ExclusaoDeRegistros';
import { asyncGetMarcas } from 'views/cadastros/outros/Marca/Requests';
import { asyncDeleteModeloDeServico, asyncGetModelosDeServico } from './Request';
import { Colors, optionsFiltroAvancadoModelo, styleBackground, styleButtonOptions } from './Util/constantes';

function ModeloServico(props) {
	const {
		valorPesquisa,
		setValorPesquisa,
		sortField,
		setSortField,
		sortOrder,
		setSortOrder,
		page,
		setPage,
		rows,
		setRows,
		filtroAvancado,
		setFiltroAvancado,
		descricaoFiltroAvancado,
		setDescricaoFiltroAvancado,
		exibirBloqueadas,
		setExibirBloqueadas,
	} = useContextPesquisa();

	const [menu, setMenu] = useState(null);
	const [registroSelecionado, setRegistroSelecionado] = useState(null);
	const [registros, setRegistros] = useState([]);
	const [totalElements, setTotalElements] = useState(0);
	const [optionsFiltroAvancado, setOptionsFiltroAvancado] = useState(optionsFiltroAvancadoModelo);
	const [tutorialVisible, setTutorialVisible] = useState(false);
	const [isFirstRender, setIsFirstRender] = useState(true);

	const deveExibirTutorial = buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS);
	const podeInserir = usuarioPossuiPermissao(recursos.SERVICOS, permissoes.INSERIR);
	const podeEditar = usuarioPossuiPermissao(recursos.SERVICOS, permissoes.EDITAR);
	const podeExcluir = usuarioPossuiPermissao(recursos.SERVICOS, permissoes.EXCLUIR);

	const sortFieldLocal = sortField?.length > 0 ? sortField : 'nome';

	const itensMenu = montarItensMenu();

	const pesquisarCallback = useCallback(() => {
		if (!isFirstRender) {
			pesquisar();
		}
	});

	useEffect(() => {
		pesquisar();

		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS, false, null, false);
		}

		setTimeout(() => {
			if (document.getElementById('InputSearchModeloServico')) {
				document.getElementById('InputSearchModeloServico').focus();
			}
		}, 500);
	}, []);

	useEffect(() => {
		pesquisarCallback();
	}, [exibirBloqueadas, page, rows, sortFieldLocal, filtroAvancado]);

	async function pesquisar() {
		let filtro = buscarFiltro();
		filtro += `${exibirBloqueadas ? '' : ';situacao=="ATIVO"'}`;
		const url = construirUrl(
			`${services.GESTOR}/v1/servicos/modelo/resumo`,
			filtro,
			rows,
			page,
			sortOrder > 0 ? `${sortFieldLocal},asc` : `${sortFieldLocal},desc`
		);

		await asyncGetModelosDeServico(url, ({ data: modelos }) => {
			setRegistros(modelos.content);
			setTotalElements(modelos.page.totalElements);
			setIsFirstRender(false);

			if (!exibirBloqueadas && !!filtroAvancado && filtroAvancado.includes('situacao=="INATIVO"')) {
				setExibirBloqueadas(true);
			}
		});
	}

	async function ComplementarFiltroAvancado() {
		const options = [...optionsFiltroAvancado];

		function addOption(newOption) {
			let indexOption = 0;
			const hasOption = options.find((e, i) => {
				if (e.name === newOption.name) {
					indexOption = i;
					return true;
				}
				return false;
			});

			if (hasOption) {
				options.splice(indexOption, 1);
			}

			options.push(newOption);
		}

		const promises = [
			asyncGetMarcas(`${services.GESTOR}/v1/servicos/modelo/relacoes/marcas?size=500`, ({ data: marcasApi }) => {
				if (marcasApi.content) {
					const newOption = {
						label: 'Marcas',
						name: 'marca.id',
						type: tipoCampos.SELECT,
						optionSelect: [],
					};
					marcasApi?.content?.map((marca) => newOption.optionSelect.push({ label: marca.nome, value: marca.id }));

					addOption(newOption);
				}
			}),
		];

		await Promise.all(promises).then(() => {
			setOptionsFiltroAvancado(
				options.sort((a, b) => {
					if (a.label < b.label) {
						return -1;
					}
					if (a.label > b.label) {
						return 1;
					}
					return 0;
				})
			);
		});
	}

	function buscarFiltro() {
		let result = `?query=(nome=contains="*${valorPesquisa}*")`;

		if (filtroAvancado) {
			result += `;${filtroAvancado}`;
		}

		return result;
	}

	async function onPesquisarFiltroAvancado(filtro) {
		setFiltroAvancado(filtro);
	}

	function onSort(event) {
		setSortOrder(event.sortOrder);
		setSortField(event.sortField);
	}

	function onChangeCheckboxExibirInativas(element) {
		setExibirBloqueadas(element.checked);
	}

	function aplicarEstiloInativa(row, field) {
		if (row.situacao === 'INATIVO') {
			return (
				<span
					style={{
						fontStyle: 'italic',
						opacity: '0.7',
						wordBreak: 'break-word',
						maxWidth: props.isMobile ? '70%' : '100%',
						textAlign: props.isMobile ? 'end' : 'start',
					}}
				>
					{field}
				</span>
			);
		}
		return (
			<span
				style={{
					display: 'flex',
					wordBreak: 'break-word',
					maxWidth: props.isMobile ? '70%' : '100%',
					textAlign: props.isMobile ? 'end' : 'start',
				}}
			>
				{field}
			</span>
		);
	}

	function renderSituacao(row) {
		const styleDescription = {
			fontStyle: row.situacao === 'INATIVO' ? 'italic' : '',
			opacity: row.situacao === 'INATIVO' ? '0.7' : '',
			margin: row.situacao === 'ATIVO' ? '3px 16px' : '3px 10px',
		};

		switch (row.situacao) {
			case 'ATIVO': {
				return Badge(Colors.textAtivo, Colors.bgAtivo, 'Ativo', styleBackground, styleDescription);
			}
			case 'INATIVO': {
				return Badge(Colors.textInativo, Colors.bgInativo, 'Inativo', styleBackground, styleDescription);
			}
			default:
				return row.situacao;
		}
	}

	function renderOpcoes(row) {
		return (
			<div style={{ display: 'flex' }}>
				<ButtonEditarTable onClick={() => onEditar(row)} disabled={!podeEditar} />
				<ButtonExcluirTable onClick={() => onExcluir(row)} podeExcluir={podeExcluir} />
				<Button
					className="p-button-secondary"
					icon="fa fa-ellipsis-v"
					style={styleButtonOptions}
					title="Opções"
					disabled={row.situacao === 'INATIVO' || !podeInserir}
					aria-controls="popup_menu"
					aria-haspopup
					onClick={(event) => {
						setRegistroSelecionado(row);
						menu.toggle(event);
					}}
				/>
			</div>
		);
	}

	function montarItensMenu() {
		const itens = [];
		itens.push({
			label: 'Duplicar',
			icon: 'fa fa-copy',
			command: () => duplicar(registroSelecionado?.id),
		});

		return itens;
	}

	function duplicar(modeloId) {
		props.history.push({
			pathname: `/servicos/modelo/cadastro/${modeloId}`,
			state: { modeloId },
		});
	}

	function onEditar(row) {
		atualizarUrl(props.history, '/servicos/modelo/cadastro', row.id);
	}

	function onExcluir(row) {
		confirmarExclusao(() => asyncExcluirRegistro(row));
	}

	async function asyncExcluirRegistro(registro) {
		await asyncDeleteModeloDeServico(registro.id, () => {
			setRegistros(removerElemento(registros, registro));
			setTotalElements(totalElements - 1);
		});
	}

	function onPageChange(event) {
		setRows(event.rows);
		setPage(event.page);
	}

	return (
		<>
			<Tutorial
				steps={tutorialStepsListagens}
				showSkipButton
				continuous
				disableScrolling
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>
			<Form header="Modelo">
				<FormActions>
					<ButtonNovo
						className="step-listagem-novo"
						label="Novo modelo"
						onClick={() => {
							props.history.push('/servicos/modelo/cadastro');
						}}
						podeInserir={podeInserir}
					/>
				</FormActions>
				<FormContent>
					<Grid justifyCenter>
						<InputSearch
							id="InputSearchModeloServico"
							className="step-listagem-input-search"
							onPesquisar={() => pesquisar()}
							value={valorPesquisa}
							onChange={(value) => setValorPesquisa(value)}
						/>
						<Col sm="12" md="4" lg="3" xl="3" className="step-listagem-filtro-avancado">
							<PesquisaAvancada
								className="step-listagem-filtro-avancado"
								optionsFiltros={optionsFiltroAvancado}
								onPesquisarClick={onPesquisarFiltroAvancado}
								onChangeFiltroRsql={(rsql) => setFiltroAvancado(rsql)}
								onChangeDescricaoFiltro={(descricao) => setDescricaoFiltroAvancado(descricao)}
								onOpen={ComplementarFiltroAvancado}
							/>
						</Col>
					</Grid>
					<DescricaoFiltroAvancado texto={descricaoFiltroAvancado} />
					<Menu model={itensMenu} popup style={{ width: '200px' }} ref={(elemento) => setMenu(elemento)} />
					<DataTable
						className="table"
						rowClassName="table-row"
						cellClassName="table-row-cell"
						responsive
						value={registros}
						sortField={sortFieldLocal}
						sortOrder={sortOrder}
						onSort={onSort}
						emptyMessage={<NenhumRegistroEncontrado />}
						header={
							<span style={{ display: 'flex', justifyContent: 'flex-end' }}>
								<Checkbox
									name="exibirBloqueadas"
									inputId="exibirBloqueadas"
									checked={exibirBloqueadas}
									onChange={onChangeCheckboxExibirInativas}
								/>
								<label htmlFor="exibirBloqueadas" className="p-checkbox-label">
									Exibir modelos inativos
								</label>
							</span>
						}
					>
						<Column
							field="nome"
							header="Nome"
							body={(row) => aplicarEstiloInativa(row, row.nome)}
							sortable
							style={{ width: '43%' }}
						/>
						<Column
							field="marca"
							header="Marca"
							body={(row) => aplicarEstiloInativa(row, row.marca?.nome)}
							sortable
							style={{ width: '40%' }}
						/>
						<Column field="situacao" header="Situação" body={renderSituacao} sortable style={{ width: '10rem' }} />
						<Column className="step-listagem-acoes" body={renderOpcoes} header="Ações" />
					</DataTable>
					<Paginacao totalElements={totalElements} rows={rows} page={page} onPageChange={onPageChange} />
				</FormContent>
			</Form>
		</>
	);
}

function mapStateToProps(state) {
	return {
		isMobile: state.dispositivo.isMobile,
	};
}

export default connect(mapStateToProps)(ModeloServico);
