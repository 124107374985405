import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { GoBookmark } from 'react-icons/go';

import {
	Badge,
	ButtonEditarTable,
	ButtonExcluirTable,
	ButtonNovo,
	Checkbox,
	Col,
	DescricaoFiltroAvancado,
	Form,
	FormActions,
	FormContent,
	Grid,
	InputSearch,
	NenhumRegistroEncontrado,
	Paginacao,
	PesquisaAvancada,
	Tutorial,
	tutorialStepsListagens,
} from 'components';
import {
	buscarConfiguracaoUsuario,
	configuracoesUsuario,
	construirUrl,
	formatarParaPesquisarTiposEnumerados,
	permissoes,
	recursos,
	removerElemento,
	replaceCaracteresEspeciais,
	salvarConfiguracaoUsuario,
	services,
	usuarioPossuiPermissao,
} from 'Common';

import { atualizarUrl } from 'views/Util';
import { confirmarExclusao } from 'views/Util/ExclusaoDeRegistros';
import { useContextPesquisa } from 'views/Util/Context/ContextPesquisa';
import { CADASTRO_URL, COLORS, OPTIONS_FILTRO_AVANCADO, PESQUISA_URL, STYLE_BACKGROUND } from './Util/constantes';
import { deleteMercadoriaTransporte, getMercadoriasTransporte } from './Request';

function MercadoriaTransporteImpl({ isMobile, history }) {
	const {
		valorPesquisa,
		setValorPesquisa,
		page,
		setPage,
		rows,
		setRows,
		sortOrder,
		setSortOrder,
		sortField,
		setSortField,
		filtroAvancado,
		setFiltroAvancado,
		descricaoFiltroAvancado,
		setDescricaoFiltroAvancado,
		exibirBloqueadas,
		setExibirBloqueadas,
	} = useContextPesquisa();

	const [registros, setRegistros] = useState([]);
	const [totalElements, setTotalElements] = useState(0);
	const [tutorialVisible, setTutorialVisible] = useState(false);
	const [firstRender, setFirstRender] = useState(true);
	const deveExibirTutorial = buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS);
	const podeInserir = usuarioPossuiPermissao(recursos.TRANSPORTE, permissoes.INSERIR);
	const podeEditar = usuarioPossuiPermissao(recursos.TRANSPORTE, permissoes.EDITAR);
	const podeExcluir = usuarioPossuiPermissao(recursos.TRANSPORTE, permissoes.EXCLUIR);

	const sortFieldLocal = sortField?.length > 0 ? sortField : 'nome';

	const pesquisarCallback = useCallback(() => {
		if (!firstRender) {
			pesquisar();
		}
	});

	useEffect(() => {
		pesquisar();

		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS, false, null, false);
		}

		const inputSearchMercadoria = document.getElementById('input-search-mercadoria');
		setTimeout(() => {
			if (inputSearchMercadoria) {
				inputSearchMercadoria.focus();
			}
		}, 300);
	}, []);

	useEffect(() => {
		pesquisarCallback();
	}, [exibirBloqueadas, page, rows, sortFieldLocal, filtroAvancado]);

	useEffect(() => {
		setPage(0);
	}, [valorPesquisa]);

	async function pesquisar() {
		let filtro = getFilter();
		filtro += `${exibirBloqueadas ? '' : ';situacao=="ATIVO"'}`;
		const url = construirUrl(
			`${services.GESTOR}/v1/${PESQUISA_URL}/resumo`,
			filtro,
			rows,
			page,
			sortOrder > 0 ? `${sortFieldLocal},asc` : `${sortFieldLocal},desc`
		);

		await getMercadoriasTransporte(url, ({ data }) => {
			setRegistros(data.content);
			setTotalElements(data.page.totalElements);
			setFirstRender(false);
		});
	}

	function getFilter() {
		const valorTiposEnumerados = formatarParaPesquisarTiposEnumerados(replaceCaracteresEspeciais(valorPesquisa));

		let filtroRSQL = String('?query=(')
			.concat(`nome=contains="*${valorTiposEnumerados}*",`)
			.concat(`codigo=contains="*${valorTiposEnumerados}*",`)
			.concat(`codigoBarra=contains="*${valorTiposEnumerados}*")`);

		if (filtroAvancado) {
			filtroRSQL += `;${filtroAvancado}`;
		}

		return filtroRSQL;
	}

	async function onPesquisarFiltroAvancado(filtro) {
		setFiltroAvancado(filtro);
	}

	function onSort(event) {
		setSortOrder(event.sortOrder);
		setSortField(event.sortField);
	}

	function onPageChange(event) {
		setRows(event.rows);
		setPage(event.page);
	}

	function renderNome(row) {
		return (
			<span style={{ display: 'flex', alignItems: 'center' }}>
				{row.nome}
				{row.favorito ? (
					<span>
						<GoBookmark size={22} color="#fbc02d" title="Mercadoria de transporte favorito" />
					</span>
				) : null}
			</span>
		);
	}

	function renderSituacao(row) {
		const styleDescription = {
			fontStyle: row.situacao === 'INATIVO' ? 'italic' : '',
			opacity: row.situacao === 'INATIVO' ? '0.7' : '',
			margin: row.situacao === 'ATIVO' ? '3px 16px' : '3px 10px',
		};

		switch (row.situacao) {
			case 'ATIVO': {
				return Badge(COLORS.textAtivo, COLORS.bgAtivo, 'Ativo', STYLE_BACKGROUND, styleDescription);
			}
			case 'INATIVO': {
				return Badge(COLORS.textInativo, COLORS.bgInativo, 'Inativo', STYLE_BACKGROUND, styleDescription);
			}
			default:
				return row.situacao;
		}
	}

	function renderOpcoes(row) {
		return (
			<div style={{ display: 'flex' }}>
				<ButtonEditarTable onClick={() => handleClickEditar(row)} disabled={!podeEditar} />
				<ButtonExcluirTable onClick={() => handleClickExcluir(row)} podeExcluir={podeExcluir} />
			</div>
		);
	}

	function handleClickEditar(row) {
		atualizarUrl(history, `/${CADASTRO_URL}`, row.id);
	}

	function handleClickExcluir(row) {
		confirmarExclusao(() => deleteRegistro(row));
	}

	async function deleteRegistro(registro) {
		await deleteMercadoriaTransporte(registro.id, () => {
			setRegistros(removerElemento(registros, registro));
			setTotalElements(totalElements - 1);
		});
	}

	function aplicarEstiloInativa(row, field) {
		if (row.situacao === 'INATIVO') {
			return <span style={{ fontStyle: 'italic', opacity: '0.7' }}>{field}</span>;
		}
		return (
			<span
				style={{
					display: 'flex',
					wordBreak: 'break-all',
					paddingLeft: isMobile ? '15%' : '0',
				}}
			>
				{field}
			</span>
		);
	}

	function onChangeCheckboxExibirInativos(element) {
		setExibirBloqueadas(element.checked);
	}

	return (
		<>
			<Tutorial
				steps={tutorialStepsListagens}
				showSkipButton
				continuous
				disableScrolling
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>
			<Form header="Mercadoria de transporte">
				<FormActions>
					<ButtonNovo
						className="step-listagem-novo"
						label="Nova mercadoria"
						onClick={() => {
							history.push(`/${CADASTRO_URL}`);
						}}
						podeInserir={podeInserir}
					/>
				</FormActions>
				<FormContent>
					<Grid justifyCenter>
						<InputSearch
							id="input-search-mercadoria"
							className="step-listagem-input-search"
							onPesquisar={() => pesquisar(0)}
							value={valorPesquisa}
							onChange={(value) => setValorPesquisa(value)}
						/>
						<Col sm="12" md="4" lg="3" xl="3" className="step-listagem-filtro-avancado">
							<PesquisaAvancada
								className="step-listagem-filtro-avancado"
								optionsFiltros={OPTIONS_FILTRO_AVANCADO}
								onPesquisarClick={onPesquisarFiltroAvancado}
								onChangeFiltroRsql={(rsql) => setFiltroAvancado(rsql, () => {})}
								onChangeDescricaoFiltro={setDescricaoFiltroAvancado}
							/>
						</Col>
					</Grid>
					<DescricaoFiltroAvancado texto={descricaoFiltroAvancado} />
					<DataTable
						className="table"
						responsive
						value={registros}
						sortField={sortFieldLocal}
						sortOrder={sortOrder}
						onSort={onSort}
						emptyMessage={<NenhumRegistroEncontrado />}
						header={
							<div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
								<span>
									<Checkbox
										name="exibirBloqueadas"
										inputId="exibirBloqueadas"
										checked={exibirBloqueadas}
										onChange={onChangeCheckboxExibirInativos}
										label={isMobile ? 'Exibir mercadorias inativos' : 'Exibir mercadorias de transporte inativos'}
									/>
								</span>
							</div>
						}
					>
						<Column
							field="codigo"
							header="Código"
							body={(row) => aplicarEstiloInativa(row, row.codigo)}
							sortable
							style={{ width: '6rem' }}
						/>
						<Column field="nome" header="Nome" body={(row) => aplicarEstiloInativa(row, renderNome(row))} sortable />
						<Column
							field="codigoBarra"
							header="Código de barra"
							body={(row) => (row.codigoBarra ? aplicarEstiloInativa(row, row.codigoBarra) : '-')}
							sortable
							style={{ width: '20%' }}
						/>
						<Column field="situacao" header="Situação" body={renderSituacao} sortable style={{ width: '15%' }} />
						<Column className="step-listagem-acoes" body={renderOpcoes} header="Ações" style={{ width: '7rem' }} />
					</DataTable>
					<Paginacao totalElements={totalElements} rows={rows} page={page} onPageChange={onPageChange} />
				</FormContent>
			</Form>
		</>
	);
}

function mapStateToProps(state) {
	return {
		isMobile: state.dispositivo.isMobile,
	};
}

export const MercadoriaTransporte = connect(mapStateToProps)(MercadoriaTransporteImpl);
