import { Switch, withRouter } from 'react-router-dom';
import { permissoes, recursos } from 'Common';
import PrivateRoute from 'components/Route';

import Pessoas from 'views/cadastros/Pessoas';
import PessoasForm from 'views/cadastros/Pessoas/Form';

import Produtos from 'views/cadastros/produtos/Produtos';
import ProdutosForm from 'views/cadastros/produtos/Produtos/Form';
import ProdutoGrupos from 'views/cadastros/produtos/Grupos';

import { Etiquetas } from 'views/cadastros/produtos/Etiquetas';
import { EtiquetasConfiguracoes } from 'views/cadastros/produtos/Etiquetas/components/configuracao';

import Ncm from 'views/cadastros/fiscal/Ncm';
import NcmForm from 'views/cadastros/fiscal/Ncm/Form';

import CodigoServico from 'views/cadastros/fiscal/CodigoServico';
import CodigoServicoForm from 'views/cadastros/fiscal/CodigoServico/Form';
import CodigoServicoProvider from 'views/cadastros/fiscal/CodigoServico/Context';

import OperacoesFiscais from 'views/cadastros/fiscal/OperacaoFiscal';
import OperacoesFiscaisForm from 'views/cadastros/fiscal/OperacaoFiscal/Form';

import TributacaoEstadual from 'views/cadastros/fiscal/TributacaoEstadual';
import TributacaoEstadualForm from 'views/cadastros/fiscal/TributacaoEstadual/Form';

import TributacaoFederal from 'views/cadastros/fiscal/TributacaoFederal';
import TributacoesFederalModalView from 'views/cadastros/fiscal/TributacaoFederal/Form';

import Contas from 'views/cadastros/financas/Contas';
import Categorias from 'views/cadastros/financas/Categorias';

import { TributacaoMunicipal } from 'views/cadastros/fiscal/TributacaoMunicipal';
import { TributacaoMunicipalForm } from 'views/cadastros/fiscal/TributacaoMunicipal/Form';

import ObjetoDeServico from 'views/cadastros/servicos/ObjetoDeServico';
import ObjetoDeServicoForm from 'views/cadastros/servicos/ObjetoDeServico/Form';

import Marca from 'views/cadastros/outros/Marca';
import MarcaForm from 'views/cadastros/outros/Marca/Form';

import ModeloServico from 'views/cadastros/servicos/Modelo';
import ModeloServicoForm from 'views/cadastros/servicos/Modelo/Form';

import Setores from 'views/cadastros/outros/Setores';
import SetoresForm from 'views/cadastros/outros/Setores/Form';

import Tecnicos from 'views/cadastros/outros/Tecnicos';
import TecnicosForm from 'views/cadastros/outros/Tecnicos/Form';

import Pastas from 'views/cadastros/Pastas';

import EtiquetaProvider from 'views/cadastros/produtos/Etiquetas/Context';
import CadastroEtiqueta from 'views/cadastros/produtos/Etiquetas/components/configuracao/Form';
import EtiquetaConfiguracaoProvider from 'views/cadastros/produtos/Etiquetas/components/configuracao/Form/Context';

import { CondutoresTransporte } from 'views/cadastros/transporte/Condutores';
import { CondutoresForm } from 'views/cadastros/transporte/Condutores/Form';
import { VeiculosTransporte } from 'views/cadastros/transporte/Veiculos';
import { VeiculosForm } from 'views/cadastros/transporte/Veiculos/Form';
import { SeguradoraForm } from 'views/cadastros/transporte/Seguradora/Form';
import { SeguradorasTransporte } from 'views/cadastros/transporte/Seguradora';

import { FormaPagamento } from 'views/cadastros/financas/FormaPagamento';
import { FormaPagamentoForm } from 'views/cadastros/financas/FormaPagamento/Form';
import { CondicaoPagamento } from 'views/cadastros/financas/CondicaoPagamento';
import { CondicaoPagamentoForm } from 'views/cadastros/financas/CondicaoPagamento/Form';
import CondicaoPagamentoProvider from 'views/cadastros/financas/CondicaoPagamento/Context';

import { Regiao } from 'views/cadastros/vendas/Regiao';
import { RegiaoForm } from 'views/cadastros/vendas/Regiao/Form';
import RegiaoVendaProvider from 'views/cadastros/vendas/Regiao/Context';
import { TabelaPreco } from 'views/cadastros/vendas/TabelaPreco';
import { TabelaPrecoForm } from 'views/cadastros/vendas/TabelaPreco/Form';
import TabelaPrecoProvider from 'views/cadastros/vendas/TabelaPreco/Context';
import { Vendedores } from 'views/cadastros/vendas/Vendedores';
import { VendedoresForm } from 'views/cadastros/vendas/Vendedores/Form';
import VendedoresProvider from 'views/cadastros/vendas/Vendedores/Context';
import { ComponenteValorPrestacao } from 'views/cadastros/transporte/ComponenteValorPrestacao';
import { ComponenteValorPrestacaoForm } from 'views/cadastros/transporte/ComponenteValorPrestacao/Form';
import { MercadoriaTransporte } from 'views/cadastros/transporte/MercadoriaTransporte';
import { MercadoriaTransporteForm } from 'views/cadastros/transporte/MercadoriaTransporte/Form';

export const pathCadastrosRoutes = [
	'/pessoas',
	'/pessoas/cadastro',
	'/pessoas/cadastro/:id',
	'/produtos',
	'/produtos/cadastro',
	'/produtos/cadastro/:id',
	'/produtos/grupos',
	'/produtos/etiquetas',
	'/produtos/etiquetas/configuracao',
	'/produtos/etiquetas/configuracao/cadastro',
	'/produtos/etiquetas/configuracao/cadastro/:id',
	'/tributacoes/ncm',
	'/tributacoes/ncm/cadastro',
	'/tributacoes/ncm/cadastro/:id',
	'/tributacoes/codigo_servico',
	'/tributacoes/codigo_servico/cadastro',
	'/tributacoes/codigo_servico/cadastro/:id',
	'/tributacoes/operacoes_fiscais',
	'/tributacoes/operacoes_fiscais/cadastro',
	'/tributacoes/operacoes_fiscais/cadastro/:id',
	'/tributacoes/municipal',
	'/tributacoes/municipal/cadastro',
	'/tributacoes/municipal/cadastro/:id',
	'/tributacoes/municipal/duplicar/:id',
	'/tributacoes/estadual',
	'/tributacoes/estadual/cadastro',
	'/tributacoes/estadual/cadastro/:id',
	'/tributacoes/federal',
	'/tributacoes/federal/cadastro',
	'/tributacoes/federal/cadastro/:id',
	'/contas',
	'/contas/:id',
	'/categorias',
	'/vendas/regioes',
	'/vendas/regioes/cadastro',
	'/vendas/regioes/cadastro/:id',
	'/vendas/tabelas_preco',
	'/vendas/tabelas_preco/cadastro',
	'/vendas/tabelas_preco/cadastro/:id',
	'/vendas/vendedores',
	'/vendas/vendedores/cadastro',
	'/vendas/vendedores/cadastro/:id',
	'/servicos/objeto',
	'/servicos/objeto/cadastro',
	'/servicos/objeto/cadastro/:id',
	'/marca',
	'/marca/cadastro',
	'/marca/cadastro/:id',
	'/servicos/modelo',
	'/servicos/modelo/cadastro',
	'/servicos/modelo/cadastro/:id',
	'/setores',
	'/setores/cadastro',
	'/setores/cadastro/:id',
	'/tecnicos',
	'/tecnicos/cadastro',
	'/tecnicos/cadastro/:id',
	'/transporte/componentes_valores_prestacao',
	'/transporte/componentes_valores_prestacao/cadastro',
	'/transporte/componentes_valores_prestacao/cadastro/:id',
	'/transporte/mercadoria',
	'/transporte/mercadoria/cadastro',
	'/transporte/mercadoria/cadastro/:id',
	'/transporte/condutores',
	'/transporte/condutores/cadastro',
	'/transporte/condutores/cadastro/:id',
	'/transporte/veiculos',
	'/transporte/veiculos/cadastro',
	'/transporte/veiculos/cadastro/:id',
	'/transporte/seguradora',
	'/transporte/seguradora/cadastro',
	'/transporte/seguradora/cadastro/:id',
	'/formas_pagamento',
	'/formas_pagamento/cadastro',
	'/formas_pagamento/cadastro/:id',
	'/condicoes_pagamento',
	'/condicoes_pagamento/cadastro',
	'/condicoes_pagamento/cadastro/:id',
	'/pastas',
];

function CadastrosRoutes() {
	return (
		<Switch>
			<PrivateRoute
				exact
				path="/pessoas"
				render={(props) => <Pessoas {...props} />}
				recurso={recursos.PESSOAS}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={['/pessoas/cadastro', '/pessoas/cadastro/:id']}
				render={(props) => <PessoasForm {...props} />}
				recurso={recursos.PESSOAS}
				permissao={permissoes.VISUALIZAR}
			/>

			<PrivateRoute
				exact
				path="/produtos"
				render={(props) => <Produtos {...props} />}
				recurso={recursos.PRODUTOS}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={['/produtos/cadastro', '/produtos/cadastro/:id']}
				render={(props) => <ProdutosForm {...props} />}
				recurso={recursos.PRODUTOS}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path="/produtos/grupos"
				render={(props) => <ProdutoGrupos {...props} />}
				recurso={recursos.PRODUTOS}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path="/produtos/etiquetas"
				render={(props) => (
					<EtiquetaProvider>
						<Etiquetas {...props} />
					</EtiquetaProvider>
				)}
				recurso={recursos.ETIQUETAS}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path="/produtos/etiquetas/configuracao"
				render={(props) => <EtiquetasConfiguracoes {...props} />}
				recurso={recursos.ETIQUETAS}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={['/produtos/etiquetas/configuracao/cadastro', '/produtos/etiquetas/configuracao/cadastro/:id']}
				render={(props) => (
					<EtiquetaConfiguracaoProvider>
						<CadastroEtiqueta {...props} />
					</EtiquetaConfiguracaoProvider>
				)}
				recurso={recursos.ETIQUETAS}
				permissao={permissoes.VISUALIZAR}
			/>

			<PrivateRoute
				exact
				path="/tributacoes/ncm"
				render={(props) => <Ncm {...props} />}
				recurso={recursos.TRIBUTACAO}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={['/tributacoes/ncm/cadastro', '/tributacoes/ncm/cadastro/:id']}
				render={(props) => <NcmForm {...props} />}
				recurso={recursos.TRIBUTACAO}
				permissao={permissoes.VISUALIZAR}
			/>

			<PrivateRoute
				exact
				path="/tributacoes/codigo_servico"
				render={(props) => (
					<CodigoServicoProvider>
						<CodigoServico {...props} />
					</CodigoServicoProvider>
				)}
				recurso={recursos.TRIBUTACAO}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={['/tributacoes/codigo_servico/cadastro', '/tributacoes/codigo_servico/cadastro/:id']}
				render={(props) => (
					<CodigoServicoProvider>
						<CodigoServicoForm {...props} />
					</CodigoServicoProvider>
				)}
				recurso={recursos.TRIBUTACAO}
				permissao={permissoes.VISUALIZAR}
			/>

			<PrivateRoute
				exact
				path="/tributacoes/operacoes_fiscais"
				render={(props) => <OperacoesFiscais {...props} />}
				recurso={recursos.TRIBUTACAO}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={['/tributacoes/operacoes_fiscais/cadastro', '/tributacoes/operacoes_fiscais/cadastro/:id']}
				render={(props) => <OperacoesFiscaisForm {...props} />}
				recurso={recursos.TRIBUTACAO}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path="/tributacoes/municipal"
				render={(props) => <TributacaoMunicipal {...props} />}
				recurso={recursos.TRIBUTACAO}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={[
					'/tributacoes/municipal/cadastro',
					'/tributacoes/municipal/cadastro/:id',
					'/tributacoes/municipal/duplicar/:id',
				]}
				render={(props) => <TributacaoMunicipalForm {...props} />}
				recurso={recursos.TRIBUTACAO}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path="/tributacoes/estadual"
				render={(props) => <TributacaoEstadual {...props} />}
				recurso={recursos.TRIBUTACAO}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={['/tributacoes/estadual/cadastro', '/tributacoes/estadual/cadastro/:id']}
				render={(props) => <TributacaoEstadualForm {...props} />}
				recurso={recursos.TRIBUTACAO}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path="/tributacoes/federal"
				render={(props) => <TributacaoFederal {...props} />}
				recurso={recursos.TRIBUTACAO}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={['/tributacoes/federal/cadastro', '/tributacoes/federal/cadastro/:id']}
				render={(props) => <TributacoesFederalModalView {...props} />}
				recurso={recursos.TRIBUTACAO}
				permissao={permissoes.VISUALIZAR}
			/>

			<PrivateRoute
				exact
				path={['/contas', '/contas/:id']}
				render={(props) => <Contas {...props} />}
				recurso={recursos.CADASTROS_CONTAS_FINANCEIRAS}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={['/categorias']}
				render={(props) => <Categorias {...props} />}
				recurso={recursos.CADASTROS_CATEGORIAS_FINANCEIRAS}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path="/formas_pagamento"
				render={(props) => <FormaPagamento {...props} />}
				recurso={recursos.FORMAS_PAGAMENTO}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={['/formas_pagamento/cadastro', '/formas_pagamento/cadastro/:id']}
				render={(props) => <FormaPagamentoForm {...props} />}
				recurso={recursos.FORMAS_PAGAMENTO}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path="/condicoes_pagamento"
				render={(props) => <CondicaoPagamento {...props} />}
				recurso={recursos.CONDICOES_PAGAMENTO}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={['/condicoes_pagamento/cadastro', '/condicoes_pagamento/cadastro/:id']}
				render={(props) => (
					<CondicaoPagamentoProvider {...props}>
						<CondicaoPagamentoForm {...props} />
					</CondicaoPagamentoProvider>
				)}
				recurso={recursos.CONDICOES_PAGAMENTO}
				permissao={permissoes.VISUALIZAR}
			/>

			<PrivateRoute
				exact
				path="/vendas/regioes"
				render={(props) => <Regiao {...props} />}
				recurso={recursos.CADASTROS_REGIOES}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={['/vendas/regioes/cadastro', '/vendas/regioes/cadastro/:id']}
				render={(props) => (
					<RegiaoVendaProvider {...props}>
						<RegiaoForm {...props} />
					</RegiaoVendaProvider>
				)}
				recurso={recursos.CADASTROS_REGIOES}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path="/vendas/tabelas_preco"
				render={(props) => <TabelaPreco {...props} />}
				recurso={recursos.CADASTROS_TABELAS_PRECO}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={['/vendas/tabelas_preco/cadastro', '/vendas/tabelas_preco/cadastro/:id']}
				render={(props) => (
					<TabelaPrecoProvider {...props}>
						<TabelaPrecoForm />
					</TabelaPrecoProvider>
				)}
				recurso={recursos.CADASTROS_TABELAS_PRECO}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path="/vendas/vendedores"
				render={(props) => <Vendedores {...props} />}
				recurso={recursos.CADASTROS_VENDEDORES}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={['/vendas/vendedores/cadastro', '/vendas/vendedores/cadastro/:id']}
				render={(props) => (
					<VendedoresProvider {...props}>
						<VendedoresForm {...props} />
					</VendedoresProvider>
				)}
				recurso={recursos.CADASTROS_VENDEDORES}
				permissao={permissoes.VISUALIZAR}
			/>

			<PrivateRoute
				exact
				path="/servicos/objeto"
				render={(props) => <ObjetoDeServico {...props} />}
				recurso={recursos.SERVICOS}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={['/servicos/objeto/cadastro', '/servicos/objeto/cadastro/:id']}
				render={(props) => <ObjetoDeServicoForm {...props} />}
				recurso={recursos.SERVICOS}
				permissao={permissoes.VISUALIZAR}
			/>

			<PrivateRoute
				exact
				path="/marca"
				render={(props) => <Marca {...props} />}
				recurso={recursos.CADASTROS_MARCAS}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={['/marca/cadastro', '/marca/cadastro/:id']}
				render={(props) => <MarcaForm {...props} />}
				recurso={recursos.CADASTROS_MARCAS}
				permissao={permissoes.VISUALIZAR}
			/>

			<PrivateRoute
				exact
				path="/servicos/modelo"
				render={(props) => <ModeloServico {...props} />}
				recurso={recursos.SERVICOS}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={['/servicos/modelo/cadastro', '/servicos/modelo/cadastro/:id']}
				render={(props) => <ModeloServicoForm {...props} />}
				recurso={recursos.SERVICOS}
				permissao={permissoes.VISUALIZAR}
			/>

			<PrivateRoute
				exact
				path="/setores"
				render={(props) => <Setores {...props} />}
				recurso={recursos.SETORES}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={['/setores/cadastro', '/setores/cadastro/:id']}
				render={(props) => <SetoresForm {...props} />}
				recurso={recursos.SETORES}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path="/tecnicos"
				render={(props) => <Tecnicos {...props} />}
				recurso={recursos.TECNICOS}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={['/tecnicos/cadastro', '/tecnicos/cadastro/:id']}
				render={(props) => <TecnicosForm {...props} />}
				recurso={recursos.TECNICOS}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={['/transporte/componentes_valores_prestacao']}
				render={(props) => <ComponenteValorPrestacao {...props} />}
				recurso={recursos.TRANSPORTE}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={[
					'/transporte/componentes_valores_prestacao/cadastro',
					'/transporte/componentes_valores_prestacao/cadastro/:id',
				]}
				render={(props) => <ComponenteValorPrestacaoForm {...props} />}
				recurso={recursos.TRANSPORTE}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={['/transporte/mercadoria']}
				render={(props) => <MercadoriaTransporte {...props} />}
				recurso={recursos.TRANSPORTE}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={['/transporte/mercadoria/cadastro', '/transporte/mercadoria/cadastro/:id']}
				render={(props) => <MercadoriaTransporteForm {...props} />}
				recurso={recursos.TRANSPORTE}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={['/transporte/condutores']}
				render={(props) => <CondutoresTransporte {...props} />}
				recurso={recursos.TRANSPORTE}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={['/transporte/condutores/cadastro', '/transporte/condutores/cadastro/:id']}
				render={(props) => <CondutoresForm {...props} />}
				recurso={recursos.TRANSPORTE}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={['/transporte/veiculos']}
				render={(props) => <VeiculosTransporte {...props} />}
				recurso={recursos.TRANSPORTE}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={['/transporte/veiculos/cadastro', '/transporte/veiculos/cadastro/:id']}
				render={(props) => <VeiculosForm {...props} />}
				recurso={recursos.TRANSPORTE}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={['/transporte/seguradora']}
				render={(props) => <SeguradorasTransporte {...props} />}
				recurso={recursos.TRANSPORTE}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={['/transporte/seguradora/cadastro', '/transporte/seguradora/cadastro/:id']}
				render={(props) => <SeguradoraForm {...props} />}
				recurso={recursos.TRANSPORTE}
				permissao={permissoes.VISUALIZAR}
			/>
			<PrivateRoute
				exact
				path={['/pastas']}
				render={(props) => <Pastas {...props} />}
				recurso={recursos.GESTAO_DOCUMENTOS}
				permissao={permissoes.VISUALIZAR}
			/>
		</Switch>
	);
}

export default withRouter(CadastrosRoutes);
