import propTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { services } from 'Common';
import MultipleSelect from '../MultipleSelect';
import { formatarNcm } from '../../../Common/Mascara';
import { removerCaracteres } from '../../../Common/ManipulacaoDeString';
import { copiarObjeto } from '../../../Common/Array';

export default function MultipleSelectNcms(props) {
	const { value } = props;
	const [valueMulti, setValueMulti] = useState(null);

	useEffect(() => {
		let ncmSelecionados = value;
		if (ncmSelecionados?.length) {
			ncmSelecionados = copiarObjeto(value);
			for (const i in ncmSelecionados) {
				if (Object.hasOwn(ncmSelecionados, i)) {
					ncmSelecionados[i].label =
						`${formatarNcm(ncmSelecionados[i].registro.codigo)  } - ${  ncmSelecionados[i].registro.descricao}`;
				}
			}
		}
		setValueMulti(ncmSelecionados);
	}, [value]);
	
	function buscarUrlPesquisa(pesquisa, page) {
		const pesquisaCodigo = removerCaracteres(pesquisa, ['.']);
		return String(`${services.GESTOR}/v1/ncms?`)
			.concat('query=utilizado==false;situacao=="ATIVO";')
			.concat(`(descricao=contains="*${pesquisa}*",`)
			.concat(`codigo=contains="*${pesquisaCodigo}*")`)
			.concat(`&page=${page}&size=50&sort=codigo`);
	}
	function montarLabel(row) {
		if (row) {
			return `${formatarNcm(row.codigo)  } - ${  row.descricao}`;
		} else {
			return ''
		}
	}

	return (
		<MultipleSelect
			{...props}
			montarLabel={montarLabel}
			buscarUrlPesquisa={buscarUrlPesquisa}
			onChange={props.onChange}
			value={valueMulti}
		/>
	);
}

MultipleSelectNcms.propTypes = {
	filtroAdicionalUrl: propTypes.string,
	label: propTypes.string,
};

MultipleSelectNcms.defaultProps = {
	label: '',
	filtroAdicionalUrl: '',
};
