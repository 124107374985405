import { useEffect, useRef, useState } from 'react';
import { ProgressBar } from 'primereact/progressbar';
import FalhaAoCarregarAsInformacoes from '../../../FalhaAoCarregarAsInformacoes';
import { buscarProdutosMaisComprados } from './Requests';
import RankingMaisComprados from '../components/RankingMaisComprados';
import NenhumaInformacaoEncontrada from '../../../NenhumaInformacaoEncontrada';
import TituloCard from '../../../../../../../components/TituloCard';
import usePrevious from '../../../../../../../Common/Hooks/usePrevious';

const cardStyle = {
	padding: '7px 7px 20px 7px',
	borderRadius: '5px',
	backgroundColor: 'white',
	boxShadow: '#00000033 0px 1px 3px 0px, #00000024 0px 1px 1px 0px, #0000001f 0px 2px 1px -1px',
	minHeight: '100%',
};

const dashboardContent = {
	display: 'flex',
	justifyContent: 'space-between',
	width: '100%',
};

export default function ListaProdutosMaisComprados(props) {
	const { dataInicial, dataFinal, atualizarGraficoNovamente, dataAtualizacaoMenuLateral } = props;

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);

	const [produtos, setProdutos] = useState([]);
	const [totalElements, setTotalElements] = useState(0);

	const [page, setPage] = useState(0);
	const afterFirstRenderRef = useRef(false);
	const prevProps = usePrevious(props);

	useEffect(() => {
		carregarInformacoes();
	}, []);

	useEffect(() => {
		if (afterFirstRenderRef) {
			if (prevProps?.dataInicial !== dataInicial || prevProps?.dataFinal !== dataFinal) {
				carregarInformacoes();
			}

			if (prevProps?.atualizarGraficoNovamente !== atualizarGraficoNovamente) {
				carregarInformacoes(false);
			}
		} else afterFirstRenderRef = true;
	}, [dataInicial, dataFinal]);

	useEffect(() => {
		buscarProdutosMaisComprados(
			dataInicial,
			dataFinal,
			0,
			({ data }) => {
				setTotalElements(data.page.totalElements);
				setProdutos(data.content);
				setLoading(false);
				setError(false);
			},
			() => {
				setError(true);
				setLoading(false);
			}
		);
	}, [loading, dataAtualizacaoMenuLateral]);

	function carregarInformacoes(exibirLoading = true) {
		setLoading(exibirLoading);
		setError(false);
	}

	function onVerMaisClick() {
		const nextPage = page + 1;
		buscarProdutosMaisComprados(
			dataInicial,
			dataFinal,
			nextPage,
			({ data }) => {
				setTotalElements(data.page.totalElements);
				setProdutos([...produtos, ...data.content]);
				setLoading(false);
				setError(false);
				setPage(nextPage);
			},
			() => {
				setError(true);
				setLoading(false);
			}
		);
	}

	if (loading || error || produtos.length === 0) {
		return (
			<div style={cardStyle}>
				<TituloCard
					title={'Produtos mais comprados'}
					helpMessage="Lista os produtos mais comprados no período selecionado"
				/>
				<div style={dashboardContent}>
					{loading && (
						<ProgressBar
							mode="indeterminate"
							style={{ height: '3px', width: '100%', margin: '10px 0px' }}
						/>
					)}
					{error && <FalhaAoCarregarAsInformacoes />}
					{produtos.length === 0 && <NenhumaInformacaoEncontrada />}
				</div>
			</div>
		);
	} else {
		const itens = produtos.map(item => {
			return {
				descricao: item.produtoNome,
				valor: item.totalProduto,
			};
		});
		return (
			<div style={cardStyle}>
				<TituloCard
					title={'Produtos mais comprados'}
					helpMessage="Lista os produtos mais comprados no período selecionado"
				/>
				<RankingMaisComprados
					itens={itens}
					exibirButtonVerMais={(page + 1) * 10 < totalElements}
					onVerMaisClick={onVerMaisClick}
				/>
			</div>
		);
	}
}
