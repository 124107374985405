import { useEffect, useRef, useState } from 'react';
import { useUpdateEffect } from 'react-use';
import { format, parse } from 'date-fns';
import { GoArrowRight } from 'react-icons/go';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Checkbox as InternalCheckbox } from 'primereact/checkbox';
import { Menu } from 'primereact/menu';

import {
	Button,
	ButtonEditarTable,
	ButtonExcluirTable,
	confirm,
	Grid,
	If,
	InputSearch,
	NenhumRegistroEncontrado,
	notify,
	PesquisaAvancada,
	ToastTypes,
} from 'components';
import {
	colors,
	construirUrl,
	copiarObjeto,
	formatarMonetario,
	formatarParaPesquisarTiposEnumerados,
	services,
} from 'Common';

import { atualizarUrl } from 'views/Util';
import { ModalPastas } from 'views/cadastros/Pastas/components/ModalPastas';
import { asyncDeletarPastas } from 'views/cadastros/Pastas/Requests';
import { useContextPesquisa } from 'views/Util/Context/ContextPesquisa';
import { aplicarEstiloArquivado, buscarMensagemExcluirDocumento } from '../../Util/funcoesList';
import {
	asyncExcluirDocumento,
	asyncGetDocumentosParaPastas,
	asyncGetPastasNivelFilho,
	asyncGetPastasNivelRaiz,
} from '../../Requests';
import { buttonTableStyle, optionsFiltroAvancado, statusGestaoDocumento } from '../../Util/constantes';
import { converterPastasParaForm, converterDocumentosParaForm } from './Utils/converter';
import { OverlayPanelInformacoesComplementares } from '../OverlayPanelInformacoesComplementares';
import { OverlayAlteracaoPastaEmLote } from './components/OverlayAlteracaoPastaEmLote';
import { HeaderListagemPastas } from './components/Header';

import './Styles/index.css';

function ListagemPastas({
	isMobile,
	isTablet,
	isLessHd,
	history,
	location,
	podeInserir,
	podeEditar,
	podeExcluir,
	pastaAnterior,
	setPastaAnterior,
}) {
	const { valorPesquisa, setValorPesquisa, setFiltroAvancado, setDescricaoFiltroAvancado, filtroAvancado } =
		useContextPesquisa();

	const [registros, setRegistros] = useState([]);
	const [isFirstRender, setIsFirstRender] = useState(true);
	const [page, setPage] = useState(0);
	const [rows] = useState(20);
	const [pagination, setPagination] = useState(null);

	const [overlayInformacoesComplementares, setOverlayInformacoesComplementares] = useState(null);
	const [contemDocumentos, setContemDocumentos] = useState(false);
	const [documentosSelecionados, setDocumentosSelecionados] = useState([]);
	const [exibirBloqueados, setExibirBloqueados] = useState(false);
	const [totalDocumentos, setTotalDocumentos] = useState(0);
	const [itensBreadCrumb, setItensBreadCrumb] = useState([]);
	const [registroSelecionado, setRegistroSelecionado] = useState(null);
	const [limparFiltroPesquisaAvancada, setLimparFiltroPesquisaAvancada] = useState(false);

	const [visibleModalPasta, setVisibleModalPasta] = useState(false);
	const [headerModal, setHeaderModal] = useState('');
	const [novoNivel, setNovoNivel] = useState(null);
	const [codigoNivel, setCodigoNivel] = useState(null);
	const [pastaEdicao, setPastaEdicao] = useState(null);
	const [callBackNovaPasta, setCallBackNovaPasta] = useState(true);
	const [pastaPaiParaSubNivel, setPastaPaiParaSubNivel] = useState(null);

	const panelInformacoesComplementares = useRef(null);
	const panelAlteracaoPastaEmLote = useRef(null);
	const menuOpcoes = useRef(null);

	useEffect(() => {
		if (isFirstRender) {
			pesquisar(false, false, 0);
			setIsFirstRender(false);

			const { state } = location;

			if (state && state.pesquisaPadrao) {
				setValorPesquisa(state.pesquisaPadrao);
			}
		}
	}, []);

	useUpdateEffect(() => {
		pesquisar(false, !!pastaAnterior?.id, 0);
	}, [pastaAnterior, exibirBloqueados]);

	useUpdateEffect(() => {
		pesquisar(false, itensBreadCrumb?.length > 0, 0);
	}, [filtroAvancado]);

	async function pesquisar(addRegistros = false, buscarPasta = false, numeroPage = 0) {
		const filtro = buscarFiltro(pastaAnterior?.id);
		const url = construirUrl(
			`${services.GESTOR}/v1/documentos/gestao_documentos/resumo`,
			filtro,
			rows,
			numeroPage,
			`vigenciaInicio,desc`
		);
		setPage(numeroPage);

		let response;
		if (addRegistros) {
			response = await buscarNovosRegistros(url);
		} else if (buscarPasta) {
			setDocumentosSelecionados([]);
			response = await buscarNovaPasta(url);
		} else {
			setDocumentosSelecionados([]);
			response = await buscarNivelRaiz(url);
		}

		if (response.length > 0) {
			setContemDocumentos(response.find((documento) => documento.isDocumento));

			if (addRegistros) {
				const newDados = [...registros, ...response];
				setRegistros(newDados);
			} else {
				setRegistros(response);
			}
		} else if (buscarPasta) {
			setContemDocumentos(false);
			setRegistros([]);
		}
	}

	async function buscarNivelRaiz(url) {
		let response = [];
		const promises = [asyncGetPastasNivelRaiz(), asyncGetDocumentosParaPastas(url)];

		await Promise.all(promises).then(async (request) => {
			let dados = [];
			if (request.length > 0) {
				if (request[0]?.length > 0) {
					dados = converterPastasParaForm(request[0]);
				}
				if (request[1]?.data?.content?.length > 0) {
					setPagination({
						totalPages: request[1].data.page?.totalPages,
						totalElements: request[1].data.page?.totalElements,
						size: request[1].data.size,
					});
					setTotalDocumentos(request[1].data.content.length);

					dados.push(...(await converterDocumentosParaForm(request[1].data.content)));
				}
			}

			response = dados;
		});

		return response;
	}

	async function buscarNovosRegistros(url) {
		let response = [];
		const promises = [asyncGetDocumentosParaPastas(url)];

		await Promise.all(promises).then(async (request) => {
			if (request[0]?.data?.content?.length > 0) {
				setPagination({
					totalPages: request[0].data.page?.totalPages,
					totalElements: request[0].data.page?.totalElements,
					size: request[0].data.size,
				});
				setTotalDocumentos((prevState) => prevState + request[0].data.content.length);
				response = await converterDocumentosParaForm(request[0].data.content);
			}
		});
		return response;
	}

	async function buscarNovaPasta(url) {
		let response = [];
		const promises = [asyncGetPastasNivelFilho(pastaAnterior?.id), asyncGetDocumentosParaPastas(url)];

		await Promise.all(promises).then(async (request) => {
			let dados = [];
			if (request.length > 0) {
				if (request[0]?.length > 0) {
					dados = converterPastasParaForm(request[0]);
				}
				if (request[1]?.data?.content?.length > 0) {
					setPagination({
						totalPages: request[1].data.page?.totalPages,
						totalElements: request[1].data.page?.totalElements,
						size: request[1].data.size,
					});
					setTotalDocumentos((prevState) => prevState + request[1].data.content.length);
					dados.push(...(await converterDocumentosParaForm(request[1].data.content)));
				}
			}
			const nivelJaExisteNoBreadcrumb = !!itensBreadCrumb.findLast((item) => item.id === pastaAnterior.id);
			if (!nivelJaExisteNoBreadcrumb) {
				setItensBreadCrumb((prevState) => [
					...prevState,
					{
						label: pastaAnterior.descricao,
						id: pastaAnterior.id,
						pasta: pastaAnterior,
						command: () => {
							const pasta = copiarObjeto(pastaAnterior);
							const indexItemBreadCrumbAtual = prevState.findIndex((item) => item.id === pasta.id);
							const novoItensBreadCrumb = prevState.slice(indexItemBreadCrumbAtual + 1);
							setItensBreadCrumb(novoItensBreadCrumb);
							setPastaAnterior(pasta);
						},
					},
				]);
			}

			response = dados;
		});

		return response;
	}

	async function onPesquisarFiltroAvancado(e) {
		setFiltroAvancado(e);
		setLimparFiltroPesquisaAvancada(false);
	}

	function buscarFiltro(pastaId = null) {
		const valorTiposEnumerados = formatarParaPesquisarTiposEnumerados(valorPesquisa.replaceAll('&', ''));

		let filtroRSQL = `?query=(responsavel.pasta.id==${pastaId})`
			.concat(`;(identificacao=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
			.concat(`status=contains="*${valorTiposEnumerados}*")`);

		if (filtroAvancado) {
			filtroRSQL = filtroRSQL.concat(`;(${filtroAvancado})`);
		}
		if (!exibirBloqueados) {
			filtroRSQL += `;arquivado==${false}`;
		}

		return filtroRSQL;
	}

	function handleClickRow(e) {
		if (e.data?.isPasta) {
			setPastaAnterior(e.data);
		} else if (e.data?.isDocumento) {
			handleChangeCheckbox(e.data?.id, !documentosSelecionados[e.data?.id]);
		}
	}

	function renderIdentificacao(row) {
		if (row.isPasta) {
			return (
				<span
					style={{ display: 'flex', alignItems: 'center' }}
					title={
						row.vinculados > 1
							? `${row.vinculados} documentos presentes na pasta`
							: `${row.vinculados} documento presente na pasta`
					}
				>
					<i
						className="fa fa-folder-o"
						aria-hidden="true"
						style={{ marginRight: '12px', fontSize: '18px', color: colors.amareloButton }}
					/>
					{aplicarEstiloArquivado(row.arquivado, row.descricao, isMobile)}
					<span style={{ color: colors.principal, marginLeft: '12px', fontSize: '12px' }}>
						{`${row?.vinculados || 0} documento(s)`}
					</span>
				</span>
			);
		} else {
			return (
				<span style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
					<InternalCheckbox
						checked={documentosSelecionados[row.id]}
						onChange={(e) => handleChangeCheckbox(row.id, e.checked)}
					/>
					{aplicarEstiloArquivado(row.arquivado, row.identificacao, isMobile)}
				</span>
			);
		}
	}

	function renderVigencia(row, isMobile = false) {
		if (row && row.isDocumento) {
			const vigenciaInicio = row.vigenciaInicio
				? format(parse(row.vigenciaInicio, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')
				: null;
			const vigenciaFinal = row.vigenciaFinal
				? format(parse(row.vigenciaFinal, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')
				: null;
			const arquivado = row.arquivado ?? false;

			if (vigenciaInicio && vigenciaFinal) {
				return (
					<div style={{ display: 'flex', alignItems: 'center' }}>
						{aplicarEstiloArquivado(arquivado, vigenciaInicio, isMobile)} <GoArrowRight style={{ margin: '0px 5px' }} />{' '}
						{aplicarEstiloArquivado(arquivado, vigenciaFinal, isMobile)}
					</div>
				);
			} else if (vigenciaInicio) {
				return aplicarEstiloArquivado(arquivado, vigenciaInicio, isMobile);
			}
		}
		return null;
	}

	function renderValor(row) {
		if (row && row.isDocumento) {
			const valor = formatarMonetario(row.documentoValor);
			return aplicarEstiloArquivado(row.arquivado, valor, isMobile);
		}

		return null;
	}

	function renderValorMensal(row) {
		if (row && row.isDocumento) {
			const valor = formatarMonetario(row.documentoValorMensal);
			return aplicarEstiloArquivado(row.arquivado, valor, isMobile);
		}

		return null;
	}

	function renderStatus(row) {
		let strongColor = '#721358';
		let lightColor = '#BFA8C5';
		let texto = null;
		let paddingSize = '';

		if (row && row.isDocumento) {
			switch (row.status) {
				case statusGestaoDocumento.RESCINDIDO:
					texto = aplicarEstiloArquivado(row.arquivado, 'Rescindido');
					strongColor = '#da1f1f';
					lightColor = '#ffcdd2';
					paddingSize = '0.2rem 1.5rem';
					break;
				case statusGestaoDocumento.PENDENTE:
					texto = aplicarEstiloArquivado(row.arquivado, 'Pendente');
					strongColor = '#01579b';
					lightColor = '#b3e5fc';
					paddingSize = '0.2rem 1.5rem';
					break;
				case statusGestaoDocumento.VIGENCIA_EXPIRADA:
					texto = aplicarEstiloArquivado(row.arquivado, 'Vigência expirada');
					strongColor = '#c16b13';
					lightColor = '#fff7cd';
					paddingSize = '0.2rem 0.9rem';
					break;
				case statusGestaoDocumento.VIGENTE:
					texto = aplicarEstiloArquivado(row.arquivado, 'Vigente');
					strongColor = 'green';
					lightColor = '#dcedc8';
					paddingSize = '0.2rem 1.5rem';
					break;
				default:
					texto = '';
			}
		}
		return (
			<If test={texto}>
				<span
					style={{
						backgroundColor: lightColor,
						color: strongColor,
						fontWeight: 'bold',
						fontSize: '13px',
						borderRadius: '20px',
						display: 'flex',
						height: '1.5rem',
						width: '9rem',
						alignItems: 'center',
						justifyContent: 'center',
						padding: paddingSize,
					}}
				>
					<span style={{ width: '100%', textAlign: 'center' }}>{texto}</span>
				</span>
			</If>
		);
	}

	function renderOptions(row) {
		if (row && row.isDocumento) {
			return (
				<div style={{ display: 'flex', padding: 0 }}>
					<Button
						style={{
							borderRadius: '50%',
							padding: '5px',
							width: '30px',
							height: '30px',
						}}
						className="p-button p-button-primary"
						title="Informações complementares"
						icon="fa fa-info"
						onClick={(e) => handleShowOverlayInformacoesComplementares(e, row)}
					/>

					<ButtonEditarTable onClick={() => handleEditarDocumento(row)} />
					<ButtonExcluirTable
						color="secondary"
						style={buttonTableStyle}
						icon="fa fa-ellipsis-v"
						size="icon"
						disabled={!podeExcluir}
						onClick={() => handleExcluirDocumento(row)}
					/>
				</div>
			);
		} else if (row && row.isPasta) {
			const acoesDoBotao = buscarAcoes(row);
			return (
				<div style={{ display: 'flex', justifyContent: 'flex-end', padding: 0 }}>
					<Button
						color="secondary"
						style={buttonTableStyle}
						icon="fa fa-ellipsis-v"
						size="icon"
						disabled={Boolean(acoesDoBotao.length === 0)}
						title={acoesDoBotao.length === 0 ? 'Nenhuma operação possível para este documento' : null}
						onClick={(event) => {
							menuOpcoes.current.toggle(event);
							setRegistroSelecionado(row);
						}}
					/>
				</div>
			);
		}
		return null;
	}

	function handleShowOverlayInformacoesComplementares(e, row) {
		document
			.getElementById('overlayPanel-informacoes-complementares')
			?.getElementsByClassName('p-overlaypanel-close p-link')[0]
			?.click();

		setOverlayInformacoesComplementares(row);
		panelInformacoesComplementares.current?.toggle(e);
	}

	function handleEditarDocumento(row) {
		atualizarUrl(history, `/documentos/gestao_documentos/cadastro/${row.id}`);
	}

	async function handleExcluirDocumento(row) {
		confirm('Atenção', buscarMensagemExcluirDocumento(row), () => {
			asyncExcluirDocumento(row.id, () => {
				notify('Documento excluído', ToastTypes.SUCCESS);
			});
		});
	}

	function buscarAcoes(row) {
		const itens = [];
		if (podeInserir) {
			itens.push({
				label: 'Nova subpasta',
				icon: 'fa fa-plus',
				command: () => {
					const nivelFilhos = row.filhos || 0;
					setVisibleModalPasta(true);
					setNovoNivel(`${row.nivel}.${nivelFilhos + 1}`);
					setPastaPaiParaSubNivel(row);
					setCodigoNivel(nivelFilhos + 1);
					setHeaderModal('Nova pasta');
					setCallBackNovaPasta(false);
				},
			});
		}
		if (podeEditar) {
			itens.push({
				label: 'Editar pasta',
				icon: 'fa fa-pencil',
				command: () => {
					setVisibleModalPasta(true);
					setPastaEdicao(row);
					setNovoNivel(row.nivel);
					setCodigoNivel(row.codigoNivel);
					setHeaderModal('Editar pasta');
				},
			});
		}
		if (podeExcluir) {
			itens.push({
				label: 'Excluir',
				icon: 'fa fa-trash',
				command: () => deletarPasta(row),
			});
		}

		return itens;
	}

	function onHideModalPasta() {
		setVisibleModalPasta(false);
		setNovoNivel(null);
		setCodigoNivel(null);
		setPastaEdicao(null);
		setPastaPaiParaSubNivel(null);
	}

	function handleChangeCheckbox(idRegistro, checked) {
		setDocumentosSelecionados((prevState) => {
			const updatedState = { ...prevState };

			if (checked) {
				updatedState[idRegistro] = true;
			} else {
				delete updatedState[idRegistro];
			}

			return updatedState;
		});
	}

	function atualizarListagem() {
		pesquisar(false, !!pastaAnterior?.id, 0);
	}

	function getFooter() {
		return (
			<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
				<Button
					label="Carregar mais registros"
					icon="fa fa-arrow-down"
					disabled={!contemDocumentos || page >= pagination.totalPages - 1}
					onClick={() => {
						pesquisar(true, false, page + 1);
					}}
				/>
			</div>
		);
	}

	function editarPasta(pasta) {
		const novaLista = copiarObjeto(registros);
		const indexPasta = registros.findIndex((item) => item.id === pasta.id);
		novaLista[indexPasta].descricao = pasta.descricao;
		setRegistros(novaLista);
		setRegistroSelecionado(null);
	}

	function deletarPasta(pasta) {
		asyncDeletarPastas(pasta.id, () => {
			const indexPastaDeletada = registros.findIndex((item) => item.id === pasta.id);
			const novaLista = copiarObjeto(registros);
			novaLista.splice(indexPastaDeletada, 1);
			setRegistros(novaLista);
		});
	}

	function inserirPasta(registro) {
		const indexUltimaPasta = registros.findLastIndex((item) => item.isPasta);
		const novaLista = copiarObjeto(registros);
		const novaPasta = converterPastasParaForm([registro])[0];
		if (indexUltimaPasta >= 0) {
			novaLista.splice(indexUltimaPasta + 1, 0, novaPasta);
		} else {
			novaLista.unshift(novaPasta);
		}
		setRegistros(novaLista);
	}

	return (
		<>
			<Grid justifyCenter verticalAlignCenter>
				<InputSearch
					onPesquisar={() => {
						pesquisar(false, itensBreadCrumb?.length > 0, 0);
					}}
					value={valorPesquisa}
					onChange={(value) => setValorPesquisa(value)}
					removerEComercial={false}
					id="DocumentosInputSearch"
				/>
				<span style={{ padding: '12px' }}>
					<PesquisaAvancada
						optionsFiltros={optionsFiltroAvancado}
						onPesquisarClick={(e) => onPesquisarFiltroAvancado(e)}
						onChangeFiltroRsql={(rsql) => setFiltroAvancado(rsql)}
						onChangeDescricaoFiltro={(e) => setDescricaoFiltroAvancado(e)}
						limparFiltro={limparFiltroPesquisaAvancada}
					/>
				</span>
			</Grid>
			<BreadCrumb
				model={itensBreadCrumb}
				home={{
					icon: 'pi pi-home',
					command: () => {
						setPastaAnterior(null);
						setItensBreadCrumb([]);
					},
				}}
				style={{
					background: colors.brancoTransparente,
					fontSize: '20px',
					border: 'none',
					whiteSpace: 'nowrap',
					overflow: 'overlay',
					textOverflow: 'ellipsis',
				}}
			/>
			<Menu model={buscarAcoes(registroSelecionado)} popup style={{ minWidth: '230px' }} ref={menuOpcoes} />
			<DataTable
				className="table"
				selectionMode="checkbox"
				value={registros}
				emptyMessage={<NenhumRegistroEncontrado />}
				onRowClick={handleClickRow}
				header={
					<HeaderListagemPastas
						isMobile={isMobile}
						isTablet={isTablet}
						podeEditar={podeEditar}
						podeInserir={podeInserir}
						exibirBloqueados={exibirBloqueados}
						setExibirBloqueados={setExibirBloqueados}
						documentosSelecionados={documentosSelecionados}
						setDocumentosSelecionados={setDocumentosSelecionados}
						panelAlteracaoPastaEmLote={panelAlteracaoPastaEmLote}
						atualizarListagem={atualizarListagem}
						totalDocumentos={totalDocumentos}
						registros={registros}
						pastaPai={pastaAnterior}
						setPastaAnterior={setPastaAnterior}
						setVisibleModalPasta={setVisibleModalPasta}
						setNovoNivel={setNovoNivel}
						setCodigoNivel={setCodigoNivel}
						setHeaderModal={setHeaderModal}
						setCallBackNovaPasta={setCallBackNovaPasta}
						itensBreadCrumb={itensBreadCrumb}
						setItensBreadCrumb={setItensBreadCrumb}
					/>
				}
				footer={getFooter}
			>
				<Column
					field="identificacao"
					header="Identificação"
					body={(e) => renderIdentificacao(e)}
					style={{ overflow: 'hidden', overflowWrap: 'break-word' }}
				/>
				<Column
					field="vigencia"
					header="Vigência"
					style={{ width: '200px', fontSize: '14px' }}
					body={(row) => renderVigencia(row, isMobile)}
				/>
				<Column
					field="documentoValor"
					header={isLessHd ? 'Valor doc.' : 'Valor do documento'}
					style={{ width: '160px' }}
					body={(row) => renderValor(row)}
					sortable
				/>
				<Column
					field="documentoValorMensal"
					header="Valor mensal"
					style={{ width: '160px' }}
					body={(row) => renderValorMensal(row)}
					sortable
				/>
				<Column
					field="situacao"
					header="Situação"
					style={{ width: '160px' }}
					body={(row) => renderStatus(row, isMobile)}
				/>
				<Column field="acoes" header="Ações" style={{ width: '8rem' }} body={(row) => renderOptions(row, isMobile)} />
			</DataTable>
			<OverlayPanelInformacoesComplementares
				documento={overlayInformacoesComplementares}
				panelInformacoesComplementares={panelInformacoesComplementares}
				isMobile={isMobile}
				setDocumentoSelecionado={setOverlayInformacoesComplementares}
			/>
			<OverlayAlteracaoPastaEmLote
				documentosSelecionados={documentosSelecionados}
				panelAlteracaoPastaEmLote={panelAlteracaoPastaEmLote}
				isMobile={isMobile}
				atualizarListagem={atualizarListagem}
			/>
			<If test={visibleModalPasta}>
				<ModalPastas
					visible={visibleModalPasta}
					onHide={onHideModalPasta}
					header={headerModal}
					novoNivel={novoNivel}
					codigoNivel={codigoNivel}
					inserirPasta={inserirPasta}
					pastaPai={pastaPaiParaSubNivel || pastaAnterior}
					pastaEdicao={pastaEdicao}
					editarPasta={editarPasta}
					deletarNivel={deletarPasta}
					simplifyMode
					callBackNovaPasta={callBackNovaPasta}
				/>
			</If>
		</>
	);
}

export { ListagemPastas };
