import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useCallback, useEffect, useState } from 'react';
import { useUpdateEffect } from 'react-use';
import { confirmarExclusao } from 'views/Util/ExclusaoDeRegistros';

import { construirUrl, removerElemento, services } from 'Common';
import {
	Button,
	ButtonEditarTable,
	ButtonExcluirTable,
	If,
	Modal,
	NenhumRegistroEncontrado,
	Paginacao,
} from 'components';

import { asyncDeleteNotaDeVersao, asyncGetNotasDeVersaoResumo } from '../../Request';
import ModalNotaDeVersao from '../ModalNotaDeVersao';

function PainelNotasDeVersao(props) {
	const { visible, onHide, isAdmin } = props;
	const [registros, setRegistros] = useState([]);
	const [rows, setRows] = useState(10);
	const [page, setPage] = useState(0);
	const [sortField, setSortField] = useState('versao');
	const [sortOrder, setSortOrder] = useState(-1);
	const [totalElements, setTotalElements] = useState(0);
	const [modalNotaDeVersao, setModalNotaDeVersao] = useState(false);
	const [registroSelecionado, setRegistroSelecionado] = useState(null);

	const pesquisarCallback = useCallback(() => {
		pesquisar();
	});

	const url = construirUrl(
		`${services.GESTOR}/v1/notas_de_versao/listar_resumo`,
		null,
		rows,
		page,
		sortOrder > 0 ? `${sortField},asc` : `${sortField},desc`
	);

	useEffect(() => {
		pesquisarCallback();
	}, []);

	useUpdateEffect(() => {
		pesquisarCallback();
	}, [page, rows]);

	function pesquisar() {
		asyncGetNotasDeVersaoResumo(url, ({ data: notasDeVersao }) => {
			setRegistros(notasDeVersao?.content);
			setTotalElements(notasDeVersao.page.totalElements);
		});
	}

	function handleSortChange(element) {
		setSortField(element.sortField);
		setSortOrder(element.sortOrder);
	}

	function onPageChange(e) {
		setPage(e.page);
		setRows(e.rows);
	}

	function onHideModal() {
		setModalNotaDeVersao(false);
		setRegistroSelecionado(null);
		pesquisarCallback();
	}

	function renderAplicacao(row) {
		return row.aplicacao === 'MSYS_GESTOR' ? 'Msys Gestor' : 'DOCs Digitais';
	}

	function renderOpcoes(row) {
		return (
			<div style={{ display: 'flex' }}>
				<ButtonEditarTable onClick={() => onEditar(row)} />
				<ButtonExcluirTable onClick={() => onExcluir(row)} disabled={!isAdmin} />
			</div>
		);
	}

	function onExcluir(row) {
		confirmarExclusao(() => asyncExcluirRegistro(row));
	}

	async function asyncExcluirRegistro(registro) {
		await asyncDeleteNotaDeVersao(registro.id, 'Registro excluído com sucesso', () => {
			setRegistros(removerElemento(registros, registro));
			setTotalElements(totalElements - 1);
		});
	}

	function onEditar(row) {
		setModalNotaDeVersao(true);
		setRegistroSelecionado(row);
	}

	return (
		<Modal header="Painel notas de versão" visible={visible} onHide={onHide}>
			<Button
				label="Adicionar nota de versão"
				className="p-button-primary"
				icon="fa fa-plus"
				title="Adicionar nova nota de versão"
				onClick={() => {
					setModalNotaDeVersao(true);
				}}
				style={{ margin: '5px' }}
				disabled={!isAdmin}
			/>
			<DataTable
				className="table"
				responsive
				value={registros}
				sortField={sortField}
				sortOrder={sortOrder}
				onSort={handleSortChange}
				emptyMessage={<NenhumRegistroEncontrado />}
				style={{ maxHeight: '480px', overflowY: rows > 10 ? 'scroll' : 'hidden' }}
			>
				<Column field="versao" header="Versão" sortable />
				<Column field="aplicacao" header="Aplicação" body={renderAplicacao} sortable />
				<Column field="acao" header="Ação" body={renderOpcoes} style={{ width: '80px' }} />
			</DataTable>
			<Paginacao totalElements={totalElements} rows={rows} page={page} onPageChange={(e) => onPageChange(e)} />
			<If test={modalNotaDeVersao}>
				<ModalNotaDeVersao
					visible={modalNotaDeVersao}
					onHide={() => onHideModal()}
					isAdmin={isAdmin}
					idRegistroSelecionado={registroSelecionado?.id}
				/>
			</If>
		</Modal>
	);
}

export { PainelNotasDeVersao };
