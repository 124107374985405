import { useEffect, useState } from 'react';

import { Button, ButtonCancelar, Checkbox, Col, confirm, estadosBotaoCancelar, Grid, notify } from 'components';
import {
	asyncArquivarGestaoDocumento,
	asyncDesarquivarGestaoDocumento,
} from 'views/documentos/GestaoDocumento/Requests';
import { removerUltimoElemento } from 'Common/Array';
import { getSelectedDocumentIds } from '../../Utils/functions';

function HeaderListagemPastas({
	isMobile,
	isTablet,
	podeEditar,
	podeInserir,
	exibirBloqueados,
	setExibirBloqueados,
	documentosSelecionados,
	setDocumentosSelecionados,
	panelAlteracaoPastaEmLote,
	atualizarListagem,
	totalDocumentos,
	registros,
	setVisibleModalPasta,
	setNovoNivel,
	setCodigoNivel,
	setHeaderModal,
	setCallBackNovaPasta,
	pastaPai,
	itensBreadCrumb,
	setPastaAnterior,
	setItensBreadCrumb,
}) {
	const [todosDocumentosSelecionados, setTodosDocumentosSelecionados] = useState(false);
	const quantidadeDocumentosSelecionados = Object.keys(documentosSelecionados)?.length;

	useEffect(() => {
		setTodosDocumentosSelecionados(
			quantidadeDocumentosSelecionados === totalDocumentos && quantidadeDocumentosSelecionados > 0
		);
	}, [documentosSelecionados, totalDocumentos]);

	function arquivarSelecionados(documentos) {
		confirm(
			'Tem certeza que deseja arquivar estes documentos?',
			'Um documento arquivado não aparecerá mais na listagem, para visualizá-los marque o item "Exibir documentos arquivados" na listagem.',
			async () => {
				const promises = documentos.map(
					(documento) =>
						new Promise((resolve, reject) => {
							asyncArquivarGestaoDocumento(
								documento,
								() => {
									resolve();
								},
								() => {
									reject();
								},
								null,
								false
							);
						})
				);

				await Promise.all(promises);
				setDocumentosSelecionados([]);
				atualizarListagem();
				notify('Documentos arquivados com sucesso');
			}
		);
	}

	function desarquivarSelecionados(documentos) {
		confirm('Confirmação', 'Tem certeza que deseja desarquivar este(s) documento(s)?', async () => {
			const promises = documentos.map(
				(documento) =>
					new Promise((resolve, reject) => {
						asyncDesarquivarGestaoDocumento(
							documento,
							() => {
								resolve();
							},
							() => {
								reject();
							},
							null,
							false
						);
					})
			);

			await Promise.all(promises);
			atualizarListagem();
			setDocumentosSelecionados([]);
			notify('Documentos desarquivados com sucesso');
		});
	}

	function marcarTodosDocumentos() {
		if (registros.length > 0) {
			const documentosMarcados = [];
			registros.forEach((registro) => {
				if (registro.isDocumento) {
					documentosMarcados[registro.id] = true;
				}
			});

			setDocumentosSelecionados(documentosMarcados);
		}
	}

	function desmarcarTodosDocumentos() {
		setDocumentosSelecionados([]);
	}

	function handleShowOverlayAlteracaoLote(e) {
		document
			.getElementById('overlayPanel-informacoes-complementares')
			?.getElementsByClassName('p-overlaypanel-close p-link')[0]
			?.click();
		panelAlteracaoPastaEmLote.current?.toggle(e);
	}

	function onChangeCheckboxExibirBloqueados(e) {
		setDocumentosSelecionados([]);
		setExibirBloqueados(e.checked);
	}

	function adicionarNovaPasta() {
		const novoNivel = registros.reduce((numeroPasta, registro) => {
			if (registro.isPasta) {
				return numeroPasta + 1;
			}

			return numeroPasta;
		}, 1);

		setVisibleModalPasta(true);
		setNovoNivel(pastaPai ? `${pastaPai.nivel}.${novoNivel}` : novoNivel);
		setCodigoNivel(novoNivel);
		setHeaderModal('Nova pasta');
		setCallBackNovaPasta(true);
	}

	function voltarParaPastaAnterior() {
		const newItensBreadCrumb = removerUltimoElemento(itensBreadCrumb);
		if (itensBreadCrumb?.length > 1) {
			newItensBreadCrumb.at(-1)?.command();
		} else {
			setPastaAnterior(null);
			setItensBreadCrumb([]);
		}
	}

	return (
		<Grid justifyBetween verticalAlignCenter style={{ marginTop: '8px' }}>
			<Col sm={12} md={12} lg={4} xl={3} style={{ padding: '0px' }}>
				<Checkbox
					id="exibirBloqueados"
					checked={exibirBloqueados}
					name="exibirBloqueados"
					label="Exibir documentos arquivados"
					onChange={(e) => onChangeCheckboxExibirBloqueados(e)}
					colStyle={
						isMobile || isTablet
							? { padding: '0px 0px 12px 0px', display: 'flex', justifyContent: 'center' }
							: { padding: '0px' }
					}
				/>
			</Col>
			<Col className="content-button-header-listagem-pastas" sm={12} md={12} lg={8} xl={9}>
				<ButtonCancelar
					estadoBotao={estadosBotaoCancelar.VOLTAR}
					onClick={voltarParaPastaAnterior}
					title="Voltar para a pasta anterior"
					disabled={!pastaPai}
				/>
				<Button
					label={isMobile ? 'Add pasta' : 'Adicionar pasta'}
					type="button"
					color="primary"
					icon="fa fa-plus"
					disabled={!podeInserir}
					title="Adicionar nova pasta"
					onClick={() => adicionarNovaPasta()}
				/>
				<Button
					label="Marcar todos"
					type="button"
					color="success"
					icon="fa fa-check"
					hidden={todosDocumentosSelecionados}
					disabled={!podeEditar || todosDocumentosSelecionados}
					title="Marca todos os recursos"
					onClick={marcarTodosDocumentos}
				/>
				<Button
					label="Desmarcar todos"
					type="button"
					color="danger"
					icon="fa fa-close"
					hidden={!todosDocumentosSelecionados}
					disabled={!podeEditar || !todosDocumentosSelecionados}
					title="Desmarca todos os recursos"
					onClick={desmarcarTodosDocumentos}
				/>
				<Button
					className="p-button-primary"
					type="button"
					label="Arquivar"
					icon="fa fa-archive"
					disabled={!podeEditar || quantidadeDocumentosSelecionados === 0}
					title={!podeEditar ? 'Você não possui permissão para executar essa ação' : ''}
					onClick={() => arquivarSelecionados(getSelectedDocumentIds(documentosSelecionados))}
				/>
				<Button
					className="p-button-primary"
					type="button"
					hidden={exibirBloqueados === false}
					disabled={!podeEditar || quantidadeDocumentosSelecionados === 0}
					title={!podeEditar ? 'Você não possui permissão para executar essa ação' : ''}
					label="Desarquivar"
					icon="fa fa-archive"
					onClick={() => desarquivarSelecionados(getSelectedDocumentIds(documentosSelecionados))}
				/>
				<Button
					className="p-button-primary"
					type="button"
					disabled={!podeEditar || quantidadeDocumentosSelecionados === 0}
					title={!podeEditar ? 'Não você pode executar essa ação' : ''}
					label="Mover documento(s)"
					icon="fa fa-pasta"
					onClick={(e) => handleShowOverlayAlteracaoLote(e)}
				/>
			</Col>
		</Grid>
	);
}

export { HeaderListagemPastas };
