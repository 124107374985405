import { colors } from 'Common';
import { tipoCampos } from 'components';

export const CADASTRO_URL = '/vendas/tabelas_preco/cadastro';
export const PESQUISA_URL = '/vendas/tabelas_preco';

export const TABELA_PRECO_SITUACAO = {
	ATIVO: 'ATIVO',
	INATIVO: 'INATIVO',
	VENCIDO: 'VENCIDO',
};

export const TABELA_PRECO_ALTERACAO_CONDICAO = {
	ACRESCIMO: 'ACRESCIMO',
	DESCONTO: 'DESCONTO',
};

export const TABELA_PRECO_SITUACAO_OPTIONS = [
	{ value: TABELA_PRECO_SITUACAO.ATIVO, label: 'Ativa' },
	{ value: TABELA_PRECO_SITUACAO.INATIVO, label: 'Inativa' },
	{ value: TABELA_PRECO_SITUACAO.VENCIDO, label: 'Vencida' },
];

export const TABELA_PRECO_ALTERACAO_CONDICAO_OPTIONS = [
	{ value: TABELA_PRECO_ALTERACAO_CONDICAO.ACRESCIMO, label: 'Acréscimo' },
	{ value: TABELA_PRECO_ALTERACAO_CONDICAO.DESCONTO, label: 'Desconto' },
];

export const TABELA_PRECO_PROMOCIONAL_OPTIONS = [
	{ value: true, label: 'Sim' },
	{ value: false, label: 'Não' },
];

export const OPTIONS_FILTRO_AVANCADO = [
	{ label: 'Código', name: 'codigo', type: tipoCampos.INTEGER },
	{ label: 'Nome', name: 'nome', type: tipoCampos.STRING },
	{
		label: 'Situação',
		name: 'situacao',
		type: tipoCampos.SELECT,
		optionSelect: [
			{ label: 'Ativa', value: 'ATIVO' },
			{ label: 'Inativa', value: 'INATIVO' },
			{ label: 'Vencida', value: 'VENCIDO' },
		],
	},
	{
		label: 'Tabela promocional',
		name: 'tabelaPromocional',
		type: tipoCampos.SELECT,
		optionSelect: [
			{ label: 'Sim', value: 'true' },
			{ label: 'Não', value: 'false' },
		],
	},
];

export const OPTIONS_PRODUTOS_FILTRO_AVANCADO = [
	{ label: 'Custo', name: 'custo', type: tipoCampos.DECIMAL },
	{ label: 'Lucro %', name: 'lucroPercentual', type: tipoCampos.DECIMAL },
	{ label: 'Preço de venda', name: 'precoVenda', type: tipoCampos.DECIMAL },
	{ label: 'Comissão %', name: 'comissaoPercentual', type: tipoCampos.DECIMAL },
	{ label: 'Desc. máx. %', name: 'descontoMaximoPercentual', type: tipoCampos.DECIMAL },
];

export const TABELA_PRECO_INFORMACOES_SITUACAO = {
	[TABELA_PRECO_SITUACAO.ATIVO]: {
		name: 'Ativa',
		description: 'Tabela de preço ativa',
		lightColor: colors.azulLight,
		strongColor: colors.principal,
	},
	[TABELA_PRECO_SITUACAO.VENCIDO]: {
		name: 'Vencida',
		description: 'Tabela de preço vencida',
		lightColor: colors.amareloLight,
		strongColor: colors.amarelo,
	},
	[TABELA_PRECO_SITUACAO.INATIVO]: {
		name: 'Inativa',
		description: 'Tabela de preço inativa',
		lightColor: colors.vermelhoLight,
		strongColor: colors.vermelho,
	},
};

export const ClassNameTabViewColor = {
	ATIVO: 'tab-view-tabela-preco-ativo',
	INATIVO: 'tab-view-tabela-preco-inativo',
	VENCIDO: 'tab-view-tabela-preco-vencido',
};

export const TIPO_CUSTO = {
	ULTIMO_CUSTO: 'ULTIMO_CUSTO',
	CUSTO_MEDIO: 'CUSTO_MEDIO',
	PRECO_CUSTO: 'PRECO_CUSTO',
};

export const OPTIONS_TIPO_CUSTO = [
	{ label: 'Último custo', value: TIPO_CUSTO.ULTIMO_CUSTO },
	{ label: 'Custo médio', value: TIPO_CUSTO.CUSTO_MEDIO },
	{ label: 'Preço custo', value: TIPO_CUSTO.PRECO_CUSTO },
];

export const INITIAL_VALUES = {
	id: null,
	codigo: null,
	nome: null,
	situacao: TABELA_PRECO_SITUACAO.ATIVO,
	tabelaPromocional: false,
	dataVencimento: null,
	alteracoesPorCondicao: [],
	descontosPorQuantidade: [],

	produtosEdicao: [],
	produtosExclusao: [],

	configuracoes: {
		editarPrecoAbaixoValorTabela: false,
		editarPrecoAcimaValorTabela: false,
	},
};
