import { useEffect, useReducer } from 'react';
import { format, parseISO } from 'date-fns';

import { atualizarUrl } from 'views/Util';
import { Button, Col } from 'components';
import { permissoes, recursos, usuarioPossuiPermissao } from 'Common';
import { CardsResultado } from '../CardsResultado';
import { CardList } from '../CardList';
import { ChartServicosPorDia } from '../ChartServicosPorDia';
import { ChartServicosUltimosDozeMeses } from '../ChartServicosUltimosDozeMeses';
import { dataReducer } from './reducer';
import {
	addDataRankingTomadores,
	addDataRankingVendedores,
	addDataServicosPrestados,
	setData,
} from './reducer/actions';
import {
	asyncGetNotasPorDia,
	asyncGetNotasUltimosDozeMeses,
	asyncGetRankingTomadores,
	asyncGetRankingVendedores,
	asyncGetResultadoPeriodo,
	asyncGetServicosMaisPrestados,
} from './requests';

function DashboardNFSe({ dataInicial, dataFinal, isMobile, history }) {
	const [
		{
			cardsResultado,
			servicosPrestados,
			rankingTomadores,
			rankingVendedores,
			notasPorDia,
			notasUltimosDozeMeses,
			loading,
			podeInserirNFSe,
		},
		dispatch,
	] = useReducer(
		dataReducer,
		{
			cardsResultado: {},
			servicosPrestados: {},
			rankingTomadores: {},
			rankingVendedores: {},
			notasPorDia: {},
			notasUltimosDozeMeses: {},
			loading: true,
			podeInserirNFSe: false,
		},
		(initialState) => ({
			...initialState,
			podeInserirNFSe: usuarioPossuiPermissao(recursos.SERVICOS_NFSE, permissoes.INSERIR),
		})
	);

	const styleCards = isMobile ? { padding: '0.5em 0em', margin: '8px', flex: 'auto' } : { padding: '0.5em' };

	const styleContainerButton = {
		display: 'flex',
		justifyContent: isMobile ? 'center' : 'flex-start',
		padding: isMobile ? '15px 0 0 2px' : '0 0 0 2px',
	};

	const styleButton = {
		margin: isMobile ? '3px 5px' : '3px 7px',
		width: '185px',
	};

	useEffect(() => {
		fetchData();
	}, [dataInicial, dataFinal]);

	async function fetchData() {
		let newData = {
			loading: false,
		};
		const promises = [
			asyncGetResultadoPeriodo(dataInicial, dataFinal, ({ data }) => {
				const { periodoAtual, periodoAnterior } = data;
				const dataCards = {
					pendentes: {
						valor: periodoAtual.valorPendente,
						evolucao: calculateEvolution(periodoAtual.valorPendente, periodoAnterior.valorPendente),
					},
					finalizados: {
						valor: periodoAtual.valorFinalizado,
						evolucao: calculateEvolution(periodoAtual.valorFinalizado, periodoAnterior.valorFinalizado),
					},
					cancelados: {
						valor: periodoAtual.valorCancelado,
						evolucao: calculateEvolution(periodoAtual.valorCancelado, periodoAnterior.valorCancelado),
					},
				};

				newData = {
					...newData,
					cardsResultado: dataCards,
				};
			}),
			asyncGetServicosMaisPrestados(dataInicial, dataFinal, 0, ({ data }) => {
				newData = {
					...newData,
					servicosPrestados: data,
				};
			}),
			asyncGetRankingTomadores(dataInicial, dataFinal, 0, ({ data }) => {
				newData = {
					...newData,
					rankingTomadores: data,
				};
			}),
			asyncGetRankingVendedores(dataInicial, dataFinal, 0, ({ data }) => {
				newData = {
					...newData,
					rankingVendedores: data,
				};
			}),
			asyncGetNotasPorDia(dataInicial, dataFinal, ({ data }) => {
				const dias = [];
				const pendentes = [];
				const finalizados = [];
				const cancelados = [];

				data.forEach((day) => {
					dias.push(format(parseISO(day.data), 'dd/MM/yyyy'));
					pendentes.push(day.valorPendentes);
					finalizados.push(day.valorFinalizados);
					cancelados.push(day.valorCancelados);
				});

				newData = {
					...newData,
					notasPorDia: { dias, pendentes, finalizados, cancelados },
				};
			}),
			asyncGetNotasUltimosDozeMeses(dataInicial, dataFinal, ({ data }) => {
				const meses = [];
				const pendentes = [];
				const finalizados = [];
				const cancelados = [];

				data.forEach((day) => {
					meses.push(day.competencia);
					pendentes.push(day.valorPendentes);
					finalizados.push(day.valorFinalizados);
					cancelados.push(day.valorCancelados);
				});

				newData = {
					...newData,
					notasUltimosDozeMeses: {
						meses,
						pendentes,
						finalizados,
						cancelados,
					},
				};
			}),
		];

		await Promise.all(promises).then(() => dispatch(setData(newData)));
	}

	async function handleClickVerMaisServicosPrestados(page) {
		await asyncGetServicosMaisPrestados(dataInicial, dataFinal, page, ({ data }) => {
			dispatch(addDataServicosPrestados(data));
		});
	}

	async function handleClickVerMaisRankingTomadores(page) {
		await asyncGetRankingTomadores(dataInicial, dataFinal, page, ({ data }) => {
			dispatch(addDataRankingTomadores(data));
		});
	}

	async function handleClickVerMaisRankingVendedores(page) {
		await asyncGetRankingVendedores(dataInicial, dataFinal, page, ({ data }) => {
			dispatch(addDataRankingVendedores(data));
		});
	}

	function handleClickNovaNFSe() {
		atualizarUrl(history, '/nfses/cadastro');
	}

	function calculateEvolution(value, oldValue) {
		if (oldValue === 0) {
			return 0;
		}

		return ((value * 100) / oldValue - 100).toFixed(0);
	}

	return (
		<>
			<Col style={styleContainerButton}>
				<Button
					label="Nova NFS-e"
					onClick={handleClickNovaNFSe}
					style={styleButton}
					disabled={!podeInserirNFSe}
					title={!podeInserirNFSe ? 'Você não possui permissão para inserir NFS-e' : null}
				/>
			</Col>
			<CardsResultado data={cardsResultado} isMobile={isMobile} />
			<Col sm="6" md="4" lg="4" xl="4" style={styleCards}>
				<CardList
					title="Serviços mais prestados"
					helpMessage="Lista os serviços mais prestados no período selecionado"
					data={servicosPrestados?.content || []}
					loading={loading}
					visibleButtonVerMais={((servicosPrestados?.page?.number ?? 0) + 1) * 10 < (servicosPrestados?.page?.totalElements ?? 0)}
					onClickVerMais={handleClickVerMaisServicosPrestados}
					page={servicosPrestados?.page?.number ?? 0}
				/>
			</Col>
			<Col sm="6" md="4" lg="4" xl="4" style={styleCards}>
				<CardList
					title="Ranking de tomadores"
					helpMessage="Lista de tomadores que mais contrataram serviços no período"
					data={rankingTomadores?.content || []}
					loading={loading}
					visibleButtonVerMais={((rankingTomadores?.page?.number ?? 0) + 1) * 10 < (rankingTomadores?.page?.totalElements ?? 0)}
					onClickVerMais={handleClickVerMaisRankingTomadores}
					page={rankingTomadores?.page?.number ?? 0}
				/>
			</Col>
			<Col sm="6" md="4" lg="4" xl="4" style={styleCards}>
				<CardList
					title="Ranking de vendedores"
					helpMessage="Lista de vendedores que mais venderam no período"
					data={rankingVendedores?.content || []}
					loading={loading}
					visibleButtonVerMais={((rankingVendedores?.page?.number ?? 0) + 1) * 10 < (rankingVendedores?.page?.totalElements ?? 0)}
					onClickVerMais={handleClickVerMaisRankingVendedores}
					page={rankingVendedores?.page?.number ?? 0}
				/>
			</Col>
			<Col style={styleCards}>
				<ChartServicosPorDia
					title="Notas no período selecionado"
					helpMessage="Lista o valor total das notas por situação no período selecionado"
					data={notasPorDia}
					loading={loading}
					isMobile={isMobile}
				/>
			</Col>
			<Col style={styleCards}>
				<ChartServicosUltimosDozeMeses
					title="Notas nos últimos 12 meses"
					label="Total de notas no mês"
					helpMessage="Lista o valor total das notas agrupadas por mês nos últimos doze meses"
					data={notasUltimosDozeMeses}
					loading={loading}
					isMobile={isMobile}
				/>
			</Col>
		</>
	);
}

export { DashboardNFSe };
