import { format, parseISO } from 'date-fns';
import { useState } from 'react';
import { withRouter } from 'react-router';

import { colors } from 'Common';

import { ModalVisualizarHistorico } from './ModalVisualizarHistorico';

import '../../Styles/index.css';

function CardHistoricoImpl({
	historico,
	valorPlanoAtual,
	dataProximoPagamento,
	atualizarInformacoesHistorico,
	isMobile,
	isLessHd,
}) {
	const { registros, totalElements } = historico;

	const [modalHistoricoVisible, setModalHistoricoVisible] = useState(false);

	function onHideModalHistorico() {
		setModalHistoricoVisible(false);
	}

	function renderDetalhesUltimoPagamento() {
		if (registros) {
			const pagamentosComSucesso = registros.filter((e) => e.status === 'Pagamento confirmado e finalizado');

			if (pagamentosComSucesso.length > 0) {
				const ultimoPgto = pagamentosComSucesso[0];
				if (ultimoPgto.data) {
					return (
						<>
							<p className="detalhesCardStyle">
								{`Data do último ${isMobile ? 'pag.' : 'pagamento'}: ${format(
									parseISO(ultimoPgto.data),
									'dd/MM/yyyy'
								)}`}{' '}
								<br />
								{`Valor do último ${isMobile ? 'pag.' : 'pagamento'}: R$ ${(ultimoPgto.valor / 100)
									.toFixed(2)
									.split('.')
									.join(',')}`}{' '}
								<br />
							</p>
							<div
								style={{
									width: '100%',
									borderBottom: '1px solid #d0d0d0',
									marginLeft: '-4px',
									marginRight: '-4px',
									marginTop: '8px',
								}}
							/>
						</>
					);
				}
			} else {
				return <p className="detalhesCardStyle">Nenhum pagamento efetivado.</p>;
			}
		}
		return null;
	}

	function renderDetalhesProximoPagamento() {
		if (registros) {
			const pagamentosComSucesso = registros.filter((e) => e.status === 'Pagamento confirmado e finalizado');

			if (pagamentosComSucesso.length > 0) {
				const ultimoPgto = pagamentosComSucesso[0];
				if (ultimoPgto.data) {
					return (
						<p className="detalhesCardStyle">
							{`Data do próximo ${isMobile ? 'pag.' : 'pagamento'}: ${
								dataProximoPagamento ? format(dataProximoPagamento, 'dd/MM/yyyy') : ''
							}`}{' '}
							<br />
							{`Valor do próximo ${isMobile ? 'pag.' : 'pagamento'}:`}{' '}
							<b style={{ color: colors.principal }}>{`R$ ${valorPlanoAtual.toFixed(2).split('.').join(',')}`}</b>{' '}
							<br />
						</p>
					);
				}
			} else {
				return null;
			}
		}
		return null;
	}

	return (
		<>
			<div className="cardStyle" style={{ justifyContent: 'space-between', paddingBottom: '8px' }}>
				{renderDetalhesProximoPagamento()}
				{renderDetalhesUltimoPagamento()}
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						marginBottom: !isLessHd && '10px',
					}}
				>
					<span className="buttonAlterarStyle" onClick={() => setModalHistoricoVisible(true)}>
						{registros.length > 0 ? 'Visualizar histórico' : ''}
					</span>
				</div>
			</div>

			<ModalVisualizarHistorico
				visible={modalHistoricoVisible}
				onHide={onHideModalHistorico}
				registros={registros}
				totalElements={totalElements}
				atualizarInformacoesHistorico={atualizarInformacoesHistorico}
			/>
		</>
	);
}

export const CardHistorico = withRouter(CardHistoricoImpl);
