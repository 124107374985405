import { useState } from 'react';
import { connect } from 'react-redux';
import { format, parseISO } from 'date-fns/esm';
import isEqual from 'date-fns/isEqual';
import isPast from 'date-fns/isPast';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { FaCheckCircle, FaExclamationCircle, FaMinusCircle } from 'react-icons/fa';

import { formatarDecimais, formatarMonetario, permissoes, recursos, usuarioPossuiPermissao } from 'Common';
import { Button, NenhumRegistroEncontrado, Paginacao } from 'components';

import { CONTA_PAGAR_STATUS } from 'views/financas/components/ModalReparcelamentoTitulos/Utils/constantes';
import { Financas } from '../../../../Util/constantes';

import './Styles/index.css';

const styleColumnOverflowElipsis = {
	overflow: 'hidden',
	textOverflow: 'ellipsis',
};

const styleDestakFiend = {
	display: 'inline-flex',
	justifyContent: 'center',
};

const styleButton = {
	borderRadius: '50%',
	padding: '5px',
	width: '30px',
	height: '30px',
	margin: '2px',
};

const dataAtual = new Date().setHours(0, 0, 0, 0);

function TabelaContasPagar({
	registros,
	totalRecords,
	rows,
	page,
	sortField,
	sortOrder,
	setSortField,
	setSortOrder,
	onEditItem,
	onEfetuarPagamentoItem,
	onRemoveItem,
	onPageChange,
	isMobile,
	documentosSelecionados,
	setDocumentosSelecionados,
}) {
	const [podeExcluirContasAPagar] = useState(
		usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_PAGAR, permissoes.EXCLUIR)
	);
	const [podeVisualizarContasAPagar] = useState(
		usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_PAGAR, permissoes.VISUALIZAR)
	);
	const [podeEditarContasAPagar] = useState(usuarioPossuiPermissao(recursos.FINANCAS_CONTAS_PAGAR, permissoes.EDITAR));
	const [podeEditarNotaEntrada] = useState(usuarioPossuiPermissao(recursos.COMPRAS_NOTA_ENTRADA, permissoes.EDITAR));

	function atualizarOrdenacao(e) {
		setSortField(e.sortField);
		setSortOrder(e.sortOrder);
	}

	function renderStatus(el) {
		const dataVencimento = parseISO(el.vencimento);

		if (
			el.status !== CONTA_PAGAR_STATUS.PAGA &&
			el.status !== CONTA_PAGAR_STATUS.REPARCELADA &&
			isPast(dataVencimento) &&
			!isEqual(dataVencimento, dataAtual)
		) {
			return <FaExclamationCircle title="Vencida" size={18} color={Financas.cores.vermelho} />;
		} else {
			if (el.status === CONTA_PAGAR_STATUS.NAO_PAGA || el.status === 'PARCIALMENTE_PAGA') {
				return <FaCheckCircle title="Não paga" size={18} color={Financas.cores.cinza} />;
			} else if (el.status === CONTA_PAGAR_STATUS.PAGA) {
				return <FaCheckCircle title="Paga" size={18} color={Financas.cores.verde} />;
			} else if (el.status === CONTA_PAGAR_STATUS.REPARCELADA) {
				return <FaMinusCircle title="Reparcelada" size={18} color={Financas.cores.amarelo} />;
			}

			return null;
		}
	}

	function renderDataVencimento(el) {
		const dataVencimento = parseISO(el.vencimento);
		const dataVencimentoTexto = format(dataVencimento, 'dd/MM/yyyy');

		if (isPast(dataVencimento) && !isEqual(dataVencimento, dataAtual)) {
			return <span title={`Conta a pagar vencida em: ${dataVencimentoTexto}`}>{dataVencimentoTexto}</span>;
		}

		return <span title={`Data de vencimento da conta a pagar: ${dataVencimentoTexto}`}>{dataVencimentoTexto}</span>;
	}

	function renderDescricaoField(el) {
		let { descricao } = el;

		if (el.itemRepeticao && el.quantidadeRepeticoes) {
			descricao = (
				<>
					<span
						style={{
							color: 'gray',
							fontWeight: 'bold',
							wordBreak: 'break-word',
							maxWidth: isMobile ? '70%' : '100%',
							textAlign: isMobile ? 'end' : 'start',
						}}
					>{`${el.itemRepeticao}/${el.quantidadeRepeticoes} `}</span>
					{el.descricao}
				</>
			);
		}

		return (
			<span
				title={el.descricao}
				style={{
					wordBreak: 'break-word',
					maxWidth: isMobile ? '70%' : '100%',
					textAlign: isMobile ? 'end' : 'start',
				}}
			>
				{descricao}
			</span>
		);
	}

	function renderNomeField(el) {
		return (
			<span
				title={el.pessoaNome}
				style={{
					wordBreak: 'break-word',
					maxWidth: isMobile ? '70%' : '100%',
					textAlign: isMobile ? 'end' : 'start',
				}}
			>
				{el.pessoaNome}
			</span>
		);
	}

	function renderCategoria(render, el, isLast) {
		return (
			<>
				{render}
				<span title={el.categoria.nome.concat(': ').concat(formatarMonetario(el.valor)).concat(' (').concat(formatarDecimais(el.percentual, 4)).concat('%)')} style={{ color: el.categoria.aparencia, fontWeight: 'bold' }}>
					{el.categoria.nome || ''}
				</span>			
				<span>
					{isLast ? '' : ', '}
				</span>				
			</>	
		);	
	}

	function renderCategoriaField(el) {
		let renderCategorias;
		el.categorias.forEach(registro =>{
			renderCategorias = renderCategoria(renderCategorias, registro, (registro.id === el.categorias[el.categorias.length-1].id));
		});		
		return (			
			<span
				style={{
					textAlign: isMobile ? 'end' : 'start',
				}}
			>
				{renderCategorias}
			</span>			
		);
	}

	function renderValorField(el) {
		const valor = formatarMonetario(el.valor);

		return <span title={valor}>{valor}</span>;
	}

	function renderPagoAPagarField(el) {
		const valorAPagar = formatarMonetario(el.valorAPagar);
		if (el.valorAPagar > 0) {
			return (
				<span title={valorAPagar} style={{ ...styleDestakFiend, fontWeight: 'bold' }}>
					{valorAPagar}
				</span>
			);
		}
		return null;
	}

	function renderAcoesField(el) {
		let titleEditar = 'Editar';
		let titleEfetuarPagamento = 'Efetuar pagamento';
		let titleExcluir = 'Excluir';
		let disableBtnEditar = false;
		let disableBtnEfetuarPagamento = false;
		let disableBtnExcluir = false;

		if (!podeVisualizarContasAPagar) {
			titleEditar = 'Você não possuir permissão para executar essa ação';
			disableBtnEditar = true;
		}

		if (!podeEditarContasAPagar) {
			titleEfetuarPagamento = 'Você não possuir permissão para executar essa ação';
			disableBtnEfetuarPagamento = true;
		}

		if (!podeExcluirContasAPagar) {
			titleExcluir = 'Você não possui permissão para executar essa ação';
			disableBtnExcluir = true;
		}

		if (el.origem === 'NOTA_COMPRA') {
			if (!podeEditarNotaEntrada) {
				titleEditar = 'Você não possui permissão para executar essa ação.';
				disableBtnEditar = true;
			}
			titleExcluir = 'Para excluir um lançamento vindo de um nota de entrada, deve ser estornada a nota de origem.';
			disableBtnExcluir = true;
		}

		if (el.status === CONTA_PAGAR_STATUS.REPARCELADA) {
			titleExcluir = 'Não é possível excluir um conta a pagar reparcelado';
			disableBtnExcluir = true;
		}

		return (
			<div>
				<Button
					style={styleButton}
					className="p-button p-button-secondary"
					icon="fa fa-check"
					hidden={el.status === 'PAGA' || el.status === 'REPARCELADA'}
					title={titleEfetuarPagamento}
					disabled={disableBtnEfetuarPagamento}
					onClick={() => onEfetuarPagamentoItem(el)}
				/>
				<Button
					style={styleButton}
					className="p-button p-button-primary"
					icon="fa fa-pencil"
					title={titleEditar}
					disabled={disableBtnEditar}
					onClick={() => onEditItem(el)}
				/>
				<Button
					style={styleButton}
					className="p-button p-button-danger"
					icon="fa fa-trash"
					title={titleExcluir}
					disabled={disableBtnExcluir}
					onClick={() => onRemoveItem(el)}
				/>
			</div>
		);
	}

	const statusStyle = !isMobile && { textAlign: 'center' };
	const acoesStyle = !isMobile && { textAlign: 'end' };

	return (
		<div style={{ width: !isMobile ? '100%' : '99%' }}>
			<DataTable
				className="table"
				value={registros}
				responsive
				sortField={sortField}
				sortOrder={sortOrder}
				onSort={atualizarOrdenacao}
				selectionMode="checkbox"
				selection={documentosSelecionados}
				onSelectionChange={(e) => setDocumentosSelecionados(e.value)}
				emptyMessage={<NenhumRegistroEncontrado message="Nenhuma conta a pagar encontrada" />}
			>
				<Column header={isMobile ? 'Seleção' : ''} selectionMode="multiple" style={{ width: '45px' }} />
				<Column
					field="status"
					header={isMobile ? 'Status' : ''}
					body={renderStatus}
					style={{ width: '60px', ...statusStyle }}
				/>
				<Column header="Vencimento" field="vencimento" body={renderDataVencimento} sortable />
				<Column
					header="Descrição"
					field="descricao"
					body={renderDescricaoField}
					sortable
					bodyStyle={styleColumnOverflowElipsis}
				/>
				<Column header="Pessoa" field="pessoaNome" sortable body={renderNomeField} />
				<Column
					header="Categoria(s)"
					field="categoriaNome"
					body={renderCategoriaField}
					bodyStyle={styleColumnOverflowElipsis}
				/>
				<Column header="Valor" field="valor" sortable body={renderValorField} bodyStyle={styleColumnOverflowElipsis} />
				<Column
					header="A pagar"
					field="valorAPagar"
					body={renderPagoAPagarField}
					bodyStyle={styleColumnOverflowElipsis}
				/>
				<Column
					header="Ações"
					headerClassName="coluna-acoes-end"
					body={renderAcoesField}
					style={{ width: '130px', ...acoesStyle }}
				/>
			</DataTable>
			<Paginacao totalElements={totalRecords} rows={rows} page={page} onPageChange={onPageChange} />
		</div>
	);
}

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
});

export default connect(mapStateToProps)(TabelaContasPagar);
