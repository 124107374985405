import { useState, useEffect } from 'react';
import {
	ButtonNovo,
	Form,
	FormActions,
	FormContent,
	If,
	AutoProgressBar,
	Tree,
	confirm,
	notify,
	ToastTypes,
	ButtonEditarTable,
	ButtonExcluirTable,
	ButtonNovoTable,
	NenhumRegistroEncontrado,
} from 'components';
import { usuarioPossuiPermissao, recursos, permissoes } from 'Common';
import ModalGrupo from './components/ModalGrupo';
import {
	asyncDelGrupos,
	asyncGetGruposNivelFilho,
	asyncGetGruposNivelRaiz,
	asyncPutDesvincularProdutos,
} from './Requests';
import { confirmarExclusao } from '../../../Util/ExclusaoDeRegistros';
import { connect } from 'react-redux';

const styleTreeView = {
	width: '100%',
	border: '0px ',
};

function ProdutoGrupos(props) {
	const { isModal, isMobile } = props;
	const [nodes, setNodes] = useState([]);
	const [selectedKey, setSelectedKey] = useState(null);
	const [visibleModal, setVisibleModal] = useState(false);
	const [selectedNode, setSelectedNode] = useState(null);
	const [headerModal, setHeaderModal] = useState('Novo grupo');
	const [novoNivel, setNovoNivel] = useState('');
	const [codigoNivel, setCodigoNivel] = useState(0);
	const [grupoPai, setGrupoPai] = useState('');
	const [grupoEdicao, setGrupoEdicao] = useState('');
	const [expandedKeys, setExpandedKeys] = useState({});
	const podeInserir = usuarioPossuiPermissao(recursos.PRODUTOS, permissoes.INSERIR);

	useEffect(() => {
		carregarNivelRaiz();
	}, []);

	async function carregarNivelRaiz(_expandedKeys = {}, node = null) {
		await asyncGetGruposNivelRaiz((event) => {
			setTimeout(async () => {
				setNodes(
					event.data.map((register) => ({
						key: register.nivel,
						codigoNivel: register.codigoNivel,
						label: register.nivel + ' - ' + register.descricao,
						icon: register.filhos === 0 ? 'pi pi-fw pi-file' : 'pi pi-fw pi-inbox',
						vinculados: register.vinculados,
						filhos: register.filhos,
						id: register.id,
						descricao: register.descricao,
						leaf: register.filhos === 0 ? null : false,
						children: [],
					}))
				);
				if (_expandedKeys != {} && node && node.grupoPai) {
					expandNode(node.grupoPai, _expandedKeys);
				} else {
					setExpandedKeys({});
				}
			}, 100);
		});
	}

	const loadOnExpand = (event) => {
		setTimeout(async () => {
			await loadFilhos(event.node);
			let value = [...nodes];
			setNodes(value);
		}, 100);
	};

	async function loadFilhos(nodePai) {
		let node = nodePai;
		node.children = [];
		await asyncGetGruposNivelFilho(node.id, (event) => {
			let filhos = event.data.map((register) => ({
				key: register.nivel,
				codigoNivel: register.codigoNivel,
				label: register.nivel + ' - ' + register.descricao,
				icon: register.filhos === 0 ? 'pi pi-fw pi-file' : 'pi pi-fw pi-inbox',
				vinculados: register.vinculados,
				filhos: register.filhos,
				id: register.id,
				descricao: register.descricao,
				leaf: register.filhos === 0 ? null : false,
				children: [],
				grupoPai: node,
			}));
			node.children = filhos;
		});
	}

	function onSelect(event) {
		setSelectedNode(event.node);
	}

	function onHideModal() {
		setVisibleModal(false);
	}

	function onShowModal() {
		setVisibleModal(true);
	}

	function getProximoNivelRaiz() {
		let codigo = 1;
		for (let i in nodes) {
			if (nodes[i].codigoNivel === codigo) {
				codigo = codigo + 1;
			} else {
				return codigo;
			}
		}
		return codigo;
	}

	function getProximoNivel(node) {
		let codigo = 1;
		for (let i in node.children) {
			if (node.children[i].codigoNivel === codigo) {
				codigo = codigo + 1;
			} else {
				return codigo;
			}
		}
		return codigo;
	}

	function novoNivelRaiz() {
		let novo = getProximoNivelRaiz();
		setNovoNivel(novo);
		setCodigoNivel(novo);
		setGrupoEdicao(null);
		setGrupoPai(null);
		setHeaderModal('Novo grupo');
		onShowModal();
	}

	async function novoSubnivel(node) {
		if (node) {
			await asyncGetGruposNivelFilho(node.id, (event) => {
				let filhos = event.data.map((register) => ({
					key: register.nivel,
					codigoNivel: register.codigoNivel,
					label: register.nivel + ' - ' + register.descricao,
					icon: register.filhos === 0 ? 'pi pi-fw pi-file' : 'pi pi-fw pi-inbox',
					vinculados: register.vinculados,
					filhos: register.filhos,
					id: register.id,
					descricao: register.descricao,
					leaf: register.filhos === 0 ? null : false,
					children: [],
					grupoPai: node,
				}));
				node.children = filhos;
			});
			let novo = getProximoNivel(node);
			if (node.vinculados == 0) {
				setHeaderModal('Novo subgrupo');
				setCodigoNivel(novo);
				setGrupoEdicao(null);
				setNovoNivel(`${node.key}.${novo}`);
				setGrupoPai(node);
				onShowModal();
			} else {
				confirmarLimparProdutos(
					node,
					'Grupo/Subgrupo referenciado em um ou mais produtos e não pode receber um subgrupo. Deseja desvincular o(s) produto(s)?',
					function () {
						setHeaderModal('Novo subgrupo');
						setCodigoNivel(novo);
						setGrupoEdicao(null);
						setNovoNivel(`${node.key}.${novo}`);
						setGrupoPai(node);
						onShowModal();
					}
				);
			}
		}
	}

	function editarNivel(node) {
		if (node) {
			setHeaderModal('Editar grupo');
			setGrupoEdicao(node);
			setNovoNivel(`${node.key}`);
			setGrupoPai(node.grupoPai);
			onShowModal();
		}
	}

	async function confirmarLimparProdutos(node, mensagem, funcao) {
		confirm('Confirmação', mensagem, async () => {
			asyncPutDesvincularProdutos(node.id, () => {
				funcao();
			});
		});
	}

	async function deletarNivel(node) {
		if (node) {
			if (node.filhos > 0) {
				notify('Grupo/Subgrupo possui registros filhos e não pode ser excluído', ToastTypes.ERROR, 5);
				return;
			}
			const excluir = () => {
				asyncDelGrupos(node.id, () => {
					atualizarNivelPai(node, node.id);
					setSelectedNode(null);
					carregarNivelRaiz(updateExpandedKeys(node, expandedKeys), node);
					setVisibleModal(false);
				});
			};

			if (node.vinculados == 0) {
				confirmarExclusao(excluir);
			} else {
				confirmarLimparProdutos(
					node,
					'Grupo/Subgrupo referenciado em um ou mais produtos e não pode ser excluído. Deseja desvincular o(s) produto(s) e excluir?',
					excluir
				);
			}
		}
	}

	function atualizarNivelPai(node, id) {
		if (node.grupoPai) {
			node.grupoPai.children = node.grupoPai.children.filter((r) => r.id !== id);
			node.grupoPai.filhos = node.grupoPai.children.length;
			if (node.grupoPai.children.length === 0) {
				node.grupoPai.icon = 'pi pi-fw pi-file';
				node.grupoPai.leaf = null;
			}
			let value = [...nodes];
			setNodes(value);
		} else {
			setNodes(nodes.filter((r) => r.id !== id));
		}
	}

	function inserirGrupo(grupo) {
		if (grupo.grupoPai == null) {
			let value = nodes;
			let node = {
				key: String(grupo.nivel),
				codigoNivel: grupo.codigoNivel,
				label: grupo.nivel + ' - ' + grupo.descricao,
				icon: 'pi pi-fw pi-file',
				vinculados: 0,
				filhos: 0,
				id: grupo.id,
				descricao: grupo.descricao,
				leaf: null,
				children: [],
			};
			value.push(node);
			value.sort(function (a, b) {
				if (a.codigoNivel > b.codigoNivel) {
					return 1;
				}
				if (a.codigoNivel < b.codigoNivel) {
					return -1;
				}
				return 0;
			});
			setNodes(value);
			setSelectedNode(node);
			setSelectedKey(node.key);
		} else {
			let node = {
				key: grupo.nivel,
				codigoNivel: grupo.codigoNivel,
				label: grupo.nivel + ' - ' + grupo.descricao,
				icon: 'pi pi-fw pi-file',
				vinculados: 0,
				filhos: 0,
				id: grupo.id,
				descricao: grupo.descricao,
				leaf: null,
				children: [],
				grupoPai: selectedNode,
			};
			selectedNode.children.push(node);
			selectedNode.filhos = selectedNode.children.length;
			selectedNode.children.sort(function (a, b) {
				if (a.codigoNivel > b.codigoNivel) {
					return 1;
				}
				if (a.codigoNivel < b.codigoNivel) {
					return -1;
				}
				return 0;
			});
			selectedNode.icon = 'pi pi-fw pi-inbox';

			let value = [...nodes];
			setNodes(value);
			setSelectedNode(node);
			setSelectedKey(node.key);
			expandNode(selectedNode, expandedKeys);
		}
	}

	function editarGrupo(descricao) {
		selectedNode.label = `${selectedNode.key} -  ${descricao}`;
		selectedNode.descricao = descricao;
		let value = [...nodes];
		setNodes(value);
	}

	const nodeTemplate = (node) => {
		let label = node.filhos === 0 ? node.label : <b>{node.label}</b>;

		if (node.url) {
			label = <a href={node.url}>{node.label}</a>;
		}

		return (
			<span
				style={{
					alignItems: 'center',
					justifyContent: 'space-between',
					width: '100%',
					display: 'flex',
					borderBottom: '1px solid #d5d5d5',
				}}
			>
				<span
					style={
						isMobile
							? {
									whiteSpace: 'nowrap',
									overflow: 'hidden',
									maxWidth: '140px',
									textOverflow: 'ellipsis',
								}
							: {}
					}
				>
					{label}
				</span>
				<span style={{ paddingBottom: '3px', paddingTop: '3px' }}>
					<ButtonNovoTable title={'Novo subgrupo'} onClick={() => novoSubnivel(node)} />
					<ButtonEditarTable onClick={() => editarNivel(node)} />
					<ButtonExcluirTable onClick={() => deletarNivel(node)} />
				</span>
			</span>
		);
	};

	const expandNode = async (node, _expandedKeys) => {
		if (node?.filhos > 0) {
			await loadFilhos(node);

			if (!!node?.children.length) {
				node.children.forEach((child) => {
					if (_expandedKeys[child.key]) {
						expandNode(child, _expandedKeys);
					}
				});
			}

			_expandedKeys[node.key] = true;
			setExpandedKeys({ ..._expandedKeys });
		}
		let value = [...nodes];
		setNodes(value);
	};

	function onCollapse(event) {
		removeExpandedKeys(event.node);
	}

	function removeExpandedKeys(node) {
		let keys = expandedKeys;
		if (keys.hasOwnProperty(node.key)) {
			delete keys[node.key];
			if (!!node.children?.length) {
				for (let i = 0; i < node.children.length; i++) {
					removeExpandedKeys(node.children[i]);
				}
			}
		}
		setExpandedKeys(keys);
		let value = [...nodes];
		setNodes(value);
	}

	function updateExpandedKeys(node, _expandedKeys) {
		let keys = _expandedKeys;

		if (keys != {}) {
			let keysChildren = Object.keys(keys);

			keysChildren.forEach((key, index) => {
				let splicKey = key.split('.');
				let splicNodeKey = node.key.split('.');

				if (
					Number(splicKey[splicKey?.length - 1]) > Number(splicNodeKey[splicNodeKey?.length - 1]) &&
					splicKey?.length === splicNodeKey?.length
				) {
					splicKey[splicKey?.length - 1] = splicKey[splicKey?.length - 1] - 1;
					keysChildren[keysChildren.indexOf(keysChildren[index])] = splicKey.join('.');

					keysChildren.forEach((value, indexValue) => {
						let splicKeyChildren = value.split('.');

						if (value > node.key && splicKeyChildren?.length > splicNodeKey?.length) {
							if (value.includes(key)) {
								splicKeyChildren[splicKey.length - 1] = (splicKeyChildren[splicKey.length - 1] - 1).toString();
								keysChildren[indexValue] = splicKeyChildren.join('.');
							}
						}
					});
				}
			});
			keys = keysChildren.reduce((obj, item) => {
				return {
					...obj,
					[item]: true,
				};
			}, {});
			return keys;
		}
	}

	return (
		<>
			<Form header="Cadastro de grupos" isModal={isModal}>
				<If test={isModal}>
					<AutoProgressBar />
				</If>
				<FormActions>
					<ButtonNovo label="Novo grupo" disabled={!podeInserir} onClick={novoNivelRaiz} />
				</FormActions>
				<FormContent>
					<div style={{ display: 'flex', justifyContent: 'center' }} className="p-tree-select">
						<If test={nodes.length}>
							<Tree
								value={nodes}
								selectionMode="single"
								selectionKeys={selectedKey}
								onSelectionChange={(event) => setSelectedKey(event.value)}
								onExpand={loadOnExpand}
								onCollapse={(event) => onCollapse(event)}
								onSelect={onSelect}
								style={styleTreeView}
								nodeTemplate={nodeTemplate}
								hidden={false}
								expandedKeys={expandedKeys}
								onToggle={(event) => setExpandedKeys(event.value)}
							/>
						</If>
						<If test={!nodes.length}>
							<NenhumRegistroEncontrado />
						</If>
					</div>
				</FormContent>
			</Form>
			<If test={visibleModal}>
				<ModalGrupo
					visible={visibleModal}
					onHide={onHideModal}
					header={headerModal}
					novoNivel={novoNivel}
					codigoNivel={codigoNivel}
					grupoPai={grupoPai}
					grupoEdicao={grupoEdicao}
					inserirGrupo={inserirGrupo}
					editarGrupo={editarGrupo}
					deletarNivel={deletarNivel}
				/>
			</If>
		</>
	);
}
const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
});

export default connect(mapStateToProps)(ProdutoGrupos);
