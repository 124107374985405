import { useState, useEffect } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { connect } from 'react-redux';

import {
	ButtonNovo,
	Grid,
	PesquisaAvancada,
	Col,
	DescricaoFiltroAvancado,
	ButtonEditarTable,
	ButtonExcluirTable,
	InputSearch,
	Form,
	FormActions,
	FormContent,
	NenhumRegistroEncontrado,
	Paginacao,
} from '../../../../../../../components';
import { recursos, permissoes, services, construirUrl, usuarioPossuiPermissao } from '../../../../../../../Common';

import { asyncGetProdutos } from '../Requests';
import { optionsFiltroAvancado } from '../Util/constantes';
import ModalRelacao from '../../../components/ModalRelacao';
import { asyncDeleteRelacao } from '../../../components/ModalRelacao/Requests';
import { confirmarExclusao } from '../../../../../../Util/ExclusaoDeRegistros';

function ProdutosReferenciaFornecedor(props) {
	const { isMobile } = props;

	const [podeInserir, setPodeInserir] = useState(usuarioPossuiPermissao(recursos.PRODUTOS, permissoes.INSERIR));
	const [podeExcluir, setPodeExcluir] = useState(usuarioPossuiPermissao(recursos.PRODUTOS, permissoes.EXCLUIR));

	const [page, setPage] = useState(0);
	const [rows, setRows] = useState(10);
	const [descricaoFiltroAvancado, setDescricaoFiltroAvancado] = useState('');
	const [filtroAvancado, setFiltroAvancado] = useState('');
	const [valorPesquisa, setValorPesquisa] = useState('');
	const [registros, setRegistros] = useState([]);
	const [sortField, setSortField] = useState('produto.codigo');
	const [sortOrder, setSortOrder] = useState(1);
	const [totalElements, setTotalElements] = useState(0);
	const [modalRelacaoIsVisible, setModalRelacaoIsVisible] = useState(false);
	const [isEdit, setIsEdit] = useState('');

	useEffect(() => {
		pesquisar();
		setTimeout(() => {
			if (document.getElementById('InputSearchProduto')) {
				document.getElementById('InputSearchProduto').focus();
			}
		}, 500);
	}, [page, rows, sortField, sortOrder, filtroAvancado]);

	function buscarFiltro() {
		const valorId = props.valuesFornecedor.id;

		let result = valorPesquisa
			? `?query=(pessoa.id=="${valorId}";produto.codigo=contains="*${valorPesquisa}*",produto.nome=contains="*${valorPesquisa}*",referencia=contains="*${valorPesquisa}*",cfop.codigo=contains="*${valorPesquisa}*")`
			: `?query=(pessoa.id=="${valorId}")`;

		if (filtroAvancado) {
			result += `;${filtroAvancado}`;
		}

		return result;
	}

	async function pesquisar() {
		let filtro = buscarFiltro();

		const url = construirUrl(
			`${services.GESTOR}/v1/fornecedores/referencia_produto/resumo`,
			filtro,
			rows,
			page,
			sortOrder > 0 ? `${sortField},asc` : `${sortField},desc`
		);
		await asyncGetProdutos(url, ({ data: produto }) => {
			setRegistros(produto.content);
			setTotalElements(produto.page.totalElements);
		});
	}

	function onPesquisar() {
		setPage(0);
		pesquisar();
	}

	function onPageChange(e) {
		setPage(e.page);
		setRows(e.rows);
	}

	function onSort(e) {
		setSortField(e.sortField);
		setSortOrder(e.sortOrder);
	}

	function onEditar(row) {
		setIsEdit(row.id);
		modalRelacaoVisible();
	}

	function onExcluir(row) {
		confirmarExclusao(() => {
			asyncDeleteRelacao(row.id, () => {
				onPesquisar();
			});
		});
	}

	function modalRelacaoVisible() {
		setModalRelacaoIsVisible(!modalRelacaoIsVisible);
	}

	function modalRelacaoOnHide() {
		setModalRelacaoIsVisible(false);
		setIsEdit('');
		onPesquisar();
	}

	function renderOpcoes(row) {
		return (
			<div style={{ display: 'flex' }}>
				<ButtonEditarTable onClick={() => onEditar(row)} />
				<ButtonExcluirTable onClick={() => onExcluir(row)} podeExcluir={podeExcluir} />
			</div>
		);
	}

	function aplicarEstilo(row, field) {
		const styleActive = {
			wordBreak: 'break-all',
			paddingLeft: isMobile ? '20%' : '0',
		};

		return <span style={styleActive}>{field}</span>;
	}

	function onPesquisarFiltroAvancado(e) {
		setFiltroAvancado(e);
	}

	return (
		<>
			<Form>
				<FormActions>
					<ButtonNovo
						className="step-listagem-novo"
						label="Nova relação"
						onClick={() => modalRelacaoVisible()}
						podeInserir={podeInserir}
					/>
				</FormActions>
				<FormContent>
					<Grid justifyCenter>
						<InputSearch
							id="InputSearchProduto"
							className="step-listagem-input-search"
							onPesquisar={() => {
								onPesquisar();
							}}
							value={valorPesquisa}
							onChange={value => setValorPesquisa(value)}
						/>
						<Col sm="12" md="4" lg="3" xl="3" className="step-listagem-filtro-avancado">
							<PesquisaAvancada
								optionsFiltros={optionsFiltroAvancado}
								onPesquisarClick={e => {
									onPesquisarFiltroAvancado(e);
								}}
								onChangeFiltroRsql={rsql => setFiltroAvancado(rsql)}
								onChangeDescricaoFiltro={e => setDescricaoFiltroAvancado(e)}
							/>
						</Col>
					</Grid>
					<DescricaoFiltroAvancado texto={descricaoFiltroAvancado} />
					<DataTable
						className="table"
						responsive
						value={registros}
						sortField={sortField}
						sortOrder={sortOrder}
						onSort={e => {
							onSort(e);
						}}
						emptyMessage={<NenhumRegistroEncontrado />}
					>
						<Column
							className="step-listagem-order"
							field="produto.codigo"
							header="Código produto"
							body={row => aplicarEstilo(row, row.codigo)}
							sortable={true}
							style={{
								textOverflow: 'ellipsis',
								overflow: 'hidden',
								width: '130px',
							}}
						/>
						<Column
							field="produto.nome"
							header="Descrição"
							body={row => aplicarEstilo(row, row.descricao)}
							sortable={true}
						/>
						<Column
							field="referencia"
							header="Cód. produto fornecedor"
							body={row => aplicarEstilo(row, row.referencia)}
							sortable={true}
						/>
						<Column
							field="cfop.codigo"
							header="CFOP"
							body={row => aplicarEstilo(row, row.cfop?.codigo)}
							sortable={true}
						/>
						<Column
							className="step-listagem-acoes"
							body={row => renderOpcoes(row)}
							header="Ações"
							style={{ width: '5em' }}
						/>
					</DataTable>
					<Paginacao
						totalElements={totalElements}
						rows={rows}
						page={page}
						onPageChange={onPageChange}
					/>
				</FormContent>
			</Form>
			{modalRelacaoIsVisible ? (
				<ModalRelacao
					valuesFornecedor={props.valuesFornecedor}
					visible={modalRelacaoIsVisible}
					onHide={() => modalRelacaoOnHide()}
					isEdit={isEdit}
				/>
			) : null}
		</>
	);
}

const mapStateToProps = state => {
	return { isMobile: state.dispositivo.isMobile };
};

export default connect(mapStateToProps)(ProdutosReferenciaFornecedor);
