import { format } from 'date-fns';
import { statusGestaoDocumento, tipoGestaoDocumento } from '../../Util/constantes';
import { colors } from 'Common';

export const InfoStatusDocumento = {
	PENDENTE: {
		name: 'Pendente',
		description: 'O documento está pendente de aceitação pelas partes associadas.',
		lightColor: colors.azulLight,
		strongColor: colors.azul,
	},
	VIGENTE: {
		name: 'Vigente',
		description: 'O documento está em vigência',
		lightColor: colors.verdeLight,
		strongColor: colors.verde,
	},
	VIGENCIA_EXPIRADA: {
		name: 'Vigência expirada',
		description: 'O Documento está com sua vigência expirada',
		lightColor: '#fff7cd',
		strongColor: '#c16b13',
	},
	RESCINDIDO: {
		name: 'Rescindido',
		description: 'O documento não é mais válido, pois foi rescindido.',
		lightColor: colors.vermelhoLight,
		strongColor: colors.vermelho,
	},
};

export const InfoStatusArquivoDocumento = {
	AGUARDANDO_ASSINATURAS: {
		name: 'Aguardando assinaturas',
		description: 'O documento está pendente de assinaturas pelas partes.',
		lightColor: '#fff7cd',
		strongColor: '#c16b13',
	},
	ASSINADO: {
		name: 'Documento assinado',
		description: 'Documento assinado pelas partes.',
		lightColor: colors.verdeLight,
		strongColor: colors.verde,
	},
	REJEITADO: {
		name: 'Documento rejeitado',
		description: 'Documento rejeitado pelas partes.',
		lightColor: colors.vermelhoLight,
		strongColor: colors.vermelho,
	},
	EXPIRADO: {
		name: 'Documento expirado',
		description: 'Assinatura do documento com data expirada.',
		lightColor: '#e6e6e6',
		strongColor: '#666666',
	},
	ARQUIVO_ADICIONADO: {
		name: 'Arquivo adicionado',
		description: 'Arquivo adicionado ao documento',
		strongColor: colors.azul,
		lightColor: colors.azulLight,
	},
	PENDENTE: {
		name: 'Documento pendente',
		description: 'Documento pendente.',
		strongColor: '#721358',
		lightColor: '#BFA8C5',
	},
};

export const helpMessage = {
	identificacao:
		'Tem como objetivo identificar de maneira simples e rápida um documento. É possível informar qualquer valor que caracteriza um documento em específico.',
	parte: 'Tem como objetivo identificar as entidades que fazem parte do documento.',
	produto: 'Tem como objetivo identificar quais produtos do sistema são citados no documento',
	vigenciaInicio: 'Data inicial da vigência do documento.',
	vigenciaFinal: 'Data final da vigência do documento.',
	documentoValor: 'Valor total do documento.',
	documentoValorMensal: 'Valor mensal do documento, caso for necessário.',
	arquivoDocumento: 'Tem como objetivo identificar qual o documento vinculado ao documento',
	documentoVigenteEditar: 'Não é possível editar um documento com status vigente.',
	documentoVigenteSalvar: 'Não é possível salvar um documento com status vigente.',

	documentoAssinadoEditar: 'Não é possível editar um anexo com um documento já assinado.',
	documentoAssinadoSalvar: 'Não é possível salvar um anexo com um documento já assinado.',

	novoAnexoDocumentoNaoVigente: 'Apenas documentos com status "Vigente" permitem a inclusão de anexos.',

	identificacaoAnexo:
		'Tem como objetivo identificar de maneira simples e rápida um anexo. É possível informar qualquer valor que caracteriza um anexo em específico.',
	parteAnexo: 'Tem como objetivo identificar as entidades que fazem parte do anexo.',
	produtoAnexo: 'Tem como objetivo identificar quais produtos do sistema são citados no anexo',
	vigenciaInicioAnexo: 'Data inicial da vigência do anexo.',
	vigenciaFinalAnexo: 'Data final da vigência do anexo.',
	anexoValor: 'Valor total do anexo.',
	anexoValorMensal: 'Valor mensal do adtivo, caso for necessário.',
	documentoAnexo: 'Tem como objetivo identificar qual o documento vinculado ao anexo',

	anexoNaoPodeExcluir: 'Apenas é possível excluir um anexo com documento pendente ou arquivo adicionado',
};

export const INITIAL_VALUE = {
	id: null,
	identificacao: null,
	status: statusGestaoDocumento.PENDENTE,
	tipoGestaoDocumento: tipoGestaoDocumento.CONTRATO,
	vigenciaInicio: format(new Date(), 'yyyy-MM-dd'),
	vigenciaFinal: null,
	documentoValor: 0,
	documentoValorMensal: 0,
	anexoValor: 0,
	anexoValorMensal: 0,
	partes: [],
	produtos: [],
	arquivoDocumento: null,
	arquivos: [],
	anexos: [],
	arquivado: false,
	usuario: null,
	setor: null,
	diasAlertaVigencia: 0,
	pasta: null,

	// usado localmente para ser um espelho do checkbox nas configuracoes
	alertaPeriodoVigenciaEnabled: true,
};
