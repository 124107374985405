import {
  buscarConfiguracaoUsuario,
  configuracoesUsuario,
  construirUrl,
  permissoes,
  recursos,
  removerCaracteres,
  removerElemento,
  salvarConfiguracaoUsuario,
  services,
  usuarioPossuiPermissao,
} from 'Common';
import {
  Badge,
  Button,
  ButtonEditarTable,
  ButtonExcluirTable,
  ButtonNovo,
  Col,
  DescricaoFiltroAvancado,
  Form,
  FormActions,
  FormContent,
  Grid,
  InputSearch,
  NenhumRegistroEncontrado,
  Paginacao,
  PesquisaAvancada,
  Tutorial,
  tutorialStepsListagens,
} from 'components';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useCallback, useEffect, useRef, useState } from 'react';
import { asyncDeleteTributacaoMunicipal, asyncGetTributacoesMunicipal } from './Requests';
import { Checkbox } from 'primereact/checkbox';
import { ColorsTributacaoMunicipal, OptionsFiltroAvancado } from './Util/constantes';
import { confirmarExclusao } from 'views/Util/ExclusaoDeRegistros';
import { atualizarUrl } from 'views/Util';
import { Menu } from 'primereact/menu';
import { GoBookmark } from 'react-icons/go';
import { useContextPesquisa } from 'views/Util/Context/ContextPesquisa';

const styleButtonOptions = {
  borderRadius: '50%',
  padding: '5px',
  width: '30px',
  height: '30px',
  margin: '2px',
};

export function TributacaoMunicipal(props) {
  const { history } = props;

  const [registros, setRegistros] = useState([]);

  const {
    valorPesquisa,
    setValorPesquisa,
    sortField,
    setSortField,
    sortOrder,
    setSortOrder,
    page,
    setPage,
    rows,
    setRows,
    filtroAvancado,
    setFiltroAvancado,
    descricaoFiltroAvancado,
    setDescricaoFiltroAvancado,
    exibirBloqueadas,
    setExibirBloqueadas,
  } = useContextPesquisa();

  const [totalElements, setTotalElements] = useState(0);
  const [rowSelected, setRowSelected] = useState({});
  const [menu, setMenu] = useState(null);
  const [tutorialVisible, setTutorialVisible] = useState(false);
  const [deveExibirTutorial] = useState(buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS));

  const [podeInserir, PodeInserir] = useState(usuarioPossuiPermissao(recursos.TRIBUTACAO, permissoes.INSERIR));
  const [podeEditar] = useState(usuarioPossuiPermissao(recursos.TRIBUTACAO, permissoes.EDITAR));
  const [podeExcluir] = useState(usuarioPossuiPermissao(recursos.TRIBUTACAO, permissoes.EXCLUIR));

  const pesquisarCallback = useCallback(() => {
    pesquisar();
  });

  const itensMenu = montarItensMenu();

  useEffect(() => {
    if (deveExibirTutorial !== false) {
      setTutorialVisible(true);
      salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS, false, null, false);
    }

    setTimeout(() => {
      document.getElementById('InputSearchTributacaoMunListagem')?.focus();
    }, 500);
  }, []);

  useEffect(() => {
    pesquisarCallback();
  }, [exibirBloqueadas, page, rows, sortField, filtroAvancado]);

  async function pesquisar() {
    let filtro = buscarFiltro();
    filtro += String(`${exibirBloqueadas ? '' : ';situacao=="ATIVO"'}`);

    const url = construirUrl(
      `${services.GESTOR}/v1/tributacoes/municipal/resumo`,
      filtro,
      rows,
      page,
      sortOrder > 0 ? `${sortField},asc` : `${sortField},desc`
    );

    await asyncGetTributacoesMunicipal(url, ({ data: tributacao }) => {
      setRegistros(tributacao.content);
      setTotalElements(tributacao.page.totalElements);

      if (!exibirBloqueadas && !!filtroAvancado && filtroAvancado.includes('situacao=="INATIVO"')) {
        setExibirBloqueadas(true);
      }
    });
  }

  function onPesquisar() {
    pesquisar();
  }

  function buscarFiltro() {
    const pesquisaCodigo = removerCaracteres(valorPesquisa, ['.']);

    let result = `?query=(codigo=contains="*${pesquisaCodigo}*",nome=contains="*${valorPesquisa}*",situacao=contains="*${valorPesquisa}*")`;

    if (filtroAvancado) {
      result += `;${filtroAvancado}`;
    }

    return result;
  }

  async function onPesquisarFiltroAvancado(filtro) {
    setFiltroAvancado(filtro);
  }

  function handleClickEditar(row) {
    atualizarUrl(history, '/tributacoes/municipal/cadastro', row.id);
  }

  function handleClickExcluir(row) {
    confirmarExclusao(() => asyncExcluirRegistro(row));
  }

  async function asyncExcluirRegistro(registro) {
    await asyncDeleteTributacaoMunicipal(registro.id, () => {
      setRegistros(removerElemento(registros, registro));
      setTotalElements(totalElements - 1);
    });
  }

  function onPageChange(event) {
    setRows(event.rows);
    setPage(event.page);
  }

  function onSort(event) {
    setSortOrder(event.sortOrder);
    setSortField(event.sortField);
  }

  function onChangeCheckboxExibirInativas(element) {
    setExibirBloqueadas(element.checked);
  }

  function aplicarEstiloInativo(row, field) {
    if (row.situacao === 'INATIVO') {
      return <span style={{ fontStyle: 'italic', opacity: '0.7' }}>{field}</span>;
    }
    return field;
  }

  function renderNome(row) {
    return (
      <span
        style={{
          display: 'flex',
          wordBreak: 'break-word',
          maxWidth: props.isMobile ? '70%' : '100%',
          textAlign: props.isMobile ? 'end' : 'start',
        }}
      >
        {aplicarEstiloInativo(row, row.nome)}
        {row.favorita ? (
          <span>
            <GoBookmark size={22} color="#fbc02d" title="Tributação municipal favorita" />
          </span>
        ) : null}
      </span>
    );
  }

  function renderSituacao(row) {
    const styleBackground = {
      borderRadius: '20px',
      padding: '0.2rem 1.1rem',
    };

    const styleDescription = {
      fontStyle: row.situacao === 'INATIVO' ? 'italic' : '',
      opacity: row.situacao === 'INATIVO' ? '0.7' : '',
      margin: row.situacao === 'ATIVO' ? '3px 16px' : '3px 10px',
    };

    switch (row.situacao) {
      case 'ATIVO': {
        return Badge(
          ColorsTributacaoMunicipal.textAtivo,
          ColorsTributacaoMunicipal.bgAtivo,
          'Ativo',
          styleBackground,
          styleDescription
        );
      }
      case 'INATIVO': {
        return Badge(
          ColorsTributacaoMunicipal.textInativo,
          ColorsTributacaoMunicipal.bgInativo,
          'Inativo',
          styleBackground,
          styleDescription
        );
      }
      default:
        return row.situacao;
    }
  }

  function renderOpcoes(row) {
    return (
      <div style={{ display: 'flex' }}>
        <ButtonEditarTable onClick={() => handleClickEditar(row)} />
        <ButtonExcluirTable onClick={() => handleClickExcluir(row)} podeExcluir={podeExcluir} />
        <Button
          className="p-button-secondary"
          icon="fa fa-ellipsis-v"
          style={styleButtonOptions}
          title="Opções"
          disabled={row.situacao === 'INATIVO'}
          aria-controls="popup_menu"
          aria-haspopup={true}
          onClick={event => {
            setRowSelected(row);
            menu.toggle(event);
          }}
        />
      </div>
    );
  }

  function montarItensMenu() {
    let itens = [];
    itens.push({
      label: 'Duplicar',
      icon: 'pi pi-copy',
      command: () => {
        atualizarUrl(history, `/tributacoes/municipal/duplicar/${rowSelected.id}`);
      },
    });
    return itens;
  }

  return (
    <>
      <Tutorial
        steps={tutorialStepsListagens}
        showSkipButton
        continuous
        visible={tutorialVisible}
        onHide={() => setTutorialVisible(false)}
      />
      <Form header="Tributação municipal">
        <FormActions>
          <ButtonNovo
            className="step-listagem-novo"
            label="Nova tributação"
            onClick={() => history.push('/tributacoes/municipal/cadastro')}
          />
        </FormActions>
        <FormContent>
          <Grid justifyCenter>
            <InputSearch
              id="InputSearchTributacaoMunListagem"
              className="step-listagem-input-search"
              onPesquisar={() => onPesquisar()}
              value={valorPesquisa}
              onChange={value => setValorPesquisa(value)}
            />
            <Col sm="12" md="4" lg="3" xl="3" className="step-listagem-filtro-avancado">
              <PesquisaAvancada
                className="step-listagem-filtro-avancado"
                optionsFiltros={OptionsFiltroAvancado}
                onPesquisarClick={onPesquisarFiltroAvancado}
                onChangeFiltroRsql={rsql => setFiltroAvancado(rsql)}
                onChangeDescricaoFiltro={descricao => setDescricaoFiltroAvancado(descricao)}
              />
            </Col>
          </Grid>
          <DescricaoFiltroAvancado texto={descricaoFiltroAvancado} />
          <Menu model={itensMenu} popup style={{ width: '200px' }} ref={element => setMenu(element)} />
          <DataTable
            className="table"
            rowClassName="table-row"
            cellClassName="table-row-cell"
            responsive
            value={registros}
            sortField={sortField}
            sortOrder={sortOrder}
            onSort={onSort}
            emptyMessage={<NenhumRegistroEncontrado />}
            header={
              <span style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Checkbox
                  name="exibirBloqueadas"
                  inputId="exibirBloqueadas"
                  checked={exibirBloqueadas}
                  onChange={onChangeCheckboxExibirInativas}
                />
                <label htmlFor="exibirBloqueadas" className="p-checkbox-label">
                  Exibir tributações inativas
                </label>
              </span>
            }
          >
            <Column
              className="step-listagem-order"
              field="codigo"
              header="Código"
              body={row => aplicarEstiloInativo(row, row.codigo)}
              sortable
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                width: '130px',
              }}
            />
            <Column field="nome" header="Nome" body={row => renderNome(row)} sortable />
            <Column field="situacao" header="Situação" sortable body={renderSituacao} style={{ width: '15rem' }} />
            <Column className="step-listagem-acoes" header="Ações" body={renderOpcoes} style={{ width: '7em' }} />
          </DataTable>
          <Paginacao totalElements={totalElements} rows={rows} page={page} onPageChange={onPageChange} />
        </FormContent>
      </Form>
    </>
  );
}
